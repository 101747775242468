/*
 * Project:   PlotPad HTML5 Viewer
 * File:      rect.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/01/2013
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var mouse = subPixel('mouse');
    var parts = subPixel('parts');
    var drawOptHelper = parts('draw-options-helper');
    var drawOptObj = drawOptHelper({});


    var rect = function(x, y, w, h){
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.mousehere = false;

    };
    parts('rect', rect);

    var p = rect.prototype;

    var rou = 10;

    p.update = function(){
        if ((mouse.x > this.x - rou) && (mouse.x < this.x + rou) &&
            (mouse.y > this.y - rou) && (mouse.y < this.y + rou)) {
            var vx = mouse.x - this.x || 0;
            var vy = mouse.y - this.y || 0;
            //this.x = this.x + vx;
            //this.y = this.y + vy;
            this.mousehere = true;
            this.draw(drawOptObj);
        } else {
            this.mousehere = false;
        }

    };

    p.predraw = function(){
        this.draw(drawOptObj);
    };

    p.draw = function(){
        var ctx = canvas.ctx;

        this.mousehere ? ctx.fillStyle="#000000" : ctx.fillStyle="#888888";
        ctx.fillRect(this.x, this.y, this.w, this.h);
    }



})(subPixel);
