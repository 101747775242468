/*
 * Project:   PlotPad HTML5 Viewer
 * File:      helpers.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var utils = subPixel('utils');
    var sheetLoader = subPixel('sheet-loader');
    
    cafm.internal = {};
    var chunkManager = subPixel('chunk-manager');
    var processObjects = chunkManager.processObjects; 

    function processFiguresFromObjIds(objIds, returnFig){
        var toProcess = helpers.convertToArray(objIds);
        var map = {};
        utils.map(toProcess, function(id){
            map[id] = true;
        });

        var correctIds = [];
        processObjects(function(el) {
            if (el && el.objectId){
                if (map[el.objectId()]){
                    if (returnFig){
                        correctIds.push(el);
                    } else {
                        correctIds.push(el.getId());
                    }
                }
            }
        });
        map = null;
        toProcess = null;
        return correctIds;
    }

    var helpers = cafm.helpers = {
        convertToArray: function (args) {
            var len = args.length;
            if (len > 1) {
                return [].slice.call(args);
            } else if (len == 0) {
                return [];
            }
            var firstElem = args[0];
            return utils.isArray(firstElem) ? firstElem : [firstElem]
        },
        getManifestList: function (manifestField, itemField) {
            var ret = [];
            var manifest = sheetLoader.getManifestData();
            if (manifest){
                itemField = itemField || 'name';
                var list = manifest[manifestField];
                if (utils.isArray(list)){
                    utils.map(list, function (item) {
                        var val = item[itemField];
                        ret.push(val);
                    })
                }
            }
            return ret;
        },
        getFiguresIdsFromObjIds: function (objIds) {
            var ret = processFiguresFromObjIds(objIds, false);
            return ret;
        },
        getFiguresFromObjIds: function (objsIds, cb) {
            var figs = processFiguresFromObjIds(objsIds, true);
            cb && utils.map(figs, cb);
            return figs;
        },
        getObjIdsFromFiguresIds: function (figIds) {
            var toProcess = helpers.convertToArray(figIds);
            var ret = [];
            utils.map(toProcess, function (id) {
                var fig = subPixel.getElementById(id);
                if (fig && fig.objectId) {
                    var objId = fig.objectId();
                    ret.push(objId);
                }
            });
            toProcess = null;
            return ret;
        },
        getObjFromFigures: function (figs) {
            var toProcess = helpers.convertToArray(figs);
            var ret = [];
            utils.map(toProcess, function (el) {
                if (el && el.objectId) {
                    ret.push(el);
                }
            });
            toProcess = null;
            return ret;
        },
        getAllObjects: function (cb) {
            var objs = [];
            processObjects(function (el) {
                objs.push(el);
            });
            cb && utils.map(objs, cb);
            return objs;
        },
        filterFiguresByGroups: function (objs, groups) {
            var ret = [];
            if (!objs) {
                objs = helpers.getAllObjects();
            }
            if (utils.isArray(objs) && utils.isArray(groups)) {
                var map = {};
                utils.map(groups, function (group) {
                    map[group] = true;
                });

                utils.map(objs, function (obj) {
                    var grp = obj.customGroup();
                    if (map[grp]) {
                        ret.push(obj);
                    }
                });

                map = null;
            }
            return ret;
        }
    };

})(cafm);