/*
 * Project:   PlotPad HTML5 Viewer
 * File:      animate-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/16/2016
 */
(function (subPixel) {

    var utils = subPixel('utils');

    var animateFrame =
        window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        window.oRequestAnimationFrame      ||
        window.msRequestAnimationFrame     ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };

    // start and process animate
    //
    // draw - callback for process animation
    function doAnimate(params, draw){
        params = params || {};
        function render(){
            draw();
            !params.prevent && animateFrame(render);
        }
        setTimeout(function(){
            render();
        }, 0);
        return params;
    }

    function AnimateControlClass(cb) {
        this._renderParams = {
            cb: cb || function(){},
            started: false,
            prevent: true
        };
    }

    utils.extendClass(AnimateControlClass, {
        changeCb: function (cb) {
            this._renderParams.cb = cb || function () {};
        },
        startRender: function () {
            var renderParams = this._renderParams;
            if (!renderParams.started){
                renderParams.started = true;
                renderParams.prevent = false;
                doAnimate(renderParams, function(){
                    renderParams.started && renderParams.cb();
                });
            }
        },
        stopRender: function () {
            var renderParams = this._renderParams;
            renderParams.prevent = true;
            renderParams.started = false;
        }
    });

    subPixel('animate-ctrl-class', function (cb) {
        return new AnimateControlClass(cb);
    });

})(subPixel);
