/*
 * Project:   PlotPad HTML5 Viewer
 * File:      group-anno.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/03/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var utils = subPixel('utils');


    var base = tools('base-group');

    var groupAnnoClass = utils.createChildClass(base, 'groupAnnoClass');

    var p = groupAnnoClass.prototype;

    // register tool in toolbox
    tools(groupAnnoClass, {
        name: 'anno',
        visible: true
    });

})(subPixel);
