/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/07/2013
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var scale = subPixel('scale');
    var parts = subPixel('parts');
    var utils = subPixel('utils');
    var selectionHelper = subPixel('selection-helper');
    var layerManager = subPixel('layer-manager');
    var crossingHelper = parts('crossing-helper');
    var canvasBbox = canvas.bbox;

    var drawOptHelper = parts('draw-options-helper');
    var drawOptObj = drawOptHelper({});

    var CONST_DEFAULT_GROUP_LAYER = layerManager.CONST_DEFAULT_GROUP_LAYER;

    function BasePartClass(data, params){
        this._idata = data;

        params = params || {};
        this.prepareParams(params);
        this.initBase(data, params);
        this.init(data, params);
    }

    var p = BasePartClass.prototype;

    var ACCESS_DRAW = BasePartClass.A_DRAW = 'd';
    var ACCESS_EDIT_MODE = BasePartClass.A_EDIT_MODE = 'e';
    var ACCESS_SELECT = BasePartClass.A_SELECT = 's';

    p.prepareParams = function(){
        //it's a cap
    };

    p.initBase = function(data, params){
        var id = data.id;
        if (!id){
            id = utils.guid();
            this.setId(id);
        }

        // bbox of figure in normal view
        this._bbox = {};

        // bbox of figure in screen(display) view
        this._scbox = {
            minx: 0,
            maxx: 0,
            miny: 0,
            maxy: 0,
            w: 0,
            h: 0
        };

        var layerName = params.layerName;
        layerName = layerName || layerManager.CONST_UNDEFINED_LAYER;
        this.props = {
            //:todo move props to class props
            pointType: crossingHelper.TYPE_POLY, // default type
            hover: (params.hover !== false),
            priorityHover: params.priorityHover || 0,
            drawSelection: (params.drawSelection === undefined) ? false : params.drawSelection,
            editing: (params.editing === true),
            scaling: (params.scaling === true),
            rotating: (params.rotating === true),
            editingMode: 'box-mode', // default type

            // props for single instance
            index: data.hasOwnProperty('index') ? data.index : -1,
            layerGroup: params.layerGroup || CONST_DEFAULT_GROUP_LAYER,
            layerName: layerName,
            layerNameLower: layerName.toLowerCase(),
            visible: true
        };

        var acc = this._ac = {};
        acc[ACCESS_DRAW] = '1'; // true
        acc[ACCESS_EDIT_MODE] = ''; // false
        acc[ACCESS_SELECT] = ''; // false

        this._drawn = '';
        this._helpPoints = [];
        this._rotationAngle = data.hasOwnProperty('angle') ? data.angle : 0,
        this.drawParams = {
            filled: '',
            lineJoin: 'miter', // default value
            fillStyle: null,
            color: null
        };
    };

    p.init = function(){
        //it's a cap
    };

    p.data = function(){
        return this._idata;
    };

    p.setId = function(val){
        this._idata.id = val;
    };

    p.getId = function(){
        return this._idata.id;
    };

    p.getObjectId = function(){
        return this._idata.id;
    };

    // update bounding box by defined points
    // points is array with objects elements like {x: 0, y: 0}
    p.initBoundingBox = function(points){
        var bbox = this._bbox;
        utils.createBboxSctruct(bbox);
        updateBboxByPoints(bbox, points);

        canvas.processModelBbox(bbox);

        //:todo change ._helpPoints to correct points of figure to detect if mouse is here or not.
        this._helpPoints.length = 0;
        this._helpPoints = null;
        this._helpPoints = points;

        this.rotateHelpPoints();
    };

    p.canDraw = function(){
        return this.props.visible && this._ac[ACCESS_DRAW];
    };

    // returns object was redraw or not
    p.predraw = function(forceDraw){
        if (!this.canDraw()){
            return false;
        }

        var inDrawArea = getDrawAreaCode.call(this);
        var res = false;

        // view area means, that part of object must be drawn, not all object
        // draw area - means, that object located all in canvas

        if (inDrawArea == 20){
            //in draw area
            //not view area
            res = true;
            this._drawn = '';
        } else if (inDrawArea == 21) {
            //in draw area
            //in view area
            res = true;
        } else if (inDrawArea == 10) {
            // out of area!!! deep space
            //not draw area
            //not view area
            this._drawn = '';
            res = false;
        } else if (inDrawArea == 11) {
            //not draw area
            //in view area
            this._drawn = '';
            res = false;
        }


        if (!forceDraw && this._drawn){
            res = false;
        }
        if (res){
            this.draw(drawOptObj);
//            this.drawSelection();

            // set ._drawn flag only for completed drawn objects (drawn all bounding box)
            (inDrawArea == 21) && (this._drawn = '1');
        }
        return res;
    };

    p.draw = function(){
        //it's a cap
    };

    p.canSelect = function(){
        return !!this.canDraw() && this.props.drawSelection;
    };

    p.inSelectionArea = function(boxClip){
        if (!this.canDraw()){
            return false;
        }

        var res = false;

        //before check figure in points area, check bbox
        if (this.canSelect()){
            res = crossingHelper.detectCrossSelection.call(this, boxClip);
        }
        return res;
    };

    p.inHoverArea = function(boxClip){
        var res = false;

        var hover = this.props.hover;
        if (hover && this.canDraw()){
            res = crossingHelper.detectCrossHover.call(this, boxClip);
        }
        return res;
    };

    p.getHelpPoints = function(){
        return this._helpPoints;
    };

    p.rotationAngle = function(val){
        if (val !== undefined){
            this._rotationAngle = val;
        }
        return this._rotationAngle;
    };

    p.onHover = function(){
        //it's a cap
    };

    p.getBoundingBox = function(){
        return this._bbox;
    };

    p.isAccessSelect = function(){
        return this._ac[ACCESS_SELECT];
    };

    p.isAccessEdit = function () {
        return this._ac[ACCESS_EDIT_MODE];
    };

    p.drawSelection = function(){
        if (this.canDraw()){
            selectionHelper.draw(this);
        }
    };

    p.updateFigure = function(data){
        //it's a cap
    };

    p.getScreenBounds = function(){
        updateScreenBbox.call(this);
        return this._scbox;
    };

    p.canEdit = function(){
        return this.props.editing;
    };

    p.canScale = function(){
        return this.props.scaling;
    };

    p.canRotate = function(){
        return this.props.rotating;
    };

    p.getDrawParams = function(){
        return this.drawParams;
    };

    p.dropDrawn = function(){
        this._drawn = '';
    };

    p.propEditingMode = function(val){
        if (val !== undefined){
            this.props.editingMode = val;
        }
        return this.props.editingMode;
    };

    p.propDrawSelection = function(val){
        if (val !== undefined){
            this.props.drawSelection = val;
        }
        return this.props.drawSelection;
    };

    p.propPointsType = function(val){
        if (val !== undefined){
            this.props.pointType = val;
        }
        return this.props.pointType;
    };

    p.propVisible = function(val){
        if (val !== undefined){
            this.props.visible = val;
        }
        return this.props.visible;
    };

    p.propLayerName = function(val){
        if (val !== undefined){
            this.props.layerName = val;
            this.props.layerNameLower = val.toLowerCase();
        }
        return this.props.layerNameLower;
    };

    p.propLayerNameFull = function(){
        return this.props.layerName;
    };

    p.propPriorityHover =function () {
        return this.props.priorityHover;
    };

    p.propLayerGroup = function(val){
        if (val !== undefined){
            this.props.layerGroup = val;
        }
        return this.props.layerGroup;
    };

    p.propIndex = function(val){
        if (val !== undefined){
            this.props.index = val;
        }
        return this.props.index;
    };

    p.destroy = function(){
        this._bbox = null;
        this._drawn = '';
        this._helpPoints.length = 0;
        this._helpPoints = null;
        this._scbox = null;
        this._idata = null;
        this._ac = null;

        this.drawParams = null;
        this.props = null;
    };

    p.setAccess = function(key, val){
        var access = this._ac;
        if (access.hasOwnProperty(key)){
            access[key] = val;
        }
    };

    p.partType = function(){
        //cap
    };

    p.rotateHelpPoints = function(){
        var center = {
                x: this._bbox.minx + this._bbox.w / 2,
                y: this._bbox.miny + this._bbox.h / 2
            };
        var angle = this._rotationAngle;
        for (var i = 0; i < this._helpPoints.length; i++) {
            this._helpPoints[i] = rotatePoint(this._helpPoints[i], center, angle);
        }
    };

    function getDrawAreaCode(){
        var bbox = this.getBoundingBox();

        var inDrawArea = (bbox.maxx >= canvasBbox.minx) && (bbox.minx <= canvasBbox.maxx) &&
        (bbox.maxy >= canvasBbox.miny) && (bbox.miny <= canvasBbox.maxy) ?
            20 : // is in drawn area
            10; // not


        var inFlowArea = (bbox.minx > canvasBbox.minx) && (bbox.maxx < canvasBbox.maxx) &&
        (bbox.miny > canvasBbox.miny) && (bbox.maxy < canvasBbox.maxy) ?
            1 : // is in view area
            0; // not in view

        return inDrawArea + inFlowArea;
    }

    function updateBboxByPoints(bbox, points){
        var point;
        for (var i = 0, l = points.length; i < l; i++){
            point = points[i];
            (bbox.minx > point.x) && (bbox.minx = point.x);
            (bbox.maxx < point.x) && (bbox.maxx = point.x);

            (bbox.miny > point.y) && (bbox.miny = point.y);
            (bbox.maxy < point.y) && (bbox.maxy = point.y);
        }
        bbox.w = bbox.maxx - bbox.minx;
        bbox.h = bbox.maxy - bbox.miny;
    }

    function updateScreenBbox(){
        var bbox = this.getBoundingBox();
        var screenBbox = this._scbox;
        screenBbox.w = bbox.w * scale.val;
        screenBbox.h = bbox.h * scale.val;
        var p1 = canvas.getRetransformedPoint(bbox.minx, bbox.miny);
        screenBbox.minx = p1.x;
        screenBbox.miny = p1.y;
        p1 = null;

        var p2 = canvas.getRetransformedPoint(bbox.maxx, bbox.maxy);
        screenBbox.maxx = p2.x;
        screenBbox.maxy = p2.y;
        p2 = null;
    }

    function rotatePoint(point, center, angle) {
        var dx = point.x - center.x;
        var dy = point.y - center.y;

        var newX = center.x + dx * Math.cos(-angle) - dy * Math.sin(-angle);
        var newY = center.y + dx * Math.sin(-angle) + dy * Math.cos(-angle);

        return {
            x: newX,
            y: newY
        };
    }

    // define methods to class, NOT to prototype
    utils.extend(BasePartClass, {
        updateBboxByPoints: updateBboxByPoints,
        setFillStyle: function (ctx, drawParams, opt) {
            if (drawParams.filled || opt.fillStyle){
                ctx.fillStyle = opt.fillStyle
                    ? opt.fillStyle
                    : drawParams.fillStyle;

                return true;
            }
        },
        getLineWidth: function (drawParams, opt) {
            var lineWidth = scale.lineWidth;

            if (opt.lineRatio){
                lineWidth = lineWidth * opt.lineRatio;
            }
            if (drawParams.lineWidth){
                lineWidth = lineWidth * drawParams.lineWidth;
            }
            return lineWidth;
        },
        getLineJoin: function (drawParams, opt) {
            return opt.lineJoin
                    ? opt.lineJoin
                    : drawParams.lineJoin;
        },
        getStrokeStyle: function (drawParams, opt) {
            return opt.strokeStyle
                    ? opt.strokeStyle
                    : drawParams.strokeStyle;

        }
    });

    parts('base', BasePartClass);

})(subPixel);
