/*
 * Project:   PlotPad HTML5 Viewer
 * File:      crossing-type-in-sel-box.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){

    var utils = subPixel('utils');
    var parts = subPixel('parts');
    var getDistance = utils.getDistance;
    var crossingHelper = parts('crossing-helper');


    function isPointInCircle(fig, clip){
        var p1 = fig.getCenterPoint();
        var distance = getDistance(p1.x, clip.x, p1.y, clip.y);
        var radius = fig.getRadius();
        var ret = false;
        if (distance <= radius) {
            ret = true;
        }
        return ret;
    }

    function isPointInPoly(poly, point){
        var pol, pol_j;
        for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i) {
            (pol_j = poly[j]) &&
            (pol = poly[i]) &&
            ((pol.y <= point.y && point.y < pol_j.y) || (pol_j.y <= point.y && point.y < pol.y))
            && (point.x < (pol_j.x - pol.x) * (point.y - pol.y) / (pol_j.y - pol.y) + pol.x)
            && (c = !c);
        }
        return c;
    }


    var crossingTypesInBox = (function () {
        var ret = {};
        ret[crossingHelper.TYPE_CIRCLE] = function(fig, clip){
            return isPointInCircle(fig, clip);
        };

        ret[crossingHelper.TYPE_LINE] =
        ret[crossingHelper.TYPE_BOX] =
        ret[crossingHelper.TYPE_CROSS] =
        ret[crossingHelper.TYPE_TEXT] = function(fig, clip){
            var ret = true;
            return ret;
        };

        ret[crossingHelper.TYPE_POLY] = function(fig, clip){
            var figPoints = fig.getHelpPoints();
            return isPointInPoly(figPoints, clip);
        };

        ret[crossingHelper.TYPE_DEFAULT] = ret[crossingHelper.TYPE_POLY];
        return ret;
    })();

    function forSelect(fig, bbox, boxClip) {
        var res = false;
        if (boxClip.isSinglePoint !== false) {
            // single click to element
            res = crossingHelper.getCrossByType(fig, boxClip, crossingTypesInBox);
        } else if (crossingHelper.isBoxInBox(bbox, boxClip)) {
            // used rect area for select
            res = true;
        }
        return res;
    }

    function forHover(fig, bbox, boxClip) {
        var res = crossingHelper.getCrossByType(fig, boxClip, crossingTypesInBox);
        return res;
    }

    crossingHelper.registerDetectType('in-selection-box', {
        forSelect: forSelect,
        forHover: forHover,
        crossTypes: crossingTypesInBox
    });

})(subPixel);
