/*
 * Project:   PlotPad HTML5 Viewer
 * File:      image.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 00/25/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var basePart = parts('base');
    var utils = subPixel('utils');
    var canvasEl = subPixel('canvas-el');
    var config = subPixel('config');
    var logger = subPixel('logger')('image-part');

    var imageFigure = utils.createChildClass(basePart, 'imageFigureClass');

    var p = imageFigure.prototype;

    /**
     * Detecting vertical squash in loaded image.
     * Fixes a bug which squash image vertically while drawing into canvas for some images.
     * This is a bug in iOS6 devices. This function from https://github.com/stomita/ios-imagefile-megapixel
     *
     */
    var canvEl = canvasEl();
    var buffCanvas = canvEl.getEl();
    var bctx = canvEl.getCtx();
    function detectVerticalSquash(img) {
        var iw = img.naturalWidth, ih = img.naturalHeight;

        if (!iw || !ih){
            return 1;
        }

        try {

            buffCanvas.width = 1;
            buffCanvas.height = ih;
            bctx.clearRect(0, 0, buffCanvas.width, buffCanvas.height);
            bctx.drawImage(img, 0, 0);
            var ratio = 0;

            var data = bctx.getImageData(0, 0, 1, ih).data;
            // search image edge pixel position in case it is squashed vertically.
            var sy = 0;
            var ey = ih;
            var py = ih;
            while (py > sy) {
                var alpha = data[(py - 1) * 4 + 3];
                if (alpha === 0) {
                    ey = py;
                } else {
                    sy = py;
                }
                py = (ey + sy) >> 1;
            }
            ratio = (py / ih);
        } catch(e){
            logger.error("Image can't detect IMAGE object correctly:", img.src, e.message);
            ratio = 1;
        }

        return (ratio===0) ? 1 : ratio;
    }

    p.init = function(data, params){
        var bounds = data.bounds;
        var angle = this.rotationAngle();
        var obj = this.object = {
            vratio: 1,
            angle: angle,
            image: null,
            imgw: 0,
            imgh: 0,
            x: bounds['left-top'].x,
            y: bounds['left-top'].y,
            w: bounds['right-bottom'].x - bounds['left-top'].x,
            h: bounds['right-bottom'].y - bounds['left-top'].y
        };
        var image = params.image || data.image;

        this.setImage(image, obj);

        var points = [
            {x: obj.x, y: obj.y},
            {x: obj.x + obj.w, y: obj.y},
            {x: obj.x + obj.w, y: obj.y + obj.h},
            {x: obj.x, y: obj.y + obj.h}
        ];

        this.initBoundingBox(points);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var obj = this.object;

        ctx.save();
        canvas.rotateAroundPoint(ctx, obj.angle, obj.x + obj.w / 2, obj.y + obj.h / 2);
        this.drawImage(ctx, obj);
        ctx.restore();

        if(opt.isSelection) {
            ctx.fillStyle = config.selectionStrokeColor;
            ctx.beginPath();
            var rx = obj.w / 2;
            var ry = obj.h / 2;

            var setR = (rx > ry) ? rx : ry;

            var x = obj.x + rx;
            var y = obj.y + ry;

            ctx.arc(x, y, setR * 3/2, 2 * Math.PI, false);
            ctx.fill();
            ctx.closePath();
        }
    };

    p.setImage = function(img, data){
        data.image = img;
        data.vratio = detectVerticalSquash(img);
        data.imgw = img.width; // IE 11 doesn't know naturalWidth, naturalHeight
        data.imgh = img.height;
    };

    p.drawImage = function(ctx, data){
        this.setImage(data.image, data);

        ctx.drawImage(data.image,
            0, 0,
            data.imgw, data.imgh,
            data.x, data.y,
            data.w, (data.h) / data.vratio);
    };

    parts('image', imageFigure);


})(subPixel);
