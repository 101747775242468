/*
 * Project:   PlotPad HTML5 Viewer
 * File:      sheet-requester.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/15/2016
 */
(function(subPixel, undefined) {
    var http = subPixel('requester');
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');

    var sheetRequesterEvs = broadcast.events('sheet-requester', {
        _onAllLoaded: 'oal'
    });

    var MAX_REQUESTS = 5;

    var PROGRESS_K = 100;

    var DEFAULT_RESEND = 3;

    var sheetLoaderRequester = {
        progressData: {
            total: 0,
            loaded: 0,
            percent: 0,
            currents: {}
        },
        reqId: 0,
        queue: [],
        urlMap: {},
        requests: [],
        onAllLoaded: function (cb) {
            broadcast.one(sheetRequesterEvs._onAllLoaded, cb);
        },
        onSheetProgress: function (cb) {
            this.reqId = 0;
            this._progressCb = cb;
        },
        startRequests: function () {
            var pd = this.progressData;
            pd.total = (this.queue.length + this.requests.length) * PROGRESS_K;
            pd.loaded = 0;
            pd.percent = 0;
            utils.clearObject(pd.currents);
            for (var i = 0; i < MAX_REQUESTS; i++){
                this.next();
            }
        },
        stopProgress: function () {
            this._progressCb = null;
        },
        isLoaded: function () {
            var isLoaded = (this.queue.length == 0 && this.requests.length == 0);
            return isLoaded;
        },
        dropCbs: function () {
            this.stopProgress();
            broadcast.off(sheetRequesterEvs._onAllLoaded);
        },
        abortGets: function (fileType) {
            var requests = this.requests;
            var urlMap = this.urlMap;
            for (var i = requests.length - 1; i >= 0; i--){
                var item = requests[i];
                if (!fileType || (item.fileType == fileType)){
                    urlMap[item.url] = null;
                    delete urlMap[item.url];
                    item.stop();
                    requests.splice(i, 1);
                }
            }
            if (requests.length == 0){
                requests.error = false;
                requests.code = 0;
                this.reqId = 0;
            }
            var queue = this.queue;
            for (var i = queue.length - 1; i >= 0; i--){
                var item = queue[i];
                if (!fileType || (item.fileType == fileType)){
                    urlMap[item.url] = null;
                    delete urlMap[item.url];
                    queue.splice(i, 1);
                }
            }
            if ((requests.length + queue.length) != 0){
                this.next();
            }
        },
        getJson: function (url, onDone) {
            var binded = true;
            if (this.urlMap[url]) {
                // already trying to get data, just skip...
                // no need to call onDone, because prev onDone will do the same actions
                binded = false;
                return binded;
            }

            var self = this;
            var requests = this.requests;
            this.urlMap[url] = true;
            this.reqId ++;
            var reqId = this.reqId;
            var fileType = onDone && onDone.fileType;
            var item = function(){
                var reqParams = (typeof onDone != "function")
                    ? onDone
                    : {onDone : onDone};

                var pushRequest = true;

                var req = http.getJSON({
                    url: url,
                    resend: reqParams.resend || DEFAULT_RESEND,
                    onProgress: function (total, loaded) {
                        onProgress.call(self, reqId, reqParams, total, loaded);
                    },
                    onComplete: function(error, code, data, xhr){
                        onProgress.call(self, reqId, reqParams, PROGRESS_K, PROGRESS_K);
                        self.urlMap[url] = null;
                        delete self.urlMap[url];
                        pushRequest = false;
                        var pos = requests.indexOf(req);
                        (pos != -1) && (requests.splice(pos, 1));

                        requests.error = error;
                        requests.code = code;

                        reqParams.onDone && reqParams.onDone(error, data);
                        // after all done, next() again
                        self.next();
                    }
                });

                req.fileType = fileType;
                req.url = url;

                // onComplete can be called before request start, soo need this check
                pushRequest && requests.push(req);
                self.next();
            };

            item.fileType = fileType;
            item.url = url;

            this.queue.push(item);
            return binded;
        },
        next: function () {
            var requests = this.requests;
            var queue = this.queue;
            if (this.isLoaded()){
                broadcast.trig(sheetRequesterEvs._onAllLoaded, requests.error, requests.code);
                requests.error = false;
                requests.code = 0;
            } else if (requests.length <= MAX_REQUESTS){
                // getting first element, and run it
                var item = queue.shift();
                item && item();
            }
        }
    };

    function onProgress(reqId, reqParams, total, loaded) {
        collectProgressData.call(this, reqId, total, loaded);
        this._progressCb && this._progressCb(this.progressData);
        reqParams.onProgress && reqParams.onProgress(this.progressData);
    }

    function collectProgressData(reqId, total, loaded) {
        var pd = this.progressData;
        var currs = pd.currents;

        // process current
        var current = currs[reqId];
        var doned = getDoned(total, loaded);
        if (current){
            current.total = total;
            current.loaded = loaded;
            current.done = doned;
        } else {
            currs[reqId] = {
                total: total,
                loaded: loaded,
                done: doned
            }
        }

        // calculate all
        var allDoned = 0;
        for (var key in currs){
            allDoned += currs[key].done;
        }
        pd.loaded = allDoned;
    }

    function getDoned(total, loaded) {
        var doned = (loaded / total) * PROGRESS_K;
        return doned;
    }

    subPixel('sheet-requester', sheetLoaderRequester);

})(subPixel);
