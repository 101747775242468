/*
 * Project:   PlotPad HTML5 Viewer
 * File:      keyboard.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/03/2014
 */
(function(subPixel){
    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var keysEvs = broadcast.events('keyboard', {
        press: 'press',
        down: 'down',
        up: 'up'
    });

    var keyboard = {
        keyCode: null,
        which: null,
        charCode: null,
        isShift: false,
        isAlt: false,
        isCtrl: false // also meta key for imac
    };

    function processSpecialKeys(ev){
        keyboard.isShift = ev.shiftKey;
        keyboard.isAlt = ev.altKey;
        keyboard.isCtrl = ev.ctrlKey || ev.metaKey; // :todo add meta key detect

        keyboard.keyCode = ev.keyCode;
        keyboard.which = ev.which;
        keyboard.charCode = ev.charCode;
    }

    function trig(evName, params){
        broadcast.trig(evName, params);
    }

    function onKeyPress(ev){
        trig(keysEvs.press, keyboard);
    }

    function onKeyUp(ev){
        processSpecialKeys(ev);
        trig(keysEvs.up, keyboard);
    }

    function onKeyDown(ev){
        processSpecialKeys(ev);
        trig(keysEvs.down, keyboard);
    }


    function start(){
        try{
            getParentDocument(window, function (curr) {
                bindEv(curr);
            });
        } catch(e){}
    }

    function stop(){
        try{
            getParentDocument(window, function (curr) {
                bindEv(curr, true);
            });
        } catch(e){}

    }

    function getParentDocument(currWindow, cb) {
        var ret = currWindow.document;
        if (currWindow.parent && currWindow != currWindow.parent){
            cb && cb(ret);
            ret = getParentDocument(currWindow.parent, cb);
        }
        return ret;
    }

    function bindEv(el, isRemove) {
        var action = 'removeEventListener';
        if (!isRemove){
            action = 'addEventListener';
        }
        el[action]('keypress', onKeyPress, false);
        el[action]('keyup', onKeyUp, false);
        el[action]('keydown', onKeyDown, false);
    }

    utils.bindStartStop(start, stop);

    subPixel('keyboard', keyboard);

})(subPixel);
