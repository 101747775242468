// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Project:   PlotPad HTML5 Viewer
 * File:      subpixel-editor.css
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */

/*editor*/
.subpixel .edit-shapes {
    z-index: 2;
}

.subpixel .edit-toolbox {
    position: absolute;
    top: 5%;
    right: 10px;
    border: 1px solid gray;
    width: 50px;
    z-index: 2;
}

.subpixel .edit-toolbox .active {
    background: gray;
}

.edit-toolbox .tool-el,
.edit-toolbox .toolbox-group {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 40px;
    border: 1px solid silver;
    cursor: pointer;
    text-align: center;
}
.edit-toolbox .tool-el-color-picker {
    position: absolute;
    display: none;
    background: gray;
}

.area-canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.edit-toolbox .tool-el-scales {
    display: none;
    position: absolute;
    white-space: nowrap;
}

.edit-toolbox .tool-el-scales .item {
    display: inline-block;
}

.edit-toolbox .tool-el-sizes {
    display: none;
    position: absolute;
    white-space: nowrap;
}
.edit-toolbox .tool-el-sizes .item {
    display: inline-block;
}

.edit-toolbox .group-container {
    position: absolute;
    white-space: nowrap;
    display: none;
    top: 0;
    right: 40px;
}
.edit-toolbox .group-overflow {
}

.toolbox-first-container,
.toolbox-sub-container {
    position: relative;
}

.subpixel-text-anno-helper {
    position: absolute;
    right: 0;
    border: none;
    outline: none !important;
    z-index: 1;
    padding: 0;
    margin: 0;
    left: -9000px;
    top: -9000px;
    height: 30px;
    background: transparent;
}
.subpixel-text-anno-helper-input {
    background: transparent;
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
    font: normal 14px Arial;
    padding: 0;
    margin: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
