/*
 * Project:   PlotPad HTML5 Viewer
 * File:      polygon-mode.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/25/2017
 */
(function(subPixel){

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var canvas = subPixel('canvas');
    var scale = subPixel('scale');
    var area = editor('area');
    var modes = editor('editing-modes');
    var base = modes('base');

    var CONST_90_DEGREE = 90 * Math.PI / 180;

    var boxMode = utils.createChildClass(base, 'boxModeClass', {
        init: function() {
            this.drawBbox = {
                points: [],
                mx: 0,
                my: 0,
                x: 0,
                y: 0,
                w: 0,
                h: 0,
                initPoints: [],
                drawPoints: [],
                initDrawPoints: []
            };
            this.groupREditing = {
                x : 0,
                y: 0
            }
        },
        updateDrawBox: function() {
            if (this.currFig){
                this.groupEditing.x = mouse.x;
                this.groupEditing.y = mouse.y;

                var rPoint = rotatePoint(mouse.x, mouse.y, this.rotation.translateX, this.rotation.translateY, -this.rotation.angle);
                this.groupREditing.x = rPoint.x;
                this.groupREditing.y = rPoint.y;

                var bbox = this.currFig.getScreenBounds();

                this.drawBbox.x = bbox.minx;
                this.drawBbox.y = bbox.miny;
                this.drawBbox.w = bbox.w;
                this.drawBbox.h = bbox.h;

                updateDrawRegions.call(this);
                // if (this.drawBbox.drawPoints.length !== 0) {
                //     this.drawBbox.initDrawPoints = getPoints(this.drawBbox.drawPoints);
                // }
                this.updateRegions(this.drawBbox.x, this.drawBbox.y, this.drawBbox.points);
            }
        },
        updateRegions: function(px, py, points) {
            var x;
            var y;

            this.hoverRegions = {};

            if (this.currFig.canRotate()) {
                var xx = px + this.drawBbox.w / 2;
                var yy = py - this.regionMargin;

                var reg0 = rotatePoint(xx, yy, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);

                this.updateSingleReg(0, reg0.x, reg0.y, this.regionSize, this.regionSize);
            } else if(this.hoverRegions[0]) {
                delete this.hoverRegions[0];
            }

            for (var i = 0; i < points.length - 1; i++){
                x = points[i].x;
                y = points[i].y;
                var reg = rotatePoint(x, y, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);
                this.updateSingleReg(i + 1, reg.x, reg.y, this.regionSize, this.regionSize);
            }
        },
        calculateHelpers: function () {
            var x = mouse.x;
            var y = mouse.y;

            var dx = x - this.groupEditing.x;
            var dy = y - this.groupEditing.y;

            var drawPoints = this.drawBbox.points;

            if (this.isRotating) {
                var figureCenterX = this.rotation.translateX = this.drawBbox.x + this.drawBbox.w / 2;
                var figureCenterY = this.rotation.translateY = this.drawBbox.y + this.drawBbox.h / 2;
                var rx = x - figureCenterX;
                var ry = y - figureCenterY;
                this.rotation.angle = Math.atan2(-ry, rx) - CONST_90_DEGREE;
            } else if(this.isSizing) {
                this.groupEditing.dx = 0;
                this.groupEditing.dy = 0;

                var point;

                point = drawPoints[this.regionsPos - 1];
                var mousePoint = rotatePoint(x, y, this.rotation.translateX, this.rotation.translateY, -this.rotation.angle);
                point.x = mousePoint.x;
                point.y = mousePoint.y;
                if (this.regionsPos == 1) {
                    this.drawBbox.drawPoints[this.regionsPos - 1] = drawPoints[this.regionsPos - 1] = point;
                    this.drawBbox.drawPoints[drawPoints.length - 1] = drawPoints[drawPoints.length - 1] = point;
                } else {
                    this.drawBbox.drawPoints[this.regionsPos - 1] = drawPoints[this.regionsPos - 1] = point;
                }
                updateDrawBoxParams.call(this, drawPoints);
                point = null;
            } else {
                this.groupEditing.dx = dx;
                this.groupEditing.dy = dy;

                var rMouse = rotatePoint(x, y, this.rotation.translateX, this.rotation.translateY, -this.rotation.angle);
                var rdx = rMouse.x - this.groupREditing.x;
                var rdy = rMouse.y - this.groupREditing.y;

                var initPoint;
                for (var i = 0; i < drawPoints.length; i++) {
                    initPoint = this.drawBbox.initPoints[i];
                    this.drawBbox.points[i] = { x: initPoint.x + dx, y: initPoint.y + dy };
                    // this.drawBbox.drawPoints[i] = { x: this.drawBbox.initDrawPoints[i].x + rdx, y: this.drawBbox.initDrawPoints[i].y + rdy };
                    this.drawBbox.drawPoints[i] = { x: initPoint.x + rdx, y: initPoint.y + rdy };
                }
                initPoint = null;
            }
            this.updateRegions(this.drawBbox.x, this.drawBbox.y, this.drawBbox.points);
        },
        draw: function (canDrawFigure) {
            if (this.working && this.mouseClicked && this.currFig){
                var groupX = this.groupEditing.ex = this.drawBbox.x + this.groupEditing.dx;
                var groupY = this.groupEditing.ey = this.drawBbox.y + this.groupEditing.dy;

                var translateX = this.rotation.translateX;
                var translateY = this.rotation.translateY;
                var angle = this.rotation.angle;

                var centerX = translateX - this.drawBbox.w / 2;
                var centerY = translateY - this.drawBbox.h / 2;
                var point = rotatePoint(groupX, groupY, centerX, centerY, -angle);

                this.clearDraw();
                var ctx = area.ctx;
                ctx.save();

                // drawPolygon(ctx, this.drawBbox.initPoints);
                // rotate canvas
                canvas.rotateAroundPoint(ctx, angle, translateX, translateY);

                // draw polygon
                if (canDrawFigure !== false){
                    this.currFig.drawEditing && this.currFig.drawEditing(ctx, this.drawBbox.drawPoints);
                }

                ctx.lineWidth = scale.defLineWidth;
                ctx.strokeStyle = "rgb(0,0,0)";
                ctx.fillStyle = 'rgba(0,0,0, 0.5)';

                if (this.currFig.canRotate()) {
                    // rotate canvas
                    ctx.beginPath();
                    this.drawBoundingBoxRect(ctx, point.x, point.y);
                    ctx.closePath();
                }
                // draw polygon bounding box
                drawPolygon(ctx, this.drawBbox.drawPoints);
                this.updateRegions(point.x, point.y, this.drawBbox.drawPoints);

                // draw regions
                ctx.beginPath();
                // draw regions without rotation, because it's not necessary
                canvas.rotateAroundPoint(ctx, -angle, translateX, translateY);
                this.drawRegions(ctx);
                canvas.rotateAroundPoint(ctx, angle, translateX, translateY);

                ctx.stroke();
                ctx.fill();
                ctx.closePath();

                ctx.restore();
                this.updateClearBox();
            }
        },
        setDimensions: function () {
            transformDrawBoxPoints.call(this);
            var angle = this.rotation.angle;
            this.currFig.setDimensions && this.currFig.setDimensions(this.drawBbox.points, angle);
        }
    });

    // function getPoints(points) {
    //     var newPoints = [];
    //
    //     for (var i = 0; i < points.length; i++) {
    //         newPoints[i] = points[i];
    //     }
    //
    //     return newPoints;
    // }

    function updateDrawRegions() {
        // var helpPoints = this.currFig.getHelpPoints();
        var points = this.currFig.data().points;

        this.drawBbox.points = [];
        this.drawBbox.points.length = 0;

        for (var i = 0; i < points.length; i++){
            var point = canvas.getRetransformedPoint(points[i].x, points[i].y);
            this.drawBbox.initDrawPoints[i] = this.drawBbox.points[i] = this.drawBbox.initPoints[i] = { x: point.x, y: point.y };
        }
    }

    function updateDrawBoxParams(points) {
        var point;
        for (var i = 0, l = points.length; i < l; i++){
            point = points[i];
            (this.drawBbox.x > point.x) && (this.drawBbox.x = point.x);
            (this.drawBbox.mx < point.x) && (this.drawBbox.mx = point.x);

            (this.drawBbox.y > point.y) && (this.drawBbox.y = point.y);
            (this.drawBbox.my < point.y) && (this.drawBbox.my = point.y);
        }
        this.drawBbox.w = this.drawBbox.mx - this.drawBbox.x;
        this.drawBbox.h = this.drawBbox.my - this.drawBbox.y;
    }

    function transformDrawBoxPoints() {
        var drawPoints = this.drawBbox.points;
        var point;
        for (var i = 0; i < drawPoints.length; i++) {
            point = drawPoints[i];
            var svgPoint = canvas.getTransformedPoint(point.x, point.y);
            this.drawBbox.points[i] = { x: svgPoint.x, y: svgPoint.y };
        }
        point = null;
    }

    function drawPolygon(ctx, points) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);

        for(var i = 1; i < points.length; i++){
            ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.stroke();
        ctx.closePath();
    }

    function rotatePoint(x, y, cx, cy, angle) {
        var dx = x - cx;
        var dy = y - cy;

        var newX = cx + dx * Math.cos(-angle) - dy * Math.sin(-angle);
        var newY = cy + dx * Math.sin(-angle) + dy * Math.cos(-angle);

        return {
            x: newX,
            y: newY
        };
    }

    modes('polygon-mode', boxMode);

})(subPixel);
