/*
 * Project:   PlotPad HTML5 Viewer
 * File:      anno-base.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/12/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var tools = editor('tools');
    var styles = editor('styles');
    var annoConst = subPixel('anno-const');
    var base = tools('base-tool');

    var AnnoBaseClass = utils.createChildClass(base, 'annoBaseClass', {
        onMouseStart: function(){
            AnnoBaseClass._parent.onMouseStart.apply(this);
            editor.startDraw();
            this.points.length = 0;
            var ctx = this.ctx;

            styles.want(ctx, "lineWidth", "strokeStyle");
        },
        onMouseEnd: function(){
            editor.stopDraw();
            AnnoBaseClass._parent.onMouseEnd.apply(this);
        },
        createFigureData: function () {
            var ret = AnnoBaseClass._parent.createFigureData.apply(this);
            var points = ret.points;
            var startPoint = points[0];
            var endPoint = points[1];
            var canReturn = false;
            if (startPoint && endPoint){
                if (points.length > 2){
                    canReturn = true;
                } else {
                    var dx = Math.abs(endPoint.x - startPoint.x);
                    var dy = Math.abs(endPoint.y - startPoint.y);
                    if (dx != 0 || dy != 0) {
                        // all ok
                        canReturn = true;
                    }
                }
            } else {
                canReturn = true;
            }
            return canReturn ? ret : null;
        }
    });

    AnnoBaseClass.pushTool = function(newToolClass, params){

        !params.hasOwnProperty('group') && (params.group = 'anno');
        !params.hasOwnProperty('type') && (params.type = annoConst.TYPE);

        !params.hasOwnProperty('isPrimary') && (params.isPrimary = true);
        !params.hasOwnProperty('extTools') && (params.extTools = ['size-tool', 'color-pick']);

        tools(newToolClass, params);
    };

    tools(AnnoBaseClass, {
        type: annoConst.TYPE,
        name: 'anno-base',
        visible: false
    });
})(subPixel);
