/*
 * Project:   PlotPad HTML5 Viewer
 * File:      image.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/20/2017
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var area = editor('area');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var scale = subPixel('scale');
    var parts = subPixel('parts');
    var ImageFigureClass = parts('image');

    var imageLoaderClass = subPixel('image-loader-class');
    var imageLoader = new imageLoaderClass({name: 'canvas'});

    var base = tools('anno-base');

    var imageClass = utils.createChildClass(base, 'imageClass', {
        processFigureData: function (newObj) {
            var imageData = this._image;
            for(var key in imageData) {
                newObj[key] = imageData[key];
            }
            this.updateImageData(newObj);

            return newObj;
        },
        onMouseStart: function () {
            imageClass._parent.onMouseStart.call(this);

            var initX = 0;
            var initY = 0;
            var initW = this.initWidth;
            var initH = this.initHeight;
            var point = { x: initX, y: initY };

            this._image = {
                image:null,
                imageUrl: this.imageUrl,
                w: initW,
                h: initH,
                bounds: {
                    'left-top': { x: initX, y: initY },
                    'right-bottom': { x: initX + initW, y: initY + initH }
                }
            };
            this.points.push(point);
        },
        onMouseMove: function () {

        },
        onMouseFreeMove: function () {
            area.clear();
            var ctx = area.ctx;
            this.toolCursorImage.w = this.initWidth * scale.val;
            this.toolCursorImage.h = this.initHeight * scale.val;
            this.toolCursorImage.x = mouse.x - this.toolCursorImage.w / 2;
            this.toolCursorImage.y = mouse.y - this.toolCursorImage.h / 2;

            ImageFigureClass.prototype.drawImage(ctx, this.toolCursorImage);
        },
        onMouseEnd: function () {
            var initX = (mouse.x - this.initWidth / 2 * scale.val);
            var initY = (mouse.y - this.initHeight / 2 * scale.val);
            this.points[0] = { x: initX, y: initY };

            this._image.image = this.toolCursorImage.image;

            imageClass._parent.onMouseEnd.call(this);
        },
        onToolSelect: function() {
            imageClass._parent.onToolSelect.call(this);

            this.toolCursorImage = {
                image:null,
                w: 0,
                h: 0,
                x: 0,
                y: 0
            };
        },
        setToolParams: function(params) {
            this.initWidth = params.initWidth;
            this.initHeight = params.initHeight;
            this.imageUrl = params.imageUrl;

            this.toolCursorImage.w = this.initWidth;
            this.toolCursorImage.h = this.initHeight;

            updateImageCursor.call(this, this.toolCursorImage);
        },
        updateImageData: function (data) {
            var points = data.points;

            var bounds = {
                'left-top': { x: points[0].x, y: points[0].y },
                'right-bottom': { x: points[0].x + data.w, y: points[0].y + data.h }
            };
            data.bounds = bounds;
        }
    });

    function updateImageCursor(imageData) {
        var imgUrl = this.imageUrl;
        if (imgUrl) {
            imageLoader.load(imgUrl, function (error, imageItem) {
                imageData.image = imageItem.img;
                if (error) {
                    if (imageItem.error) {
                        //onComplete(53, imageItem.message);
                    }
                }
            });
        }
    }

    base.pushTool(imageClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-image',
        zoomWindow: true,
        title: 'image',
        text: 'image',
        imageUrl: '',
        initWidth: 50,
        initHeight: 50
    });
})(subPixel);
