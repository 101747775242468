/*
 * Project:   PlotPad HTML5 Viewer
 * File:      pen-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');

    var figure = utils.createChildClass(AnnoBaseClass, 'penChunkClass');

    function midPointBtw(p1, p2) {
        return {
            x: p1.x + (p2.x - p1.x) / 2,
            y: p1.y + (p2.y - p1.y) / 2
        };
    }

    figure.drawPen = function(ctx, points){

        ctx.beginPath();
        var p1 = points[0];
        var p2 = points[1];

        ctx.moveTo(p1.x, p1.y);

        for (var i = 1, len = points.length; i < len; i++) {
            // we pick the point between pi+1 & pi+2 as the
            // end point and p1 as our control point
            var midPoint = midPointBtw(p1, p2);
            ctx.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            p1 = points[i];
            p2 = points[i+1];
        }
        // Draw last line as a straight line while
        // we wait for the next point to be able to calculate
        // the bezier control point
        ctx.lineTo(p1.x, p1.y);
        ctx.stroke();
        ctx.closePath();

    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        this.initAnnoDrawParams(data);

        this.initBoundingBox(data.points);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var points = this.data().points;

        this.setAnnoDrawStyles(opt);
        figure.drawPen(ctx, points);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-pen', figure);


})(subPixel);
