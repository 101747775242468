/*
 * Project:   PlotPad HTML5 Viewer
 * File:      shape.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/01/2013
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var basePart = parts('base');
    var utils = subPixel('utils');

    var color = 256;


    var shape = function(id, points){
        shape._parent.constructor.apply(this, arguments);

        var drawParams = this.drawParams;
        color++;
        if (color >= 1000){
            color = 256;
        }
        this.id = id;
        drawParams.fillStyle = "#" + color.toString(16);
        this.points = points;

        this.initBoundingBox(points);
    };

    utils.inherit(shape, basePart);

    var p = shape.prototype;

    p.draw = function(){
        var ctx = canvas.buffCtx,
            points = this.points,
            point;

        var drawParams = this.drawParams;

        ctx.beginPath();

        ctx.moveTo(points[0].x, points[0].y);
        for (var i = 1, l = points.length; i < l; i++){
            point = points[i];
            ctx.lineTo(point.x, point.y)
        }

        ctx.fillStyle = drawParams.fillStyle;
        ctx.fill();
        ctx.fillStyle = '#fff';
        ctx.fillText(this.id,points[0].x, points[0].y);

        ctx.closePath();
    };


    parts('shape', shape);

})(subPixel);
