/*
 * Project:   PlotPad HTML5 Viewer
 * File:      select-manager.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/04/2014
 */
(function(subPixel){
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var mouse = subPixel('mouse');
    var config = subPixel('config');
    var keyboard;
    var selectManagerEvs = broadcast.events('select-manager', {
        changed: 'changed'
    });

    var elementsCtrl;

    var CONST_ACCESS_KEY;

    var selected = [];
    var selIds = [];
    var oldIds = '';

    var selectionClip = {
        isSinglePoint: false,
        minx: 0,
        miny: 0,
        maxx: 0,
        maxy: 0,
        x: 0,
        y: 0
    };


    // drop all selected elements
    function cleanUp(){
        for (var i = 0, l = selected.length; i < l; i++){
            var item = selected[i];
            item.setAccess(CONST_ACCESS_KEY, false);
        }
        selected.length = 0;
        selIds.length = 0;
    }

    function getElements(){
        // dont' create new array, just return link from main array!
        return elementsCtrl.getElements();
    }

    function processBroadcast(){
//        broadcast
//            [action](designCtrlEvs.elementsSets, onElementsSet);
    }

    function start(){
        elementsCtrl = subPixel('elements-ctrl');
        keyboard = subPixel('keyboard');
        var basePart = subPixel('parts')('base');
        CONST_ACCESS_KEY = basePart.A_SELECT;

        processBroadcast('on');
    }


    function stop(){
        //clean all layers
        cleanUp();
        processBroadcast('off');
    }

    function trig(){
        var newIds = JSON.stringify(selIds);
        if (newIds != oldIds){
            oldIds = newIds;
            broadcast.trig(selectManagerEvs.changed);
        }
    }

    function processSelection(selClip){
        var isShift = keyboard.isShift;
        var isCtrl = false; //keyboard.isCtrl;
        var elements = getElements();
        if (isShift || isCtrl){
            // need drop all selected for collect they
        } else {
            cleanUp();
        }

        var dropMap = {};
        var dropMapUsed = false;

        for (var i = elements.length - 1, l = 0; i >= l; i--){
            var item = elements[i];
            if (item.canSelect() && item.inSelectionArea(selClip)){
                if (isShift || isCtrl){
                    if (item.isAccessSelect()){
                        if (isShift){
                            // xor selection
                            item.setAccess(CONST_ACCESS_KEY, false);
                            // drop from selected
                            dropMap[item.getId()] = true;
                            dropMapUsed = true;
                        }
                    } else {
                        pushItem(item);
                    }
                } else {
                    if (!item.isAccessSelect()){
                        pushItem(item);
                    }
                }
            }
        }

        if (dropMapUsed){
            cleanByDropMap(selected, selIds, dropMap)
        }

        if (config.useSelectLastElement){
            // select only last element
            selectLastEl(selClip, selected);
        }

        dropMap = null;
    }

    function selectLastEl(selArea, selectedEls) {
        var isShift = keyboard.isShift;
        var isCtrl = false; //keyboard.isCtrl;
        if (selArea.isSinglePoint){
            if (!isShift && !isCtrl){
                var lastSel = selectedEls[0];
                cleanUp();
                if (lastSel){
                    pushItem(lastSel);
                }
            }
        }
    }

    function cleanByDropMap(selEls, selectedIds, dropMap) {
        for (var i = selEls.length - 1; i >= 0; i--){
            var item = selEls[i];
            var id = item.getId();
            if (dropMap[id]){
                selEls.splice(i, 1);
                selectedIds.splice(i, 1);
            }
        }
    }

    function pushItem(item){
        item.setAccess(CONST_ACCESS_KEY, true);
        selected.push(item);
        selIds.push(item.getId());
    }

    function createSelClip(selClip, rect){
        selClip.isSinglePoint = rect.isSinglePoint;
        // select by point
        selClip.minx = rect ? rect.minx : mouse.ox;
        selClip.maxx = rect ? rect.maxx : mouse.ox;
        selClip.miny = rect ? rect.miny : mouse.oy;
        selClip.maxy = rect ? rect.maxy : mouse.oy;


        // detect w,h params
        selClip.w = selClip.maxx - selClip.minx;
        selClip.h = selClip.maxy - selClip.miny;
        if (!rect.hasOwnProperty('isSinglePoint')){
            if (selClip.w || selClip.h){
                selClip.isSinglePoint = true;
            }
        }

        // getting point for some clips
        selClip.x = rect ? rect.x : mouse.ox;
        selClip.y = rect ? rect.y : mouse.oy;

        rect = null;
    }

    subPixel('select-manager', {
        clear: function () {
            cleanUp();
            trig();
        },
        selectAll: function () {
            cleanUp();
            var elements = getElements();
            for (var i = elements.length - 1, l = 0; i >= l; i--) {
                var item = elements[i];
                if (item.canSelect()) {
                    item.setAccess(CONST_ACCESS_KEY, true);
                    selected.push(item);
                    selIds.push(item.getId());
                }
            }
            trig();
            return selected;
        },
        selectElements: function (ids) {
            cleanUp();

            if (!utils.isArray(ids)) {
                ids = [ids];
            }

            for (var i = 0, l = ids.length; i < l; i++) {
                var id = ids[i];
                var item = elementsCtrl.getElementById(id);
                if (item) {
                    item.setAccess(CONST_ACCESS_KEY, true);
                    selected.push(item);
                    selIds.push(item.getId());
                }
            }
            trig();
            return selected;
        },
        selectElementsByClip: function (rect) {
            createSelClip(selectionClip, rect);
            processSelection(selectionClip);
            trig();
            return selected;
        },
        getSelected: function () {
            return selected;
        },
        getSelectedIds: function () {
            return selIds;
        },
        check: function () {
            var elements = getElements();
            var changed = false;
            for (var i = selected.length - 1; i >= 0; i--) {
                var item = selected[i];
                if (elements.indexOf(item) == -1) {
                    item.setAccess(CONST_ACCESS_KEY, false);
                    selected.splice(i, 1);
                    selIds.splice(i, 1);
                    changed = true;
                }
            }
            changed && trig();
        }
    });

    utils.bindStartStop(start, stop);

})(subPixel);
