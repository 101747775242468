/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme-as-method.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 08/08/2016
 */
(function (subPixel) {
    var themeMod = subPixel('theme-module');
    var logger = subPixel('logger')('theme-patterns-create');

    var patternsAsMethods = {};
    var sizes = {};

    var themeAsMethod = themeMod('theme-as-method', {});

    themeAsMethod.addPattern = function (name, w, h, cb) {
        if (!patternsAsMethods[name]){
            patternsAsMethods[name] = cb;
            sizes[name] = {
                w: w || 0,
                h: h || 0
            };
        } else {
            logger.log('trying to redefine theme!');
        }
    };

    themeAsMethod.process = function (pId, props) {
        var ret;
        var method = patternsAsMethods[pId];
        if (method){
            var buffCanvas = document.createElement('canvas');
            var buffCtx = buffCanvas.getContext("2d");

            var imgSize = sizes[pId];

            var w = imgSize.w * props.scale;
            var h = imgSize.h * props.scale;

            buffCanvas.width = buffCtx.width = w;
            buffCanvas.height = buffCtx.height = h;

            method(buffCtx, props.scale);
            ret = buffCanvas;
        }
        return ret;
    };

})(subPixel);
