/*
 * Project:   PlotPad HTML5 Viewer
 * File:      config-blocks.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/10/2017
 */
/*
 * just config
 * */
(function(subPixel, undefined){
    var broadcast = subPixel('broadcast');
    var configBlocksEvs = broadcast.events('config-blocks', {
        onBeforeProcess: 'onSp',
        onAfterProcess: 'onEp'
    });
    var confBlocks = subPixel('config-blocks', function (newConf) {
        broadcast.trig(configBlocksEvs.onBeforeProcess, newConf);

        var utils = subPixel('utils');

        !newConf.tools && (newConf.tools = {});
        !newConf.indicators && (newConf.indicators = {});
        !newConf.offset && (newConf.offset = {});
        !newConf.globalStyles && (newConf.globalStyles = {});

        var tmpColor = newConf.hoverColor || 'rgba(0,0,0,0.1)';
        newConf.hoverColor = utils.colorToStr(tmpColor);

        newConf.crossDetectSelection = (newConf.crossDetectSelection === true); // false by default
        newConf.usePriorityHover = (newConf.usePriorityHover === true); // false by default
        newConf.useAutoClearSelection = (newConf.useAutoClearSelection === true); // false by default
        newConf.useSelectLastElement = (newConf.useSelectLastElement === true);
        newConf.usePlanLoadIndicator = (newConf.usePlanLoadIndicator === true);

        !newConf.lineRatio && (newConf.lineRatio = 1); // default 1

        broadcast.trig(configBlocksEvs.onAfterProcess, newConf);
    });

    confBlocks.onBeforeProcess = function (cb) {
        broadcast.on(configBlocksEvs.onBeforeProcess, cb);
    };

    confBlocks.onAfterProcess = function (cb) {
        broadcast.on(configBlocksEvs.onAfterProcess, cb);
    };

})(subPixel);
