/*
 * Project:   PlotPad HTML5 Viewer
 * File:      prompt-event-sender.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/01/2015
 */
(function (subPixel, undefined) {

    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var offset = subPixel('offset');

    var isTouchSupport = utils.support.touch;

    // 600 ms is enought for wait, until keyboard will be closed
    var CONST_DELAY_CLOSE = isTouchSupport ? 600 : 0;

    subPixel('prompt-event-sender', function(globalEvName, internalEvName, data, cb){

        var isResizeEnable = offset.resize;
        isResizeEnable && offset.disableResize();

        var params = {
            data: data,
            catchEv: globalEvName,
            apply: function(canApply, fields){
                if (canApply){
                    for (var key in fields){
                        data[key] = fields[key];
                    }
                }
                fields = null;
                isResizeEnable && offset.enableResize();
                cb();
            }
        };


        setTimeout(function(){
            // timeout need for process old stack, IOS have problems with calling correct events
            // mechanism for detect catch of event and await this, or drop in other way
            var isTriggerCatched = false;
            subPixel.one(globalEvName, function(){
                isTriggerCatched = true;
            });
            broadcast.trig(internalEvName, params);

            if (!isTriggerCatched){
                // need call trig for process .one bind
                subPixel.trig(globalEvName);

                // default way is prompt ask
                var text = window.prompt('Enter title: ', '');
                // delay needed for wait, until keyboard closed
                setTimeout(function(){
                    params && params.apply(true, {
                        text: text
                    });
                }, CONST_DELAY_CLOSE);

            }
        }, 0);

    });


})(subPixel);
