/*
 * Project:   PlotPad HTML5 Viewer
 * File:      shapes.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/07/2017
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var helpers = cafm.helpers;
    var holdover = subPixel('holdover');
    var utils = subPixel('utils');

    var imageLoaderClass = subPixel('image-loader-class');
    var imageLoader = new imageLoaderClass({name: 'canvas'});

    cafm.AddShapes = function(){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);
        var annoImages = [];

        for (var i = 0, l = list.length; i < l; i++) {
            var annoObj = list[i];
            if(annoObj.name === 'anno-image' && annoObj.imageUrl) {
                annoImages.push(annoObj);
            } else {
                subPixel.addElement(annoObj);
            }
        }

        loadAnnoImages(annoImages, function() {
            subPixel.redraw(holder.await());
        });

        return holder;
    };

    function loadAnnoImages(annotations, onDone) {
        if (annotations.length !== 0) {
            var anno = annotations.shift();
            loadSingleAnnoImage(anno, function(){
                loadAnnoImages(annotations, onDone);
            });
        } else {
            onDone && onDone();
        }
    }

    function loadSingleAnnoImage(anno, onDone) {
        imageLoader.load(anno.imageUrl, function (error, imageItem) {
            anno.image = imageItem.img;
            if (error) {
                if (imageItem.error) {
                    cafm.events.OnError(53, 'Couldn\'t load image for image-annotation');
                }
            } else {
                subPixel.addElement(anno);
                onDone && onDone();
            }
        });
    }

    cafm.ClearShapes = function(){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);

        subPixel.removeElement(list);
        subPixel.redraw(holder.await());

        return holder;
    };

    cafm.GetAllShapes = function(){
        var shapes = [];
        var elements = subPixel.getAllAnnotationElements();

        utils.map(elements, function(el){
            if (el && el.getStruct()){
                var struct = el.getStruct();
                shapes.push(struct);
            }
        });

        return shapes;
    };

    cafm.SelectShapes = function(ids){
        if (ids) {
            subPixel.selectElements(ids);
        } else {
            var shapesIds = [];
            var elements = subPixel.getAllAnnotationElements();
            utils.map(elements, function(el){
                if (el){
                    shapesIds.push(el.getId());
                }
            });
            subPixel.selectElements(shapesIds);
        }
    };

})(cafm);