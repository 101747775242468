/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.slantRightDash.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('slantRightDash', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(30,0);
        // ctx.lineTo(30,30);
        // ctx.lineTo(0,30);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);
        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.3380279541015625, 0, 0, 0.3380279541015625, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#282425";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(71.05, 88.75);
        ctx.lineTo(76, 88.75);
        ctx.lineTo(71, 83.75);
        ctx.lineTo(68.55, 86.2);
        ctx.lineTo(71.05, 88.75);
        ctx.moveTo(58.25, 88.75);
        ctx.lineTo(53.25, 83.75);
        ctx.lineTo(50.75, 86.2);
        ctx.lineTo(53.3, 88.75);
        ctx.lineTo(58.25, 88.75);
        ctx.moveTo(53.25, 65.95);
        ctx.lineTo(50.75, 68.45);
        ctx.lineTo(62.15, 79.8);
        ctx.lineTo(64.6, 77.3);
        ctx.lineTo(53.25, 65.95);
        ctx.moveTo(44.35, 62);
        ctx.lineTo(46.85, 59.55);
        ctx.lineTo(35.45, 48.2);
        ctx.lineTo(33, 50.65);
        ctx.lineTo(44.35, 62);
        ctx.moveTo(62.15, 62);
        ctx.lineTo(64.6, 59.55);
        ctx.lineTo(53.25, 48.2);
        ctx.lineTo(50.75, 50.65);
        ctx.lineTo(62.15, 62);
        ctx.moveTo(71, 65.95);
        ctx.lineTo(68.55, 68.45);
        ctx.lineTo(79.9, 79.8);
        ctx.lineTo(82.35, 77.3);
        ctx.lineTo(71, 65.95);
        ctx.moveTo(68.55, 50.65);
        ctx.lineTo(79.9, 62);
        ctx.lineTo(82.35, 59.55);
        ctx.lineTo(71, 48.2);
        ctx.lineTo(68.55, 50.65);
        ctx.moveTo(33, 86.2);
        ctx.lineTo(35.55, 88.75);
        ctx.lineTo(40.5, 88.75);
        ctx.lineTo(35.45, 83.75);
        ctx.lineTo(33, 86.2);
        ctx.moveTo(17.7, 83.75);
        ctx.lineTo(15.25, 86.2);
        ctx.lineTo(17.75, 88.75);
        ctx.lineTo(22.7, 88.75);
        ctx.lineTo(17.7, 83.75);
        ctx.moveTo(0, 88.75);
        ctx.lineTo(4.95, 88.75);
        ctx.lineTo(0, 83.8);
        ctx.lineTo(0, 88.75);
        ctx.moveTo(0, 66);
        ctx.lineTo(0, 70.95);
        ctx.lineTo(8.8, 79.8);
        ctx.lineTo(11.3, 77.3);
        ctx.lineTo(0, 66);
        ctx.moveTo(17.7, 65.95);
        ctx.lineTo(15.25, 68.45);
        ctx.lineTo(26.6, 79.8);
        ctx.lineTo(29.05, 77.3);
        ctx.lineTo(17.7, 65.95);
        ctx.moveTo(0, 53.2);
        ctx.lineTo(8.8, 62);
        ctx.lineTo(11.3, 59.55);
        ctx.lineTo(0, 48.25);
        ctx.lineTo(0, 53.2);
        ctx.moveTo(26.6, 62);
        ctx.lineTo(29.05, 59.55);
        ctx.lineTo(17.7, 48.2);
        ctx.lineTo(15.25, 50.65);
        ctx.lineTo(26.6, 62);
        ctx.moveTo(35.5, 65.95);
        ctx.lineTo(33, 68.45);
        ctx.lineTo(44.35, 79.8);
        ctx.lineTo(46.85, 77.3);
        ctx.lineTo(35.5, 65.95);
        ctx.moveTo(17.7, 30.4);
        ctx.lineTo(15.25, 32.9);
        ctx.lineTo(26.6, 44.25);
        ctx.lineTo(29.05, 41.8);
        ctx.lineTo(17.7, 30.4);
        ctx.moveTo(0, 30.5);
        ctx.lineTo(0, 35.45);
        ctx.lineTo(8.8, 44.25);
        ctx.lineTo(11.3, 41.8);
        ctx.lineTo(0, 30.5);
        ctx.moveTo(0.1, 0);
        ctx.lineTo(8.8, 8.7);
        ctx.lineTo(11.3, 6.25);
        ctx.lineTo(5.05, 0);
        ctx.lineTo(0.1, 0);
        ctx.moveTo(0, 12.7);
        ctx.lineTo(0, 17.65);
        ctx.lineTo(8.8, 26.5);
        ctx.lineTo(11.3, 24);
        ctx.lineTo(0, 12.7);
        ctx.moveTo(17.7, 12.65);
        ctx.lineTo(15.25, 15.1);
        ctx.lineTo(26.6, 26.5);
        ctx.lineTo(29.05, 24);
        ctx.lineTo(17.7, 12.65);
        ctx.moveTo(26.6, 8.7);
        ctx.lineTo(29.05, 6.25);
        ctx.lineTo(22.85, 0);
        ctx.lineTo(17.9, 0);
        ctx.lineTo(26.6, 8.7);
        ctx.moveTo(44.35, 26.5);
        ctx.lineTo(46.85, 24);
        ctx.lineTo(35.45, 12.65);
        ctx.lineTo(33, 15.1);
        ctx.lineTo(44.35, 26.5);
        ctx.moveTo(68.55, 32.9);
        ctx.lineTo(79.9, 44.25);
        ctx.lineTo(82.4, 41.8);
        ctx.lineTo(71, 30.4);
        ctx.lineTo(68.55, 32.9);
        ctx.moveTo(44.35, 44.25);
        ctx.lineTo(46.85, 41.8);
        ctx.lineTo(35.45, 30.4);
        ctx.lineTo(33, 32.9);
        ctx.lineTo(44.35, 44.25);
        ctx.moveTo(53.25, 30.4);
        ctx.lineTo(50.75, 32.9);
        ctx.lineTo(62.15, 44.25);
        ctx.lineTo(64.6, 41.8);
        ctx.lineTo(53.25, 30.4);
        ctx.moveTo(46.85, 6.25);
        ctx.lineTo(40.6, 0);
        ctx.lineTo(35.65, 0);
        ctx.lineTo(44.35, 8.7);
        ctx.lineTo(46.85, 6.25);
        ctx.moveTo(53.25, 12.65);
        ctx.lineTo(50.75, 15.1);
        ctx.lineTo(62.15, 26.5);
        ctx.lineTo(64.6, 24);
        ctx.lineTo(53.25, 12.65);
        ctx.moveTo(71, 12.65);
        ctx.lineTo(68.55, 15.15);
        ctx.lineTo(79.9, 26.5);
        ctx.lineTo(82.4, 24);
        ctx.lineTo(71, 12.65);
        ctx.moveTo(62.15, 8.7);
        ctx.lineTo(64.6, 6.25);
        ctx.lineTo(58.35, 0);
        ctx.lineTo(53.4, 0);
        ctx.lineTo(62.15, 8.7);
        ctx.moveTo(76.15, 0);
        ctx.lineTo(71.2, 0);
        ctx.lineTo(79.9, 8.7);
        ctx.lineTo(82.35, 6.25);
        ctx.lineTo(76.15, 0);
        ctx.moveTo(86.3, 50.65);
        ctx.lineTo(88.75, 53.1);
        ctx.lineTo(88.75, 48.25);
        ctx.lineTo(86.3, 50.65);
        ctx.moveTo(86.3, 68.45);
        ctx.lineTo(88.75, 70.85);
        ctx.lineTo(88.75, 66);
        ctx.lineTo(86.3, 68.45);
        ctx.moveTo(86.3, 86.2);
        ctx.lineTo(88.75, 88.6);
        ctx.lineTo(88.75, 83.8);
        ctx.lineTo(86.3, 86.2);
        ctx.moveTo(88.75, 12.7);
        ctx.lineTo(86.3, 15.1);
        ctx.lineTo(88.75, 17.55);
        ctx.lineTo(88.75, 12.7);
        ctx.moveTo(86.3, 32.9);
        ctx.lineTo(88.75, 35.3);
        ctx.lineTo(88.75, 30.5);
        ctx.lineTo(86.3, 32.9);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
