/*
 * Project:   PlotPad HTML5 Viewer
 * File:      text.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/03/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var textChunkClass = parts('anno-text');
    var config = subPixel('config');
    var scale = subPixel('scale');
    var promptEventSender = subPixel('prompt-event-sender');
    var domHelper = subPixel('dom-helper');
    var engineDom = subPixel('engine-dom');

    var broadcast = subPixel('broadcast');
    var annoTextEvs = broadcast.events('anno-text', {
        onPrompt: 'onPrompt',
        onPromptCatched: 'onPromptCatched'
    });

    var userApiEvents = subPixel('user-api-events');

    userApiEvents(annoTextEvs.onPrompt, 'anno-text-onpromt');

    var container = document.createElement('div');
    var inputEl = document.createElement('input');
    container.className = 'subpixel-text-anno-helper';
    inputEl.className = 'subpixel-text-anno-helper-input';
    container.appendChild(inputEl);

    var CONST_MAX_CHARS = 1000;
    var CONST_DEF_VIEW = 'Введите текст';

    var isTouchSupport = utils.support.touch;

    domHelper.moveToFragment(container);

    inputEl.setAttribute('type', 'text');
    inputEl.setAttribute('placeholder', CONST_DEF_VIEW);
    inputEl.setAttribute('maxlength', CONST_MAX_CHARS);

    var containerStyle = container.style;
    containerStyle.position = 'absolute';
    containerStyle.top = '-9000px';
    containerStyle.left = '-9000px';

    var base = tools('anno-base');

    var textClass = utils.createChildClass(base, 'textClass');

    var p = textClass.prototype;

    function desktopWay(data, callback){
        inputEl.value = '';
        containerStyle.top = '-9000px';
        containerStyle.left = '-9000px';
        inputEl.style.color = styles.globals.strokeStyle;
        function apply(){
            inputEl.onblur = null;
            apply = null;
            domHelper.moveToFragment(container);
            data.text = inputEl.value;
            callback();
        }
        inputEl.onkeypress = function(ev){
            if (ev.keyCode == 13){
                // enter pressed
                apply && apply();
            }
        };
        inputEl.onblur = function(){
            apply && apply();
        };

        var baseEl = engineDom.getBaseElement();
        baseEl.appendChild(container);
        var rect = container.getBoundingClientRect();
        containerStyle.top = (data.position.y - rect.height) + 3 + 'px';
        containerStyle.left = data.position.x + 'px';
        inputEl.focus();
    }

    function touchWay(data, callback){
        //inputEl.onblur = function(){
        //    params && params.apply();
        //};

        promptEventSender('anno-text-onpromt-catched', annoTextEvs.onPrompt, data, function(){
            //inputEl.onblur = null;
            callback();
        });
    }

    function addText(data, callback){

        if (isTouchSupport){
            // for devices, who working with popup keyboard
            // and we can't enable input mode
            touchWay(data, callback);
        } else {
            // desktop way
            desktopWay(data, callback)
        }
    }

    function blur(){
        inputEl.onblur && inputEl.onblur();
    }

    p.onToolDeselect = function(){
        blur();
        textClass._parent.onToolDeselect.call(this);
    };

    p.onMouseStart = function(){
        blur();
        textClass._parent.onMouseStart.call(this);

        var ctx = this.ctx;
        this._font = {
            text: "",
            position:{
                x: mouse.x,
                y: mouse.y
            },
            font: {
                color: styles.globals.strokeStyle,
                name: 'Arial',
                size: 14
            }
        };
        this.points.push(this._font.position);
        styles.want(ctx, "strokeStyle");
        textChunkClass.drawText(ctx, this._font, CONST_DEF_VIEW);

    };

    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var font = this._font;

        font.position.x = mouse.x;
        font.position.y = mouse.y;

        textChunkClass.drawText(ctx, font, CONST_DEF_VIEW);
    };

    p.onMouseEnd = function(){
        this.createTextAnno();
    };

    p.createTextAnno = function(){
        var self = this;
        !isTouchSupport && area.clear();
        addText(this._font, function(){
            textClass._parent.onMouseEnd.call(self);
        });
    };

    p.processFigureData = function(newObj){
        var figObj = this._font;

        if (figObj.text){
            var text = (figObj.text + '');
            if (text.length > CONST_MAX_CHARS){
                text = text.substr(0, CONST_MAX_CHARS);
                figObj.text = text;
            }
            // create new figure
            figObj.position = newObj.points[0];
            figObj.font.size = 14 / scale.val;
            for (var key in figObj){
                newObj[key] = figObj[key];
            }
        } else {
            newObj = null;
        }

        this._font = null;
        return newObj;
    };


    base.pushTool(textClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-text',
        zoomWindow: true,
        extTools: ['color-pick'],
        title: 'text',
        text: 'text'
    });
})(subPixel);
