/*
 * Project:   PlotPad HTML5 Viewer
 * File:      image-chunk.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/20/2017
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var crossingHelper = parts('crossing-helper');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var ImageFigureClass = parts('image');
    var config = subPixel('config');

    var f360 = 2 * Math.PI;

    var AnnoImageClass = utils.createChildClass(ImageFigureClass, 'imageChunkClass', {
        init: function () {
            AnnoImageClass._parent.init.apply(this, arguments);
            AnnoBaseClass.prototype.initAnnoDrawParams.apply(this, arguments);

            this._struct = this.createStruct();
            this.props.rotating = true;
            this.propPointsType(crossingHelper.TYPE_POLY);
        },
        initBase: function () {
            AnnoImageClass._parent.initBase.apply(this, arguments);
            AnnoBaseClass.prototype.initBase.apply(this, arguments);
        },
        prepareParams: function () {
            AnnoImageClass._parent.prepareParams.apply(this, arguments);
            AnnoBaseClass.prototype.prepareParams.apply(this, arguments);
        },
        canDraw: function () {
            var ret = AnnoBaseClass._parent.canDraw.apply(this, arguments);
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function (ctx, x, y, w, h) {
            var data = this.data();
            data.w = w;
            data.h = h;
            data.x = x;
            data.y = y;
            this.updateImageData();
            this.drawImage(ctx, data);
        },
        setDimensions: function(newX, newY, newW, newH, newAngle) {
            var data = this.data();

            this.rotationAngle(newAngle);

            data.w = newW / scale.val;
            data.h = newH / scale.val;
            data.x = newX;
            data.y = newY;

            this.updateImageData();
            this.init(data, this.props);
            this.updateStruct();
        },
        updateImageData: function() {
            var data = this.data();
            data.bounds = {
                'left-top': { x: data.x, y: data.y },
                'right-bottom': { x: data.x + data.w, y: data.y + data.h }
            }
        },
        updateStruct: function() {
            var data = this.data();
            var struct = this._struct;

            var point = {
                x: data.x,
                y: data.y
            };

            struct.points[0] = point;

            struct.w = data.w;
            struct.h = data.h;

            struct.bounds = {
                'left-top': { x: point.x, y: point.y },
                'right-bottom': { x: point.x + data.w, y: point.y + data.h }
            };

            struct.angle = this.rotationAngle();
        },
        createStruct: function() {
            var data = this.data();
            var bPoints = data.points;
            var struct = {
                points: [
                    bPoints[0]
                ],
                name: data.name,
                id: data.id,
                imageUrl: data.imageUrl,
                w: data.w,
                h: data.h,
                bounds: {
                    'left-top': { x: bPoints[0].x, y: bPoints[0].y },
                    'right-bottom': { x: bPoints[0].x + data.w, y: bPoints[0].y + data.h }
                },
                angle: this.rotationAngle(),
                styles: data.styles
            };

            return struct;
        },
        getStruct: function() {
            return this._struct;
        },
        objectId: function() {
            return this.data().id;
        },
        onHover: function() {
            var bbox = this.getBoundingBox();
            var ctx = canvas.buffCtx;

            var rx = bbox.w / 2;
            var ry = bbox.h / 2;
            var setR = (rx > ry) ? rx : ry;
            var x = bbox.minx + rx;
            var y = bbox.miny + ry;

            ctx.beginPath();
            ctx.fillStyle = config.selectionStrokeColor;
            ctx.arc(x, y, setR * 3/2, f360, false);
            ctx.fill();
            ctx.closePath();
        }
    });

    AnnoImageClass.drawImage = ImageFigureClass.drawImage;


    parts('anno-image', AnnoImageClass);


})(subPixel);
