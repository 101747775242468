/*
 * Project:   PlotPad HTML5 Viewer
 * File:      modes.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/24/2017
 */
(function(subPixel){
    var editor = subPixel('editor');
    var logger = subPixel('logger')('modes');

    var modes = {};

    function editingModes(name, val){
        var ret;
        if (val){
            // setter
            if (modes[name]){
                // defined
                logger.error('trying to register defined editing mode', name);
            } else {
                modes[name] = val;
            }
        } else {
            ret = modes[name];
            if (!ret){
                logger.error('trying to get not defined editing mode', name);
            }
        }

        return ret;
    }

    editor('editing-modes', editingModes);

})(subPixel);
