/*
 * Project:   PlotPad HTML5 Viewer
 * File:      lm.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/16/2016
 */
(function(subPixel){
    var utils = subPixel('utils');
    var holdover = subPixel('holdover');

    var elementsCtrl;
    var drawingCtrl;

    var groupsCache = {};

    var layerManager = {
        addLayer: function (groupName, layerName) {
            groupName = groupName + '';
            layerName = layerName + '';

            if (!groupsCache[groupName]){
                // create group
                groupsCache[groupName] = {}
            }

            var layersInGroup = groupsCache[groupName];
            if (!layersInGroup[layerName]){
                // create layer
                layersInGroup[layerName] = {
                    name: layerName,
                    lowerCaseName: layerName.toLowerCase(),
                    visible: true
                };
            }
        },
        show: function (groupNames, layerNames, cb) {
            changeVisible(groupNames, layerNames, true, cb);
        },
        hide: function (groupNames, layerNames, cb) {
            changeVisible(groupNames, layerNames, false, cb);
        },
        setLayersVisible: function(groupNames, layerNames) {
            updateLayersCache(groupNames, layerNames, true);
        },
        setLayersHidden: function(groupNames, layerNames) {
            updateLayersCache(groupNames, layerNames, false);
        },
        holderShow: function (groups, layers) {
            var holder = holdover(true);
            this.show(groups, layers, holder.await());
            return holder;
        },
        holderHide: function (groups, layers) {
            var holder = holdover(true);
            this.hide(groups, layers, holder.await());
            return holder;
        },
        isLayerVisible: function (groupName, layerName) {
            var res = false;
            var groupItem = groupsCache[groupName];

            if (groupItem && groupItem[layerName]){
                var item = groupItem[layerName];
                res = item.visible;
            }
            return res;
        },
        get: function () {
            return groupsCache;
        },
        removeLayer: function (group, layer) {
            clearData(group, layer);
        },
        isAllLayersInOneState: function (state) {
            var ret = true;
            for (var gKey in groupsCache){
                var group = groupsCache[gKey];
                for (var lKey in group){
                    var item = group[lKey];
                    if (item.visible == state){
                        ret = false;
                        break;
                    }
                }
            }
            return ret;
        }
    };

    function changeVisible(groupNames, layerNames, state, cb) {
        updateLayersCache(groupNames, layerNames, state);
        changeElementsVisible(groupNames, layerNames, state, cb);
    }

    function updateLayersCache(groupNames, layerNames, state) {
        groupNames = toArray(groupNames); // if null - process all groups
        layerNames = toArray(layerNames); // if null -  process all layers

        // set state for layer
        processCache(groupsCache, groupNames, function (group) {
            processCache(group, layerNames, function (layer) {
                layer.visible = state;
            });
        });
    }

    function processCache(list, filter, cb) {
        for (var key in list){
            var obj = list[key];
            if (!filter || (filter.indexOf(key) != -1)){
                cb(obj);
            }
        }
    }

    function changeElementsVisible(groupNames, layersNames, state, cb) {
        groupNames = toArray(groupNames); // if null - process all groups
        layersNames = toArray(layersNames); // if null -  process all layers

        var groupMap;
        var changed = false;
        if (groupNames) {
            groupMap = {};
            utils.map(groupNames, function (group) {
                groupMap[group] = true;
            });
        }

        var layersMap;
        if (layersNames) {
            layersMap = {};
            utils.map(layersNames, function (layer) {
                layersMap[layer] = true;
            });
        }
        var elements = elementsCtrl.getElements();
        utils.map(elements, function (el) {
            var elGroup = el.propLayerGroup();
            if (!groupMap || groupMap[elGroup]){
                // el in group list

                var elLayer = el.propLayerNameFull();
                if (!layersMap || layersMap[elLayer]){
                    el.propVisible(state);
                    changed = true;
                }
            }
        });

        if (changed){
            drawingCtrl.redraw(cb);
        } else {
            cb && cb();
        }
    }

    function clearData(groupName, layer) {
        for (var key in groupsCache){
            var group = groupsCache[key];
            if (!groupName || (key == groupName)){
                if (!layer){
                    utils.clearObject(group);
                    groupsCache[key] = null;
                    delete groupsCache[key];
                } else {
                    for (var layerKey in group){
                        if (layer == layerKey){
                            utils.clearObject(group[layerKey]);
                            group[layerKey] = null;
                            delete group[layerKey];
                        }
                    }
                }
            }
        }
    }

    function toArray(val){
        var ret = isNotDefined(val) ? null
            : !utils.isArray(val) ? [val]
            : val;
        return ret;
    }

    function isNotDefined(val) {
        return (val === undefined || val === null);
    }

    layerManager.CONST_UNDEFINED_LAYER = '__undefined_layer__';
    layerManager.CONST_DEFAULT_GROUP_LAYER = 'base';


    subPixel('layer-manager', layerManager);

    function start() {
        elementsCtrl = subPixel('elements-ctrl');
        drawingCtrl = subPixel('drawing-ctrl');
    }

    function stop() {

    }

    utils.bindStartStop(start, stop);

})(subPixel);
