/*
 * Project:   PlotPad HTML5 Viewer
 * File:      anno-const.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/19/2014
 */
(function(subPixel, undefined){
    var annoConst = {
        TYPE: 'annotation',
        LAYER_NAME: 'def'
    };


    subPixel('anno-const', annoConst);
})(subPixel);
