/*
 * Project:   PlotPad HTML5 Viewer
 * File:      box-mode.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/24/2017
 */
(function(subPixel){

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var canvas = subPixel('canvas');
    var scale = subPixel('scale');
    var area = editor('area');
    var modes = editor('editing-modes');
    var base = modes('base');

    var CONST_90_DEGREE = 90 * Math.PI / 180;

    var boxMode = utils.createChildClass(base, 'boxModeClass', {
        init: function() {
            this.drawBbox = {
                sx: 0,
                sy: 0,
                sw: 0,
                sh: 0,
                x: 0,
                y: 0,
                w: 0,
                h: 0
            };
        },
        updateDrawBox: function() {
            if (this.currFig){
                this.groupEditing.x = mouse.x;
                this.groupEditing.y = mouse.y;
                var bbox = this.currFig.getScreenBounds();

                this.drawBbox.x = this.drawBbox.sx = bbox.minx;
                this.drawBbox.y = this.drawBbox.sy = bbox.miny;
                this.drawBbox.w = this.drawBbox.sw = bbox.w;
                this.drawBbox.h = this.drawBbox.sh = bbox.h;

                this.updateRegions(this.drawBbox);
            }
        },
        updateRegions: function (x, y, clip) {
            if (!clip){
                clip = x;
                x = clip.x;
                y = clip.y;
            }
            var w = clip.w;
            var h = clip.h;

            if (this.currFig.canRotate()) {
                var xx = x + w / 2;
                var yy = y - this.regionMargin;

                var reg0 = rotatePoint(xx, yy, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);

                this.updateSingleReg(0, reg0.x, reg0.y, this.regionSize, this.regionSize);
            } else if(this.hoverRegions[0]) {
                delete this.hoverRegions[0];
            }

            var reg1 = rotatePoint(x, y, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);
            var reg2 = rotatePoint(x + w, y, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);
            var reg3 = rotatePoint(x + w, y + h, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);
            var reg4 = rotatePoint(x, y + h, this.rotation.translateX, this.rotation.translateY, this.rotation.angle);

            this.updateSingleReg(1, reg1.x, reg1.y, this.regionSize, this.regionSize);
            this.updateSingleReg(2, reg2.x, reg2.y, this.regionSize, this.regionSize);
            this.updateSingleReg(3, reg3.x, reg3.y, this.regionSize, this.regionSize);
            this.updateSingleReg(4, reg4.x, reg4.y, this.regionSize, this.regionSize);
        },
        calculateHelpers: function () {
            var x = mouse.x;
            var y = mouse.y;

            var dx = x - this.groupEditing.x;
            var dy = y - this.groupEditing.y;

            if (this.isRotating) {
                var figureCenterX = this.rotation.translateX = this.drawBbox.x + this.drawBbox.w / 2;
                var figureCenterY = this.rotation.translateY = this.drawBbox.y + this.drawBbox.h / 2;
                var rx = x - figureCenterX;
                var ry = y - figureCenterY;
                this.rotation.angle = Math.atan2(-ry, rx) - CONST_90_DEGREE;
            } else if (this.isSizing){
                // reverse mouse position rotation for regions
                var point = rotatePoint(x, y, this.groupEditing.x, this.groupEditing.y, -this.rotation.angle);
                dx = point.x - this.groupEditing.x;
                dy = point.y - this.groupEditing.y;

                this.groupEditing.dx = 0;
                this.groupEditing.dy = 0;
                (this.regionsPos == 1) && (dx = -dx, dy = -dy);
                (this.regionsPos == 2) && (dy = -dy);
                (this.regionsPos == 4) && (dx = -dx);

                // var angle = this.rotation.angle;
                var newW = this.drawBbox.sw + dx;
                var newH;

                if (this.currFig.canScale()){
                    var k = newW ? this.drawBbox.sw / newW : 0;
                    newH = 1 / k * this.drawBbox.sh;
                } else {
                    newH = this.drawBbox.sh + dy;
                }

                dx = newW - this.drawBbox.sw;
                dy = newH - this.drawBbox.sh;
                if (newH < 20 || newW < 20){
                    return;
                }

                if (this.regionsPos == 1){
                    this.drawBbox.x = this.drawBbox.sx - dx;
                    this.drawBbox.y = this.drawBbox.sy - dy;
                    this.drawBbox.w = newW;
                    this.drawBbox.h = newH;

                    this.rotation.translateX = this.drawBbox.x + this.drawBbox.w;
                    this.rotation.translateY = this.drawBbox.y + this.drawBbox.h;
                } else if (this.regionsPos == 2){
                    this.drawBbox.x = this.drawBbox.sx;
                    this.drawBbox.y = this.drawBbox.sy - dy;
                    this.drawBbox.w = newW;
                    this.drawBbox.h = newH;

                    this.rotation.translateX = this.drawBbox.x;
                    this.rotation.translateY = this.drawBbox.y + this.drawBbox.h;
                } else if (this.regionsPos == 3){
                    dx = x - this.groupEditing.x;
                    newW = this.drawBbox.sw + dx;
                    if (this.currFig.canScale()) {
                        k = newW ? this.drawBbox.sw / newW : 0;
                        newH = 1 / k * this.drawBbox.sh;
                    } else {
                        newH = this.drawBbox.sh + dy;
                    }
                    this.drawBbox.x = this.drawBbox.sx;
                    this.drawBbox.y = this.drawBbox.sy;
                    this.drawBbox.w = newW;
                    this.drawBbox.h = newH;

                    this.rotation.translateX = this.drawBbox.x;
                    this.rotation.translateY = this.drawBbox.y;
                } else if (this.regionsPos == 4){
                    this.drawBbox.x = this.drawBbox.sx - dx;
                    this.drawBbox.y = this.drawBbox.sy;
                    this.drawBbox.w = newW;
                    this.drawBbox.h = newH;

                    this.rotation.translateX = this.drawBbox.x + this.drawBbox.w;
                    this.rotation.translateY = this.drawBbox.y;
                }
            } else {
                this.groupEditing.dx = dx;
                this.groupEditing.dy = dy;

                this.rotation.translateX = this.drawBbox.x + this.drawBbox.w / 2;
                this.rotation.translateY = this.drawBbox.y + this.drawBbox.h / 2;
            }

            this.updateRegions(this.drawBbox);
        },
        draw: function (canDrawFigure) {
            if (this.working && this.mouseClicked && this.currFig){
                var groupX = this.groupEditing.ex = this.drawBbox.x + this.groupEditing.dx;
                var groupY = this.groupEditing.ey = this.drawBbox.y + this.groupEditing.dy;

                var translateX = this.rotation.translateX;
                var translateY = this.rotation.translateY;
                var angle = this.rotation.angle;

                // some kind of magic here
                // don't know how it works but it does
                // todo: make architecture/algorithm clear and simple
                var centerX = translateX - this.drawBbox.w / 2;
                var centerY = translateY - this.drawBbox.h / 2;
                var point = rotatePoint(groupX, groupY, centerX, centerY, -angle);

                this.clearDraw();
                var ctx = area.ctx;
                ctx.save();

                // rotate canvas
                canvas.rotateAroundPoint(ctx, angle, translateX, translateY);

                // draw figure
                if (canDrawFigure !== false){
                    this.currFig.drawEditing && this.currFig.drawEditing(ctx, point.x, point.y, this.drawBbox.w, this.drawBbox.h);
                }

                ctx.beginPath();
                ctx.lineWidth = scale.defLineWidth;
                ctx.strokeStyle = "rgb(0,0,0)";
                ctx.fillStyle = 'rgba(0,0,0, 0.5)';

                // draw bounding box rectangle
                this.drawBoundingBoxRect(ctx, point.x, point.y);

                this.updateRegions(point.x, point.y, this.drawBbox);
                // draw regions without rotation, because it's not necessary
                canvas.rotateAroundPoint(ctx, -angle, translateX, translateY);
                this.drawRegions(ctx);
                canvas.rotateAroundPoint(ctx, angle, translateX, translateY);

                ctx.stroke();
                ctx.fill();
                ctx.closePath();

                ctx.restore();
                this.updateClearBox();
            }
        },
        setDimensions: function () {
            var pos = canvas.getTransformedPoint(this.groupEditing.ex, this.groupEditing.ey);
            var w = this.drawBbox.w;
            var h = this.drawBbox.h;
            var angle = this.rotation.angle;
            this.currFig.setDimensions && this.currFig.setDimensions(pos.x, pos.y, w, h, angle);
        }
    });

    function rotatePoint(x, y, cx, cy, angle) {
        var dx = x - cx;
        var dy = y - cy;

        var newX = cx + dx * Math.cos(-angle) - dy * Math.sin(-angle);
        var newY = cy + dx * Math.sin(-angle) + dy * Math.cos(-angle);

        return {
            x: newX,
            y: newY
        };
    }

    modes('box-mode', boxMode);

})(subPixel);
