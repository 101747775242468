/*
 * Project:   PlotPad HTML5 Viewer
 * File:      config.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/05/2013
 */
/*
* just config
* */
(function(subPixel, undefined){

    // config exportable object
    var config = {

    };

    //register config
    subPixel('config', config);


})(subPixel);
