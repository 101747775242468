/*
 * Project:   PlotPad HTML5 Viewer
 * File:      scenario-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/22/2016
 */
(function(subPixel, undefined){
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var logger = subPixel('logger')('scenario-ctrl');
    var mouseEvs = broadcast.events('mouse');
    var hoverEvs = broadcast.events('hover-manager');
    var config = subPixel('config');
    var engineDom = subPixel('engine-dom');
    var domHelper;
    var scena;

    var scenarioEvs = broadcast.events('scenario-ctrl', {
        scenarioChanged: 'sc',
        scaleStart: 'ss',
        scaleStop: 'st'
    });

    var currentScenario;
    var working = false;

    var scenarioCtrl = {};
    var scenaCssName = 'subpixel-scenario-';


    var SC_VIEW = scenarioCtrl.view = 'view';
    var SC_EDIT = scenarioCtrl.edit = 'edit';
    var SC_NOT_MOVE = scenarioCtrl.not_move = 'not_move';
    var SC_SELECT = scenarioCtrl.select = 'select';

    var scenarios = {};

    scenarios[SC_VIEW] = scenarioView;
    scenarios[SC_EDIT] = scenarioEdit;
    scenarios[SC_NOT_MOVE] = scenarioNotMove;
    scenarios[SC_SELECT] = scenarioSelect;

    var NS_CURRENT = ".scenario-ctrl";

    function onScaleStart(x, y){
        broadcast.trig(scenarioEvs.scaleStart);
        scena.onScaleStart(x, y);
    }

    function onScaleStop(){
        broadcast.trig(scenarioEvs.scaleStop);
        scena.onScaleStop();
    }

    function bindScales(){
        broadcast
            .on([mouseEvs.wheel, mouseEvs.pitch], NS_CURRENT, scena.onScale)
            .on([mouseEvs.wheelstop, mouseEvs.pitchstop], NS_CURRENT, onScaleStop)
            .on([mouseEvs.wheelstart, mouseEvs.pitchstart], NS_CURRENT, onScaleStart);
    }

    function bindTaps(){
        broadcast
            .on(mouseEvs.tapstart, NS_CURRENT, scena.onTapStart)
            .on(mouseEvs.tapend, NS_CURRENT, scena.onTapEnd)
            .on(mouseEvs.tapmove, NS_CURRENT, scena.onTapMove);
    }

    function bindHover(){
        broadcast
            .on(mouseEvs.leave, NS_CURRENT, scena.onHoverEnd)
            .on(hoverEvs.hovered, NS_CURRENT, scena.onHover)
            .on(mouseEvs.move, NS_CURRENT, scena.onMouseMove);
    }

    // drop all scenarious
    function removeScenarios(){
        broadcast
            .off(NS_CURRENT)
    }

    function scenarioNotMove(){
        logger.log('scenarioNotMove');
        broadcast
            .on(hoverEvs.hovered, NS_CURRENT, scena.onHover)
            .on(mouseEvs.tapstart, NS_CURRENT, scena.onMouseMoveHover)
            .on(mouseEvs.move, NS_CURRENT, scena.onMouseMoveHover);
        bindScales();
    }

    // set view scenarioCtrl
    function scenarioView(){
        logger.log('scenarioView');
        bindTaps();
        bindScales();
    }

    // set edit scenarioCtrl
    function scenarioEdit(){
        logger.log('scenarioEdit');
        bindScales();
    }

    function scenarioSelect(){
        logger.log('scenarioSelect');
        bindHover();
        bindScales();
    }

    // change scenarioCtrl mode
    scenarioCtrl.switchScenarios = function (name){
        if (!working){
            return this;
        }
        removeScenarios();
        var method = scenarios[name];
        if (method){
            logger.log(name);
            var el = engineDom.getBaseElement();
            currentScenario && domHelper.removeClass(el, scenaCssName + currentScenario);
            currentScenario = name;
            domHelper.addClass(el, scenaCssName + currentScenario);
            method();
            broadcast.trig(scenarioEvs.scenarioChanged, name);
        }
        return this;
    };

    scenarioCtrl.runDefaultScenario = function(){
        scenarioCtrl.switchScenarios(SC_VIEW);
    };

    scenarioCtrl.getCurrentScenario = function(){
        return currentScenario;
    };

    scenarioCtrl.startScaling = onScaleStart;
    scenarioCtrl.changeScale = function(){
        scena.onScale.apply(scena, arguments);
    };
    scenarioCtrl.stopScaling = onScaleStop;

    // register scenarioCtrl

    function start(){
        working = true;
        var moveCtrl = subPixel('move-ctrl');
        domHelper = subPixel('dom-helper');
        scena = moveCtrl.scena;
    }

    function stop(){
        working = false;
        broadcast.off(NS_CURRENT);
        removeScenarios();
    }

    utils.bindStartStop(start, stop);

    subPixel('scenario-ctrl', scenarioCtrl);

})(subPixel);
