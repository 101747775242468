/*
 * Project:   PlotPad HTML5 Viewer
 * File:      build-version.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/29/2017
 */
(function(subPixel, undefined){

    var utils = subPixel('utils');

    // format variable can be changed WITH tasks/build-version.js grunt task !!!
    var format = {
        dateInLocale: '',
        toString: function () {
            var str = format.major + '.' + format.minor + '.' + format.build + ' (' + format.dateInLocale + ' / ' + format.revision + ')';
            return str;
        },
        description: 'this is version control',
        changeset: '',//{{version-changeset}}
        date: '',//{{version-date}}
        major: 0,//{{version-major}}
        minor: 0,//{{version-minor}}
        build: 0,//{{version-build}}
        branch: 0,//{{version-branch}}
        revision: ''//{{version-revision}}
    };

    if (format.changeset == ''){
        // used NOT build version of engine, just putting version inside here
        format.minor = 0;
        format.major = 0;
        format.build = 5;
    }

    var viewDate = format.date ? (new Date(format.date)) : new Date();
    format.dateInLocale = viewDate.toLocaleString();

    subPixel('interface').register({
        version: function () {
            var ret = {};

            for (var key in format){
                ret[key] = format[key];
            }

            return ret;
        }
    });

})(subPixel);
