/*
 * Project:   PlotPad HTML5 Viewer
 * File:      logger.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 08/30/2014
 */
/*
 * Processing logging module
 *
 * var logging = app('logging');
 *
 * logging.log('myModule', 'some messageError');
 * //or
 * logging.log('myModule', 'some messageError', data, data2, etc...);
 *
 * */
(function (subPixel) {
    var logging = {};
    var utils;
    var getTimeInterval = subPixel('time-interval');

    logging.LEVEL_DEV = 5;
    logging.LEVEL_PROD = 0;
    logging.LEVEL_WARN = 4;

    var logLevel = logging.LEVEL_DEV;

    var prevTime = (new Date()).getTime();
    var timeIds = {};

    // wrap for window.console
    var manConsole = (window['console'] && window.console) || {
            log: function () {
                //it's a cap
            },
            warn: function () {
                //it's a cap
            },
            info: function () {
                //it's a cap
            },
            warning: function () {
                //it's a cap
            },
            error: function () {
                //it's a cap
            },
            time: function () {
                //it's a cap
            },
            timeEnd: function () {
                //it's a cap
            }
        };

    function getArgs(args, count) {
        var args = Array.prototype.slice.apply(args);
        args.splice(0, count);
        return args;
    }

    function apply(action, args, doLog) {
        if (manConsole[action]) {
            args = getArgs(args);
            var nowTime = (new Date()).getTime();
            var firstArg = ":sp:#" + args[0] + ' [' + getTimeInterval(prevTime, nowTime) + "]: ";
            prevTime = nowTime;
            args[0] = firstArg;
            if (doLog === undefined || doLog) {
                manConsole[action].apply(manConsole, args);
            }

            args.splice(0, 0, action);
            args = null;
        }
    }

    // set log level
    logging.setLevel = function (lev) {
        logLevel = lev;
    };

    logging.getLevel = function () {
        return logLevel;
    };

    // first argument is module name, where error appear, others - it's just a params to show...
    logging.log = function () {
        apply('log', arguments, logLevel);
    };

    logging.warning = logging.warn = function () {
        apply('warn', arguments);
    };

    logging.error = function () {
        apply('error', arguments);
    };

    logging.info = function () {
        apply('info', arguments);
    };

    logging.time = function (id, message) {
        if (logLevel) {
            if (!timeIds[id]) {
                timeIds[id] = [];
            }
            pushTime(id, message);
        }
    };

    logging.timeEnd = function (id, message, header) {
        if (logLevel) {
            !header && (header = id);
            if (timeIds[id]) {
                var timer = timeIds[id];
                pushTime(id, message);
                var title = "time";
                var total = 0;
                logging.warn(header, "---------- '" + id + "' ----------");
                for (var i = 0, l = timer.length - 1; i < l; i++) {
                    var item = timer[i];
                    var nextItem = timer[i + 1];
                    var dx = nextItem.time - item.time;
                    total += dx;
                    logging.warn(title, item.message + " : " + dx + 'ms');
                }
                var last = timer[timer.length - 1];
                logging.warn(title, "total: " + total + "ms | " + last.message);
                logging.warn(header, "----------------------------");

                timer.length = 0;
                timer = null;
                timeIds[id] = null;
            } else {
                logging.warning('timeEnd', 'wrong End for "' + id + '" timer');
            }
        }
    };

    function pushTime(id, message) {
        message = message || id;
        timeIds[id].push({
            time: getNow(),
            message: message
        });
    }

    function getNow() {
        return (new Date()).getTime();
    }

    var mainLogger = null;
    // Logger
    function LoggerClass(name) {
        this._name = name;
        this._enable = true;
    }

    function applyLogger(name, type, params) {
        var args = getArgs(params);
        args.splice(0, 0, name);
        logging[type].apply(logging, args);
    }

    function canILog() {
        var ret = this._enable;
        if (mainLogger && this != mainLogger) {
            ret = false;
        }
        return ret;
    }

    var p = LoggerClass.prototype;

    p.enable = function (val) {
        if (val !== undefined) {
            this._enable = val;
        }
        return this._enable;
    };

    p.setAsMain = function (val) {
        if (val) {
            mainLogger && (mainLogger._isMain = false);
            mainLogger = this;
        }
    };

    p.log = function () {
        utils.enable && canILog.call(this) && applyLogger(this._name, 'log', arguments);
    };

    p.error = function () {
        canILog.call(this) && applyLogger(this._name, 'error', arguments);
    };

    p.warn = p.warning = function () {
        canILog.call(this) && applyLogger(this._name, 'warn', arguments);
    };

    p.info = function () {
        utils.enable && canILog.call(this) && applyLogger(this._name, 'info', arguments);
    };

    p.time = function () {
        canILog.call(this) && logging.time.apply(logging, arguments);
    };

    p.timeEnd = function (id, message) {
        canILog.call(this) && logging.timeEnd.call(logging, id, message, this._name);
    };

    p.winJsLog = function (p1, p2, p3) {
        if (utils.enable && canILog.call(this)) {
            this.log.apply(this, arguments);
            window.jsObject && window.jsObject.log('---- >' + p1, p2, p3);
        }
    };

    function createLogger(name) {
        !utils && (utils = subPixel('utils'));
        return new LoggerClass(name);
    }

    subPixel('logger', createLogger);

})(subPixel);
