/*
 * Project:   PlotPad HTML5 Viewer
 * File:      codes.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/28/2014
 */
(function(subPixel, undefined){
    var externalCodes = {
        // system error codes
        0: 'ok', // all is ok

        10: ' - %s',
        11: "Doesn't have start callback for .bindStartStop()",

        50: 'manifest file is not exist. %s',
        51: 'publish file is not exist. %s',
        52: 'chunk "%s" is not exist',
        53: 'image "%s" is not exist',

        // error figures codes
        117: 'cannot detect type of "%s" chunk'
    };


    subPixel('codes', externalCodes);

})(subPixel);
