/*
 * Project:   PlotPad HTML5 Viewer
 * File:      object.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/29/2016
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var figure = parts('figure');
    var utils = subPixel('utils');
    var BasePartClass = parts('base');
    var crossingHelper = parts('crossing-helper');
    var config = subPixel('config');

    var themeMod = subPixel('theme-module');
    var drawTheme = themeMod('object-theme-draw');

    var chunkManager = subPixel('chunk-manager');
    var G_NAME = chunkManager.TYPE_OBJECT;

    var SUB_TYPE = 'Selectable Object';

    var f360 = 2 * Math.PI;

    var SUB_FILL_COLOR = 'rgba(0, 255, 0, 0.5)';
    var SUB_HOVER_COLOR = 'rgba(255, 0, 0, 0.5)';

    var drawOptHelper = parts('draw-options-helper');
    var drawOptObj = drawOptHelper({
        forceFill: true,
        fillStyle: null,
        lineJoin: 'round',

        _canDrawTheme: false
    });

    function drawCircle(x, y, r, opt){
        var ctx = canvas.buffCtx;
        var drawParams = this.drawParams;

        if (drawParams.fillStyle || drawParams.strokeStyle){
            ctx.beginPath();
            ctx.strokeStyle = 'transparent';
            ctx.arc(x, y, r, f360, false);
            ctx.stroke();

            var optObj = opt;

            if (opt && opt.isSelection){
                drawOptObj.fillStyle = SUB_FILL_COLOR;
                optObj = drawOptObj;
            }

            if (BasePartClass.setFillStyle(ctx, drawParams, optObj)){
                ctx.fill();
            }


            ctx.closePath();
        }

    }

    function updateBbox(inst, point){
        var nPoints = [];
        var r = point.r;

        nPoints.push({x: point.x + r, y: point.y + r});
        nPoints.push({x: point.x - r, y: point.y - r});

        inst.initBoundingBox(nPoints);
    }

    var defObject = utils.createChildClass(figure, 'defObjectClass', {
        init: function(data){
            var props = this.props;
            var oType = this.props._oType = data.type;

            // if (oType == SUB_TYPE && data.components && data.components.length) {
            //     var mess = data.components;
            //     for (var i = 0, l = mess.length; i < l; i++){
            //         if (mess[i].point){
            //             mess[i].point.x -= 100;
            //         }
            //     }
            // }

            defObject._parent.init.apply(this, arguments);
            props.drawSelection = true;
            props.layerGroup = G_NAME;
            props._themes = null;
            props._themeBgColor = null;
            this.customGroup(data.group);
            // this._layerIndex = data.layerIndex;
            var layerName = chunkManager.getLayerNameByIndex(data.layerIndex);
            props.layerName = layerName;
            props.layerNameLower = (layerName + '').toLowerCase();
            this.customProps(data.properties);
            var labelPos = this.props._labelPosition = {
                x: undefined,
                y: undefined
            };
            if (data.labelPosition){
                var dataLabelPos = (data.labelPosition + '').split('|');
                var labelPosX = (dataLabelPos[0] - 0);
                var labelPosY = (dataLabelPos[1] - 0);
                !isNaN(labelPosX) && (labelPos.x = labelPosX);
                !isNaN(labelPosY) && (labelPos.y = labelPosY);
            }
            var cPoints = this.props._circlePoints = {
                x: 0,
                y: 0,
                r: 0
            };
            if (oType == SUB_TYPE){
                //special objects
                var drawParams = this.drawParams;
                drawParams.fillStyle = SUB_FILL_COLOR;
                props.priorityHover = 10;
                // create circle from object points
                var bbox = this.getBoundingBox();
                cPoints.r = Math.max(bbox.w, bbox.h) / 2;
                cPoints.x = bbox.minx + bbox.w / 2;
                cPoints.y = bbox.miny + bbox.h / 2;
                this.propPointsType(crossingHelper.TYPE_CIRCLE);
                updateBbox(this, cPoints);
            }
        },
        labelPosition: function(){
            return this.props._labelPosition;
        },
        prepareThemeClip: function(ctx){
            var oType = this.props._oType;
            if (oType == SUB_TYPE){
                var cPoint = this.props._circlePoints;
                var pos = canvas.getRetransformedPoint(cPoint.x, cPoint.y);
                var pos2 = canvas.getRetransformedPoint(cPoint.x + cPoint.r, cPoint.y);
                var r = pos2.x - pos.x;
                ctx.strokeStyle = 'transparent';
                ctx.arc(pos.x, pos.y, r, f360, false);
                ctx.stroke();
                pos = pos2 = null;
            } else {
                this.processScreenFigureComponents(
                    ctx.moveTo,
                    ctx.lineTo,
                    ctx.quadraticCurveTo,
                    ctx.bezierCurveTo,
                    ctx
                );
            }
        },
        // customLayerIndex: function(){
        //     return this._layerIndex;
        // },
        customGroup: function(val){
            if (val !== undefined){
                this.props._customGroup = val;
            }
            return this.props._customGroup;
        },
        customProps: function(val){
            if (val !== undefined){
                var newProps = [];
                if (utils.isArray(val)){
                    utils.map(val, function(prop){
                        var nProp = createPropItem(prop);
                        newProps.push(nProp);
                    });
                }
                this.props._customProps = newProps;
            }
            return this.props._customProps;
        },
        customObjectType: function () {
            return this.props._oType;
        },
        labels: function(val){
            if (val !== undefined){
                this.props.labels = val;
            }
            return this.props.labels;
        },
        themes: function(val){
            if (val !== undefined){
                if (val === null){
                    if (utils.isArray(this.props._themes)){
                        this.props._themes.length = 0;
                    }
                }
                this.themeBgColor(null);
                this.props._themes = val;
            }
            return this.props._themes;
        },
        objectId: function(){
            var initData = this.data();
            return initData.objId || initData.id;
        },
        getCenterPoint: function(){
            return this.props._circlePoints;
        },
        getRadius: function(){
            return this.props._circlePoints.r;
        },
        getInternalRadius: function () {
            return 0;
        },
        draw: function(drawOpt){
            var oType = this.props._oType;
            if (oType == SUB_TYPE){
                var cPoint = this.props._circlePoints;
                drawCircle.call(this, cPoint.x, cPoint.y, cPoint.r, drawOpt);
            } else {
                defObject._parent.draw.apply(this, arguments);
            }
            if (drawOpt._canDrawTheme && !drawOpt.isSelection) {
                this.drawTheme();
            }
        },
        themeBgColor: function(val){
            if (val !== undefined){
                this.props._themeBgColor = val ? utils.colorToStr(val) : null;
            }
            return this.props._themeBgColor;
        },
        drawTheme: function(){
            var themes = this.props._themes;
            if (themes && utils.isArray(themes)) {
                drawTheme.call(this, themes);
            }
        },
        onHover: function(){
            var ctx = canvas.buffCtx;
            var fillStyleColor = config.groupEditColor;
            if (this.props._oType == SUB_TYPE) {
                fillStyleColor = SUB_HOVER_COLOR;
            }
            drawOptObj.fillStyle = fillStyleColor;
            this.draw(drawOptObj);

            ctx.closePath();
            ctx.stroke();
        }
    });

    parts('defObject', defObject);


    defObject.G_NAME = G_NAME;
    defObject.SUB_TYPE = SUB_TYPE;

    function createPropItem(item){
        var native = item.native;
        var nativeVal = native ? native.value || '' : item.nativeValue || '';
        var nativeDataType = native ? native.dataType || '' : item.nativeDataType || '';
        var ret = {
            name: item.name || '',
            category: item.category || '',
            nativeDataType: nativeDataType,
            nativeValue: nativeVal,
            value: item.value || ''
        };
        return ret;
    }



})(subPixel);
