/*
 * Project:   PlotPad HTML5 Viewer
 * File:      events.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/17/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var events = cafm.events = {};
    var helper = cafm.helpers;
    var utils = subPixel('utils');

    function bindSelectManagerEvents(){
        var oldObjsStr = '';
        var selected = {};
        var deselected = {};
        var oldSelected = {};

        subPixel.on('sm-changed', function(){
            var selIds = subPixel.getSelectedElementsIds();
            var newIdsStr = JSON.stringify(selIds);
            if (newIdsStr != oldObjsStr){
                oldObjsStr = newIdsStr;

                utils.clearObject(deselected);

                for (var key in selected){
                    deselected[key] = true;
                }

                utils.clearObject(oldSelected);
                utils.map(selIds, function(id){
                    oldSelected[id] = true;
                    delete deselected[id];
                });

                var selectedArr = [];
                for (var key in oldSelected){
                    if (selected[key]){
                        // exist
                     } else {
                        selected[key] = true;
                        selectedArr.push(key);
                    }
                }

                var deselectedArr = [];
                for (var key in deselected){
                    delete selected[key];
                    deselectedArr.push(key);
                }

                if (deselectedArr.length){
                    var deSelObjIds = helper.getObjIdsFromFiguresIds(deselectedArr);
                    events.OnDeselect(deSelObjIds);
                }

                if (selectedArr.length){
                    var selObjIds = helper.getObjIdsFromFiguresIds(selectedArr);
                    events.OnSelect(selObjIds);
                }


            }
        });
    }

    function bindMouseEvents(){
        subPixel.on('mouse-dbl-tap', function(){
            var objId = processMouseEv();
            if (objId){
                events.OnDblClickObject(objId);
            }
        });

        subPixel.on('mouse-tap-end', function(){
            var objId = processMouseEv();
            if (objId){
                var mousePos = subPixel.getCanvasMousePos();
                events.OnClickObject(objId, mousePos.x, mousePos.y);
            }
        });
    }

    function processMouseEv(){
        var objId;
        if (subPixel.isScenarioSelect()){
            var figs = subPixel.getElementsByMousePos();
            utils.map(figs, function(el){
                if (el.objectId){
                    objId = el.objectId();
                    return false;
                }
            });
        } else if (subPixel.isScenarioEdit()){
            //if in edit labels mode
            subPixel.processDynamicElements(function(fig){
                if (fig.isAccessEdit() && fig.getObjectId){
                    objId = fig.getObjectId();
                }
            });
        }
        return objId;
    }

    function bindHoverEvents(){
        var lastOverId;
        subPixel.on('hm-hovered', function(els){
            var objs = helper.getObjFromFigures(els);
            var lastObj = objs[0];
            var id = null;
            if (lastObj){
                id = lastObj.objectId();
            }
            if (id != lastOverId){
                events.OnOver(id);
            }
            lastOverId = id;
        });
    }

    function bindLabelsEvents(){
        subPixel.on('editor-ge-changed', function(fig){
            var data = fig.data();
            var struct = fig.getStruct && fig.getStruct();
            if (struct && struct.pos && data.toFigId){
                var sPos = struct.pos;
                events.onLabelEditEnd(data.toFigId, struct.scale, sPos.x, sPos.y, struct.background, struct.opacity);
            } else {
                events.onShapeEditEnd(struct);
            }
        });
    }

    function bindShapeEvents(){
        subPixel.on('anno-el-added', function(fig){
            var struct = fig.getStruct && fig.getStruct();
            if (struct) {
                events.onShapeCreate(struct);
            }
        });
    }

    events.OnError = function(errNum, errDesc){

    };

    events.OnSelect = function(objIds){

    };

    events.OnDeselect = function(objIds){

    };

    events.OnOver = function(objId){

    };

    events.onDocumentLoadComplete = function(isLoadSuccess){

    };

    events.onLabelEditEnd = function(id, scale, x, y, background, opacity){

    };

    events.OnLabelsLoaded = function(){

    };

    events.onDocumentRenderComplete = function(){

    };

    events.OnClickObject = function(objId, x, y){

    };

    events.OnDblClickObject = function(objId){

    };

    events.OnDblClickLabel = function(objId){

    };

    events.onShapeCreate = function(obj){

    };

    events.onShapeEditEnd = function(obj){

    };

    bindSelectManagerEvents();
    bindMouseEvents();
    bindHoverEvents();
    bindLabelsEvents();
    bindShapeEvents();

})(cafm);
