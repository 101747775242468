/*
 * Project:   PlotPad HTML5 Viewer
 * File:      to-object-ns.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/20/2016
 */
(function(cafm, undefined){

    var dropMethods = {
        wrapMethods: true,
        initViewer: 'true'
    };

    cafm.wrapMethods = function(obj){
        for (var key in cafm){
            var val = cafm[key];
            if (typeof val == "function" && !dropMethods[val]){
                obj[key] = val;
            }
        }

        var events = cafm.events;

        for (var key in events){
            (function(key){
                var methodOld = events[key];
                events[key] = function(){
                    methodOld.apply(events, arguments);
                    if (obj[key]){
                        obj[key].apply(events, arguments);
                    }
                };
            })(key);
        }
    };


})(cafm);