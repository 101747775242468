/*
 * Project:   PlotPad HTML5 Viewer
 * File:      time-info.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/16/2016
 */
(function (subPixel) {
    var utils = subPixel('utils');
    var getTimeInterval = subPixel('time-interval');

    function TimeInfoClass(name, params){
        this._name = name;
        this._startTime = 0;
        this._endTime = 0;
        this._prevTime = 0;
    }

    function getNowMS(){
        return (new Date()).getTime();
    }

    utils.extendClass(TimeInfoClass, {
        startTimer: function(){
            this._prevTime = this._startTime = this._endTime = getNowMS();
        },
        portionTimer: function(name){
            var nowTime = getNowMS();
            var dx = getTimeInterval(this._prevTime, nowTime);
            this._prevTime = nowTime;
            return name + ' in ' + dx;
        },
        stopTimer: function(){
            this._endTime = getNowMS();
        },
        getTimerInfo: function(){
            var total = getTimeInterval(this._startTime, this._endTime);
            return this._name + ' in ' + total;
        }
    });

    subPixel('time-info', function(name, params){
        return new TimeInfoClass(name, params);
    });

})(subPixel);
