/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.brick.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('brick', 20, 16, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);
        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(14.95, 0);
        ctx.lineTo(13.9, 0);
        ctx.lineTo(13.9, 2.95);
        ctx.lineTo(4.95, 2.95);
        ctx.lineTo(4.95, 0);
        ctx.lineTo(3.9, 0);
        ctx.lineTo(3.9, 2.95);
        ctx.lineTo(0, 2.95);
        ctx.lineTo(0, 3.95);
        ctx.lineTo(8.95, 3.95);
        ctx.lineTo(8.95, 6.95);
        ctx.lineTo(0, 6.95);
        ctx.lineTo(0, 8);
        ctx.lineTo(3.9, 8);
        ctx.lineTo(3.9, 10.95);
        ctx.lineTo(0, 10.95);
        ctx.lineTo(0, 11.95);
        ctx.lineTo(8.95, 11.95);
        ctx.lineTo(8.95, 14.95);
        ctx.lineTo(0, 14.95);
        ctx.lineTo(0, 16);
        ctx.lineTo(20, 16);
        ctx.lineTo(20, 10.95);
        ctx.lineTo(14.95, 10.95);
        ctx.lineTo(14.95, 8);
        ctx.lineTo(20, 8);
        ctx.lineTo(20, 2.95);
        ctx.lineTo(14.95, 2.95);
        ctx.lineTo(14.95, 0);
        ctx.moveTo(13.9, 8);
        ctx.lineTo(13.9, 10.95);
        ctx.lineTo(4.95, 10.95);
        ctx.lineTo(4.95, 8);
        ctx.lineTo(13.9, 8);
        ctx.moveTo(18.95, 3.95);
        ctx.lineTo(18.95, 6.95);
        ctx.lineTo(10, 6.95);
        ctx.lineTo(10, 3.95);
        ctx.lineTo(18.95, 3.95);
        ctx.moveTo(10, 11.95);
        ctx.lineTo(18.95, 11.95);
        ctx.lineTo(18.95, 14.95);
        ctx.lineTo(10, 14.95);
        ctx.lineTo(10, 11.95);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
