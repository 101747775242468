/*
 * Project:   PlotPad HTML5 Viewer
 * File:      nearest-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){

    var parts = subPixel('parts');
    var utils = subPixel('utils');

    var crossingHelper = parts('crossing-helper');
    var getDistance = utils.getDistance;

    // this variable is for more clean of memory.
    // we don't need to create new object on each .getNearestPoint...() method
    var nearPoint = {
        distance: 0,
        x: 0,
        y: 0
    };

    function setNearest(x, y, len){
        nearPoint.x = x;
        nearPoint.y = y;
        nearPoint.distance = len;
    }

    function compareDistance(point, x, y, minLen){
        var len = getDistance(x, point.x, y, point.y);
        if (minLen >= len){
            minLen = len;
            setNearest(x, y, len);
        }
        return minLen;
    }


    function getNearestPointBox(fig, point){
        var figPoints = fig.getHelpPoints();
        nearPoint.x = point.x;
        nearPoint.y = point.y;
        var p1 = figPoints[0];
        var p2 = figPoints[1];
        var minLen = Infinity;

        minLen = compareDistance(point, p1.x, p1.y, minLen);
        minLen = compareDistance(point, p2.x, p2.y, minLen);
        minLen = compareDistance(point, p1.x, p2.y, minLen);
        compareDistance(point, p2.x, p1.y, minLen);

        return nearPoint;
    }


    function getNearestPointPoly(fig, po){
        var poly = fig.getHelpPoints();
        nearPoint.x = po.x;
        nearPoint.y = po.y;

        var minLen = Infinity;
        for (var i = 0, l = poly.length; i < l; i++){
            var item = poly[i];
            var len = getDistance(item.x, po.x, item.y, po.y);
            if (minLen >= len){
                minLen = len;
                setNearest(item.x, item.y, len);
            }
        }
        return nearPoint;
    }

    function getNearestPointCircle(fig, point){
        // :todo add this method for circles

        var centerPoint = fig.getCenterPoint();
        var len = getDistance(centerPoint.x, point.x, centerPoint.y, point.y);
        var radius = fig.getRadius();


        var minLen = Math.abs(len - radius);

        if (compareDistance(point, centerPoint.x, centerPoint.y, minLen) == minLen) {
            var x1 = ((radius * (point.x - centerPoint.x)) / len) + centerPoint.x;
            var y1 = ((radius * (point.y - centerPoint.y)) / len) + centerPoint.y;
            nearPoint.distance = minLen;
            nearPoint.x = x1;
            nearPoint.y = y1;
        }

        return nearPoint;
    }


    var nearestPointMethods = {};
    nearestPointMethods[crossingHelper.TYPE_CIRCLE] = getNearestPointCircle;
    nearestPointMethods[crossingHelper.TYPE_BOX] = getNearestPointBox;
    // :todo add detect nearest points for text
    // nearestPointMethods[crossingHelper.TYPE_TEXT] = ???

    nearestPointMethods[crossingHelper.TYPE_LINE] =
        nearestPointMethods[crossingHelper.TYPE_POLY] =
            getNearestPointPoly;

    parts('nearest-helper', {
        getNearestPoint: function (fig, po) {
            var ret;
            if (crossingHelper.isBoxCrossingBox(fig.getBoundingBox(), po)) {
                //after check poly lines
                var type = fig.propPointsType();
                var method = nearestPointMethods[type];

                if (!method) {
                    method = nearestPointMethods[crossingHelper.TYPE_POLY];
                } // as default

                ret = method.call(this, fig, po);
            }
            return ret;
        }
    });

})(subPixel);
