/*
 * Project:   PlotPad HTML5 Viewer
 * File:      zoom-to.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');

    var base = tools('base-tool');

    function drawBox(ctx, points, w, h){
        var fromx = points[0].x;
        var fromy = points[0].y;

        ctx.beginPath();
        ctx.fillRect(fromx, fromy, w, h);
        ctx.rect(fromx, fromy, w, h);
        ctx.stroke();
        ctx.closePath();
    }

    var TRESHOLD = 3;


    var ZoomToClass = utils.createChildClass(base, 'zoomToClass');

    var p = ZoomToClass.prototype;

    p.onMouseStart = function(){
        editor.startDraw();
        this.points.length = 0;
        var ctx = this.ctx;

        styles.want(ctx); // define default styles

        ctx.fillStyle = "rgba(0, 53, 255, 0.2)";
        ctx.strokeStyle = "rgb(97, 97, 255)";


        var point = { x: mouse.x, y: mouse.y };
        this.startPoint = canvas.getTransformedPoint(point.x, point.y);

        this.points.push(point);
        this.points.push(point);
    };


    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        points[1] = {x: mouse.x, y: mouse.y};
        points[1] = {x: mouse.x, y: mouse.y};
        var w = points[1].x - points[0].x;
        var h = points[1].y - points[0].y;
        drawBox(ctx, points, w, h);
    };

    p.onMouseEnd = function(){
        editor.stopDraw();
        var endPoint = canvas.getTransformedPoint(mouse.x, mouse.y);
        var startPoint = this.startPoint;
        var minx = Math.min(endPoint.x, startPoint.x);
        var maxx = Math.max(endPoint.x, startPoint.x);
        var miny = Math.min(endPoint.y, startPoint.y);
        var maxy = Math.max(endPoint.y, startPoint.y);
        var w = maxx - minx;
        var h = maxy - miny;
        if ((w > TRESHOLD) &&
            (h > TRESHOLD)){
            subPixel.zoomTo({
                x: minx,
                y: miny,
                w: w,
                h: h
            });
        }
    };

    p.onToolDeselect = function(){

    };

    p.createFigureData = function(){
        return false;
    };

    tools(ZoomToClass, {
        isPrimary: true,
        mode: scenarioCtrl.edit,
        name: 'zoom-to',
        title: 'zoom-to',
        text: 'zoom-to'
    });
})(subPixel);
