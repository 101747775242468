/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cross.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoBox = parts('anno-cross');


    var base = tools('anno-base');

    var crossClass = utils.createChildClass(base, 'crossClass');

    var p = crossClass.prototype;

    p.onMouseStart = function(){
        crossClass._parent.onMouseStart.call(this);

        var point = { x: mouse.x, y: mouse.y };
        this.points.push(point);
        this.points.push(point);
    };


    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        points[1] = {x: mouse.x, y: mouse.y};
        annoBox.drawCross(ctx, points);
    };

    base.pushTool(crossClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-cross',
        zoomWindow: true,
        title: 'cross',
        text: 'cross'
    });
})(subPixel);
