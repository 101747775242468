/*
 * Project:   PlotPad HTML5 Viewer
 * File:      polygon-chunk.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 29/5/2017
 */
(function (subPixel, undefined) {

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var crossingHelper = parts('crossing-helper');

    var polygonClass = utils.createChildClass(AnnoBaseClass, 'polygonChunkClass', {
        init: function(data) {
            this._struct = this.createStruct();
            var points = data.points;
            // this.props.rotating = true;

            this.initAnnoDrawParams(data);
            this.updateBoundingBox(points);
            this.propPointsType(crossingHelper.TYPE_POLY);
            this.propEditingMode('polygon-mode');
        },
        draw: function(opt) {
            var ctx = canvas.buffCtx;
            var points = this.data().points;
            // points = this.getHelpPoints();

            var filled = !!opt.fillStyle;

            this.setAnnoDrawStyles(opt);

            if (this.rotationAngle() !== 0) {
                var angle = this.rotationAngle();
                var sbox = this.getScreenBounds();
                var translateX = sbox.minx + sbox.w / 2;
                var translateY = sbox.miny + sbox.h / 2;

                ctx.save();
                canvas.rotateAroundPoint(ctx, angle, translateX, translateY);
                polygonClass.drawPolygon(ctx, points, filled);
                ctx.restore();
            } else {
                polygonClass.drawPolygon(ctx, points, filled);
            }
        },
        getPolygonPoints: function(){
            return this.data().points;
        },
        updateBoundingBox: function(points) {
            var sPoints = [];

            for(var i = 0; i < points.length; i++){
                sPoints.push({ x:points[i].x, y: points[i].y });
            }

            this.initBoundingBox(sPoints);
        },
        createStruct: function() {
            var data = this.data();
            var bPoints = this.getPolygonPoints();
            return {
                points: bPoints,
                name: data.name,
                id: data.id,
                styles: data.styles
            };
        },
        getStruct: function() {
            return this._struct;
        },
        objectId: function() {
            return this.data().id;
        },
        canDraw: function() {
            var ret = AnnoBaseClass._parent.canDraw.apply(this, arguments);
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function(ctx, points) {
            this.setAreaCtx(ctx);
            polygonClass.drawPolygon(ctx, points, false);
        },
        setDimensions: function(points, angle) {
            var ctx = canvas.buffCtx;
            this.rotationAngle(angle);

            this.updateBoundingBox(points);
            this.data().points = this._struct.points = points;

            polygonClass.drawPolygon(ctx, points, false);
        }
    });

    polygonClass.drawPolygon = function(ctx, points, filled) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);

        for(var i = 1; i < points.length; i++){
            ctx.lineTo(points[i].x, points[i].y);
        }

        ctx.stroke();
        ctx.closePath();
        if(filled) {
            ctx.fill();
        }
    };

    parts('anno-polygon', polygonClass);

})(subPixel);
