/*
 * Project:   PlotPad HTML5 Viewer
 * File:      object-label.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/01/2016
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var textClass = parts('text');

    var ObjectLabelClass = utils.createChildClass(textClass, 'objectLabelClass', {
        init: function(){
            ObjectLabelClass._parent.init.apply(this, arguments);
            var props = this.props;
            props.drawSelection = true;
            props.editing = true;
            this._editingProps = {
                x: 0,
                y: 0,
                scaleVal: 1
            };
        },
        drawText: function(ctx, data){
            var bbox = this.getBoundingBox();
            ObjectLabelClass.drawText(ctx, data, bbox);
        },
        canDraw: function(){
            var ret = ObjectLabelClass._parent.canDraw.apply(this, arguments);
            var initData = this.data();
            if (ret && initData.visibleScale){
                ret = scale.val >= initData.visibleScale;
            }
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function(ctx, x, y, w, h, useAligns){
            var data = this.data();
            var drawParams = this.drawParams;
            ctx.fillStyle = drawParams.strokeStyle;
            ctx.lineWidth = scale.lineWidth;
            var eProps = this._editingProps;
            var bbox = this.getScreenBounds();
            var align = data.align;
            var baseLine = data.baseline;
            x = x + 0;
            y = y + 0;

            if (useAligns !== false){
                x += textClass.getXByAlign(w, align);
                y += textClass.getYByBaseline(h, baseLine);
            }

            eProps.x = x;
            eProps.y = y;
            w && (eProps.scaleValX = scale.val * w / bbox.w);
            h && (eProps.scaleValY = scale.val * h / bbox.h);
            ObjectLabelClass.drawText(ctx, data, bbox, eProps);
        },
        setDimensions: function(x, y, w, h){
            var initData = this.data();
            var screenBbox = this.getScreenBounds();

            var align = initData.align;
            var baseLine = initData.baseline;

            var oldSize = initData.font.size;
            var oldW = screenBbox.w;
            var dxw = w - oldW;

            var k = oldSize / oldW;
            var newFontSize = oldSize + k * dxw;
            initData.font.size = newFontSize;

            this.init(initData);

            var bbox = this.getBoundingBox();

            var pos = initData.position;
            x += textClass.getXByAlign(bbox.w, align);
            y += textClass.getYByBaseline(bbox.h, baseLine);
            pos.x = x;
            pos.y = y;

            this.init(initData);
        }
    });

    function drawTextRect(ctx, data, bbox){

        var px = data.position.x;
        var py = data.position.y;

        ctx.beginPath();
        ctx.lineWidth = scale.val;
        var th = bbox.h;
        var tw = bbox.w;
        var x = bbox.minx;
        var y = bbox.miny;
        ctx.rect(x, y, tw, th);
        ctx.fillStyle = 'rgba(0,0,0, 0.5)';
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.lineWidth = scale.val;
        ctx.rect(px, py, 10, 10);
        ctx.fillStyle = 'rgba(255,255,0, 0.8)';
        ctx.fillStyle = 'rgba(0,0,0, 1)';
        ctx.fill();
        ctx.closePath();

    }

    ObjectLabelClass.drawText = function(ctx, data, bbox, x, y){
        textClass.drawText(ctx, data, null, x, y);

        // draw center point helper
        //drawTextRect(ctx, data, bbox);
    };

    parts('object-label', ObjectLabelClass);


})(subPixel);
