/*
 * Project:   PlotPad HTML5 Viewer
 * File:      zoom.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var holdover = subPixel('holdover');

    function zoomInOut(val){
        var holder = holdover(true);
        var currValue = subPixel.zoom();
        currValue = currValue + (val - 0);
        !isNaN(currValue) && subPixel.zoom(currValue, holder.await());
        return holder;
    }

    cafm.ZoomIn = function(val){
        return zoomInOut(val);
    };

    cafm.ZoomOut = function(val){
        return zoomInOut(-(val - 0));
    };

    cafm.ZoomExtents = function(){
        return subPixel.fitToScreen();
    };

    cafm.ZoomObjects = function(objIds){
        var holder = holdover(true);
        var correctIds = cafm.helpers.getFiguresIdsFromObjIds(objIds);
        subPixel.focusElement(correctIds, holder.await());
        return holder;
    };

    cafm.ShowZoomPanel = function(val){
        return subPixel.zoomPanelVisible(val);
    };

})(cafm);