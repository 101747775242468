/*
 * Project:   PlotPad HTML5 Viewer
 * File:      chunk-manager.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/15/2016
 */
(function(subPixel, undefined){

    var utils = subPixel('utils');
    var inter = subPixel('interface');
    var parts = subPixel('parts');
    var holdover = subPixel('holdover');
    var lm = subPixel('layer-manager');
    var imageLoaderClass = subPixel('image-loader-class');
    var imageLoader = new imageLoaderClass({name: 'canvas'});
    var logger = subPixel('logger')('chunk-manager');
    var drawFig;
    var textFig;
    var imgFig;
    var defObject;

    var undefinedLayer = lm.CONST_UNDEFINED_LAYER;

    var TYPE_OBJECT = 'objects';
    var TYPE_DRAWN = 'drawings';

    var totalDrawnChunks = 0;
    var totalObjectChunks = 0;

    var M_DRAWN_CHUNKS = 'drawing-chunks';
    var M_OBJECT_CHUNKS = 'object-chunks';

    var drawnParts = [];
    var objectParts = [];

    var viewLayersMap = {};
    var viewGroupsMap = {};
    var viewObjectIdsMap = {};
    var viewLayersNames = [];
    var viewLayersNamesIndexes = [];
    var viewGroupsNames = [];
    var viewObjectIdsNames = [];

    var chunkManager = {
        _canCreateElements: true,
        _layersMap: {
            indexToName: {},
            nameToIndex: {}
        },
        _groupsMap: {
            indexToName: {},
            nameToIndex: {}
        },
        _objectIdsMap: {
            indexToName: {},
            nameToIndex: {}
        },
        _drawnChunksMap: {},
        _objectChunksMap: {},
        TYPE_OBJECT: TYPE_OBJECT,
        TYPE_DRAWN: TYPE_DRAWN,
        getTypeByFileName: function (fName) {
            var ret = TYPE_OBJECT;
            if (fName.indexOf('drawing') == 0){
                ret = TYPE_DRAWN;
            }
            return ret;
        },
        getLayerNameByIndex: function (index) {
            return this._layersMap.indexToName[index];
        },
        dropData: function () {
            dropElements();
            imageLoader.abort();
            this.dropObjects();
            this.dropDrawings();
            totalDrawnChunks = 0;
            totalObjectChunks = 0;
        },
        dropDrawings: function (layers) {
            if (!layers){
                drawnParts.length = 0;
                utils.clearObject(viewLayersMap);
                viewLayersNames.length = 0;
                viewLayersNamesIndexes.length = 0;
            } else if (utils.isArray(layers)){
                utils.map(layers, function (layer) {
                    viewLayersMap[layer] = null;
                    delete viewLayersMap[layer];
                    var pos = viewLayersNames.indexOf(layer);
                    if (pos != -1){
                        viewLayersNames.splice(pos, 1);
                    }
                    pos = viewLayersNamesIndexes.indexOf(layer);
                    if (pos != -1){
                        viewLayersNamesIndexes.splice(pos, 1);
                    }
                });
            }
        },
        dropObjects: function (objList, groupList) {
            if (!objList && !groupList){
                objectParts.length = 0;
            }

            if (!groupList){
                utils.clearObject(viewGroupsMap);
                viewGroupsNames.length = 0;
            } else if (utils.isArray(groupList)){
                utils.map(groupList, function (group) {
                    viewGroupsMap[group] = null;
                    delete viewGroupsMap[group];
                    var pos = viewGroupsNames.indexOf(group);
                    if (pos != -1){
                        viewGroupsNames.splice(pos, 1);
                    }
                });
            }

            if (!objList){
                utils.clearObject(viewObjectIdsMap);
                viewObjectIdsNames.length = 0;
            } else if (utils.isArray(objList)){
                utils.map(objList, function (objId) {
                    viewObjectIdsMap[objId] = null;
                    delete viewObjectIdsMap[objId];
                    var pos = viewObjectIdsNames.indexOf(objId);
                    if (pos != -1){
                        viewObjectIdsNames.splice(pos, 1);
                    }
                });
            }
        },
        getLayersNames: function () {
            return viewLayersNames;
        },
        getLayersNamesIndexes: function () {
            return viewLayersNamesIndexes;
        },
        getGroupsNames: function () {
            return viewGroupsNames;
        },
        getObjectIdsNames: function () {
            return viewObjectIdsNames;
        },
        setOptions: function (opt) {
            this._loadObjects = opt.loadObjectChunk;
            this._objectsAsFirst = opt.loadObjectChunkFirst;
            this._sheetUrl = opt.url;
            this._canCreateElements = opt.canCreateElements;
        },
        getProcessedFigures: function () {
            var ret = [];
            if (this._objectsAsFirst){
                //
                totalObjectChunks = collectFigures(ret, objectParts);
                totalDrawnChunks = collectFigures(ret, drawnParts);
            } else {
                totalDrawnChunks = collectFigures(ret, drawnParts);
                totalObjectChunks = collectFigures(ret, objectParts);
            }
            return ret;
        },
        isManifestCorrect: function (manifest) {
            var ret = false;
            if (manifest){
                if (manifest.formats && manifest.formats.html5){
                    ret = true;
                }
            }
            return ret;
        },
        processManifest: function (manifest) {
            this.dropData();

            createLinkMap(this._layersMap, manifest.layers);
            createLinkMap(this._groupsMap, manifest.groups);
            createLinkMap(this._objectIdsMap, manifest.objects, 'id');

            var formats = manifest.formats;

            var drawnChunks = formats.html5[M_DRAWN_CHUNKS];
            createChunkIndex(this._drawnChunksMap, drawnChunks);

            utils.clearObject(this._objectChunksMap);

            if (this._loadObjects) {
                var objectChunks = formats.html5[M_OBJECT_CHUNKS];
                createChunkIndex(this._objectChunksMap, objectChunks);
            }
            return manifest;
        },
        getAllChunks: function (manifest) {
            var formats = manifest.formats;
            var ret = [];
            collectAllLayers(manifest);
            var drawnChunks = formats.html5[M_DRAWN_CHUNKS];
            var objectChunks = formats.html5[M_OBJECT_CHUNKS];
            utils.push(ret, drawnChunks);
            this._loadObjects && utils.push(ret, objectChunks);
            return ret;
        },
        processChunks: function (manifest, layers, groups, objIds) {
            // prepare list of all chunks to load
            var ret = [];

            var formats = manifest.formats;
            var drawnChunks = formats.html5[M_DRAWN_CHUNKS];
            var pushedChunks = {};

            collectLayers(manifest, layers);

            pushName(undefinedLayer, viewLayersMap, viewLayersNames);

            function onChunk(chunk) {
                if (!pushedChunks[chunk]){
                    pushedChunks[chunk] = true;
                    ret.push(chunk);
                }
            }

            // process drawns
            collectChunksByMap(layers, this._layersMap, drawnChunks, manifest['drawing-chunks-to-layers-map'],
                function(layerName){
                    pushName(layerName, viewLayersMap, viewLayersNames);
                }, onChunk);

            if (this._loadObjects){
                // process objects
                var objectChunks = formats.html5[M_OBJECT_CHUNKS];
                // collectChunksByMap(layers, this._layersMap, objectChunks, manifest['object-chunks-to-layers-map'],
                //     function(layerName){
                //         pushName(layerName, viewLayersMap, viewLayersNames);
                //     }, onChunk);

                collectChunksByMap(groups, this._groupsMap, objectChunks, manifest['object-chunks-to-groups'],
                    function(groupName){
                        pushName(groupName, viewGroupsMap, viewGroupsNames);
                    }, onChunk);

                collectChunksByMap(objIds, this._objectIdsMap, objectChunks, manifest['object-chunks-to-objects-map'],
                    function(objId){
                        pushName(objId, viewObjectIdsMap, viewObjectIdsNames);
                    }, onChunk);
            }

            onChunk = null;

            return ret;
        },
        processObjects: function (cb) {
            var elements = subPixel.elements();
            var startPos = subPixel.getPosStartObjects();
            var endPos = subPixel.getPosEndObjects();
            utils.map(elements, cb, startPos, endPos);
        },
        waitImageLoader: function (onProgress, onComplete) {
            if (imageLoader.isLoading()){
                imageLoader.setProcessors({
                    onProgress: function(perc){
                        onProgress(perc);
                    },
                    onAllLoaded: function(){
                        onComplete();
                    }
                });
            } else {
                onComplete();
            }
        },
        addLoadedChunk: function (chunkName, data, onComplete) {
            var type;

            if (this._drawnChunksMap.hasOwnProperty(chunkName)){
                type = TYPE_DRAWN;
            } else if (this._objectChunksMap.hasOwnProperty(chunkName)){
                type = TYPE_OBJECT;
            } else {
                // error
                onComplete(117, chunkName);
            }

            if (type){
                // create chunks list for collect figures
                var index = (type == TYPE_DRAWN) ? this._drawnChunksMap[chunkName] : this._objectChunksMap[chunkName];

                var chunksParts;
                if (type == TYPE_DRAWN){
                    chunksParts = drawnParts[index] = drawnParts[index] || [];
                } else {
                    chunksParts = objectParts[index] = objectParts[index] || [];
                }


                chunksParts.length = 0;
                processLoadedChunk.call(this, data, type, chunksParts, onComplete);
                onComplete(0);
            }
        },
        dropOnAllImagesLoaded: function () {
            imageLoader.dropProcessors({
                onAllLoaded: true
            });
        }
    };

    function collectAllLayers(manifest) {
        if (manifest.layers && utils.isArray(manifest.layers)){
            utils.map(manifest.layers, function (layer) {
                var name = layer.name;
                var index = layer.index;
                var pushed = pushName(name, viewLayersMap, viewLayersNames);
                if (pushed){
                    viewLayersNamesIndexes[index] = name;
                }
            });
        }
    }

    function collectLayers(manifest, layers) {
        if (manifest.layers && utils.isArray(manifest.layers)){
            utils.map(manifest.layers, function (layer) {
                if (layers.indexOf(layer.name) != -1) {
                    var name = layer.name;
                    var index = layer.index;
                    var pushed = pushName(name, viewLayersMap, viewLayersNames);
                    if (pushed) {
                        viewLayersNamesIndexes[index] = name;
                    }
                }
            });
        }
    }

    function pushName(name, map, arr) {
        var ret = false;
        if (!map[name]){
            map[name] = true;
            arr.push(name);
            ret = true;
        }
        return ret;
    }

    function collectFigures(toArr, fromParts) {
        var total = 0;
        utils.map(fromParts, function (list) {
            list && utils.map(list, function (fig) {
                toArr.push(fig);
                total++;
            });
        });
        return total;
    }

    function processLoadedChunk(figures, chunkType, chunksParts, onComplete) {
        var self = this;
        var layerName;
        utils.map(figures, function(subItem){
            if (subItem){
                if (subItem.figures){
                    //drawn chunks ?
                    layerName = subItem.layerName;
                    utils.map(subItem.figures, function(el){
                        // creating array of drawing figures and objects...
                        var opt = {
                            layerName: layerName,
                            hover: false
                        };
                        processFigure.call(self, chunksParts, el, opt, chunkType, onComplete);
                    });
                    // subItem.figures.length = 0;
                    // subItem.figures = null;
                    // subItem = null;
                } else if (subItem && chunkType == TYPE_OBJECT){
                    // object chunks
                    layerName = undefined;
                    if (subItem.hasOwnProperty('layerIndex')){
                        layerName = viewLayersNamesIndexes[subItem.layerIndex];
                    }
                    var opt = {
                        layerName: layerName,
                        hover: true
                    };
                    processFigure.call(self, chunksParts, subItem, opt, chunkType, onComplete);
                }
            }
        });
    }

    function processFigure(subFigures, el, opt, chunkType, onComplete){
        if (el){
            // figures
            var fig;
            var self = this;
            var isPushed = false;
            opt.layerGroup = TYPE_DRAWN;
            if (chunkType == TYPE_OBJECT){
                if (el.components && defObject) {
                    opt.layerGroup = TYPE_OBJECT;
                    fig = makeFigure(defObject, el, opt);
                }
            } else {
                // default figure
                if (el.components && drawFig){
                    fig = makeFigure(drawFig, el, opt);
                } else
                //text
                if (el.font && textFig){
                    fig = makeFigure(textFig, el, opt);
                } else
                //images
                if (el.hasOwnProperty('image-url') && imgFig){
                    var item = {}; // subitem for correct pos of element
                    fig = item;
                    var sheetUrl = self._sheetUrl;
                    (function(figItem, el, opt){
                        var imgUrl = sheetUrl + el['image-url'];
                        imageLoader.load(imgUrl, function(error, imageItem){
                            var imgEl = imageItem.img;
                            // indexOf needed for correct detect position in elements
                            var pos = subFigures.indexOf(figItem);
                            opt = opt || {};
                            opt.image = imgEl;
                            var newEl = makeFigure(imgFig, el, opt);

                            if (pos != -1){
                                subFigures[pos] = newEl;
                            } else if (!isPushed){
                                fig = newEl;
                            }

                            if (error){
                                if (imageItem.error){
                                    onComplete(53, imageItem.message);
                                }
                            }
                        });
                    })(item, el, opt);
                } else {
                    // trying to create undefined part
                    logger.error('trying to create undefined part', el);
                }
            }

            fig && subFigures.push(fig);
            isPushed = true;
            el = null;
        } else {
            logger.info('no figure', el);
        }
    }

    function makeFigure(FigureClass, el, opt) {
        var ret;
        if (chunkManager._canCreateElements){
            ret = new FigureClass(el, opt);
        } else {
            ret = {}; // as cap
        }
        return ret;
    }

    function collectChunksByMap(filterNames, helpedMap, chunks, chunkToObject, filterCb, cb) {
        // filterNames == filled array -> load filtered data
        // filterNames == empty array -> nothing to load ???
        // filterNames == not defined -> load all data
        if (filterNames){
            // need process
            if (filterNames.length == 0 || utils.isEmpty(chunkToObject)){
                return;
            }
            var filtersNamesMap = {};
            utils.map(filterNames, function (name) {
                var index = helpedMap.nameToIndex[name];
                filtersNamesMap[index] = name;
                filterCb(name);
            });
            utils.map(chunks, function (name) {
                var canProcess = false;
                var objectIndexes = chunkToObject[name];
                if (objectIndexes){
                    if (utils.isArray(objectIndexes)){
                        utils.map(objectIndexes, function (index) {
                            if (filtersNamesMap.hasOwnProperty(index)) {
                                canProcess = true;
                                return false;
                            }
                        });
                    }
                }
                canProcess && cb(name);
            });
        // } else {
            // just process all data
            // for (var name in helpedMap.nameToIndex){
            //     filterCb(name);
            // }

            // just put all chunks
            // utils.map(chunks, function (name) {
            //     cb(name);
            // });
        }
    }

    function createLinkMap(toCreate, map, field) {
        utils.clearObject(toCreate.indexToName);
        utils.clearObject(toCreate.nameToIndex);
        var indexToName = toCreate.indexToName;
        var nameToIndex = toCreate.nameToIndex;
        field = field || 'name';
        if (map){
            function processItem(item) {
                var index = item.index;
                var name = item[field];
                indexToName[index] = name;
                nameToIndex[name] = index;
            }
            if (utils.isArray(map)){
                utils.map(map, processItem);
            } else {
                for (var key in map){
                    processItem(map[key]);
                }
            }
        }
    }

    function createChunkIndex(toCreate, chunks){
        utils.clearObject(toCreate);
        utils.map(chunks, function (chunk, index) {
            toCreate[chunk] = index;
        });
    }

    function dropElements() {
        subPixel.dropElements();
        subPixel.clearSelection();
        subPixel.hoverElements();// drop hovers
    }

    subPixel('chunk-manager', chunkManager);


    inter.register({
        getPosStartObjects: function(){
            var ret = totalDrawnChunks;
            if (chunkManager._objectsAsFirst){
                ret = 0;
            }
            return ret;
        },

        getPosEndObjects: function(){
            var ret = totalDrawnChunks + totalObjectChunks;
            if (chunkManager._objectsAsFirst){
                ret = totalObjectChunks;
            }
            ret--;
            return ret;
        }
    });

    function start(){
        drawFig = parts('figure');
        textFig = parts('text');
        imgFig = parts('image');
        defObject = parts('defObject');
        !defObject && (defObject = drawFig);
    }

    function stop(){

    }

    utils.bindStartStop(start, stop);

})(subPixel);
