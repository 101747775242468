/*
 * Project:   PlotPad HTML5 Viewer
 * File:      group-editing.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/06/2016
 */
(function(subPixel){

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var broadcast = subPixel('broadcast');
    var mouseEvs = broadcast.events('mouse');
    var scenarioEvs = broadcast.events('scenario-ctrl');
    var drawingCtrlEvs = broadcast.events('drawing-ctrl');
    var scaleEvs = broadcast.events('scale');
    var sheetLoaderEvs = broadcast.events('sheet-loader');
    var area = editor('area');
    var scale = subPixel('scale');
    var BasePartClass = parts('base');
    var crossingHelper = parts('crossing-helper');
    var config = subPixel('config');

    var editingModes = editor('editing-modes');
    var baseEditingMode = editingModes('base');

    var CONST_ACCESS_KEY = BasePartClass.A_EDIT_MODE;

    var modeExternalParams;
    var modeName = 'base';
    var currentEditingMode;

    var groupEditing = {
        editing: true
    };

    var THRESHOLD_MOUSE = 3;

    var clipArea = {};
    var colorGroupEdit;

    function getClickedElement(){
        var pos = canvas.getTransformedPoint(mouse.x, mouse.y);
        var minPoint = canvas.getTransformedPoint(mouse.x - THRESHOLD_MOUSE, mouse.y - THRESHOLD_MOUSE);
        var maxPoint = canvas.getTransformedPoint(mouse.x + THRESHOLD_MOUSE, mouse.y + THRESHOLD_MOUSE);

        var x = pos.x;
        var y = pos.y;
        clipArea.minx = minPoint.x;
        clipArea.miny = minPoint.y;
        clipArea.maxx = maxPoint.x;
        clipArea.maxy = maxPoint.y;
        clipArea.w = clipArea.maxx - clipArea.minx;
        clipArea.h = clipArea.maxy - clipArea.miny;
        clipArea.x = x;
        clipArea.y = y;

        var ret = null;
        subPixel.processDynamicElements(function(el){
            if (el.canEdit()){
                el.setAccess(CONST_ACCESS_KEY, false);
                if (crossingHelper.detectCrossSelection.call(el, clipArea)) {
                    ret && ret.setAccess(CONST_ACCESS_KEY, false);
                    el.setAccess(CONST_ACCESS_KEY, true);
                    ret = el;
                }
            }
        });
        return ret;
    }

    function drawHover() {
        if (!modeExternalParams.moved && modeExternalParams.currFig && !modeExternalParams.isSizing && !modeExternalParams.isRotating){
            // detect hover
            var old = modeExternalParams.regionsPos;
            currentEditingMode.detectRegionPos();
            updateModeExternalParams();
            if (old !== modeExternalParams.regionsPos){
                var ctx = area.ctx;
                currentEditingMode.draw();
                ctx.save();

                ctx.beginPath();
                ctx.lineWidth = scale.defLineWidth;
                ctx.fillStyle = modeExternalParams.colorGroupEdit;
                ctx.strokeStyle = modeExternalParams.colorGroupEdit;

                for (var key in modeExternalParams.hoverRegions){
                    var reg = modeExternalParams.hoverRegions[key];
                    if (key == 0 && key === modeExternalParams.regionsPos) {
                        var r = reg.w / 2;
                        ctx.clearRect(reg.x - 1, reg.y - 1, reg.w + 2, reg.h + 2); // small fix to clear whole circle
                        ctx.arc(reg.x + r, reg.y + r, r, 2 * Math.PI, false);
                    } else if (key === modeExternalParams.regionsPos) {
                        ctx.clearRect(reg.x, reg.y, reg.w, reg.h);
                        ctx.rect(reg.x, reg.y, reg.w, reg.h);
                        ctx.strokeRect(reg.x, reg.y, reg.w, reg.h);
                    }
                }
                ctx.stroke();
                ctx.fill();

                ctx.closePath();
                ctx.restore();
            }
        }
    }

    function processBroadcast(action) {
        broadcast
            [action]([mouseEvs.tapmove, mouseEvs.move], onScreenMouseMove)
            [action](scenarioEvs.scaleStart, onScaleStart)
            [action](drawingCtrlEvs.endDrawing, onDrawEnd)
            [action](scenarioEvs.scaleStop, onScaleStop)
            [action](scaleEvs.onZoomExtend, onScaleStop)
            [action](sheetLoaderEvs.started, onSheetLoad);
    }

    function onScreenMouseMove() {
        if(modeExternalParams) {
            drawHover();
        }
    }

    function onScaleStart () {
        area.clear();
    }

    function onSheetLoad () {
        currentEditingMode = new baseEditingMode;;
        modeName = 'base';
        currentEditingMode.setColorGroup(colorGroupEdit);
        currentEditingMode.start();
        updateModeExternalParams();
        area.clear();
    }

    function onDrawEnd () {
        if(currentEditingMode) {
            currentEditingMode.checkExist();
            if (modeExternalParams.currFig) {
                currentEditingMode.draw(!modeExternalParams.isSizing);
            } else {
                currentEditingMode.clearDraw();
            }
        }
    }

    function onScaleStop () {
        if(currentEditingMode) {
            currentEditingMode.checkExist();
            if (modeExternalParams.currFig) {
                currentEditingMode.processFig(modeExternalParams.currFig);
            }
        }
    }

    utils.extend(groupEditing, {
        init: function () {
        },
        start: function () {
            currentEditingMode = new baseEditingMode;
            currentEditingMode.setColorGroup(colorGroupEdit);
            currentEditingMode.start();
            updateModeExternalParams();
            processBroadcast('on');
        },
        stop: function () {
            processBroadcast('off');
            currentEditingMode.stop();
            currentEditingMode = null;
            modeName = null;
        },
        onMouseEnd: function () {
            currentEditingMode.onMouseEnd();
            updateModeExternalParams();
        },
        onMouseMove: function () {
            currentEditingMode.onMouseMove();
            updateModeExternalParams();
        },
        onMouseStart: function () {
            currentEditingMode.onMouseStart();
            updateModeExternalParams();

            if (!modeExternalParams.isSizing && !modeExternalParams.isRotating) {
                var fig = getClickedElement();
                if (fig) {
                    var mName = fig.propEditingMode();
                    if (mName !== modeName) {
                        setCurrentMode(mName);
                        currentEditingMode.onMouseStart();
                    }
                }
                currentEditingMode.processFig(fig);
            } else {
                currentEditingMode.processFig(modeExternalParams.currFig);
            }
            updateModeExternalParams();
        }
    });

    function setCurrentMode(name) {
        currentEditingMode.clearDraw();
        currentEditingMode.stop();

        var mode = editingModes(name);
        currentEditingMode = new mode;
        modeName = name;
        currentEditingMode.setColorGroup(colorGroupEdit);
        currentEditingMode.start();
    }

    function updateModeExternalParams() {
        modeExternalParams = currentEditingMode.getExternalParams();
    }

    subPixel('group-editing', groupEditing);

    function start() {
        colorGroupEdit = config.groupEditColor;
    }

    function stop() {

    }

    utils.bindStartStop(start, stop);

})(subPixel);
