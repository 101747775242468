/*
 * Project:   PlotPad HTML5 Viewer
 * File:      dom-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/11/2014
 */
(function (subPixel, undefined) {
    var utils = subPixel('utils');

    var CSS3_TRANSFORM = utils.browserPrefix + 'transform';
    var CSS3_ORIGIN = utils.browserPrefix + 'transform-origin';

    var detachFragment = document.createDocumentFragment();

    var domHelper = {
        closest: function (el, className) {
            var ret = null;
            if (typeof className == "string") {
                ret = findClosestByClassName(el, className);
            } else {
                ret = findClosestByEl(el, className)
            }

            return ret;
        },
        show: function (el) {
            el.style.display = 'block';
        },
        hide: function (el) {
            el.style.display = 'none';
        },
        addClass: function (el, newClass) {
            var classes = el.className.split(" ");
            if (classes.indexOf(newClass) == -1) {
                classes.push(newClass);
            }
            el.className = classes.join(" ");
        },
        removeClass: function (el, remClass) {
            var classes = el.className.split(" ");
            var pos = classes.indexOf(remClass);
            if (pos != -1) {
                classes.splice(pos, 1);
            }
            el.className = classes.join(" ");
        },
        insertAfter: function (elem, refElem) {
            var parent = refElem.parentNode;
            var next = refElem.nextSibling;
            if (next) {
                return parent.insertBefore(elem, next);
            } else {
                return parent.appendChild(elem);
            }
        },
        moveToFragment: function (el) {
            detachFragment.appendChild(el);
        },
        setCSSTransform: function (container, offsetx, offsety, centerX, centerY, scaleVal, rotateVal) {
            // DON'T USE left/top, margins to change offset, because it will be not good view on android devices...

            var style = container.style;
            // centerX = Math.floor(centerX);
            // centerY = Math.floor(centerY);
            // offsetx = Math.floor(offsetx);
            // offsety = Math.floor(offsety);
            //container.clientHeight;
            var val = (centerX - offsetx) + 'px ' + (centerY - offsety) + 'px 0px' ;
            (style[CSS3_ORIGIN] != val) && (style[CSS3_ORIGIN] = val);
            (style['transform-origin'] != val) && (style['transform-origin'] = val);
            (style['transformOrigin'] != val) && (style['transformOrigin'] = val);

            // var transVal = "translateX(" + offsetx + "px) translateY(" + offsety + "px) scale("+ scaleVal +") rotate(" + rotateVal + "deg)";
            var transVal = "translate3d(" + offsetx + "px, " + offsety + "px, 0px) scale("+ scaleVal +") rotate(" + rotateVal + "deg)";
            (style[CSS3_TRANSFORM] != transVal) && (style[CSS3_TRANSFORM] = transVal);
            (style['transform'] != transVal) && (style['transform'] = transVal);
        }
    };

    function findClosestByClassName(el, className) {
        className = className.toLowerCase();
        do {
            if (el && el.className !== undefined) {
                var elClass = el.className.toLowerCase();
                var classes = elClass.split(' ');
                if (classes.indexOf(className) != -1) {
                    return el;
                }
            }
        } while (el = el.parentNode);
    }

    function findClosestByEl(child, parent) {
        do {
            if (child && child === parent) {
                return child;
            }
        } while (child = child.parentNode);
    }

    subPixel('dom-helper', domHelper);
})(subPixel);
