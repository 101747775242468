/*
 * Project:   PlotPad HTML5 Viewer
 * File:      canvas-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/25/2016
 */
(function (subPixel) {

    var devicePixelRatio = window.devicePixelRatio || 1;

    function setHdpi(canvas, w, h, useDef) {

        // var context = canvas.getContext('2d');
        //
        // var backingStoreRatio = context.webkitBackingStorePixelRatio ||
        //     context.mozBackingStorePixelRatio ||
        //     context.msBackingStorePixelRatio ||
        //     context.oBackingStorePixelRatio ||
        //     context.backingStorePixelRatio || 1;
        //
        // var ratio = devicePixelRatio / backingStoreRatio;
        // get the canvas and context

        // finally query the various pixel ratios


        // upscale the canvas if the two ratios don't match
        // if (devicePixelRatio !== backingStoreRatio && useDef !== false) {
        //
        //     canvas.width = w * ratio;
        //     canvas.height = h * ratio;
        //
        //     canvas.style.width = w + 'px';
        //     canvas.style.height = h + 'px';
        //
        //     // now scale the context to counter
        //     // the fact that we've manually scaled
        //     // our canvas element
        //     context.setTransform(1,0,0,1,0,0);
        //     context.scale(ratio, ratio);
        // } else {
        //     canvas.width = w;
        //     canvas.height = h;
        // }
        //
        canvas.width = w;
        canvas.height = h;

    }

    subPixel('canvas-helper', {
        setHdpi: setHdpi
    });

})(subPixel);
