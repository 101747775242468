/*
 * Project:   PlotPad HTML5 Viewer
 * File:      arrow-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/12/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var toolsHelper = subPixel('tools-helper');
    var crossingHelper = parts('crossing-helper');

    var CONST_DEFAULT_HEAD_LENGTH = 10;  // length of head in pixels

    var figure = utils.createChildClass(AnnoBaseClass, 'arrowChunkClass');

    figure.drawArrow = function(ctx, points){
        var fromx = points[0].x;
        var fromy = points[0].y;

        var tox = points[1].x;
        var toy = points[1].y;

        var headlen = toolsHelper.getScaledValue(points, CONST_DEFAULT_HEAD_LENGTH);
        var angle = Math.atan2(toy-fromy,tox-fromx);
        ctx.beginPath();

        ctx.moveTo(fromx, fromy);
        ctx.lineTo(tox, toy);

        ctx.moveTo(tox, toy);
        ctx.lineTo(tox - headlen*Math.cos(angle-Math.PI/6),toy - headlen*Math.sin(angle-Math.PI/6));

        ctx.moveTo(tox, toy);
        ctx.lineTo(tox - headlen*Math.cos(angle+Math.PI/6),toy - headlen*Math.sin(angle+Math.PI/6));

        ctx.stroke();
        ctx.closePath();
    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        // for figure from parameters getting for now only 'color' and 'filled' value
        this.initAnnoDrawParams(data);

        this.initBoundingBox(data.points);

        this.propPointsType(crossingHelper.TYPE_LINE);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var points = this.data().points;

        this.setAnnoDrawStyles(opt);

        figure.drawArrow(ctx, points);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-arrow', figure);


})(subPixel);
