/*
 * Project:   PlotPad HTML5 Viewer
 * File:      size.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var utils = subPixel('utils');
    var styles = editor('styles');

    var scenarioCtrl = subPixel('scenario-ctrl');
    var domHelper = subPixel('dom-helper');


    var base = tools('base-tool');

    var container = document.createElement('div');
    var overflowEl = document.createElement('div');
    overflowEl.className = 'tool-sizes-overflow tools-overflow';
    container.appendChild(overflowEl);
    domHelper.moveToFragment(container);
    container.className = 'tool-el-sizes tool-el-container';


    var values = [0.5, 1, 2, 4];

    (function (){
        for (var i = 0, l = values.length; i < l; i++){
            var el = document.createElement('div');
            el.className = 'item ' + getItemClass(i);
            el.setAttribute('data-pos', i);
            container.appendChild(el);
        }
    })();

    function getItemClass(pos) {
        return 'tool-size-item-' + pos;
    }

    var sizeTool = utils.createChildClass(base, 'size-tool', {
        onStart: function () {
            clearStyles.call(this);
            var value = styles.globals.lineWidth;
            var pos = values.indexOf(value);
            setCurrentSize.call(this, pos);
        },
        onToolSelect: function () {
            domHelper.show(container);
        },
        onToolDeselect: function () {
            domHelper.hide(container);
        },
        onToolAdded: function () {
            this.getEl().appendChild(container);
        },
        onClick: function (e) {
            var target = domHelper.closest(e.target, 'item');
            var pos = target && target.getAttribute('data-pos');
            setCurrentSize.call(this, pos);
        }
    });

    function setCurrentSize(pos) {
        if (pos !== undefined) {
            var el = this.getEl();
            clearStyles.call(this);
            var className = getItemClass(pos);
            domHelper.addClass(el, className);
            var sizeVal = values[pos];
            styles.globals.lineWidth = sizeVal;
        }
    }

    function clearStyles() {
        var el = this.getEl();
        utils.map(values, function (val, pos) {
            var className = getItemClass(pos);
            domHelper.removeClass(el, className);
        });
    }

    tools(sizeTool, {
        mode: scenarioCtrl.view,
        zoomWindow: false,
        name: 'size-tool',
        isExtTool: true,
        title: 'size',
        text: 'size'
    });


})(subPixel);
