/*
 * Project:   PlotPad HTML5 Viewer
 * File:      main.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/29/2013
 */
/*
* subPixel defined in window, by default
* license: mit
* author: idudiq
* created: 2013-10-29
* */

window.subPixel =

(function(){
//this file for build
    function logError(msg){
        window['console'] && window['console'].error('subPixel error: ' + msg);
    }
    // checking empty object
    function isEmptyObject( obj ) {
        for (var name in obj ) {
            return false;
        }
        return true;
    }

    function Mod(){

        //defined components in module instance
        var components = {};

        function moduleC(name, component, params){
            //set/get components
            if (typeof name == "string"){
                if (component !== undefined){
                    if (components[name] !== undefined){
                        logError('component "' + name + '" is already defined. terminating...');
                        return;
                    }

                    //defining new component is here
                    var newComp = (typeof component == "function")
                        ? component
                        : (isEmptyObject(component))
                        ? new Mod()
                        : component;

                    components[name] = newComp;
                }
                if (name && !components[name]){
                    if (params && params.silent){
                        // do nothing
                    } else {
                        logError('component "' + name + '" is not defined...');
                    }
                    return;
                }

                return components[name];
            }
        }

        return moduleC;
    }

    var subPixel = new Mod();

    //{{output_code}}

    return subPixel;
})();
