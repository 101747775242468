/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme-manager.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/29/2016
 */
(function(subPixel, undefined){
    var utils = subPixel('utils');
    var themeMod = subPixel('theme-module');
    var ThemeClass = themeMod('theme-class');
    var elementsCtrl = subPixel('elements-ctrl');
    var drawingCtrl = subPixel('drawing-ctrl');

    var inter = subPixel('interface');

    var themeManager = {};

    var collection = [];

    themeManager.setThemeObjects = function(list, cb){
        if (utils.isArray(list)){
            utils.map(list, function(opt){
                var inst = new ThemeClass(opt);
                collection.push(inst);
            });
            drawingCtrl.redraw(cb);
        } else {
            cb && cb();
        }
    };

    themeManager.removeThemes = function(elIds, cb){
        var map = {};
        var useIds = false;
        if (utils.isArray(elIds)){
            useIds = true;
            utils.map(elIds, function(id){
                map[id] = true;
            });
        }

        for (var i = collection.length - 1; i >=0; i--){
            var coll = collection[i];
            var collObjects = coll.objects();
            if (collObjects){
                for (var j = collObjects.length - 1; j >=0; j--){
                    var figId = collObjects[j];
                    if (useIds){
                        if (map[figId]) {
                            objectDropTheme(figId);
                            collObjects.splice(j, 1);
                        }
                    } else {
                        objectDropTheme(figId);
                        collObjects.splice(j, 1);
                    }
                }
                if (collObjects.length == 0){
                    coll.destroy();
                    collection.splice(i, 1);
                }
            }
        }
        drawingCtrl.redraw(cb);
    };


    function objectDropTheme(figId){
        var el = elementsCtrl.getElementById(figId);
        if (el){
            el.themes(null);
        }
    }


    subPixel('theme-manager', themeManager);


    inter.register('setThemeObjects', themeManager.setThemeObjects);

    inter.register('removeThemesByObjects', themeManager.removeThemes);


})(subPixel);
