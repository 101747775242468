/*
 * Project:   PlotPad HTML5 Viewer
 * File:      area.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/06/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var offsetEvs = broadcast.events('offset');
    var canvasEl = subPixel('canvas-el');
    var domHelper = subPixel('dom-helper');
    var config = subPixel('config');
    var engineDom = subPixel('engine-dom');

    var canvEl = canvasEl();
    var bcanv = canvEl.getEl();
    bcanv.className = "area-canvas can-click-area";
    domHelper.moveToFragment(bcanv);
    var bctx = canvEl.getCtx();

    var attachedEl = null;

    var defBbox = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
    };

    var area = {
        ctx: bctx,
        place: bcanv,

        clear: function (bbox) {
            bbox = bbox || defBbox;
            area.ctx.clearRect(bbox.x, bbox.y, bbox.w, bbox.h);
            return this;
        },
        attach: function () {
            this.clear();
            var subCanvas = engineDom.getCanvasElement();
            defBbox.w = bcanv.width = subCanvas.width;
            defBbox.h = bcanv.height = subCanvas.height;
            domHelper.insertAfter(bcanv, subCanvas.parentNode);
            attachedEl = subCanvas;
            return this;
        },
        show: function () {
            bcanv.style.display = 'block';
            return this;
        },
        hide: function () {
            bcanv.style.display = 'none';
            return this;
        },
        detach: function () {
            domHelper.moveToFragment(bcanv);
            attachedEl = null;
            return this;
        }
    };


    function onResize(){
        if (attachedEl){
            defBbox.w = bcanv.width = attachedEl.width;
            defBbox.h = bcanv.height = attachedEl.height;
        }
    }

    function processBroadcast(action){
        broadcast
            [action](offsetEvs.resize, onResize);
    }

    function start(){
        processBroadcast('on');
    }

    function stop(){
        processBroadcast('off');
    }

    utils.bindStartStop(start, stop);


    editor('area', area);


})(subPixel);
