/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/16/2014
 */
(function(subPixel){
    var editor = subPixel('editor');
    var scenarios = editor('tools-scenarios');
    var utils = subPixel('utils');

    function BaseScenario(tool){
        this.tool = tool;
        this.init();
    }

    utils.extendClass(BaseScenario, {
        init: function () {
            this.drop();
        },
        onMouseStart: function (ev) {
            this.tapDown = true;
            this.tool.onMouseStart(ev);
        },
        onMouseMove: function (ev) {
            this.tapDown && this.tool.onMouseMove(ev);
        },
        onMouseEnd: function (ev) {
            this.tapDown = false;
            this.tool.onMouseEnd(ev);
        },
        onMouseDblTap: function (ev) {
            this.tapDown = false;
            this.tool.onMouseDblTap && this.tool.onMouseDblTap(ev);
        },
        finishDrawing: function () {
            this.tool.finishDrawing && this.tool.finishDrawing();
        },
        accurateDrawing: function(constrain) {
            this.tool.accurateDrawing && this.tool.accurateDrawing(constrain);
        },
        onMouseFreeMove: function (ev) {
            this.tool.onMouseFreeMove && this.tool.onMouseFreeMove(ev);
        },
        drop: function () {
            this.tapDown = false;
        },
        getTool: function () {
            return this.tool;
        }
    });

    scenarios('base', BaseScenario);

})(subPixel);
