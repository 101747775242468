/*
 * Project:   PlotPad HTML5 Viewer
 * File:      engine.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/10/2017
 */
(function(subPixel, undefined){
    // exportable modules
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');
    var configBlocks = subPixel('config-blocks');
    var engineDom = subPixel('engine-dom');
    var logger = subPixel('logger')('engine');

    var systemEvs = broadcast.events('system');

    var isStopped = true;

    // init config, defined by user
    //
    // newConfig - config structure
    function initConfig(newConfig){
        for (var key in newConfig){
            config[key] = newConfig[key];
        }
    }

    //external interface

    // call callback, when DOM is ready
    subPixel.ready = function (callback){
        utils.ready(callback);
        return this;
    };

    // start subPixel
    //
    // conf - config
    subPixel.start = function(conf){
        if (!isStopped){
            this.stop();
        }
        if (conf.el){
            utils.clearObject(config);
            initConfig(conf);
            configBlocks(config);

            engineDom.buildIndicators(config);
            engineDom.buildDom(config);

            logger.log('system init');
            isStopped = false;
            broadcast.trig(systemEvs.start);
        } else {
            logger.error('Wrong View ELEMENT, terminating...');
        }
        return this;
    };

    // stop subPixel
    subPixel.stop = function() {
        isStopped = true;
        broadcast.trig(systemEvs.stop);
//        subPixel('userBroadcast').clean();
        utils.clearObject(config);
        return this;
    };

    subPixel.isStopped = function(){
        return isStopped;
    };

})(subPixel);
