/*
 * Project:   PlotPad HTML5 Viewer
 * File:      parts.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/30/2013
 */
/*
* parts defined module
* collect all parts
* */
(function(subPixel, undefined){

    // defined parts
    var data = {};
    var utils = subPixel('utils');

    // defined single part into storage
    function parts(name, val) {
        name && (val !== undefined) && (data[name] = val);
        if (!data[name]){
            utils.info('part is not defined, is it all ok?', name);
        }
        return data[name];
    }

    // register parts
    subPixel('parts', parts);

})(subPixel);
