/*
 * Project:   PlotPad HTML5 Viewer
 * File:      box.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var area = editor('area');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoBox = parts('anno-box');

    var base = tools('anno-base');

    var boxClass = utils.createChildClass(base, 'boxClass');

    var p = boxClass.prototype;

    p.onMouseStart = function(){
        boxClass._parent.onMouseStart.call(this);

        var point = { x: mouse.x, y: mouse.y };
        this.points.push(point);
        this.points.push(point);
    };


    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        points[1] = {x: mouse.x, y: mouse.y};
        var w = points[1].x - points[0].x;
        var h = points[1].y - points[0].y;
        annoBox.drawBox(ctx, points, w, h);
    };

    p.onMouseEnd = function(){
        this.points[1] = {x: mouse.x, y: mouse.y};
        boxClass._parent.onMouseEnd.call(this);
    };

    base.pushTool(boxClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-box',
        zoomWindow: true,
        title: 'box',
        text: 'box'
    });
})(subPixel);
