/*
 * Project:   PlotPad HTML5 Viewer
 * File:      models.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/24/2013
 */
/*
 * models defined module
 * collect all models
 * */
(function(subPixel, undefined){
    // external modules
    var utils = subPixel('utils');
    var config = subPixel('config');

    // defined models
    var data = {};

    // defined single model into storage
    function models(name, val) {
        name && (val !== undefined) && (data[name] = val);
        if (!data[name]){
            utils.info('model is not defined, is it all ok?', name);
        }
        return name ? data[name] : data;
    }

    utils.bindStartStop(function(){
        //start
        var model = data[config.model];
        if (model){
            var elements = model();
            subPixel.elements(elements);
        }
    }, function(){
        //stop
    });

    // register models
    subPixel('models', models);

})(subPixel);
