/*
 * Project:   PlotPad HTML5 Viewer
 * File:      engine-dom.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/10/2017
 */
(function(subPixel, undefined){

    var utils = subPixel('utils');

    var baseElement;
    var canvasEl;
    var helperEl;

    function getDOMbyString(val){
        var ret = val;
        if (typeof val == "string"){
            ret = document.querySelector(val);
        }
        return ret;
    }

    subPixel('engine-dom', {
        getBaseElement: function () {
            return baseElement;
        },
        getCanvasElement: function () {
            return canvasEl;
        },
        getHelperElement: function () {
            return helperEl;
        },
        buildDom: function (conf) {
            var el = baseElement = getDOMbyString(conf.el);
            conf.el = el;
            var definedClasses = el.className.split(' ');

            if (definedClasses.indexOf('subpixel') == -1){
                el.className += ' subpixel';
            }

            if (definedClasses.indexOf('subpixel-container') == -1){
                el.className += ' subpixel-container';
            }

            helperEl = document.createElement('div');
            helperEl.className = 'subpixel-container-helper';
            el.appendChild(helperEl);

            var backEl = document.createElement('div');
            backEl.className = 'subpixel-container-back can-click-area';
            el.appendChild(backEl);

            canvasEl = document.createElement('canvas');
            canvasEl.className = 'subpixel-canvas can-click-area';
            helperEl.appendChild(canvasEl);
        },
        buildIndicators: function (newConf) {
            var indicators = newConf.indicators;
            for (var key in indicators){
                indicators[key] = getDOMbyString(indicators[key]);
            }
        }
    });

    function start() {

    }

    function stop() {
        if (helperEl){
            helperEl.parentNode.removeChild(helperEl);
        }
    }

    utils.bindStartStop(start, stop);

})(subPixel);
