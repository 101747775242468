/*
 * Project:   PlotPad HTML5 Viewer
 * File:      localStorage.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/23/2014
 */
(function(subPixel){
    var utils = subPixel('utils');

    var localStorage = window.localStorage;

    // prefix for separate items in storage
    var PREFIX = "subPixel-";

    var customPrefix = PREFIX;

    var ls = function(key, value){

        // for each item creating new object with some params.
        var pattObj = {
            val: null,
            time: (new Date()).getTime()
        };
        var ret;
        if (value !== undefined){
            //setter
            pattObj.val = value;
            try {
                var setVal = JSON.stringify(pattObj);
                localStorage.setItem(customPrefix + key, setVal);
                ret = value;
            } catch(e){
                ret = null;
            }
        } else {
            try {
                var storedVal = localStorage.getItem(customPrefix + key);
                var getVal = storedVal && JSON.parse(storedVal);
                if (getVal){
                    ret = getVal.val;
                } else {
                    ret = null;
                }
                getVal = null;
            } catch(e){
                ret = null;
            }
            //getter
        }
        return ret;
    };

    ls.remove = function(key){
        var ret = true;
        try {
            localStorage.removeItem(customPrefix + key);
            ret = true;
        } catch(e){
            ret = false;
        }
        return ret;
    };

    var ext = {
        localStorage: ls
    };

    utils.extend(utils, ext);


})(subPixel);
