/*
 * Project:   PlotPad HTML5 Viewer
 * File:      canvas.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/29/2013
 */
/*
 * canvas module
 * module for manipulate <canvas> dom element
 * */
(function(subPixel, undefined){
    // exportable modules
    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var config = subPixel('config');
    var offsetEvs = broadcast.events('offset');
    var scaleEvs = broadcast.events('scale');
    var scale = subPixel('scale');
    var offset = subPixel('offset');
    var mouse = subPixel('mouse');
    var rotate = subPixel('rotate');
    var canvasEl = subPixel('canvas-el');
    var logger = subPixel('logger')('canvas');
    var canvasHelper = subPixel('canvas-helper');
    var engineDom = subPixel('engine-dom');
    // var domHelper = subPixel('dom-helper');

    //module using prebuffer for render elements in canvas
    var buffCanvas;
    var buffCtx;
    var ctx;

    var backgroundCanvas;
    var backgroundCtx;
    var container;

    var place;

    var cWidth = 0, cHeight = 0;

    // bounding box in normal view (with offset and scaling), this is not screen view
    var bbox = {
        minx: 0,
        miny: 0,
        maxx: 0,
        maxy: 0
    };

    // bbox of all model(plan)
    var modelBbox = {};

    utils.createBboxSctruct(modelBbox);

    var CONST_MODE_DRAW = 'draw';
    var CONST_MODE_VIEW = 'view';

    // canvas object for export
    var canvas = {
        w: 0,
        h: 0,
        mode_view: CONST_MODE_DRAW,
        mode_draw: CONST_MODE_VIEW,
        mode: CONST_MODE_VIEW,
        ctx: null,
        buffCtx: null,
        buffCanvas: null,
        bbox: bbox,
        screenRatio: 1,

        clearAll: function () {
            if (ctx) {
                this.clear().clearBg().clearBuffer();
            }
            return this;
        },
        dropModelBbox: function () {
            utils.createBboxSctruct(modelBbox);
            return this;
        },
        processModelBbox: function (figBbox) {
            (modelBbox.minx > figBbox.minx) && (modelBbox.minx = figBbox.minx);
            (modelBbox.maxx < figBbox.maxx) && (modelBbox.maxx = figBbox.maxx);
            (modelBbox.miny > figBbox.miny) && (modelBbox.miny = figBbox.miny);
            (modelBbox.maxy < figBbox.maxy) && (modelBbox.maxy = figBbox.maxy);
            return this;
        },
        updateScreenRatio: function () {
            var mw = this.getModelWidth();
            var mh = this.getModelHeight();

            this.screenRatio = scale.calcMinScale(screen.width, screen.height, mw, mh);
        },
        getModelWidth: function () {
            var w = modelBbox.maxx - modelBbox.minx;
            return w;
        },
        getModelHeight: function () {
            var h = modelBbox.maxy - modelBbox.miny;
            return h;
        },
        getModelOffset: function () {
            return {
                x: modelBbox.minx,
                y: modelBbox.miny
            }
        },
        drawModelTo: function (ctx, w, h) {
            var topPoint = this.getRetransformedPoint(modelBbox.minx, modelBbox.miny);
            var bottomPoint = this.getRetransformedPoint(modelBbox.maxx, modelBbox.maxy);

            ctx.drawImage(place,
                Math.floor(topPoint.x), Math.floor(topPoint.y),
                bottomPoint.x - topPoint.x, bottomPoint.y - topPoint.y,
                0, 0, w, h);

            topPoint = null;
            bottomPoint = null;
        },
        updateMouseOffsetPos: function () {
            var pos = buffCtx.transformedPoint(mouse.x, mouse.y);
            mouse.ox = pos.x;
            mouse.oy = pos.y;
            pos = null;
            return this;
        },
        getTransformedPoint: function (x, y) {
            return buffCtx.transformedPoint(x, y);
        },
        getRetransformedPoint: function (rx, ry) {
            return buffCtx.retransformedPoint(rx, ry);
        },
        setViewToModelSize: function (x, y) {
            var scaleVal = scale.val;
            var modelW = (modelBbox.maxx - modelBbox.minx) * scaleVal;
            var modelH = (modelBbox.maxy - modelBbox.miny) * scaleVal;

            container.style.width = modelW + "px";
            container.style.height = modelH + "px";

            var pt = buffCtx.transformedPoint(x, y);
            buffCtx.translate(pt.x, pt.y);
            updateBbox();
            pt = null;
            onResize();

            return this;
        },
        setViewToScreenSize: function (x, y) {
            container.style.width = '';
            container.style.height = '';

            buffCtx.translate(x, y);
            updateBbox();
            onResize();

            return this;
        },
        fitToScreen: function () {
            var modelW = this.getModelWidth();
            var modelH = this.getModelHeight();
            var newScaleX = cWidth / modelW;
            var newScaleY = cHeight / modelH;
            var newScale = Math.min(newScaleX, newScaleY);
            newScale = scale.floorVal(newScale);
            var normalScale = 1 / scale.val;
            if (newScale) {
                //at first, change scale
                buffCtx.translate(offset.x, offset.y);
                buffCtx.scale(normalScale, normalScale);

                buffCtx.scale(newScale, newScale);

                scale.setScale(newScale);

                //at second, move offset to center of screen
                updateBbox();
                var newW = bbox.maxx - bbox.minx;
                var newH = bbox.maxy - bbox.miny;

                var offx = (newW - modelW) / 2;
                var offy = (newH - modelH) / 2;

                buffCtx.translate(-modelBbox.minx + offx, -modelBbox.miny + offy);

                //at the end, just update bbox
                updateBbox();
                broadcast.trig(scaleEvs.onZoomExtend);
            }
            return this;
        },
        setOffset: function (x, y) {
            buffCtx.translate(offset.x, offset.y);

            buffCtx.translate(x, y);
            updateBbox();
            return this;
        },
        rotateAroundPoint: function (ctx, r, x ,y) {
            ctx.translate(x, y);
            ctx.rotate(-r);
            ctx.translate(-x, -y);
        },
        updateBbox: updateBbox,
        // clear display
        clear: function () {
            clearCtx(ctx, 0, 0, cWidth, cHeight);
//        place.width = place.width;
            return this;
        },
        // put bg to buffer
        drawBgToBuffer: function (x, y) {
            x = x || 0;
            y = y || 0;
            buffCtx.save();
            buffCtx.setTransform(1, 0, 0, 1, 0, 0);

            drawImage(buffCtx, backgroundCanvas, x, y);

            buffCtx.restore();
            return this;
        },
        // put buffer to display
        drawBufferToDisplay: function () {
            drawImage(ctx, buffCanvas, 0, 0);

            return this;
        },
        // clear buffer
        clearBuffer: function () {
            //see clear() this is the same
            buffCtx.save();
            buffCtx.setTransform(1, 0, 0, 1, 0, 0);
            clearCtx(buffCtx, 0, 0, cWidth, cHeight);
            buffCtx.restore();
            //buffCanvas.width = buffCanvas.width;
            //
            //var w = Math.abs(bbox.maxx - bbox.minx);
            //var h = Math.abs(bbox.maxy - bbox.miny);
            //buffCtx.clearRect(bbox.minx, bbox.miny, w, h);
            return this;
        },
        // put bg to display
        bgToDisplay: function () {
            drawImage(ctx, backgroundCanvas, 0, 0);
            return this;
        },
        // put buffer to bg
        drawBufferToBg: function () {
            drawImage(backgroundCtx, buffCanvas, 0, 0);
            return this;
        },
        clearBg: function () {
            clearCtx(backgroundCtx, 0, 0, cWidth, cHeight);
//        bgCanvas.width = bgCanvas.width;
            return this;
        },
        // draw some image to background
        drawToBg: function (image, x, y) {
            drawImage(backgroundCtx, image, x, y);
            return this;
        },
        // draw some image to main canvas
        drawToDisplay: function (image, x, y) {
            drawImage(ctx, image, x, y);
            return this;
        },
        getScreenImage: function (type) {
            type = type || "image/png";
            var dataUrl;
            try {
                dataUrl = place.toDataURL(type);
            } catch (err) {
                logger.winJsLog(err);
            }

            return dataUrl;
        }
    };

    function drawImage(toCtx, img, x, y) {
        toCtx.drawImage(img, x, y);
    }

    // bind events by action
    //
    // action - "on" or "off" state for broadcast module
    function processBroadcast(action){
        broadcast
            [action](offsetEvs.resize, onResize);
    }

    //start working with canvas
    function start() {
        processBroadcast('on');

        place = engineDom.getCanvasElement();
        container = engineDom.getHelperElement();

        canvas.ctx = ctx = place.getContext('2d');

        canvas.w = cWidth = container.clientWidth;
        canvas.h = cHeight = container.clientHeight;
        canvasHelper.setHdpi(place, cWidth, cHeight);

		var canvEl;

        canvEl = canvasEl({name: 'buffCanvas', trackTransforms: true, boundingBox: bbox, w: cWidth, h: cHeight, className: 'subpixel-bg-canvas'});
        buffCanvas = canvas.buffCanvas = canvEl.getEl();
        buffCtx = canvas.buffCtx = canvEl.getCtx();
        canvEl = null;

        // domHelper.insertAfter(buffCanvas, container);
        // buffCanvas.style.position = 'absolute';
        // buffCanvas.style.top = 0;
        // buffCanvas.style.left = '30%';
        // buffCanvas.style.display = 'block';

        canvEl = canvasEl({name: 'bgCanvas', w: cWidth, h: cHeight, className: 'subpixel-bg-canvas'});
        backgroundCanvas = canvas.backgroundCanvas = canvEl.getEl();
        backgroundCtx = canvEl.getCtx();
        canvEl = null;
        canvasHelper.setHdpi(backgroundCanvas, cWidth, cHeight, false);

        // domHelper.insertAfter(backgroundCanvas, buffCanvas);
        // backgroundCanvas.style.position = 'absolute';
        // backgroundCanvas.style.top = 0;
        // backgroundCanvas.style.left = '60%';
        // backgroundCanvas.style.display = 'block';

        buffCtx.translate(0, 0);
        canvas.clearBg();

        updateBbox();
    }

    // stop canvas working
    function stop (){
        canvas.dropModelBbox();

        processBroadcast('off');
        canvas.clearAll();
        canvas.ctx = null;
        ctx = null;

        buffCanvas = null;
        buffCtx = null;

        backgroundCanvas = null;
        backgroundCtx = null;
    }

    // processing resize event
    function onResize() {
        buffCtx.translate(offset.x, offset.y);
        buffCtx.scale(1/scale.val, 1/scale.val);

        canvas.w = cWidth = buffCanvas.width = container.clientWidth;
        canvas.h = cHeight = buffCanvas.height = container.clientHeight;

        canvasHelper.setHdpi(place, cWidth, cHeight);
        canvasHelper.setHdpi(backgroundCanvas, cWidth, cHeight, false);

        buffCtx.scale(scale.val, scale.val);
        buffCtx.translate(-offset.x, -offset.y);

        updateBbox();
        canvas.updateScreenRatio();
    }

    function updateBbox(){
        var topLeft = buffCtx.transformedPoint(0, 0);
        var bottomRight = buffCtx.transformedPoint(cWidth, cHeight);
        bbox.minx = topLeft.x;
        bbox.miny = topLeft.y;
        bbox.maxx = bottomRight.x;
        bbox.maxy = bottomRight.y;
        bottomRight = null;
        topLeft = null;
        updateOffset();
    }

    function updateOffset(){
        var ptoffset = buffCtx.transformedPoint(0, 0);
        offset.update(ptoffset.x, ptoffset.y);
        ptoffset = null;
    }

    function clearCtx(ctx, x, y, w, h){
        //clearRect not correct working in android webview, see details https://code.google.com/p/android/issues/detail?id=35474
//        ctx.save();
//        ctx.setTransform(1,0,0,1,0,0);
//        ctx.clearRect(x, y , w + 1, h + 1);
//        ctx.restore();
        ctx.fillStyle = config.clearColor || '#fff';
        ctx.fillRect(x, y, w, h);
    }

    //processing zoom action

    canvas.zoom = function(newVal){

        // calculate new scale val
        var oldScale = scale.val;
        var normalScale = 1 / oldScale;
        var newScale = newVal;
        if (typeof newVal != "number"){
            newScale = scale.getNewValue();
        }

        // scaling canvas
        var sx = cWidth / 2;
        var sy = cHeight / 2;

        var pt = buffCtx.transformedPoint(sx, sy);

        buffCtx.translate(pt.x, pt.y);
        buffCtx.scale(normalScale, normalScale);
        buffCtx.scale(newScale, newScale);
        buffCtx.translate(-pt.x, -pt.y);
        pt = null;

        // update new scale val
        updateBbox();

        scale.setScale(newScale);
        broadcast.trig(scaleEvs.onZoomExtend);
        return this;
    };

    // change zoom by percents, manually
    //
    // val - percents
    canvas.setZoom = function(val){
        var newScale = val / 100;
        canvas.zoom(newScale);
        return this;
    };

    //initialize canvas module, when system starts or stops
    utils.bindStartStop(start, stop);

    //register canvas module
    subPixel('canvas', canvas);

})(subPixel);
