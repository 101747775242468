/*
 * Project:   PlotPad HTML5 Viewer
 * File:      elements-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/18/2016
 */
(function (subPixel) {
    var utils = subPixel('utils');
    var logger = subPixel('logger')('elements-ctrl');
    var sorting = subPixel('sorting');
    var timeInfo = subPixel('time-info')('elements-ctrl');
    var broadcast = subPixel('broadcast');
    var sm = subPixel('select-manager');
    var history = subPixel('history');
    var canvas = subPixel('canvas');
    var drawingCtrl;
    var domCanvasCtrl;

    var annoAddEvs = broadcast.events('anno-create');

    var elementsCtrlEvs = broadcast.events('elements-ctrl', {
        addedNewElement: 'addNewEls',
        removedElements: 'removeEls'
    });

    var CONST_ANNO_LAYER = 'annotation';

    var annoQueue = {
        'anno-box': 0,
        'anno-polygon': 1,
        'anno-image': 2,
        'anno-text': 3,
        'text-group': 4,
        'anno-cloud': 5,
        'anno-arrow': 6,
        'anno-circle': 7,
        'anno-cross': 8,
        'anno-line': 9,
        'anno-pen': 10
    };

    var needReindex = false;
    var reindexCache = [];
    var reindexPosition = 0;
    // elements for drawing
    var elements = [];
    var hashElemets = {};
    // for fast accessing of elements length
    var elementsLen = 0;
    var selectableObjectsIndex = 0;

    var elementsCtrl = {
        reindex: function () {
            needReindex = true;
        },
        reindexPosition: function (val) {
            if (val !== undefined) {
                reindexPosition = val;
            }
            return reindexPosition;
        },
        objectIndex: function () {
            return selectableObjectsIndex;
        },
        reindexElements: function () {
            // reindex working only with indexed elements
            // what we do here:
            // we have start point, from `reindexPosition`
            // after we remove all elements, with propIndex >= 0 from start point
            // sorting them and insert again to main array to start point
            if (!needReindex) {
                return;
            }

            timeInfo.startTimer();
            var canReindex = true;
            var reindexMap = {};
            reindexCache.length = 0;
            var startPos = reindexPosition;
            for (var i = startPos; i < elementsLen; i++) {
                var el = elements[i];
                var index = el.propIndex();
                if (index >= 0) {
                    reindexCache.push(index);
                    if (reindexMap[index] === undefined) {
                        reindexMap[index] = i;
                    } else {
                        canReindex = false;
                        logger.error("equal indexes in elements, can't run reindex method");
                        break;
                    }
                }
            }

            if (canReindex) {
                logger.log(timeInfo.portionTimer("created cache"));
                var sorted = sorting.adaptiveSort(reindexCache, false);
                logger.log(timeInfo.portionTimer("adaptive sort: " + reindexCache.length));
                updateAfterSort(sorted, reindexCache, reindexMap);
                logger.log(timeInfo.portionTimer("update after sort"));
                sorted.length = 0;
                sorted = null;
            }
            reindexCache.length = 0;
            needReindex = false;
            reindexMap = null;
            timeInfo.stopTimer();
            var tInfo = timeInfo.getTimerInfo();
            logger.info('sorting timing', tInfo);
        },
        //set elements to design controller
        //
        // newEls - elements
        setElements: function (newEls) {
            timeInfo.startTimer();
            this.drop();
            for (var i = 0, l = newEls.length; i < l; i++) {
                var el = newEls[i];
                var itemId = el.getId();
                elements.push(el);
                hashElemets[itemId] = el;

                if (el.customObjectType && el.customObjectType() == 'Selectable Object' && selectableObjectsIndex == 0) {
                    selectableObjectsIndex = i;
                }
            }
            elementsLen = elements.length;
            if (selectableObjectsIndex == 0) {
                selectableObjectsIndex = elementsLen;
            }
            timeInfo.stopTimer();
            var tInfo = timeInfo.getTimerInfo();
            logger.log('created elements', elementsLen, tInfo);
            return this;
        },
        drop: function () {
            drawingCtrl.stopDrawing();

            for (var i = 0; i < elementsLen; i++){
                var el = elements[i];
                // el.destroy();//:todo need to check how to drop data correctly, because _initData have link to chunks and when do destroy, chunks(or commands) are cleared...
                el = null;
            }
            elements.length = 0;
            elementsLen = 0;

            utils.clearObject(hashElemets);
            return this;

        },
        // add new element to all
        add: function (figure) {
            // don't drop figure link object, don't override figure parameter

            // flag for indicated, that new elements was added
            var added = false;
            timeInfo.startTimer();
            logger.log('---started add figure/s');
            var historyState = [];
            processFiguresList(figure, function (item) {
                var itemId = item.getId();
                var notFounded = false;
                if (itemId) {
                    var foundedEl = findById(itemId);
                    notFounded = foundedEl ? true : false;
                    if (foundedEl) {
                        // set index for draw element, what was drawn before
                        foundedEl.propIndex(item.propIndex());
                    }
                }

                if (!notFounded) {
                    if (added == false) {
                        drawBeforeAdd();
                    }
                    added = true;
                    if (history.enable()) {
                        var clonedData = utils.clone(item.data(), true);
                        historyState.push(clonedData);
                    }

                    if (canDrawToCanvas()) {
                        item.predraw();
                    }

                    // check if item is annotation
                    var itemName = item.data().name;
                    if (annoQueue.hasOwnProperty(itemName)) {
                        // :todo instead of single elements array add type arrays
                        var pos = getPosToInsert(itemName);
                        // logger.log('insert in to POS ==== ' + pos);
                        elements.splice(pos, 0, item);
                    } else {
                        elements.push(item);
                    }

                    itemId && (hashElemets[itemId] = item);
                }
            });

            logger.log(timeInfo.portionTimer("processed"));
            if (added) {
                // finish adding new element
                history.createElementsPoints(history.codes.ADD_NEW, historyState);
                finishAdd(figure);
            }

            logger.log(timeInfo.portionTimer('add end'));
            timeInfo.stopTimer();
            var tInfo = timeInfo.getTimerInfo();
            logger.info('add figure', tInfo);
            return this;
        },
        // remove only selected elements
        removeSelected: function () {
            var selected = sm.getSelected();
            var len = selected.length;
            if (len) {
                this.remove(selected);
            }
            var newSelected = sm.getSelected();
            if (newSelected.length != len) {
                drawingCtrl.redraw();
            }
        },

        setCheckRemoveCb: function (cb) {
            checkRemoveCb = cb;
        },

        enableCheckRemove: function () {
            canCheckRemoveCb = true;
        },

        disableCheckRemove: function () {
            canCheckRemoveCb = false;
        },

        // remove figures from elements
        remove: function (figure) {
            var historyState = [];
            var removedElements = [];
            processFiguresList(figure, function (item) {
                var pos = elements.indexOf(item);
                if (pos >= 0) {
                    // check for can delete element
                    var canRemove = false;
                    if (item.canEdit()){
                        if (canCheckRemoveCb) {
                            canRemove = (checkRemoveCb(item) !== false);
                        } else {
                            canRemove = true;
                        }
                    }

                    if (canRemove) {
                        if (history.enable()) {
                            var clonedData = utils.clone(item.data(), true);
                            historyState.push(clonedData);
                        }
                        var rEl = elements[pos];
                        removedElements.push(rEl);
                        elements.splice(pos, 1);
                        var elId = item.getId();
                        hashElemets[elId] = null;
                        delete hashElemets[elId];
                    }
                }
            });

            if (removedElements.length) {
                elementsLen = elements.length;
                history.createElementsPoints(history.codes.REMOVE, historyState, true);

                drawingCtrl.redraw();

                // please, do not create array for figure, it can be created only for broadcast
                broadcast.trig(elementsCtrlEvs.removedElements, removedElements);

                removedElements.length = 0;
                removedElements = null;

                // check select manager selected elements
                sm.check();

                // update modelBbox of canvas
                updateModelBboxOfCanvas();
            }
            return this;
        },

        // return all elements
        getElements: function () {
            return elements;
        },
        getElementsLen: function () {
            return elementsLen;
        },
        getElementById: findById,
        getElementsByLayerName: function (layerName) {
            var els = [];
            utils.map(elements, function (el) {
                var elLayer = el.propLayerNameFull();
                if (elLayer == layerName){
                    els.push(el);
                }
            });
            return els;
        }
    };

    function updateModelBboxOfCanvas() {
        canvas.dropModelBbox();
        for (var i = 0; i < elementsLen; i++){
            var el = elements[i];
            var box = el.getBoundingBox();
            canvas.processModelBbox(box);
        }
    }

    function canDrawToCanvas() {
        var ret = !domCanvasCtrl.isWorking() && !drawingCtrl.isDrawing() && !drawingCtrl.silentMode();
        return ret;
    }

    function getPosToInsert(name) {
        if (name == 'anno-box' || name == 'anno-polygon') {
            return selectableObjectsIndex;
        }
        var pos = elementsLen;
        var weight = annoQueue[name];
        for (var i = elementsLen - 1; i >= reindexPosition; i--) {
            var el = elements[i];
            var elName = el.data().name;
            var elWeight = annoQueue[elName];

            if (elWeight <= weight) {
                break;
            } else {
                pos = i;
            }
        }
        return pos;
    }

    // finish adding new elements, draw and trig events
    function finishAdd(figure) {

        elementsLen = elements.length;
        if (canDrawToCanvas()) {
            logger.log('finishAdd() canvas.drawBufferToBg(), clear(), bgToDisplay()');
            canvas.drawBufferToBg();
            canvas.clear();
            canvas.bgToDisplay();
            // drawingCtrl.drawSelection();
            drawingCtrl.redraw();
        }

        // please, do not create array for figure, it can be created only for broadcast
        var evParams = utils.isArray(figure) ? figure : [figure];
        broadcast.trig(elementsCtrlEvs.addedNewElement, evParams);

        // todo: fix this hack
        if(evParams[0].propLayerName() === CONST_ANNO_LAYER) {
            broadcast.trig(annoAddEvs.added, evParams[0]);
        }
    }

    function drawBeforeAdd() {
        if (canDrawToCanvas()) {
            logger.log('drawBeforeAdd() canvas.clearBuffer(), drawBgToBuffer(0, 0)');
            canvas.clearBuffer();
            canvas.drawBgToBuffer(0, 0);
        }
    }

    function processFiguresList(figure, callback) {
        if (utils.isArray(figure)) {
            for (var i = 0, l = figure.length; i < l; i++) {
                var item = figure[i];
                callback(item);
            }
        } else {
            callback(figure);
        }
    }

    function updateAfterSort(sorted, reIndCache, reMap) {
        var startPos = reindexPosition;

        // map for detect correct elements
        var hashMap = {};

        // at first, remove all sorted elements from base array
        for (var i = reIndCache.length - 1; i >= 0; i--) {
            var indexRe = reIndCache[i];

            var oldPos = reMap[indexRe];
            hashMap[indexRe] = elements[oldPos];
            elements.splice(oldPos, 1);
        }

        // and then, push they into base array as sorted points
        for (var k = 0, l = sorted.length; k < l; k++) {
            var index = sorted[k];
            var el = hashMap[index];
            elements.splice(startPos + k, 0, el);
        }

        //check elements
        if (elements.length != elementsLen) {
            // show sorting error message
            // this error can be show, if indexes are equal for some elements
            logger.error('SOMETHING WRONG WITH SORTING!!! elements length before and after are NOT equal!');
        }

        hashMap = null;
    }

    // func for checking element for remove
    var checkRemoveCb = function () {
        return true;
    };

    var canCheckRemoveCb = false;

    function findById(id) {
        var ret = hashElemets[id];
        return ret;
    }

    subPixel('elements-ctrl', elementsCtrl);

    function start() {
        drawingCtrl = subPixel('drawing-ctrl');
        domCanvasCtrl = subPixel('dom-canvas-ctrl');
    }

    function stop() {
        elementsCtrl.reindexPosition(0);
    }

    utils.bindStartStop(start, stop);

})(subPixel);
