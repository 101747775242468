/*
 * Project:   PlotPad HTML5 Viewer
 * File:      select.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var utils = subPixel('utils');

    cafm.GetSelected = function(){
        var selected = subPixel.getSelectedElementsIds();
        var ret = cafm.helpers.getObjIdsFromFiguresIds(selected);
        return ret;
    };

    cafm.ClearSelection = function(){
        subPixel.clearSelection();
    };

    cafm.SelectObjects = function(objIds){
        var correctIds = cafm.helpers.getFiguresIdsFromObjIds(objIds);
        subPixel.selectElements(correctIds);
    };

})(cafm);