/*
 * Project:   PlotPad HTML5 Viewer
 * File:      canvas-el.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/21/2014
 */
(function(subPixel, undefined){
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var fragment = document.createDocumentFragment();

    function canvasEl(params){
        start();
        params = params || {};
        var el = this.el = document.createElement('canvas');
        fragment.appendChild(el);
        var ctx = this.ctx = el.getContext("2d");
        var className = params.className ? params.className : '';
        params.name && (className += " " + params.name);
        el.className = className;

        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        params.trackTransforms && trackTransforms(this.ctx, params.boundingBox);

        this.setDimensions(params.w, params.h);
    }

    var p = canvasEl.prototype;

    p.getCtx = function () {
        return this.ctx;
    };

    p.getEl = function () {
        return this.el;
    };

    p.getWidth = function () {
        return this.w;
    };

    p.getHeight = function () {
        return this.h;
    };

    p.setDimensions = function(w, h){
        (w !== undefined && this.el.width != w) && (this.el.width = w);
        (h !== undefined && this.el.height != h) && (this.el.height = h);
        this.w = w;
        this.h = h;
    };

    // 10thx to http://phrogz.net/tmp/canvas_zoom_to_cursor.html for full detailed example!!!
    // Adds ctx.getTransform() - returns an SVGMatrix
    // Adds ctx.transformedPoint(x,y) - returns an SVGPoint
    function trackTransforms(ctx, bbox){
        var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
        var xform = svg.createSVGMatrix();
        ctx.getTransform = function(){ return xform; };

        var savedTransforms = [];
        var save = ctx.save;
        ctx.save = function(){
            savedTransforms.push(xform.translate(0,0));
            return save.call(ctx);
        };
        var restore = ctx.restore;
        ctx.restore = function(){
            xform = savedTransforms.pop();
            return restore.call(ctx);
        };

        var cscale = ctx.scale;
        ctx.scale = function(sx,sy){
            xform = xform.scaleNonUniform(sx,sy);
            return cscale.call(ctx,sx,sy);
        };
        var rotate = ctx.rotate;
        ctx.rotate = function(radians){
            xform = xform.rotate(radians*180/Math.PI);
            return rotate.call(ctx,radians);
        };
        var translate = ctx.translate;
        ctx.translate = function(dx,dy){
            xform = xform.translate(dx,dy);
            return translate.call(ctx,dx,dy);
        };
        var transform = ctx.transform;
        ctx.transform = function(a,b,c,d,e,f){
            var m2 = svg.createSVGMatrix();
            m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
            xform = xform.multiply(m2);
            return transform.call(ctx,a,b,c,d,e,f);
        };
        var setTransform = ctx.setTransform;
        ctx.setTransform = function(a,b,c,d,e,f){
            xform.a = a;
            xform.b = b;
            xform.c = c;
            xform.d = d;
            xform.e = e;
            xform.f = f;
            return setTransform.call(ctx,a,b,c,d,e,f);
        };
        var pt = svg.createSVGPoint();
        ctx.transformedPoint = function(x,y){
            pt.x = x; pt.y = y;
            return pt.matrixTransform(xform.inverse());
        };

        // var ptRe = svg.createSVGPoint();
        ctx.retransformedPoint = function(x, y){
            // :todo change this logic to matrix.
            // ptRe.x = 0;
            // ptRe.y = 0;
            // var p2 = pt.matrixTransform(xform.inverse());

            // p2.x = x;
            // p2.y = y;
            // var sub = p2.matrixTransform(xform);
            // p2 = null;
            return {
                x: (x - bbox.minx) * scale.val,
                y: (y - bbox.miny) * scale.val
                // sx: sub.x,
                // sy: sub.y
            };
        }
    }

    subPixel('canvas-el', function(params){
        return new canvasEl(params);
    });


    function start(){

    }

    function stop(){
        if (fragment){
            while (fragment.firstChild) {
                fragment.removeChild(fragment.firstChild);
            }
        }
    }

    var CP = window.CanvasRenderingContext2D && CanvasRenderingContext2D.prototype;
    if (CP && CP.lineTo){
        CP.dashedLine = function (x, y, x2, y2, da) {
            if (!da) da = [10,5];
            this.save();
            var dx = (x2-x), dy = (y2-y);
            var len = Math.sqrt(dx*dx + dy*dy);
            var rot = Math.atan2(dy, dx);
            this.translate(x, y);
            this.moveTo(0, 0);
            this.rotate(rot);
            var dc = da.length;
            var di = 0, draw = true;
            x = 0;
            while (len > x) {
                x += da[di++ % dc];
                if (x > len) x = len;
                draw ? this.lineTo(x, 0): this.moveTo(x, 0);
                draw = !draw;
            }
            this.restore();
        }
    }

    utils.bindStartStop(start, stop);

})(subPixel);
