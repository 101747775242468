/*
 * Project:   PlotPad HTML5 Viewer
 * File:      objects.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var utils = subPixel('utils');
    var helpers = cafm.helpers;
    var internal = cafm.internal;
    var holdover = subPixel('holdover');
    var lm = subPixel('layer-manager');
    var sheetLoader = subPixel('sheet-loader');
    var chunkManager = subPixel('chunk-manager');

    var imageLoaderClass = subPixel('image-loader-class');
    var imageLoader = new imageLoaderClass({name: 'canvas'});

    var drawingGroup = chunkManager.TYPE_DRAWN;
    var objectGroup = chunkManager.TYPE_OBJECT;

    function processVisible(visibleState, ids, groups){
        var holder = holdover(true);
        var objs;
        if (ids || groups){
            ids && (objs = helpers.getFiguresFromObjIds(ids));
            if (groups){
                var objsGroups = helpers.filterFiguresByGroups(null, groups);
                if (objsGroups.length){
                    if (!objs || objs.length == 0){
                        objs = objsGroups;
                    } else {
                        var helperMap = {};
                        utils.map(objs, function (el) {
                            helperMap[el.getId()] = true;
                        });
                        utils.map(objsGroups, function (el) {
                            if (!helperMap[el.getId()]){
                                objs.push(el);
                            }
                        })
                    }
                }
            }
        } else {
            objs = helpers.getAllObjects();
        }

        if (objs.length){
            utils.map(objs, function(el){
                el.propVisible(visibleState);
            });
            subPixel.redraw(holder.await());
        }
        return holder;
    }

    cafm.GetObjectsIds = function(){
        var ret = helpers.getManifestList('objects', 'id');
        return ret;
    };

    cafm.LoadObjects = function(ids, groups){
        return sheetLoader.loadLayersAndGroups([], groups, ids);
    };
    
    cafm.UnLoadObjects = function(ids, groups){
        sheetLoader.dropObjects(ids, groups);
        return processVisible(false, ids, groups);
    };

    cafm.LoadAllObjects = function(){
        var groups = cafm.GetGroupsNames();
        return sheetLoader.loadLayersAndGroups([], groups, null);
    };

    cafm.UnLoadAllObjects = function(){
        sheetLoader.dropObjects();
        return processVisible(false);
    };

    cafm.GetObjectsProperties = function(objIds){
        var ret = [];
        var figs = helpers.getFiguresFromObjIds(objIds);
        utils.map(figs, function(fig){
            var node = {
                id: fig.objectId(),
                properties: fig.customProps()
            };
            ret.push(node);
        });
        return ret;
    };

    cafm.AddObjects = function(){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);

        for (var i = 0, l = list.length; i < l; i++) {
            var annoObj = list[i];
            if(annoObj.name === 'anno-image' && annoObj.imageUrl) {
                imageLoader.load(annoObj.imageUrl, function (error, imageItem) {
                    annoObj.image = imageItem.img;
                    if (error) {
                        if (imageItem.error) {
                            cafm.events.OnError(53, 'Couldn\'t load image for image-annotation');
                        }
                    } else {
                        subPixel.addElement(annoObj);
                    }
                });
            } else {
                subPixel.addElement(annoObj);
            }
        }

        subPixel.redraw(holder.await());

        return holder;
    };

    cafm.ClearObjects = function(){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);

        subPixel.removeElement(list);
        subPixel.redraw(holder.await());

        return holder;
    };

    internal.updateViewOfObjects = function (ids, groups) {
        processVisible(false);
        processVisible(true, ids, groups);
    };

    internal.setDataToLayerManager = function(layers){
        if (utils.isArray(layers)){
            lm.removeLayer(drawingGroup);
            lm.removeLayer(objectGroup);
            utils.map(layers, function(layer){
                lm.addLayer(drawingGroup, layer.name);
                lm.addLayer(objectGroup, layer.name);
            });
        }
    };

    internal.setDataToObjects = function(objects){
        if (utils.isArray(objects)){
            var map = {};
            var objIds = [];
            // create map
            utils.map(objects, function(obj){
                var id = obj.id;
                if (!map[id]){
                    map[id] = obj;
                    objIds.push(id);
                }
            });
            var figs = helpers.getFiguresFromObjIds(objIds);
            utils.map(figs, function(fig){
                var objId = fig.objectId();
                if (map[objId]){
                    var subData = map[objId];
                    fig.customProps(subData.properties);
                }
            });
        }
    };

})(cafm);