/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.wave.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('wave', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(30,0);
        // ctx.lineTo(30,30);
        // ctx.lineTo(0,30);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);
        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.3380279541015625, 0, 0, 0.338226318359375, -156.55, -60.1);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(534.2, 261.35);
        ctx.lineTo(529.2, 266.35);
        ctx.lineTo(534.15, 266.35);
        ctx.lineTo(534.2, 266.3);
        ctx.lineTo(534.25, 266.35);
        ctx.lineTo(539.2, 266.35);
        ctx.lineTo(534.2, 261.35);
        ctx.moveTo(551.95, 266.35);
        ctx.lineTo(551.95, 261.4);
        ctx.lineTo(547, 266.35);
        ctx.lineTo(551.95, 266.35);
        ctx.moveTo(503.7, 266.35);
        ctx.lineTo(498.65, 261.35);
        ctx.lineTo(493.65, 266.35);
        ctx.lineTo(498.6, 266.35);
        ctx.lineTo(498.7, 266.3);
        ctx.lineTo(498.75, 266.35);
        ctx.lineTo(503.7, 266.35);
        ctx.moveTo(521.45, 266.35);
        ctx.lineTo(516.45, 261.35);
        ctx.lineTo(511.45, 266.35);
        ctx.lineTo(516.4, 266.35);
        ctx.lineTo(516.45, 266.3);
        ctx.lineTo(516.5, 266.35);
        ctx.lineTo(521.45, 266.35);
        ctx.moveTo(463.2, 261.4);
        ctx.lineTo(463.2, 266.35);
        ctx.lineTo(468.15, 266.35);
        ctx.lineTo(463.2, 261.4);
        ctx.moveTo(472, 257.45);
        ctx.lineTo(480.9, 248.55);
        ctx.lineTo(489.8, 257.45);
        ctx.lineTo(498.7, 248.55);
        ctx.lineTo(507.55, 257.45);
        ctx.lineTo(516.45, 248.55);
        ctx.lineTo(525.35, 257.45);
        ctx.lineTo(534.2, 248.55);
        ctx.lineTo(543.1, 257.45);
        ctx.lineTo(551.95, 248.6);
        ctx.lineTo(551.95, 243.65);
        ctx.lineTo(543.1, 252.5);
        ctx.lineTo(534.2, 243.6);
        ctx.lineTo(525.35, 252.5);
        ctx.lineTo(516.45, 243.6);
        ctx.lineTo(507.55, 252.5);
        ctx.lineTo(498.7, 243.6);
        ctx.lineTo(489.8, 252.5);
        ctx.lineTo(480.9, 243.6);
        ctx.lineTo(472, 252.5);
        ctx.lineTo(463.2, 243.65);
        ctx.lineTo(463.2, 248.6);
        ctx.lineTo(472, 257.45);
        ctx.moveTo(480.95, 266.35);
        ctx.lineTo(485.9, 266.35);
        ctx.lineTo(480.9, 261.35);
        ctx.lineTo(475.9, 266.35);
        ctx.lineTo(480.85, 266.35);
        ctx.lineTo(480.9, 266.3);
        ctx.lineTo(480.95, 266.35);
        ctx.moveTo(463.2, 225.9);
        ctx.lineTo(463.2, 230.85);
        ctx.lineTo(472, 239.65);
        ctx.lineTo(480.9, 230.75);
        ctx.lineTo(489.8, 239.65);
        ctx.lineTo(498.65, 230.75);
        ctx.lineTo(507.55, 239.65);
        ctx.lineTo(516.45, 230.75);
        ctx.lineTo(525.35, 239.65);
        ctx.lineTo(534.2, 230.75);
        ctx.lineTo(543.1, 239.65);
        ctx.lineTo(551.95, 230.85);
        ctx.lineTo(551.95, 225.9);
        ctx.lineTo(543.1, 234.7);
        ctx.lineTo(534.2, 225.8);
        ctx.lineTo(525.35, 234.7);
        ctx.lineTo(516.45, 225.8);
        ctx.lineTo(507.55, 234.7);
        ctx.lineTo(498.65, 225.8);
        ctx.lineTo(489.8, 234.7);
        ctx.lineTo(480.9, 225.8);
        ctx.lineTo(472, 234.7);
        ctx.lineTo(463.2, 225.9);
        ctx.moveTo(472, 221.9);
        ctx.lineTo(480.9, 213);
        ctx.lineTo(489.8, 221.9);
        ctx.lineTo(498.7, 213);
        ctx.lineTo(507.55, 221.9);
        ctx.lineTo(516.45, 213);
        ctx.lineTo(525.35, 221.9);
        ctx.lineTo(534.2, 213);
        ctx.lineTo(543.1, 221.9);
        ctx.lineTo(551.95, 213.05);
        ctx.lineTo(551.95, 208.1);
        ctx.lineTo(543.1, 216.95);
        ctx.lineTo(534.2, 208.05);
        ctx.lineTo(525.35, 216.95);
        ctx.lineTo(516.45, 208.05);
        ctx.lineTo(507.55, 216.95);
        ctx.lineTo(498.7, 208.05);
        ctx.lineTo(489.8, 216.95);
        ctx.lineTo(480.9, 208.05);
        ctx.lineTo(472, 216.95);
        ctx.lineTo(463.2, 208.1);
        ctx.lineTo(463.2, 213.05);
        ctx.lineTo(472, 221.9);
        ctx.moveTo(472, 186.35);
        ctx.lineTo(480.75, 177.65);
        ctx.lineTo(475.8, 177.65);
        ctx.lineTo(472, 181.4);
        ctx.lineTo(468.25, 177.65);
        ctx.lineTo(463.3, 177.65);
        ctx.lineTo(472, 186.35);
        ctx.moveTo(534.4, 177.65);
        ctx.lineTo(543.1, 186.35);
        ctx.lineTo(551.8, 177.65);
        ctx.lineTo(546.85, 177.65);
        ctx.lineTo(543.1, 181.4);
        ctx.lineTo(539.35, 177.65);
        ctx.lineTo(534.4, 177.65);
        ctx.moveTo(551.95, 195.3);
        ctx.lineTo(551.95, 190.35);
        ctx.lineTo(543.1, 199.15);
        ctx.lineTo(534.2, 190.3);
        ctx.lineTo(525.35, 199.15);
        ctx.lineTo(516.45, 190.3);
        ctx.lineTo(507.55, 199.15);
        ctx.lineTo(498.65, 190.3);
        ctx.lineTo(489.8, 199.15);
        ctx.lineTo(480.9, 190.3);
        ctx.lineTo(472, 199.15);
        ctx.lineTo(463.2, 190.35);
        ctx.lineTo(463.2, 195.3);
        ctx.lineTo(472, 204.1);
        ctx.lineTo(480.9, 195.25);
        ctx.lineTo(489.8, 204.1);
        ctx.lineTo(498.65, 195.25);
        ctx.lineTo(507.55, 204.1);
        ctx.lineTo(516.45, 195.25);
        ctx.lineTo(525.35, 204.1);
        ctx.lineTo(534.2, 195.25);
        ctx.lineTo(543.1, 204.1);
        ctx.lineTo(551.95, 195.3);
        ctx.moveTo(525.35, 181.4);
        ctx.lineTo(521.55, 177.65);
        ctx.lineTo(516.6, 177.65);
        ctx.lineTo(525.35, 186.35);
        ctx.lineTo(534.05, 177.65);
        ctx.lineTo(529.1, 177.65);
        ctx.lineTo(525.35, 181.4);
        ctx.moveTo(503.8, 177.65);
        ctx.lineTo(498.85, 177.65);
        ctx.lineTo(507.55, 186.35);
        ctx.lineTo(516.25, 177.65);
        ctx.lineTo(511.3, 177.65);
        ctx.lineTo(507.55, 181.4);
        ctx.lineTo(503.8, 177.65);
        ctx.moveTo(498.5, 177.65);
        ctx.lineTo(493.55, 177.65);
        ctx.lineTo(489.8, 181.4);
        ctx.lineTo(486.05, 177.65);
        ctx.lineTo(481.1, 177.65);
        ctx.lineTo(489.8, 186.35);
        ctx.lineTo(498.5, 177.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
