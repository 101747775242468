/*
 * Project:   PlotPad HTML5 Viewer
 * File:      utils.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/29/2013
 */
/*
 * utils module
 * helpers and debuggers
 * */
(function (subPixel, undefined) {
    // exportable modules
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');

    // register events in storage
    var systemEvs = broadcast.events('system', {
        defaultState: 'default-state',
        domReady: 'domready',
        start: 'start',
        stop: 'stop'
    });

    // utils object external

    var isDomReady = false;

    var hasTouch =
        ("ontouchend" in document) ||
        navigator.pointerEnabled ||
        navigator.msPointerEnabled;

    var CONST_RECALC = 'subpixel-recalc-dom';

    var utils = {
        enable: true,
        support: {
            touch: hasTouch
        },
        start_ev: "mousedown",
        move_ev: "mousemove",
        end_ev: "mouseup",
        leave_ev: "mouseleave",

        t_start_ev: "touchstart",
        t_move_ev: "touchmove",
        t_end_ev: "touchend",
        t_cancel_ev: "touchcancel",
        t_leave_ev: "touchleave",

        getLogger : subPixel('logger'),

        // Browser prefix for set correct gradients.
        browserPrefix: (function () {
            var styles = window.getComputedStyle(document.documentElement, ''); // CSSStyleDeclaration
            var browserMatch = (Array.prototype.slice
                    .call(styles)
                    .join('')
                    .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
            )[1];

            return '-' + browserMatch + '-';
        })(),
        //calculate distance between two points
        getDistance: function (x1, x2, y1, y2) {
            var ret = Math.sqrt(Math.pow((y2 - y1), 2) + Math.pow((x2 - x1), 2));
            return ret;
        },
        createBboxSctruct: function (obj) {
            obj.minx = Infinity;
            obj.maxx = -Infinity;
            obj.miny = Infinity;
            obj.maxy = -Infinity;
            obj.w = 0;
            obj.h = 0;
        },
        isArray: (function () {
            // Use compiler's own isArray when available
            if (Array.isArray) {
                return Array.isArray;
            }

            // Retain references to variables for performance
            // optimization
            var objectToStringFn = Object.prototype.toString,
                arrayToStringResult = objectToStringFn.call([]);

            return function (subject) {
                return objectToStringFn.call(subject) === arrayToStringResult;
            };
        }()),
        map: function (arr, callback, startPos, endPos) {
            var sPos = startPos || 0;
            var ePos = (endPos === undefined) ? (arr.length - 1) : endPos;
            for (var i = sPos; i <= ePos; i++){
                var ret = callback(arr[i], i);
                if (ret === false) {
                    break;
                }
            }
        },
        extend: extendMe,
        extendClass: function (classObj, from) {
            extendMe(classObj.prototype, from);
        },
        clone: function (obj, fast) {
            var res;
            if (fast) {
                res = cloneMe(obj);
            } else {
                try {
                    res = cloneMe(obj);
                } catch (e) {
                    res = null;
                }
            }
            return res;
        },
        isEmpty: function (obj) {
            var ret = true;
            if (obj === undefined || obj === null || obj === "") {
                ret = true;
            } else if (this.isArray(obj)) {
                ret = (obj.length == 0);
            } else {
                var type = (typeof obj);
                if (type == "object") {
                    for (var key in obj) {
                        ret = false;
                        break;
                    }
                } else if (type == "boolean" || (obj === 0)) {
                    ret = false;
                } else if (type == "string" && obj != "") {
                    ret = false;
                } else {
                    ret = !!obj;
                }
            }
            return ret;
        },
        // recalculate dom after some manipulations
        recalcDom: function (el) {
            //this hack is call immediately recalculate DOM of element
            var classes = el.className.split(" ");
            classes.push(CONST_RECALC);
            el.className = classes.join(" ");
            var fakeWidth = el.clientWidth;
            removeRecalcClass(classes);
            el.className = classes.join(" ");
        },
        // helper for merge array2 into array1
        push: function (destArr, srcArr) {
            for (var i = 0, l = srcArr.length; i < l; i++) {
                destArr.push(srcArr[i]);
            }
        },
        clearObject: function (obj) {
            if (obj) {
                for (var key in obj) {
                    obj[key] = null;
                    delete obj[key];
                }
            }
        },
        colorToStr: function (color) {
            var str = color;
            if (utils.isArray(color)) {
                var preColor = "rgb";
                if (color.length == 4) {
                    (preColor += "a");
                    if (color[4] > 1) {
                        color[4] = color[4] / 255;
                    }
                }
                preColor += "(";
                str = preColor + color.join(',') + ')';
            } else if (typeof color == 'number') {
                if (color == 0) {
                    str = '000';
                } else {
                    str = color.toString(16);
                    if (str.length == 2) {
                        str = '0000' + str;
                    } else if (str.length == 4) {
                        str = '00' + str;
                    }
                }
                str = '#' + str;
            }
            return str;
        },

        // helper for inherit child from parent
        inherit: function (child, parent) {
            function F() {
            }

            F.prototype = parent && parent.prototype;
            child.prototype = new F();
            child._parent = parent.prototype;
            child._parent.constructor = parent;
        },

        log: function () {
            logger.log.apply(logger, arguments);
        },
        info: function () {
            logger.info.apply(logger, arguments);
        },
        error: function () {
            logger.error.apply(logger, arguments);
        },
        warn: function () {
            logger.warn.apply(logger, arguments);
        },

        createChildClass: function (base, name, methods) {
            function func() {
                func._parent.constructor.apply(this, arguments);
            }

            utils.inherit(func, base);
            var p = func.prototype;

            p.toString = function () {
                return name;
            };

            if (methods) {
                for (var key in methods) {
                    p[key] = methods[key];
                }
            }

            return func;
        },

        // processing document load ready event
        //
        // callback - callback for run after dom ready
        ready: function (callback) {
            if (!isDomReady) {
                broadcast.one(systemEvs.domReady, callback);
            } else {
                callback.call(subPixel);
            }
            return this;
        },
        // helper for start/stop subPixel engine methods
        bindStartStop: function (startCall, stopCall, startEv, stopEv) {
            (!startCall) && this.error("Doesn't have start callback for .bindStartStop()");
            // (!stopCall) && this.error("Doesn't have stop callback for .bindStartStop()");
            startEv = startEv || systemEvs.start;
            stopEv = stopEv || systemEvs.stop;
            function bindCycled() {
                broadcast.one(startEv, function () {
                    //after start calling, need bind one callback for stop event
                    startCall();
                    if (stopCall){
                        broadcast.one(stopEv, function () {
                            //after stop calling, need bind one callback for start event again
                            stopCall();
                            bindCycled();
                        });
                    }
                });
            }

            bindCycled();
        }
    };

    subPixel('utils', utils);

    var logger = subPixel('logger')('main');


    // define animate frame method
    // define mouse/touch events
    function defineWindowsTouches() {
        if (navigator.pointerEnabled) {
            utils.t_start_ev = 'pointerdown';
            utils.t_move_ev = 'pointermove';
            utils.t_end_ev = 'pointerup';
            utils.t_cancel_ev = "pointercancel";
            utils.t_leave_ev = "pointerleave";
        } else if (navigator.msPointerEnabled) {
            utils.t_start_ev = 'MSPointerDown';
            utils.t_move_ev = 'MSPointerMove';
            utils.t_end_ev = 'MSPointerUp';
            utils.t_cancel_ev = "MSPointerCancel";
        }
    }

    defineWindowsTouches();

    function extendMe(whatExtend, from) {
        for (var key in from) {
            if (from.hasOwnProperty(key)) {
                if (whatExtend.hasOwnProperty(key)) {
                    utils.error("Can't extend '"+ key +"' because this key already exist in extended object");
                    break;
                }
                whatExtend[key] = from[key];
            }
        }
    }

    function cloneMe(obj) {
        var tmp = {
            val: obj
        };
        var buff = JSON.stringify(tmp);
        var ret = (JSON.parse(buff)).val;
        return ret;
    }

    function removeRecalcClass(classes) {
        for (var i = classes.length - 1; i >= 0; i--) {
            if (classes[i] == CONST_RECALC) {
                classes.splice(i, 1);
            }
        }
        return classes;
    }

    // processing dom content loaded
    function completed() {
        document.removeEventListener("DOMContentLoaded", completed, false);
        window.removeEventListener("load", completed, false);
        isDomReady = true;
        broadcast.trig(systemEvs.domReady);
    }

    if (document.readyState === "complete") {
        completed();
    } else {
        // bind dom content load events
        document.addEventListener("DOMContentLoaded", completed, false);
        window.addEventListener("load", completed, false);
    }

})(subPixel);
