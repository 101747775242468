/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.brickSlant.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('brickSlant', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(30,0);
        // ctx.lineTo(30,30);
        // ctx.lineTo(0,30);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);
        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.3380279541015625, 0, 0, 0.338226318359375, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#282425";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(4.95, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 4.95);
        ctx.lineTo(4.95, 0);
        ctx.moveTo(88.75, 5.05);
        ctx.lineTo(88.75, 0.1);
        ctx.lineTo(73.65, 15.2);
        ctx.lineTo(67.2, 8.8);
        ctx.lineTo(76.05, 0);
        ctx.lineTo(71.1, 0);
        ctx.lineTo(55.85, 15.2);
        ctx.lineTo(49.45, 8.8);
        ctx.lineTo(58.25, 0);
        ctx.lineTo(53.3, 0);
        ctx.lineTo(38.1, 15.2);
        ctx.lineTo(31.65, 8.8);
        ctx.lineTo(40.5, 0);
        ctx.lineTo(35.55, 0);
        ctx.lineTo(20.3, 15.2);
        ctx.lineTo(13.9, 8.8);
        ctx.lineTo(22.75, 0);
        ctx.lineTo(17.8, 0);
        ctx.lineTo(2.55, 15.2);
        ctx.lineTo(0, 12.7);
        ctx.lineTo(0, 17.65);
        ctx.lineTo(0.05, 17.7);
        ctx.lineTo(0, 17.75);
        ctx.lineTo(0, 22.7);
        ctx.lineTo(11.45, 11.3);
        ctx.lineTo(17.85, 17.7);
        ctx.lineTo(2.55, 33);
        ctx.lineTo(0, 30.45);
        ctx.lineTo(0, 35.4);
        ctx.lineTo(0.05, 35.45);
        ctx.lineTo(0, 35.5);
        ctx.lineTo(0, 40.45);
        ctx.lineTo(11.45, 29.05);
        ctx.lineTo(17.85, 35.45);
        ctx.lineTo(2.55, 50.75);
        ctx.lineTo(0, 48.2);
        ctx.lineTo(0, 53.15);
        ctx.lineTo(0.05, 53.25);
        ctx.lineTo(0, 53.3);
        ctx.lineTo(0, 58.25);
        ctx.lineTo(11.45, 46.8);
        ctx.lineTo(17.85, 53.25);
        ctx.lineTo(2.55, 68.55);
        ctx.lineTo(0, 66);
        ctx.lineTo(0, 70.95);
        ctx.lineTo(0.05, 71);
        ctx.lineTo(0, 71.05);
        ctx.lineTo(0, 76);
        ctx.lineTo(11.45, 64.6);
        ctx.lineTo(17.85, 71);
        ctx.lineTo(2.55, 86.3);
        ctx.lineTo(0, 83.75);
        ctx.lineTo(0, 88.7);
        ctx.lineTo(5.1, 88.7);
        ctx.lineTo(11.45, 82.35);
        ctx.lineTo(17.8, 88.7);
        ctx.lineTo(22.85, 88.7);
        ctx.lineTo(29.2, 82.35);
        ctx.lineTo(35.55, 88.7);
        ctx.lineTo(40.6, 88.7);
        ctx.lineTo(46.95, 82.35);
        ctx.lineTo(53.3, 88.7);
        ctx.lineTo(58.4, 88.7);
        ctx.lineTo(64.75, 82.35);
        ctx.lineTo(71.1, 88.7);
        ctx.lineTo(76.15, 88.7);
        ctx.lineTo(82.5, 82.35);
        ctx.lineTo(88.75, 88.6);
        ctx.lineTo(88.75, 83.65);
        ctx.lineTo(85, 79.9);
        ctx.lineTo(88.75, 76.15);
        ctx.lineTo(88.75, 71.2);
        ctx.lineTo(73.6, 86.3);
        ctx.lineTo(67.2, 79.9);
        ctx.lineTo(82.5, 64.6);
        ctx.lineTo(88.75, 70.85);
        ctx.lineTo(88.75, 65.9);
        ctx.lineTo(85, 62.1);
        ctx.lineTo(88.75, 58.35);
        ctx.lineTo(88.75, 53.4);
        ctx.lineTo(73.6, 68.55);
        ctx.lineTo(67.2, 62.1);
        ctx.lineTo(82.5, 46.8);
        ctx.lineTo(88.75, 53.05);
        ctx.lineTo(88.75, 48.1);
        ctx.lineTo(85, 44.35);
        ctx.lineTo(88.75, 40.6);
        ctx.lineTo(88.75, 35.65);
        ctx.lineTo(73.6, 50.75);
        ctx.lineTo(67.2, 44.35);
        ctx.lineTo(82.5, 29.05);
        ctx.lineTo(88.75, 35.3);
        ctx.lineTo(88.75, 30.35);
        ctx.lineTo(85, 26.6);
        ctx.lineTo(88.75, 22.8);
        ctx.lineTo(88.75, 17.85);
        ctx.lineTo(73.65, 33);
        ctx.lineTo(67.2, 26.6);
        ctx.lineTo(82.5, 11.3);
        ctx.lineTo(88.75, 17.5);
        ctx.lineTo(88.75, 12.55);
        ctx.lineTo(85, 8.8);
        ctx.lineTo(88.75, 5.05);
        ctx.moveTo(64.75, 11.3);
        ctx.lineTo(71.15, 17.7);
        ctx.lineTo(55.85, 33);
        ctx.lineTo(49.45, 26.6);
        ctx.lineTo(64.75, 11.3);
        ctx.moveTo(46.95, 11.3);
        ctx.lineTo(53.4, 17.7);
        ctx.lineTo(38.1, 33);
        ctx.lineTo(31.65, 26.6);
        ctx.lineTo(46.95, 11.3);
        ctx.moveTo(46.95, 29.05);
        ctx.lineTo(53.4, 35.45);
        ctx.lineTo(38.1, 50.75);
        ctx.lineTo(31.65, 44.35);
        ctx.lineTo(46.95, 29.05);
        ctx.moveTo(64.75, 29.05);
        ctx.lineTo(71.15, 35.45);
        ctx.lineTo(55.85, 50.75);
        ctx.lineTo(49.45, 44.35);
        ctx.lineTo(64.75, 29.05);
        ctx.moveTo(29.2, 11.3);
        ctx.lineTo(35.6, 17.7);
        ctx.lineTo(20.3, 33);
        ctx.lineTo(13.9, 26.6);
        ctx.lineTo(29.2, 11.3);
        ctx.moveTo(29.2, 29.05);
        ctx.lineTo(35.6, 35.45);
        ctx.lineTo(20.3, 50.75);
        ctx.lineTo(13.9, 44.35);
        ctx.lineTo(29.2, 29.05);
        ctx.moveTo(35.6, 53.25);
        ctx.lineTo(20.3, 68.55);
        ctx.lineTo(13.9, 62.1);
        ctx.lineTo(29.2, 46.8);
        ctx.lineTo(35.6, 53.25);
        ctx.moveTo(38.1, 68.55);
        ctx.lineTo(31.65, 62.1);
        ctx.lineTo(46.95, 46.8);
        ctx.lineTo(53.4, 53.25);
        ctx.lineTo(38.1, 68.55);
        ctx.moveTo(29.2, 64.6);
        ctx.lineTo(35.6, 71);
        ctx.lineTo(20.3, 86.3);
        ctx.lineTo(13.9, 79.9);
        ctx.lineTo(29.2, 64.6);
        ctx.moveTo(38.1, 86.3);
        ctx.lineTo(31.65, 79.9);
        ctx.lineTo(46.95, 64.6);
        ctx.lineTo(53.4, 71);
        ctx.lineTo(38.1, 86.3);
        ctx.moveTo(64.75, 64.6);
        ctx.lineTo(71.15, 71);
        ctx.lineTo(55.85, 86.3);
        ctx.lineTo(49.45, 79.9);
        ctx.lineTo(64.75, 64.6);
        ctx.moveTo(55.85, 68.55);
        ctx.lineTo(49.45, 62.1);
        ctx.lineTo(64.75, 46.8);
        ctx.lineTo(71.15, 53.25);
        ctx.lineTo(55.85, 68.55);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
