/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.checkerboard.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('checkerboard', 32, 32, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(32, 32);
        ctx.lineTo(32, 28);
        ctx.lineTo(28, 28);
        ctx.lineTo(28, 32);
        ctx.lineTo(32, 32);
        ctx.moveTo(4, 32);
        ctx.lineTo(8, 32);
        ctx.lineTo(8, 28);
        ctx.lineTo(4, 28);
        ctx.lineTo(4, 32);
        ctx.moveTo(16, 32);
        ctx.lineTo(16, 28);
        ctx.lineTo(12, 28);
        ctx.lineTo(12, 32);
        ctx.lineTo(16, 32);
        ctx.moveTo(20, 28);
        ctx.lineTo(20, 32);
        ctx.lineTo(24, 32);
        ctx.lineTo(24, 28);
        ctx.lineTo(20, 28);
        ctx.moveTo(12, 24);
        ctx.lineTo(8, 24);
        ctx.lineTo(8, 28);
        ctx.lineTo(12, 28);
        ctx.lineTo(12, 24);
        ctx.moveTo(20, 24);
        ctx.lineTo(16, 24);
        ctx.lineTo(16, 28);
        ctx.lineTo(20, 28);
        ctx.lineTo(20, 24);
        ctx.moveTo(12, 20);
        ctx.lineTo(12, 24);
        ctx.lineTo(16, 24);
        ctx.lineTo(16, 20);
        ctx.lineTo(12, 20);
        ctx.moveTo(20, 16);
        ctx.lineTo(16, 16);
        ctx.lineTo(16, 20);
        ctx.lineTo(20, 20);
        ctx.lineTo(20, 16);
        ctx.moveTo(8, 16);
        ctx.lineTo(8, 20);
        ctx.lineTo(12, 20);
        ctx.lineTo(12, 16);
        ctx.lineTo(8, 16);
        ctx.moveTo(4, 24);
        ctx.lineTo(0, 24);
        ctx.lineTo(0, 28);
        ctx.lineTo(4, 28);
        ctx.lineTo(4, 24);
        ctx.moveTo(4, 20);
        ctx.lineTo(4, 24);
        ctx.lineTo(8, 24);
        ctx.lineTo(8, 20);
        ctx.lineTo(4, 20);
        ctx.moveTo(4, 16);
        ctx.lineTo(0, 16);
        ctx.lineTo(0, 20);
        ctx.lineTo(4, 20);
        ctx.lineTo(4, 16);
        ctx.moveTo(8, 8);
        ctx.lineTo(8, 4);
        ctx.lineTo(4, 4);
        ctx.lineTo(4, 8);
        ctx.lineTo(8, 8);
        ctx.moveTo(8, 12);
        ctx.lineTo(4, 12);
        ctx.lineTo(4, 16);
        ctx.lineTo(8, 16);
        ctx.lineTo(8, 12);
        ctx.moveTo(4, 12);
        ctx.lineTo(4, 8);
        ctx.lineTo(0, 8);
        ctx.lineTo(0, 12);
        ctx.lineTo(4, 12);
        ctx.moveTo(4, 4);
        ctx.lineTo(4, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 4);
        ctx.lineTo(4, 4);
        ctx.moveTo(16, 12);
        ctx.lineTo(12, 12);
        ctx.lineTo(12, 16);
        ctx.lineTo(16, 16);
        ctx.lineTo(16, 12);
        ctx.moveTo(16, 4);
        ctx.lineTo(12, 4);
        ctx.lineTo(12, 8);
        ctx.lineTo(16, 8);
        ctx.lineTo(16, 4);
        ctx.moveTo(20, 12);
        ctx.lineTo(20, 8);
        ctx.lineTo(16, 8);
        ctx.lineTo(16, 12);
        ctx.lineTo(20, 12);
        ctx.moveTo(12, 12);
        ctx.lineTo(12, 8);
        ctx.lineTo(8, 8);
        ctx.lineTo(8, 12);
        ctx.lineTo(12, 12);
        ctx.moveTo(12, 4);
        ctx.lineTo(12, 0);
        ctx.lineTo(8, 0);
        ctx.lineTo(8, 4);
        ctx.lineTo(12, 4);
        ctx.moveTo(16, 0);
        ctx.lineTo(16, 4);
        ctx.lineTo(20, 4);
        ctx.lineTo(20, 0);
        ctx.lineTo(16, 0);
        ctx.moveTo(28, 16);
        ctx.lineTo(32, 16);
        ctx.lineTo(32, 12);
        ctx.lineTo(28, 12);
        ctx.lineTo(28, 16);
        ctx.moveTo(24, 16);
        ctx.lineTo(24, 12);
        ctx.lineTo(20, 12);
        ctx.lineTo(20, 16);
        ctx.lineTo(24, 16);
        ctx.moveTo(28, 20);
        ctx.lineTo(28, 24);
        ctx.lineTo(32, 24);
        ctx.lineTo(32, 20);
        ctx.lineTo(28, 20);
        ctx.moveTo(24, 24);
        ctx.lineTo(24, 20);
        ctx.lineTo(20, 20);
        ctx.lineTo(20, 24);
        ctx.lineTo(24, 24);
        ctx.moveTo(28, 24);
        ctx.lineTo(24, 24);
        ctx.lineTo(24, 28);
        ctx.lineTo(28, 28);
        ctx.lineTo(28, 24);
        ctx.moveTo(28, 16);
        ctx.lineTo(24, 16);
        ctx.lineTo(24, 20);
        ctx.lineTo(28, 20);
        ctx.lineTo(28, 16);
        ctx.moveTo(24, 8);
        ctx.lineTo(24, 4);
        ctx.lineTo(20, 4);
        ctx.lineTo(20, 8);
        ctx.lineTo(24, 8);
        ctx.moveTo(24, 8);
        ctx.lineTo(24, 12);
        ctx.lineTo(28, 12);
        ctx.lineTo(28, 8);
        ctx.lineTo(24, 8);
        ctx.moveTo(28, 4);
        ctx.lineTo(28, 0);
        ctx.lineTo(24, 0);
        ctx.lineTo(24, 4);
        ctx.lineTo(28, 4);
        ctx.moveTo(32, 8);
        ctx.lineTo(32, 4);
        ctx.lineTo(28, 4);
        ctx.lineTo(28, 8);
        ctx.lineTo(32, 8);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
