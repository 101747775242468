/*
 * Project:   PlotPad HTML5 Viewer
 * File:      select.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/03/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var drawingCtrl = subPixel('drawing-ctrl');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var selectManager = subPixel('select-manager');
    var scale = subPixel('scale');
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');

    var parts = subPixel('parts');
    var ImageFigureClass = parts('image');
    var imageLoaderClass = subPixel('image-loader-class');
    var imageLoader = new imageLoaderClass({name: 'canvas'});

    var toolsSelectEvs = broadcast.events('tools-select',{
        stateChanged: 'sc'
    });

    var base = tools('base-tool');

    var THRESHOLD_MOUSE = 4;

    var startSelect = false;

    function drawBox(ctx, points, w, h){
        var fromx = points[0].x;
        var fromy = points[0].y;

        ctx.beginPath();
        ctx.fillRect(fromx, fromy, w, h);
        ctx.stroke();

        ctx.lineWidth = scale.lineWidth;

        ctx.strokeStyle = "rgba(0,0,0,0.8)";
        ctx.strokeRect(fromx, fromy, w, h);

        ctx.stroke();


        ctx.closePath();
    }


    var selectClass = utils.createChildClass(base, 'selectClass');

    var p = selectClass.prototype;

    p.onMouseStart = function(){
        startSelect = true;
        editor.startDraw();
        this.points.length = 0;
        var ctx = this.ctx;

        styles.want(ctx); // define default styles

        ctx.fillStyle = "rgba(0,0,0, 0.2)";
        var point = { x: mouse.x, y: mouse.y };
        this.points.push(point);
        this.points.push(point);

        drawCursor.call(this);
    };

    p.onMouseFreeMove = function(){
        drawCursor.call(this);
    };

    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        points[1] = {x: mouse.x, y: mouse.y};
        points[1] = {x: mouse.x, y: mouse.y};
        var w = points[1].x - points[0].x;
        var h = points[1].y - points[0].y;
        drawBox(ctx, points, w, h);
    };

    p.onMouseEnd = function(){
        startSelect = false;
        editor.stopDraw();
        var points = this.points;
        var startPos = points[0];
        var endPos = points[1];
        endPos.x = mouse.x;
        endPos.y = mouse.y;
        var isSinglePoint = false;

        var dx = 0;
        if (startPos.x == endPos.x && startPos.y == endPos.y){
            // is point
            dx = THRESHOLD_MOUSE;
            isSinglePoint = true;
        }

        var startPoint = canvas.getTransformedPoint(startPos.x - dx, startPos.y - dx);
        var endPoint = canvas.getTransformedPoint(endPos.x + dx, endPos.y + dx);

        var selectedEls = selectManager.selectElementsByClip({
            isSinglePoint: isSinglePoint,
            x: endPoint.x,
            y: endPoint.y,
            minx: Math.min(endPoint.x, startPoint.x),
            maxx: Math.max(endPoint.x, startPoint.x),
            miny: Math.min(endPoint.y, startPoint.y),
            maxy: Math.max(endPoint.y, startPoint.y)
        });
        endPoint = null;
        if (selectedEls.length){
            drawingCtrl.drawSelection();
        } else {
            drawingCtrl.drawForeground();
        }

        drawCursor.call(this);
    };

    function checkSelectBar(){
        var isVisible = false;
        if (this._visible && this._enable){
            isVisible = true;
        }
        broadcast.trig(toolsSelectEvs.stateChanged, isVisible);
    }

    function drawCursor(){
        if (this.toolCursorImage.image) {
            if (!startSelect) {
                area.clear();
            }
            var ctx = this.ctx;
            this.toolCursorImage.w = this.toolCursorImage.initW * scale.val;
            this.toolCursorImage.h = this.toolCursorImage.initH * scale.val;
            this.toolCursorImage.x = mouse.x - this.toolCursorImage.w / 2;
            this.toolCursorImage.y = mouse.y - this.toolCursorImage.h / 2;

            ImageFigureClass.prototype.drawImage(ctx, this.toolCursorImage);
        }
    }

    function updateImageCursor(imageData) {
        var imgUrl = imageData.imageUrl;
        if (imgUrl) {
            imageLoader.load(imgUrl, function (error, imageItem) {
                imageData.image = imageItem.img;
                if (error) {
                    if (imageItem.error) {
                        //onComplete(53, imageItem.message);
                    }
                }
            });
        }
    }

    p.visible = function(){
        var val = selectClass._parent.visible.apply(this, arguments);
        checkSelectBar.call(this);
        return val;
    };

    p.disable = function(){
        var val = selectClass._parent.disable.apply(this, arguments);
        checkSelectBar.call(this);
        return val;
    };

    p.enable = function(){
        var val = selectClass._parent.enable.apply(this, arguments);
        checkSelectBar.call(this);
        return val;
    };

    p.onToolSelect = function(){
        selectClass._parent.onToolSelect.call(this);

        this.toolCursorImage = {
            image:null,
            imageUrl: '',
            w: 0,
            h: 0,
            x: 0,
            y: 0,
            initW: 0,
            initH: 0
        };
    };

    p.setToolParams = function(params) {
        this.toolCursorImage.initW = params.initWidth;
        this.toolCursorImage.initH = params.initHeight;
        this.toolCursorImage.imageUrl = params.imageUrl;

        updateImageCursor.call(this, this.toolCursorImage);
    };

    p.onToolDeselect = function(){
        if (config.useAutoClearSelection){
            selectManager.clear();
            drawingCtrl.drawForeground();
        }
    };

    p.createFigureData = function(){
        return false;
    };

    tools(selectClass, {
        isPrimary: true,
        mode: scenarioCtrl.select,
        name: 'select',
        title: 'select',
        text: 'select'
    });
})(subPixel);
