/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.horizontal.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('horizontal', 30, 20, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(30, 1);
        ctx.lineTo(30, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 1);
        ctx.lineTo(30, 1);
        ctx.moveTo(30, 16);
        ctx.lineTo(30, 15);
        ctx.lineTo(0, 15);
        ctx.lineTo(0, 16);
        ctx.lineTo(30, 16);
        ctx.moveTo(30, 11);
        ctx.lineTo(30, 10);
        ctx.lineTo(0, 10);
        ctx.lineTo(0, 11);
        ctx.lineTo(30, 11);
        ctx.moveTo(30, 6);
        ctx.lineTo(30, 5);
        ctx.lineTo(0, 5);
        ctx.lineTo(0, 6);
        ctx.lineTo(30, 6);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
