// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Project:   PlotPad HTML5 Viewer
 * File:      subpixel-zoom-container.css
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/18/2016
 */
.view-zoom-toolbox {
    position: absolute;
    top: 5%;
    left: 10px;
    border: 1px solid gray;
    width: 40px;
    z-index: 2;
    max-height: 300px;
    bottom: 5%;
    cursor: default;
    border-radius: 5px;
}

.toolbox-zoom-container {
    bottom: 20px;
    top: 30px;
    position: absolute;
    left: 18px;
    width: 2px;
    border: 1px solid silver;
}
.zoom-toolbox-drag {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #1000ff;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    border-radius: 30px;
    opacity: 0.6;
}
.zoom-toolbox-title {
    font-size: 11px;
    font-family: tahoma;
    text-align: center;
    color: #000;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
