/*
 * Project:   PlotPad HTML5 Viewer
 * File:      batch.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/31/2016
 */
(function(subPixel, undefined){
    var broadcast = subPixel('broadcast');
    var batchEvs = broadcast.events('batch', {
        cmdExecuted: 'cmdExecuted',
        competed: 'competed'
    });

    var utils = subPixel('utils');
    var holdover = subPixel('holdover');

    var BaseCommandClass;
    var currCmd;
    var context;

    subPixel('batch', {
        baseCmd: function (val) {
            if (val !== undefined){
                BaseCommandClass = val
            }
            return BaseCommandClass;
        },
        context: function (val) {
            if (val !== undefined){
                context = val;
            }
            return context;
        },
        getCurrentCmd: function () {
            return currCmd;
        },
        executeBatch: function (commands, timeout) {
            var holder = holdover(true);
            var results = [];
            var canRun = true;
            currCmd = null;

            if (utils.isArray(commands)){
                var cmdList = [];
                utils.map(commands, function(item, index){
                    if (item && item.method){
                        var methodName = item.method;
                        var opt = {
                            next: function(err, data){
                                results.push(data);
                                if (err){
                                    onBatchDone(err, results, holder);
                                } else {
                                    // this is current cmd
                                    var obj = {
                                        methodName: methodName,
                                        error: err,
                                        args: item.args,
                                        result: data
                                    };
                                    broadcast.trig(batchEvs.cmdExecuted, obj);

                                    var nextCmd = cmdList[index + 1];
                                    currCmd = nextCmd;

                                    if (nextCmd){
                                        if (timeout){
                                            setTimeout(function(){
                                                nextCmd.run();
                                            }, timeout);
                                        } else {
                                            nextCmd.run();
                                        }
                                    } else {
                                        onBatchDone(err, results, holder);
                                    }
                                }
                            }
                        };
                        var cmd = new BaseCommandClass(methodName, item.args, opt);
                        cmdList.push(cmd);
                    } else {
                        canRun = false;
                    }
                });
                if (cmdList[0]){
                    cmdList[0].run();
                }
            } else {
                onBatchDone(true, results, holder);
            }
            return holder;
        }
    });

    function onBatchDone(err, results, holder){
        currCmd = null;
        var obj = {
            error: err,
            result: results
        };
        broadcast.trig(batchEvs.competed, obj);
        if (err){
            holder.reject(obj);
        } else {
            holder.resolve(obj);
        }
    }

})(subPixel);
