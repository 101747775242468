/*
 * Project:   PlotPad HTML5 Viewer
 * File:      zoom-container.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/18/2016
 */
(function(subPixel, undefined){


    var domHelper = subPixel('dom-helper');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var editor = subPixel('editor');
    var tools = editor('tools');
    var mouse = subPixel('mouse');
    var scale = subPixel('scale');
    var offset = subPixel('offset');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var inter = subPixel('interface');

    var broadcast = subPixel('broadcast');
    var mouseEvs = broadcast.events('mouse');
    var scaleEvs = broadcast.events('scale');

    var base = tools.placeForTools('baseClass');

    var zoomContainer = utils.createChildClass(base, 'zoom-container');

    var p = zoomContainer.prototype;

    var inZooming = false;
    var isVisible = false;
    var startPos = 0;
    var height = 0;
    var maxVal = 0;
    var minVal = 0;
    var scaleRange = 0;

    p.init = function(){
        var base = this.base();
        base.className = 'view-zoom-toolbox';
        // tools DOM element
        var container = this.element();
        container.className = 'toolbox-zoom-container';

        var title = document.createElement('div');
        title.className = 'zoom-toolbox-title';
        base.appendChild(title);
        this._title = title;

        var dragEl = document.createElement('div');
        dragEl.className = 'zoom-toolbox-drag';
        container.appendChild(dragEl);
        this._dragEl = dragEl;
        this.hide();
    };

    p.show = function(){
        var container = this.base();
        container.style.display = '';
        isVisible = true;
    };

    p.hide = function(){
        var container = this.base();
        container.style.display = 'none';
        isVisible = false;
    };

    var inst = new zoomContainer();

    tools.placeForTools('zoomContainer', inst);

    function onMouseUp(){
        if (inZooming){
            inZooming = false;
            scenarioCtrl.stopScaling();
        }
    }

    function onMouseDown(e) {
        var target = e.target;
        if (!inZooming && domHelper.closest(target, 'zoom-toolbox-drag')) {
            inZooming = true;
            var rect = inst.element().getBoundingClientRect();
            startPos = rect.top - mouse.containerTop;
            height = rect.height;
            var cx = offset.width / 2;
            var cy = offset.height / 2;
            scenarioCtrl.startScaling(cx, cy);
        }
    }

    function onMouseMove(){
        if (inZooming){
            var currPos = mouse.y;
            var val = currPos - startPos;
            (val <= 0) && (val = 0);
            (val >= height) && (val = height);

            var x = height ? (val * 100 / height) : 0;
            x = Math.floor(x * 100) / 100;
            setZoomValue(x);
        }
    }

    function setZoomValue(perc){
        var val = (100 - perc);
        var subVal = val;
        var scaleVal = (subVal * scaleRange / 100) + minVal;
        (scaleVal <= minVal) && (scaleVal = minVal);
        (scaleVal >= maxVal) && (scaleVal = maxVal);

        var cx = offset.width / 2;
        var cy = offset.height / 2;

        scenarioCtrl.changeScale(scaleVal, cx, cy);
    }

    function updateScaleIndicator(){
        var x = scale.val;
        var subVal = 100 - ((x - minVal) * 100 / scaleRange);
        var val = subVal;

        inst._dragEl.style.top = val + '%';
        inst._title.innerHTML = scale.getPercentValue() + '%';
    }

    function watchMouse(action){
        broadcast[action](mouseEvs.up, onMouseUp)
            [action](mouseEvs.down, onMouseDown)
            [action](mouseEvs.move, onMouseMove)
            [action](mouseEvs.leave, onMouseUp)
            [action](scaleEvs.onUpdated, updateScaleIndicator);
    }


    function start(){
        watchMouse('off');
        watchMouse('on');
        maxVal = scale.max;
        minVal = scale.min;
        scaleRange = maxVal - minVal;
    }

    function stop(){
        watchMouse('off');
    }

    utils.bindStartStop(start, stop);

    inter.register({
        zoomPanelVisible: function(val){
            if (val !== undefined){
                !val ? inst.hide() : inst.show();
            }
            return isVisible;
        }
    });

})(subPixel);
