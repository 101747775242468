/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/29/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var utils = subPixel('utils');
    var helpers = cafm.helpers;
    var holdover = subPixel('holdover');

    cafm.ThemeObjects = function(/*list*/){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);
        if (list.length > 0) {
            utils.map(list, function(item){
                item.objects = helpers.getFiguresIdsFromObjIds(item.objects);
            });
            subPixel.setThemeObjects(list, holder.await());
        } else {
            holder.resolve();
        }
        return holder;
    };

    cafm.ThemeObjectsProportional = function(/*list*/){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);

        if (list.length > 0){
            var themes = {};
            var hash = {};
            var pos = 1;
            var objIds = {};
            utils.map(list, function(item){
                var objId = item.object;
                objIds[objId] = true;
                var figIds = helpers.getFiguresIdsFromObjIds([objId]);
                var figId = figIds[0];
                var itemThemes = item.themes;
                if (figId && utils.isArray(itemThemes)){
                    utils.map(itemThemes, function(theme){
                        var themeId = theme._id = theme._id || pos;
                        themes[themeId] = theme;
                        theme.objects = theme.objects || [];
                        var hashKey = themeId + '|' + figId;
                        if (!hash[hashKey]){
                            theme.objects.push(figId);
                        }
                        hash[hashKey] = true;
                        pos++;
                    })
                }
            });
            var toSetList = [];
            for (var key in themes){
                var singleTheme = themes[key];
                toSetList.push(singleTheme);
            }

            var dropObjects = [];
            for (var key in objIds){
                dropObjects.push(key);
            }
            clearObjThemes(dropObjects);
            subPixel.setThemeObjects(toSetList, holder.await());
        } else {
            holder.resolve();
        }
        return holder;
    };

    cafm.ClearThemes = function(objIds){
        var holder = holdover(true);
        clearObjThemes(objIds, holder.await());
        return holder;
    };

    cafm.ClearAllThemes = function(){
        var holder = holdover(true);
        subPixel.removeThemesByObjects(holder.await());
        return holder;
    };

    function clearObjThemes(objIds, cb){
        var figs = helpers.getFiguresIdsFromObjIds(objIds);
        subPixel.removeThemesByObjects(figs, cb);
    }

    cafm.SetBackgroundColor = function(/*list*/){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);
        if (list.length > 0){
            utils.map(list, function(node){
                var color = node.color;
                var objIds = node.objects;
                var figs = helpers.getFiguresFromObjIds(objIds);
                utils.map(figs, function(el){
                    el.themeBgColor(color);
                });
            });
            subPixel.redraw(holder.await());
        } else {
            holder.resolve();
        }
        return holder;
    };

})(cafm);