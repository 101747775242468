/*
 * Project:   PlotPad HTML5 Viewer
 * File:      history.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/10/2014
 */
(function(subPixel, undefined){
    var inter = subPixel('interface');
    var history = subPixel('history');

    inter.register({
        historySetMaxStates: function(val){
            history.setMaxStates(val);
        },
        historyEnable: function(val){
            return history.enable(val);
        },
        undo: history.undo,
        redo: history.redo
    });


})(subPixel);
