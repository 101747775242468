/*
 * Project:   PlotPad HTML5 Viewer
 * File:      offset.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/04/2013
 */
/*
* offset transformer module
* */
(function(subPixel, undefined){
    // exportable modules
    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var config = subPixel('config');
    var engineDom = subPixel('engine-dom');

    // register events in storage
    var offsetEvs = broadcast.events('offset', {
        resize: 'resize'
    });

    //timeout ID for window.resize event
    var lastResizeTimeId;

    // offset object external
    var offset = {
        resize: true,
        //start position of center point
        x: 0,
        y: 0,

        mx: 0,
        my: 0,

        // window dimensions
        width: 0,
        height: 0
    };

    offset.disableResize = function(){
        this.resize = false;
    };

    offset.enableResize = function(){
        this.resize = true;
        this.checkResize();
    };

    offset.checkResize = function(){
        clearTimeout(lastResizeTimeId);
        updateWH();
    };

    offset.update = function(x, y){
        offset.x = x;
        offset.y = y;
    };

    // update width and height after resize
    function updateWH(){
        var container = engineDom.getHelperElement();
        var ret = false;
        if (container){
            var w = parseInt(container.clientWidth, 10);
            var h = parseInt(container.clientHeight, 10);
            if (w != offset.width || h != offset.height){
                (w > 0) && (offset.width = w);
                (h > 0) && (offset.height = h);
                ret = true;
                trigResize();
            }
        }
        return ret;
    }

    // processing resize event after window.resize calling
    function onResize(){
        clearTimeout(lastResizeTimeId);
        //settimeout only for don't call resize twice
        lastResizeTimeId = setTimeout(function(){
            updateWH();
        }, 100);
    }

    function trigResize(){
        if (offset.resize && !subPixel.isStopped()){
            broadcast.trig(offsetEvs.resize);
        }
    }

    // start working of offset module
    function start(){
        updateWH();
        window.addEventListener('resize', onResize, false);
    }

    // stop working of offset module
    function stop(){
        window.removeEventListener('resize', onResize, false);
    }

    //initialize offset module, when system starts or stops
    utils.bindStartStop(start, stop);

    //register offset module
    subPixel('offset', offset);

})(subPixel);
