/*
 * Project:   PlotPad HTML5 Viewer
 * File:      sheet-loader.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/15/2016
 */
(function(subPixel, undefined) {
    var sheetRequester = subPixel('sheet-requester');
    var broadcast = subPixel('broadcast');
    var holdover = subPixel('holdover');
    var utils = subPixel('utils');
    var canvas = subPixel('canvas');
    var chunkManager = subPixel('chunk-manager');
    var logger = subPixel('logger')('sheet-loader');
    var lm = subPixel('layer-manager');

    var sheetLoaderEvs = broadcast.events('sheet-loader', {
        started: 'started',
        loaded: 'loaded',
        error: 'error',
        progress: 'progress'
    });

    var codes = {
        NO_MANIFEST: 50, //10,
        NO_PUBLISH: 51, //11,
        NO_CHUNK: 52, //12,
        NO_IMAGE: 53 //13,
    };

    var MANIFEST_PERC = 0.1;
    var IMAGES_PERC = 0.3;
    var CHUNKS_PERC = 1 - (MANIFEST_PERC + IMAGES_PERC);
    var CHUNKS_PERC_PRE = MANIFEST_PERC * 100;
    var CHUNKS_PERC_PRE_IMAGES = (1 - IMAGES_PERC) * 100;

    var dropGroups = [
        chunkManager.TYPE_DRAWN//,
        // chunkManager.TYPE_OBJECT
    ];

    var firstDrawn = false;

    var loaderParams = {};

    subPixel('sheet-loader', {
        manifest: null,
        getCodes: function () {
            return codes;
        },
        getManifestData: function () {
            return this.manifest;
        },
        stopLoad: function () {
            this.manifest = null;
            sheetRequester.dropCbs();
            sheetRequester.abortGets();
            chunkManager.dropData();
            chunkManager.dropOnAllImagesLoaded();
            return this;
        },
        dropObjects: function (objList, groupList) {
            sheetRequester.abortGets(chunkManager.TYPE_OBJECT);
            chunkManager.dropObjects(objList, groupList);
        },
        dropDrawings: function (layers) {
            sheetRequester.abortGets(chunkManager.TYPE_DRAWN);
            chunkManager.dropDrawings(layers);
        },
        onError: function (code, msg){
            logger.error(code, msg);
            loaderParams.onError && loaderParams.onError(code, msg);
            broadcast.trig(sheetLoaderEvs.error, code);
        },
        loadLayersAndGroups: function (layers, groups, objIds) {
            var holder = holdover(true);
            if (this.manifest){
                var self = this;

                // all ok
                // layers for drawn chunks and object chunks
                // groups ONLY for object chunks
                var chunksForLoad;
                if (loaderParams.loadAllChunks){
                    chunksForLoad = chunkManager.getAllChunks(this.manifest);
                } else {
                    chunksForLoad = chunkManager.processChunks(this.manifest, layers, groups, objIds);
                }

                if (chunksForLoad.length){
                    broadcast.trig(sheetLoaderEvs.started);

                    sheetRequester.onSheetProgress(function (data) {
                        var totalPreData = (data.loaded / data.total * 100) * CHUNKS_PERC + CHUNKS_PERC_PRE;
                        broadcast.trig(sheetLoaderEvs.progress, totalPreData);
                        loaderParams.onProgress && loaderParams.onProgress(totalPreData);
                    });

                    sheetRequester.onAllLoaded(function (err, code) {
                        // process all figures from chunk manager and reorganize data in canvas drawings
                        chunkManager.waitImageLoader(function(perc){
                            var totalPreData = perc * IMAGES_PERC + CHUNKS_PERC_PRE_IMAGES;
                            broadcast.trig(sheetLoaderEvs.progress, totalPreData);
                            loaderParams.onProgress && loaderParams.onProgress(totalPreData);
                        },function () {

                            // exit point
                            holder.resolve();
                            onChunksLoaded.call(self);
                        });
                    });

                    utils.map(chunksForLoad, function (chunkName) {
                        loadChunkByName.call(self, chunkName, function (errCode) {
                            // chunk inited
                            if (errCode){
                                holder.reject(errCode);
                            }
                        });
                    });
                    sheetRequester.startRequests();
                } else {

                    // exit point
                    holder.resolve();
                    onChunksLoaded.call(self);
                }
            } else {
                this.onError(codes.NO_MANIFEST, 'Trying load layers and groups without manifest');
                holder.reject(codes.NO_MANIFEST);
            }
            return holder;
        },
        startLoad: function (opt) {
            var self = this;
            this.stopLoad();

            defineLoaderParams(opt);
            firstDrawn = true;

            var url = loaderParams.url;

            broadcast.trig(sheetLoaderEvs.started);
            chunkManager.setOptions(loaderParams);

            sheetRequester.onSheetProgress(function (data) {
                var totalPreData = (data.loaded / data.total * 100) * MANIFEST_PERC;
                broadcast.trig(sheetLoaderEvs.progress, totalPreData);
                loaderParams.onProgress && loaderParams.onProgress(totalPreData);
            });

            sheetRequester.onAllLoaded(function (error, code) {
                sheetRequester.stopProgress();
                loaderParams.onPrepared && loaderParams.onPrepared(error, code);
            });

            var loadPublish = (loaderParams.loadPublish === undefined) ? true : loaderParams.loadPublish;
            if (loadPublish){
                var publishUrl = url + "/publish.json";
                sheetRequester.getJson(publishUrl, {
                    onDone: function(error, data){
                        if (error){
                            self.onError(codes.NO_PUBLISH, "No publish file");
                        }
                    }
                });
            }

            var manifestUrl = url + "/manifest.json";
            sheetRequester.getJson(manifestUrl, {
                onDone: function(error, data){
                    onManifestLoad.call(self, error, data);
                }
            });

            sheetRequester.startRequests();

            return this;
        }
    });

    function defineLoaderParams(opt) {
        utils.clearObject(loaderParams);

        // !== false - is TRUE by default

        opt.loadObjectChunk = (opt.loadObjectChunk !== false);
        opt.loadAllChunks = (opt.loadAllChunks !== false);
        opt.loadObjectChunkFirst = (opt.loadObjectChunkFirst !== false);
        opt.canCreateElements = (opt.canCreateElements !== false);
        opt.drawAfterAddFigures = (opt.drawAfterAddFigures !== false);
        opt.useUnitScaleFromManifest = (opt.useUnitScaleFromManifest !== false);

        for (var key in opt){
            loaderParams[key] = opt[key];
        }
    }

    function onManifestLoad(error, data) {
        var opt = loaderParams;
        if (error){
            this.onError(codes.NO_MANIFEST, "No manifest file");
        } else {
            if (chunkManager.isManifestCorrect(data)){
                var manifest = this.manifest = chunkManager.processManifest(data);

                if (opt.useUnitScaleFromManifest){
                    setUnitScales(manifest);
                }

            } else {
                error = true;
                this.onError(codes.NO_MANIFEST, "No manifest file");
            }
        }
        opt.onManifestLoaded && opt.onManifestLoaded(error, data);
    }

    function setUnitScales(manifest) {
        var mInfo = manifest.info;
        if (mInfo){
            if (mInfo['units-scale']){
                subPixel.measureScale(mInfo['units-scale'], mInfo.units, mInfo.units);
            }
        }
    }

    function onChunksLoaded() {
        var drawAfterAdd = loaderParams.drawAfterAddFigures;
        var canCreateFigures = loaderParams.canCreateElements;

        sheetRequester.stopProgress();

        var figures = canCreateFigures ? chunkManager.getProcessedFigures() : [];

        subPixel.silentMode(true);
        loaderParams.onBeforeSetFigures && loaderParams.onBeforeSetFigures(figures);

        subPixel.elements(figures);

        var staticFigures = figures.length;
        subPixel.reindexPosition(staticFigures);

        if (drawAfterAdd) {
            firstDrawn && subPixel.fitToScreen();
            firstDrawn = false;
        }

        var layersNames = chunkManager.getLayersNames();

        // drop all not drawn layers
        if (!loaderParams.loadAllChunks){
            lm.hide(dropGroups);
            lm.show(dropGroups, layersNames);
        }

        loaderParams.onFiguresAdded && loaderParams.onFiguresAdded();

        subPixel.silentMode(false);
        canvas.updateScreenRatio();
        if (drawAfterAdd){
            subPixel.redraw(function () {
                loaderParams.onDrawn && loaderParams.onDrawn();
                broadcast.trig(sheetLoaderEvs.loaded);
            });
        } else {
            broadcast.trig(sheetLoaderEvs.loaded);
        }
    }

    function loadChunkByName(chunkName, cb) {
        var self = this;
        var urlTo = loaderParams.url;
        var fullUrl = urlTo + "/html5/" + chunkName;
        var fileType = chunkManager.getTypeByFileName(chunkName);
        function onError() {
            sheetRequester.abortGets();
            self.onError(codes.NO_CHUNK, chunkName);
            cb(codes.NO_CHUNK, chunkName);
        }
        var binded = sheetRequester.getJson(fullUrl, {
            fileType: fileType,
            onDone: function (error, data) {
                if (error) {
                    // if one chunk not loaded, drop all requests!!!
                    // because plan will be incorrect
                    onError();
                } else {
                    if (data && utils.isArray(data)){
                        chunkManager.addLoadedChunk(chunkName, data, function (code, retData) {
                            if (code){
                                self.onError(code, retData);
                            }
                            cb && cb(code, retData);
                        });
                    } else {
                        // something wrong with structure of chunk
                        onError();
                    }
                }
                onError = null;
            }
        });
        if (!binded){
            cb();
            onError = null;
        }
    }

})(subPixel);
