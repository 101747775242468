/*
 * Project:   PlotPad HTML5 Viewer
 * File:      plans.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var internal = cafm.internal;
    var events = cafm.events;
    var sheetLoader = subPixel('sheet-loader');
    var config = subPixel('config');
    var offset = subPixel('offset');
    var holdover = subPixel('holdover');
    var chunkManager = subPixel('chunk-manager');
    var utils = subPixel('utils');
    var parts = subPixel('parts');
    var defObject = parts('defObject');
    var TYPE_SEL_OBJ = defObject.SUB_TYPE;

    function onFiguresAdded(){
        var manifest = sheetLoader.getManifestData();
        if (manifest){
            internal.setDataToObjects(manifest.objects);
            internal.setDataToLayerManager(manifest.layers);
            
            var groups = chunkManager.getGroupsNames();
            var ids = chunkManager.getObjectIdsNames();
            internal.updateViewOfObjects(ids, groups);
        }
        
    }

    function convertUrlFromOldFormat(url){
        var checkUrl = (url + '').toLowerCase();
        var pos = checkUrl.indexOf('manifest.xml');
        var pos2 = checkUrl.indexOf('manifest.json');
        if (pos != -1){
            url = url.substring(0, pos - 1);
        } else if (pos2 != -1){
            url = url.substring(0, pos2 - 1);
        }
        return url;
    }

    function sortByObjectType(figures) {
        var startPos = subPixel.getPosStartObjects();
        var endPos = subPixel.getPosEndObjects();

        // var cnt = 0;
        // for (var i = startPos; i <= endPos; i++) {
        //     var fig = figures[i];
        //     fig._posCheck = cnt;
        //     cnt++;
        // }

        for (var i = startPos; i <= endPos; i++) {
            var fig = figures[i];
            for (var k = i + 1; k <= endPos; k++) {
                var checkFig = figures[k];
                if (/*i > k &&*/ checkFig != fig &&
                    fig.customObjectType && fig.customObjectType() == TYPE_SEL_OBJ &&
                    checkFig.customObjectType && checkFig.customObjectType() != TYPE_SEL_OBJ
                ) {
                    figures[i] = checkFig;
                    figures[k] = fig;
                    break;
                }
            }
        }

        // var rev = [];
        // utils.map(figures, function (item) {
        //     rev.push(item);
        // }, startPos, endPos);
        //
        // rev.reverse();
        //
        // var cnt = 0;
        // utils.map(figures, function (item, index) {
            // var newItem = rev[cnt];
            // figures[index] = newItem;
        //     cnt++;
        // }, startPos, endPos);
        //
        //
        // // checking
        // var map = [];
        // for (var i = startPos; i <= endPos; i++) {
        //     var fig = figures[i];
        //     var pos = fig._posCheck;
        //     map[pos] = true;
        // }
        //
        // var dropped = false;
        // utils.map(map, function (item) {
        //     if (!item){
        //         dropped = true;
        //     }
        // });
        //
        // console.info('checkPos', map.length - 1, endPos - startPos);
        // console.info('dropped', dropped);
    }

    // function getObjects() {
    //     var chunkManager = subPixel('chunk-manager');
    //     chunkManager.processObjects(function (fig) {
    //         console.log(fig.customObjectType());
    //     });
    // }

    cafm.LoadDrawing = function(url, layers, groups){
        var holder = holdover(true);
        url = convertUrlFromOldFormat(url);

        sheetLoader
            .startLoad({
                url: url,
                layers: layers,
                groups: groups,
                loadObjectChunk: true,
                loadAllChunks: false,
                loadObjectChunkFirst: true,
                loadPublish: false,
                onPrepared: function (error, code) {
                    if (error){
                        events.OnError(1, 'Manifest load error. Input/output error.');
                    } else {
                        if (!layers || layers.length == 0){
                            layers = cafm.GetLayers();
                        }
                        if (!groups || groups.length == 0){
                            groups = cafm.GetGroupsNames();
                        }
                        sheetLoader.loadLayersAndGroups(layers, groups);
                    }
                },
                onManifestLoaded: function (error) {
                    events.onDocumentLoadComplete(!error);
                },
                onDrawn: function () {
                    events.onDocumentRenderComplete();
                    holder.resolve();
                },
                onBeforeSetFigures: function (figures) {
                    sortByObjectType(figures);
                },
                onFiguresAdded: onFiguresAdded,
                onError: function(code, data){
                    holder.reject(code, data);
                }
            });
        return holder;
    };

    cafm.PrintDrawing = function(){
        var imgURI = subPixel.getScreenImage();
        var windowContent = '<!DOCTYPE html>';
        windowContent += '<html>';
        windowContent += '<head><title></title></head>';
        windowContent += '<body onload=\'window.print()\'>';
        windowContent += '<img src="' + imgURI + '">';
        windowContent += '</body>';
        windowContent += '</html>';
        var printWin = window.open('', '', '0,0');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
        printWin.focus();
        // printWin.print();
        // printWin.close();
    };

    cafm.SaveToPng = function(){
        var imgURI = subPixel.getScreenImage();
        return imgURI;
    };

    cafm.SendPng = function(id, reportId){
        var holder = holdover(true);
        var sendUrl = cafm.options.imageSendingUrl;
        if (!sendUrl){
            cafm.events.OnError(1, 'Not defined "imageSendingUrl" parameter');
            return;
        }

        if (!id){
            cafm.events.OnError(1, 'Not defined "id" parameter for .SendPng()');
            return;
        }

        var manifest = sheetLoader.getManifestData();
        if (manifest && manifest.info) {
            var w = manifest.info.width;
            var h = manifest.info.height;
            var el = config.el;
            var style = el.style;
            style.width = w + 'px';
            style.height = h + 'px';
            offset.checkResize();
            subPixel.fitToScreen().then(function () {
                var imgURI = subPixel.getScreenImage('image/png');
                imgURI = imgURI.replace("data:image/png;base64,", "");
                sendPngData(imgURI, id, reportId);
                style.height = '';
                style.width = '';
                offset.checkResize();
                holder.resolve();
            });
        }
        return holder;
    };

    function sendPngData(imgURI, id, reportId){
        var sendUrl = cafm.options.imageSendingUrl;
        var data = new FormData();
        data.append('data', imgURI);

        var xhr = new window.XMLHttpRequest();

        var url = sendUrl + "?id=" + id + "&reportID=" + reportId;

        xhr.open("POST", url, true);
        xhr.send(data);

    }

})(cafm);