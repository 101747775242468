/*
 * Project:   PlotPad HTML5 Viewer
 * File:      tools.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/09/2014
 */
/*
* store all toolsCollection (classes and instances) and draw toolsCollection element
*
* */
(function(subPixel, undefined){
    var utils = subPixel('utils');

    var editor = subPixel('editor');
    var mouse = subPixel('mouse');
    var config = subPixel('config');
    var domHelper = subPixel('dom-helper');
    var broadcast = subPixel('broadcast');
    var mouseEvs = broadcast.events('mouse');
    var systemEvs = broadcast.events('system');
    var userApiEvents = subPixel('user-api-events');
    var logger = subPixel('logger')('tools');

    var toolboxEvs = broadcast.events('editor-tools', {
        notSelected: "ns",
        select: "s",
        deselect: "d"
    });

    userApiEvents(toolboxEvs.select, 'editor-toolbox-tool-select');
    userApiEvents(toolboxEvs.notSelected, 'editor-toolbox-tool-not-selected');

    var TOOL_CSS_CLASS = 'tool-el';
    var GROUP_TOOL_CSS_CLASS;

    // collections for all toolsCollection (instances and classes)
    var toolsCollection = {};
    var domPlaces = {};

    var defToolName = 'base';

    // previous selected tool
    var prevPrimaryTool;

    var ACTIVE_CSS = "active";

    // current tool DOM elements, that was selected
    var currTool;
    var currentGroup;

    // external interface
    function editorTools(toolClass, params){
        var ret;
        if (typeof toolClass == "string"){
            // getter
            // toolClass is name of tool
            ret = toolsCollection[toolClass];
        } else {
            // setter
            ret = addNewTool(toolClass, params);
        }
        if (ret){
            ret = ret.getClass();
        }
        return ret;
    }

    utils.extend(editorTools, {
        TOOL_CSS_CLASS: TOOL_CSS_CLASS,

        // return tool instance
        getTool: function(name){
            return toolsCollection[name] && toolsCollection[name];
        },
        selectTool: function(name){
            var tool = this.getTool(name);
            if (tool){
                selectTool(tool);
            }
        },
        setToolParams: function(params) {
            if(currTool) {
                currTool.setToolParams(params);
            }
        },
        placeForTools: function(name, val){
            if (val){
                if (domPlaces[name]){
                    logger.error('trying to register defined DOM container "'+ name +'"');
                } else {
                    if (typeof val == "function"){
                        // is class
                        val._usedAsClass = true;
                    }
                    domPlaces[name] = val;
                }
            }
            return domPlaces[name];
        }
    });

    function addNewTool(elClass, params){
        var collection = toolsCollection;
        if (!elClass || !params){
            utils.info("toolsCollection", 'Trying register not correct defined tool. This tool will dropped. continue...');
            return;
        }

        var name = params.name;
        var inst;

        if (!collection[name]){

            inst = collection[name] = new elClass(params, elClass);

            appendToolToContainer(inst, params);
            inst.onToolAdded();
        } else {
            logger.error('trying to register defined tool "'+ name +'"');
        }
        return inst;
    }

    function appendToolToContainer(inst, params) {
        var addToBox = params.visible = (params.visible === undefined) ? true : params.visible;
        if (addToBox){
            var groupName = params.group || "";
            if (groupName){
                var group = toolsCollection[groupName];
                group.append(inst.getEl());
                inst.parentGroup(group);
            } else {
                var placeDom = inst.getPlace();
                var addPlace;
                if (inst.isExtTool()){
                    inst.visible(false);
                    addPlace = placeDom.subContainer();
                } else {
                    addPlace = placeDom.element();
                }
                addPlace.appendChild(inst.getEl());
            }
        }
    }

    // drop 'active' DOM element in toolbox
    function removeActive(tool){
        if (tool){
            var el = tool.getEl();
            domHelper.removeClass(el, ACTIVE_CSS);
        }
    }

    // set 'active' DOM element in toolbox
    function addActive(tool){
        if (tool){
            var el = tool.getEl();
            domHelper.addClass(el, ACTIVE_CSS);
        }
    }

    // show extTools
    function showExtTools(newTool){
        if (!newTool.isExtTool() && !newTool.isGroup() && newTool.isPrimary()){
            var placeName = newTool.getPlaceName();
            var extTools = newTool ? newTool.extTools() : null;
            for (var key in toolsCollection){
                // hide all placeName tools
                var tool = toolsCollection[key];
                if (tool.isExtTool()){
                    if (tool.getPlaceName() == placeName){
                        tool.visible(false);
                    }
                    var toolName = tool.name();
                    if (extTools && extTools.indexOf(toolName) != -1){
                        tool.visible(true);
                    }
                }
            }
            extTools = null;
        }
    }

    // return action name of selected DOM element
    function getToolByElement(el){
        var toolName = el ? el.getAttribute('data-toolname') : null;
        var ret = toolsCollection[toolName];
        return ret;
    }

    function deselectCurrentGroup(){
        if (currentGroup) {
            currentGroup.onToolDeselect();
            removeActive(currentGroup);
        }
        currentGroup = null;
    }

    //trigger deselect event for tool
    function onDeselect(tool, trigger){
        if (tool){
            // tool must be always defined, if not, something wrong with caller
            removeActive(currTool);
            currTool = null;
            deselectCurrentGroup();
            tool.onToolDeselect();
            (trigger !== false) && !tool.isGroup() && broadcast.trig(toolboxEvs.deselect, tool.name());
        }
    }

    function isClickInsideTool(currEl, target){
        var ret = false;
        if (currEl == target){
            ret = true;
        } else {
            var currRect = currEl.getBoundingClientRect();
            // var targetRect = target.getBoundingClientRect();
            if ((currRect.left < mouse.x) && mouse.x < (currRect.left + currRect.width)  &&
                (currRect.top < mouse.y) && mouse.y < (currRect.top + currRect.height)){
                //clicked inside tool DOM element
                ret = true;
            }
        }

        return ret;
    }

    function onToolSelect(newTool){
        newTool.isPrimary() && (prevPrimaryTool = newTool); // return tool after deselect extTool
        newTool.onToolSelect();
        showExtTools(newTool);
        broadcast.trig(toolboxEvs.select, newTool.name());
    }

    function dropSelectedTool(){
        deselectCurrentGroup();
        removeActive(currTool);
        onDeselect(currTool);
    }

    //when system calls to set default state, we need drop all tools to default tool
    function onSystemDefaultState(){
        dropSelectedTool();
        selectDefaultTool();
    }

    // process selection of tool in toolbox
    function onMouseUp(e){
        var target = e.target;
        var toolEl = domHelper.closest(target, TOOL_CSS_CLASS);
        if (toolEl){
            var newTool = getToolByElement(toolEl);
            if (currTool != newTool) {
                // select other tool
                selectTool(newTool);
            } else {
                // select the same tool
                if (newTool && currTool && isClickInsideTool(currTool.getEl(), target)){
                    // clicked on by tool div
                    onDeselect(newTool);
                } else {
                    // clicked inside tool div
                    newTool.onClick(e);
                    if (newTool.canDeselectInside(e)){
                        onDeselect(newTool);
                    }
                }
            }
        } else {
            // clicked outside
            var lastTool = currTool;
            if (lastTool && lastTool.canDeselectOutside(e)){
                lastTool.onToolDeselect();
                onDeselect(lastTool);
            }
        }
        selectPrevTool();
    }

    function canUseTool(tool) {
        var ret = (tool.canUseTool() !== false);
        return ret;
    }

    function getNewToolByGroup(group, newTool, lastTool) {
        var ret = newTool;
        if (newTool == group){
            // group selected
            var childToolName = group.getSelectedChildTool();
            if (childToolName && !isToolInGroup(lastTool, group)){
                if (!lastTool || (lastTool && lastTool != group)){
                    ret = toolsCollection[childToolName];
                }
            }
        } else {
            // tool selected
        }
        return ret;
    }

    function selectTool(newTool){
        if (currTool != newTool) {
            if (newTool && newTool.isEnable()){
                var lastTool = currTool;

                var group = !newTool.isGroup() ? newTool.parentGroup() : newTool;
                if (group){
                    newTool = getNewToolByGroup(group, newTool, lastTool)
                }

                if (canUseTool(newTool)){
                    onDeselect(currTool, false);
                    if (group){
                        !newTool.isGroup() && group.setIndicator(newTool.name());
                        currentGroup = group;
                        addActive(currentGroup);
                    }

                    activateTool(newTool);
                } else {
                    if (group){
                        group.setDefaultTool();

                        if (config.tools.returnToDefault){
                            var notActiveTool = defToolName;
                            deselectCurrentGroup();
                        } else {
                            notActiveTool = group.getSelectedChildTool();
                        }

                        if (notActiveTool && toolsCollection[notActiveTool]){
                            activateTool(toolsCollection[notActiveTool]);
                        }
                    }
                    // trying to select tool, but tool can not be used
                    broadcast.trig(toolboxEvs.notSelected, newTool.name());
                }
            }
        }
    }

    function activateTool(newTool) {
        !newTool.isGroup() && addActive(newTool);
        onToolSelect(newTool);
        currTool = newTool;
    }

    function isToolInGroup(tool, group) {
        var ret = false;
        if (tool){
            var parentGroup = tool.parentGroup();
            ret = (parentGroup == group);
        }
        return ret;
    }

    function selectPrevTool(){
        if (!currTool){
            if (prevPrimaryTool && prevPrimaryTool.isPrimary() && prevPrimaryTool.isEnable()){
                // select primary tool
                selectTool(prevPrimaryTool);
            } else {
                selectDefaultTool();
            }
        }
    }

    function selectDefaultTool(){
        if (!currTool && defToolName){
            // select default tool
            var tool = toolsCollection[defToolName];
            tool && selectTool(tool);
        }
    }

    // bind/unbind mouse event
    function watchMouse(action){
        broadcast
            [action](mouseEvs.up, onMouseUp)
            [action](systemEvs.defaultState, onSystemDefaultState);
    }

    // processing place actions
    function processPlaces(onProcess){
        for (var key in domPlaces){
            var item = domPlaces[key];
            if (!item._usedAsClass){
                onProcess(item);
            }
        }
    }

    function processTools(cb) {
        for (var key in toolsCollection){
            cb(toolsCollection[key]);
        }
    }

    function start(){
        GROUP_TOOL_CSS_CLASS = toolsCollection['base-group'].GROUP_CSS;
        // start all places
        processPlaces(function(item){
            item.onStart();
        });
        processTools(function (tool) {
            tool.onStart();
        });
        watchMouse('off');
        watchMouse('on');
        defineBaseTool();
        selectDefaultTool();
    }

    function defineBaseTool() {
        if (config.tools.defaultTool){
            defToolName = config.tools.defaultTool;
        } else {
            defToolName = 'base';
        }
    }

    function stop(){
        watchMouse('off');
        dropSelectedTool();
        //stop all places
        processPlaces(function(item){
            item.onStop();
        });
    }

    utils.bindStartStop(start, stop);

    editor('tools', editorTools);

})(subPixel);
