/*
 * Project:   PlotPad HTML5 Viewer
 * File:      hover-manager.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/04/2014
 */
(function(subPixel){

    var utils = subPixel('utils');
    var canvas = subPixel('canvas');
    var mouse = subPixel('mouse');
    var broadcast = subPixel('broadcast');
    var hoverManagerEvs = broadcast.events('hover-manager', {
        hovered: "hovered"
    });
    var elementsCtrl;

    var isCanvasChecker = {
        'canvas': true,
        'CANVAS': true
    };

    var inCanvasHover = true;

    var prevEls = [];
    var newEls = [];
    var hoverArea = {
        minx: 0,
        miny: 0,
        maxx: 0,
        maxy: 0,
        x: 0,
        y: 0
    };

    var THRESHOLD_MOUSE = 3;

    function getElements(){
        // dont' create new array, just return link from main array!
        return elementsCtrl.getElements();
    }

    function cleanUp(){
        newEls.length = 0;
    }

    function checkHovered(){
        var canTrig = false;
        if (newEls.length == prevEls.length){
            for (var j = 0, k = newEls.length; j < k; j++){
                if (newEls[j] != prevEls[j]){
                    canTrig = true;
                    break;
                }
            }
        } else {
            canTrig = true;
        }

        hoverManager.dropHovers();
        utils.push(prevEls, newEls);
//        prevEls.push.apply(prevEls, newEls);

        if (canTrig) {
            broadcast.trig(hoverManagerEvs.hovered, prevEls);
        }
        return canTrig;
    }

    function getHoverClip(){

        var helperPoint = canvas.getTransformedPoint(mouse.x, mouse.y);
        var minPoint = canvas.getTransformedPoint(mouse.x - THRESHOLD_MOUSE, mouse.y - THRESHOLD_MOUSE);
        var maxPoint = canvas.getTransformedPoint(mouse.x + THRESHOLD_MOUSE, mouse.y + THRESHOLD_MOUSE);

        // select by point
        hoverArea.minx = minPoint.x;
        hoverArea.miny = minPoint.y;

        hoverArea.maxx = maxPoint.x;
        hoverArea.maxy = maxPoint.y;


        // detect w,h params
        hoverArea.w = hoverArea.maxx - hoverArea.minx;
        hoverArea.h = hoverArea.maxy - hoverArea.miny;

        // gettin point for some clips
        hoverArea.x = helperPoint.x;
        hoverArea.y = helperPoint.y;

        helperPoint = minPoint = maxPoint = null;

        return hoverArea;
    }

    var hoverManager = subPixel('hover-manager', {
        getHovered: function () {
            return prevEls;
        },
        dropHovers: function () {
            prevEls.length = 0;
        },
        endHovers: function () {
            cleanUp();
            checkHovered()
        },
        hoverElements: function (ids) {
            inCanvasHover = true;
            cleanUp();
            if (!utils.isArray(ids)) {
                ids = [ids];
            }

            for (var i = 0, l = ids.length; i < l; i++) {
                var id = ids[i];
                var item = elementsCtrl.getElementById(id);
                item && item.canDraw() && newEls.push(item);
            }

            checkHovered();
        },
        hover: function (ev) {
            inCanvasHover = true;
            if (ev) {
                inCanvasHover = (isCanvasChecker[ev.target.nodeName]) ? true : false;
            }
            var area = getHoverClip();
            var elements = getElements();

            cleanUp();
            for (var i = elements.length - 1, l = 0; i >= l; i--) {
                var item = elements[i];
                if (item.inHoverArea(area)) {
                    newEls.push(item);
                }
            }
            var ret = checkHovered();
            return ret;
        }
    });

    utils.bindStartStop(function () {
        elementsCtrl = subPixel('elements-ctrl');
    });

})(subPixel);
