/*
 * Project:   PlotPad HTML5 Viewer
 * File:      text.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 00/25/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var BasePartClass = parts('base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var broadcast = subPixel('broadcast');
    var crossingHelper = parts('crossing-helper');
    var domHelper = subPixel('dom-helper');
    var config = subPixel('config');

    var bboxTextHelper = document.createElement('p');
    // do not use <pre> tag, because it's not correctly working with NON en langs

    bboxTextHelper.style.display = 'inline';
    var bboxDOMhelper = document.createElement('div');
    bboxDOMhelper.style.display = 'inline-block';
    bboxDOMhelper.className = 'subpixel-text-figure-helper';
    bboxDOMhelper.appendChild(bboxTextHelper);

    moveElementFromHere(bboxDOMhelper);

    var showHelper = false;

    var DEF_MIN_FONT_SIZE = 1;
    var minFontSize = DEF_MIN_FONT_SIZE;


    function moveElementFromHere(el){
        var spanStyle = el.style;
        spanStyle.position = 'absolute';
        spanStyle.top = '-9000px';
        spanStyle.left = '-9000px';
        spanStyle['white-space'] = 'nowrap';
    }

    function getMinimumFontSize(val) {
        moveElementFromHere(bboxDOMhelper);
        var DOMbBox = setBboxHelperData('a b c d e f g h i j k l m n o p q r s t u v w x y z', val + 'px arial');
        var minimumHeight = DOMbBox.height;
        var least = 0;
        var most = 64;
        var middle;
        for (var i = 0; i < 10; ++i) {
            middle = (least + most)/2;
            bboxDOMhelper.style.fontSize = middle + 'px';
            DOMbBox = bboxDOMhelper.getBoundingClientRect();
            if (DOMbBox.height === minimumHeight) {
                least = middle;
            } else {
                most = middle;
            }
        }

        if (middle <= 1.5){
            middle = 1.5;
        }
        return middle;
    }

    function onStart(){
        bboxDOMhelper.style.display = 'inline';
        addHelper();
    }

    function onStop(){
        bboxDOMhelper.style.display = 'none';
        showHelper = false;
        domHelper.moveToFragment(bboxDOMhelper);
    }


    function addHelper(){
        showHelper = true;
        document.body.appendChild(bboxDOMhelper);
        if (config.minFontSize){
            minFontSize = config.minFontSize;
        }  else {
            minFontSize = getMinimumFontSize(DEF_MIN_FONT_SIZE) - 0.5;
        }
    }

    function setBboxHelperData(text, font) {
        bboxTextHelper.innerHTML = text;
        bboxDOMhelper.style.font = font;

        var DOMbBox = bboxDOMhelper.getBoundingClientRect();
        return DOMbBox;
    }

    function getAlignValue(fontObj) {
        var fontData = fontObj.font;
        var align = fontObj.align || (fontData && fontData.align);
        return align;
    }

    function getBounds(fontObj){
        var pos = fontObj.position;
        var posx = pos.x;
        var posy = pos.y;
        var fontData = fontObj.font;
        var fontSize = fontData.size;
        var align = getAlignValue(fontObj);
        var baseLine = fontObj.baseline;
        var scaleValHeight = 1;
        var scaleValWidth = 1;
        var useScaling = (fontSize <= minFontSize);

        if (useScaling){
            scaleValHeight = (minFontSize / fontSize);
            fontSize = minFontSize;
        }
        var styleFont = getFont(fontData.name, fontData.size, fontSize);
        var DOMbBox = setBboxHelperData(fontObj.text, styleFont);
        var w = DOMbBox.width;
        var h = DOMbBox.height;

        if (useScaling){
            // calculate width value
            var checkSize = minFontSize * scaleValHeight;
            styleFont = getFont(fontData.name, fontData.size, checkSize);
            DOMbBox = setBboxHelperData(fontObj.text, styleFont);
            scaleValWidth = DOMbBox.width / w;
        }

        var scaledW = w / scaleValWidth;
        var scaledH = h / scaleValHeight;

        var alignX = textFigure.getXByAlign(w, align);
        var baselineY = textFigure.getYByBaseline(h, baseLine);
        var alignXScaled = alignX / scaleValWidth;
        var baselineYScaled = baselineY / scaleValHeight;

        posx -= alignXScaled;
        posy -= baselineYScaled;

        var x = posx + scaledW;
        var y = posy + scaledH;

        var points = [
            {x: x, y: y},
            {x: posx, y: y},
            {x: posx, y: posy},
            {x: x, y: posy}
        ];
        return points;
    }

    function updateHelper(inst){
        var points = getBounds(inst.data());
        inst.initBoundingBox(points);
    }

    function getFont(name, defSize, fontSize){
        return "normal " +  (fontSize !== undefined ? fontSize : defSize) + "px " + name;
    }

    function drawSelection(ctx){
        var angle = this.rotationAngle();
        var bbox = this.getBoundingBox();
        var saveStroke = ctx.strokeStyle;

        ctx.save();

        canvas.rotateAroundPoint(ctx, angle, bbox.minx + bbox.w / 2, bbox.miny + bbox.h / 2);

        ctx.beginPath();
        ctx.strokeStyle = "#f00";
        ctx.strokeRect(bbox.minx, bbox.miny, bbox.w, bbox.h);
        ctx.closePath();

        ctx.strokeStyle = saveStroke;
        saveStroke = null;
        ctx.restore();
    }


    /// text figure instance creating

    var textFigure = utils.createChildClass(BasePartClass, 'textFigureClass', {
        init: function (data) {
            !showHelper && addHelper();
            var fontParams = data.font;
            var drawParams = this.drawParams;

            var color = utils.colorToStr(fontParams.color) || "#000";

            drawParams.strokeStyle = color;
            updateHelper(this);

            this.propPointsType(crossingHelper.TYPE_POLY);

        },
        draw: function (opt) {
            var ctx = canvas.buffCtx;
            var data = this.data();
            var drawParams = this.drawParams;
            if(opt.isSelection) {
                ctx.fillStyle = opt.fillStyle;
                drawSelection.call(this, ctx);
            } else {
                ctx.fillStyle = BasePartClass.getStrokeStyle(drawParams, opt); // hack for text figure, using strokeStyle for fillStyles
            }
            ctx.lineWidth = BasePartClass.getLineWidth(drawParams, opt);
            if (data.name === "anno-text") {
                data.font.rotation = -this.rotationAngle();
            }
            data.bbox = this.getHelpPoints();
            this.drawText(ctx, data);

            // delete data.font.rotation;
        },
        drawText: function () {
            textFigure.drawText.apply(textFigure, arguments);
        }
    });

    var intBboxHelper = {};
    textFigure.getInitDataBbox = function(data){
        utils.createBboxSctruct(intBboxHelper);
        var points = getBounds(data);
        BasePartClass.updateBboxByPoints(intBboxHelper, points);
        return intBboxHelper;
    };

    textFigure.renderText = function (ctx, renderObj) {

        var x = renderObj.x;
        var y = renderObj.y;

        var scaleValX = renderObj.scaleValX;
        var scaleValY = renderObj.scaleValY;

        var widthScale = renderObj.widthScale;
        var rotation = renderObj.rotation;
        var fontName = renderObj.fontName;
        var fontSizeNorm = renderObj.fontSize;

        var text = renderObj.text;
        var align = renderObj.align;
        var baseline = renderObj.baseline;

        ctx.save();

        ctx.translate(x, y);

        if (scaleValX && scaleValY){
            ctx.scale(scaleValX, scaleValY);
        }

        if (rotation !== undefined){
            ctx.rotate(rotation);
        }

        x = 0;
        y = 0;

        if (widthScale && widthScale != 1) {
            // the width of text a little bit more than they should
            var dx = (widthScale > 1) ? 1.1 * widthScale : 0.9 * widthScale;
            ctx.scale(dx, 1);
        }

        var fontSize = fontSizeNorm;
        // calculate scale for min text size
        if (fontSize <= minFontSize){
            var scaleVal = 1 / (minFontSize / fontSize);
            ctx.scale(scaleVal, scaleVal);
            fontSize = minFontSize;
        } else if (fontSize < 100){
            // fix fractional scale
            fontSize *= 100;
            ctx.scale(0.01, 0.01);
        }
        ctx.font = getFont(fontName, fontSizeNorm, fontSize);
        text = text || '';
        align && (ctx.textAlign = align);
        baseline && (ctx.textBaseline = baseline);
        ctx.fillText(text, x, y);

        //ctx.beginPath();
        //ctx.rect(x, y, fontSize * 5, fontSize / 5);
        //ctx.fillStyle = 'yellow';
        //ctx.fill();
        //ctx.closePath();

        ctx.restore();
    };

    var singleParams = {
        x: 0,
        y: 0,

        scaleValX: 0,
        scaleValY: 0,

        widthScale: 0,
        rotation: null,
        fontName: "",
        fontSize: 0,

        text: "",
        align: "",
        baseline: ""
    };

    textFigure.drawText = function(ctx, textObject, defText, opt){

        singleParams.x = textObject.position.x;
        singleParams.y = textObject.position.y;
        singleParams.scaleValX = 0;
        singleParams.scaleValY = 0;
        singleParams.widthScale = textObject.font['width-scale'];
        singleParams.bbox = textObject.bbox;

        var textFont = textObject.font;
        singleParams.rotation = textFont.rotation;
        singleParams.fontName = textFont.name;
        singleParams.fontSize = textFont.size;

        singleParams.text = textObject.text || defText || '';
        singleParams.align = getAlignValue(textObject);
        singleParams.baseline = textObject.baseline;

        if (opt){
            (opt.x !== undefined) && (singleParams.x = opt.x);
            (opt.y !== undefined) && (singleParams.y = opt.y);
            (opt.scaleValX !== undefined) && (singleParams.scaleValX = opt.scaleValX);
            (opt.scaleValY !== undefined) && (singleParams.scaleValY = opt.scaleValY);
        }

        textFigure.renderText(ctx, singleParams);
    };

    textFigure.getXByAlign = function(w, align){
        var ret = 0;
        if (align == 'center'){
            w && (ret = w / 2);
        } else if (align == 'right'){
            w && (ret = w);
        }
        return ret;
    };

    textFigure.getYByBaseline = function(h, baseLine){
        var ret = 0;
        if (baseLine == 'middle'){
            h && (ret = h / 2);
        } else if (!baseLine){
            h && (ret = h / 1.5);
        }
        return ret;
    };

    parts('text', textFigure);



    utils.bindStartStop(onStart, onStop);

})(subPixel);
