/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.slantLeftDash.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('slantLeftDash', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.338226318359375, 0, 0, 0.3380279541015625, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#282425";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(70.95, 88.75);
        ctx.lineTo(73.5, 86.2);
        ctx.lineTo(71, 83.75);
        ctx.lineTo(66, 88.75);
        ctx.lineTo(70.95, 88.75);
        ctx.moveTo(53.25, 83.75);
        ctx.lineTo(48.25, 88.75);
        ctx.lineTo(53.2, 88.75);
        ctx.lineTo(55.7, 86.2);
        ctx.lineTo(53.25, 83.75);
        ctx.moveTo(55.7, 68.45);
        ctx.lineTo(53.25, 65.95);
        ctx.lineTo(41.9, 77.3);
        ctx.lineTo(44.35, 79.8);
        ctx.lineTo(55.7, 68.45);
        ctx.moveTo(44.35, 62);
        ctx.lineTo(55.7, 50.65);
        ctx.lineTo(53.25, 48.2);
        ctx.lineTo(41.9, 59.55);
        ctx.lineTo(44.35, 62);
        ctx.moveTo(73.5, 68.45);
        ctx.lineTo(71, 65.95);
        ctx.lineTo(59.65, 77.3);
        ctx.lineTo(62.15, 79.8);
        ctx.lineTo(73.5, 68.45);
        ctx.moveTo(62.15, 62);
        ctx.lineTo(73.5, 50.65);
        ctx.lineTo(71, 48.2);
        ctx.lineTo(59.65, 59.55);
        ctx.lineTo(62.15, 62);
        ctx.moveTo(35.45, 83.75);
        ctx.lineTo(30.45, 88.75);
        ctx.lineTo(35.4, 88.75);
        ctx.lineTo(37.95, 86.2);
        ctx.lineTo(35.45, 83.75);
        ctx.moveTo(2.4, 86.2);
        ctx.lineTo(0, 83.8);
        ctx.lineTo(0, 88.6);
        ctx.lineTo(2.4, 86.2);
        ctx.moveTo(20.2, 86.2);
        ctx.lineTo(17.7, 83.75);
        ctx.lineTo(12.7, 88.75);
        ctx.lineTo(17.65, 88.75);
        ctx.lineTo(20.2, 86.2);
        ctx.moveTo(8.8, 62);
        ctx.lineTo(20.2, 50.65);
        ctx.lineTo(17.7, 48.2);
        ctx.lineTo(6.35, 59.55);
        ctx.lineTo(8.8, 62);
        ctx.moveTo(20.2, 68.45);
        ctx.lineTo(17.7, 65.95);
        ctx.lineTo(6.35, 77.3);
        ctx.lineTo(8.8, 79.8);
        ctx.lineTo(20.2, 68.45);
        ctx.moveTo(2.4, 68.45);
        ctx.lineTo(0, 66);
        ctx.lineTo(0, 70.85);
        ctx.lineTo(2.4, 68.45);
        ctx.moveTo(0, 53.1);
        ctx.lineTo(2.4, 50.65);
        ctx.lineTo(0, 48.25);
        ctx.lineTo(0, 53.1);
        ctx.moveTo(37.95, 68.45);
        ctx.lineTo(35.45, 65.95);
        ctx.lineTo(24.1, 77.3);
        ctx.lineTo(26.6, 79.8);
        ctx.lineTo(37.95, 68.45);
        ctx.moveTo(26.6, 62);
        ctx.lineTo(37.95, 50.65);
        ctx.lineTo(35.45, 48.2);
        ctx.lineTo(24.1, 59.55);
        ctx.lineTo(26.6, 62);
        ctx.moveTo(35.45, 30.4);
        ctx.lineTo(24.1, 41.8);
        ctx.lineTo(26.6, 44.25);
        ctx.lineTo(37.95, 32.9);
        ctx.lineTo(35.45, 30.4);
        ctx.moveTo(0, 35.3);
        ctx.lineTo(2.4, 32.9);
        ctx.lineTo(0, 30.5);
        ctx.lineTo(0, 35.3);
        ctx.moveTo(20.2, 32.9);
        ctx.lineTo(17.7, 30.4);
        ctx.lineTo(6.35, 41.8);
        ctx.lineTo(8.8, 44.25);
        ctx.lineTo(20.2, 32.9);
        ctx.moveTo(20.2, 15.15);
        ctx.lineTo(17.7, 12.65);
        ctx.lineTo(6.35, 24);
        ctx.lineTo(8.8, 26.5);
        ctx.lineTo(20.2, 15.15);
        ctx.moveTo(2.4, 15.1);
        ctx.lineTo(0, 12.7);
        ctx.lineTo(0, 17.55);
        ctx.lineTo(2.4, 15.1);
        ctx.moveTo(17.55, 0);
        ctx.lineTo(12.6, 0);
        ctx.lineTo(6.35, 6.25);
        ctx.lineTo(8.8, 8.7);
        ctx.lineTo(17.55, 0);
        ctx.moveTo(37.95, 15.1);
        ctx.lineTo(35.45, 12.65);
        ctx.lineTo(24.1, 24);
        ctx.lineTo(26.6, 26.5);
        ctx.lineTo(37.95, 15.1);
        ctx.moveTo(26.6, 8.7);
        ctx.lineTo(35.3, 0);
        ctx.lineTo(30.35, 0);
        ctx.lineTo(24.1, 6.25);
        ctx.lineTo(26.6, 8.7);
        ctx.moveTo(62.15, 44.25);
        ctx.lineTo(73.5, 32.9);
        ctx.lineTo(71, 30.4);
        ctx.lineTo(59.65, 41.8);
        ctx.lineTo(62.15, 44.25);
        ctx.moveTo(53.25, 30.4);
        ctx.lineTo(41.9, 41.8);
        ctx.lineTo(44.35, 44.25);
        ctx.lineTo(55.7, 32.9);
        ctx.lineTo(53.25, 30.4);
        ctx.moveTo(55.7, 15.1);
        ctx.lineTo(53.25, 12.65);
        ctx.lineTo(41.9, 24);
        ctx.lineTo(44.35, 26.5);
        ctx.lineTo(55.7, 15.1);
        ctx.moveTo(48.1, 0);
        ctx.lineTo(41.9, 6.25);
        ctx.lineTo(44.35, 8.7);
        ctx.lineTo(53.05, 0);
        ctx.lineTo(48.1, 0);
        ctx.moveTo(73.5, 15.1);
        ctx.lineTo(71, 12.65);
        ctx.lineTo(59.65, 24);
        ctx.lineTo(62.15, 26.5);
        ctx.lineTo(73.5, 15.1);
        ctx.moveTo(70.85, 0);
        ctx.lineTo(65.9, 0);
        ctx.lineTo(59.65, 6.25);
        ctx.lineTo(62.15, 8.7);
        ctx.lineTo(70.85, 0);
        ctx.moveTo(88.7, 53.2);
        ctx.lineTo(88.7, 48.25);
        ctx.lineTo(77.4, 59.55);
        ctx.lineTo(79.9, 62);
        ctx.lineTo(88.7, 53.2);
        ctx.moveTo(88.7, 70.95);
        ctx.lineTo(88.7, 66);
        ctx.lineTo(77.4, 77.3);
        ctx.lineTo(79.9, 79.8);
        ctx.lineTo(88.7, 70.95);
        ctx.moveTo(88.7, 83.8);
        ctx.lineTo(83.8, 88.75);
        ctx.lineTo(88.7, 88.75);
        ctx.lineTo(88.7, 83.8);
        ctx.moveTo(88.7, 35.45);
        ctx.lineTo(88.7, 30.5);
        ctx.lineTo(77.4, 41.8);
        ctx.lineTo(79.9, 44.25);
        ctx.lineTo(88.7, 35.45);
        ctx.moveTo(88.7, 17.65);
        ctx.lineTo(88.7, 12.7);
        ctx.lineTo(77.4, 24);
        ctx.lineTo(79.9, 26.5);
        ctx.lineTo(88.7, 17.65);
        ctx.moveTo(88.6, 0);
        ctx.lineTo(83.65, 0);
        ctx.lineTo(77.4, 6.25);
        ctx.lineTo(79.9, 8.7);
        ctx.lineTo(88.6, 0);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
