/*
 * Project:   PlotPad HTML5 Viewer
 * File:      tooltip.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/08/2014
 */
(function(subPixel){

    var utils = subPixel('utils');
    var config = subPixel('config');
    var drawingCtrl = subPixel('drawing-ctrl');
    var inter = subPixel('interface');
    var broadcast = subPixel('broadcast');
    var logger = subPixel('logger')('tooltip');
    var domHelper = subPixel('dom-helper');
    var userApiEvents = subPixel('user-api-events');
    var engineDom = subPixel('engine-dom');
    var parts = subPixel('parts');
    var drawOptHelper = parts('draw-options-helper');
    var configBlocks = subPixel('config-blocks');

    var toolboxEvs = broadcast.events('editor-tools');
    var mouseEvs = broadcast.events('mouse');
    var scenarioCtrlEvs = broadcast.events('scenario-ctrl');
    var elementsCtrlEvs = broadcast.events('elements-ctrl');

    var tooltipEvs = broadcast.events('tooltip', {
        onDraw: 'onDraw',
        onTap: 'onTap',
    });

    userApiEvents(tooltipEvs.onDraw, 'tooltip-on-draw');
    userApiEvents(tooltipEvs.onTap, 'tooltip-on-tap');

    var DEF_OVERFILL_CLR = "rgba(128, 128, 128, 0.5)";

    var drawOptObj = drawOptHelper({
        isTooltip: true,
        strokeStyle: DEF_OVERFILL_CLR,
        lineJoin: 'round'
    });

    var fragment = document.createDocumentFragment();

    var wasDrawn = false;
    var wasShown = false;
    var lastDrawnFig;

    var OVER_SCREEN_POSITION = '-9000px';

    var working = false;
    var topEdgeValue = 0;

    var isCanvas = {
        'canvas': true,
        'CANVAS': true
    };

    var accToolsSelect = {};

    var collection = {};

    var tooltip = subPixel('tooltip', {
        register: function (name, container) {
            var obj = {
                currentId: null,
                name: name,
                container: container
            };
            if (utils.isArray(name)) {
                for (var i = 0, l = name.length; i < l; i++) {
                    var item = name[i];
                    registerModule(item, obj);
                }
            } else {
                registerModule(name, obj);
            }

            return collection[name];
        },
        setTopEdgeValue: function (value) {
            topEdgeValue = value;
        },
        showTip: function (fig) {
            if (fig && !subPixel.isStopped()) {
                var partType = fig.partType();

                if (partType) {
                    cleanCanvas();
                    var item = collection[partType];
                    if (item && fig) {
                        drawElement(item, fig);
                    }
                }
            }
        },
        hideTip: function () {
            cleanCanvas();
        }
    });

    function registerModule(name, obj) {
        if (collection[name]){
            logger.error('tooltip "'+ name +'" already defined');
        } else {
            collection[name] = obj;
        }
    }

    function cleanCanvas(){
        if (wasDrawn){
            if (!subPixel.isStopped()){
                drawingCtrl.drawForeground();
                hideTooltip();
            }
            dropShown();
        }
    }

    function dropShown(){
        wasShown = false;
        lastDrawnFig = null;
    }

    function hideTooltip(){
        if (wasShown){
            hideTooltipContainers();
            dropShown();
        }
    }

    function hideTooltipContainers() {
        for (var key in collection){
            hideContainerTip(collection[key]);
        }
    }

    function hideContainerTip(item) {
        domHelper.hide(item.container);
    }

    function drawElement(item, fig){
        wasDrawn = true;
        wasShown = true;
        hideTooltipContainers();
        drawingCtrl.drawForeground(function(){
            onDrawElementEnd(item, fig);
        });
    }

    function onDrawElementEnd(item, fig) {
        fig.draw(drawOptObj);
        var sbox = fig.getScreenBounds();
        var centerX = (((sbox.maxx - sbox.minx) / 2) + sbox.minx);
        var centerY = (((sbox.maxy - sbox.miny) / 2) + sbox.miny);

        var topPos = sbox.h / 2;

        var x = centerX;
        var y = centerY + topPos;
        var partType = fig.partType();
        var domTemplate = item.container;
        var domStyle = domTemplate.style;
        domStyle.left = OVER_SCREEN_POSITION;
        domStyle.top = OVER_SCREEN_POSITION;

        var baseEl = engineDom.getBaseElement();

        baseEl.appendChild(domTemplate);

        if (partType && (partType == item.name)){
            var figData = fig.data();
            item.currentId = fig.getId();
            broadcast.trig(tooltipEvs.onDraw, figData);

            domHelper.show(domTemplate);

            // detect correct position of fig in screen
            var sRect = domTemplate.getBoundingClientRect();
            var wh = sRect.width;
            var h = sRect.height;

            var mainContainer = engineDom.getBaseElement();
            var maxTop = mainContainer.clientHeight - h;
            var maxLeft = mainContainer.clientWidth - wh;
            var minLeft = wh;

            (x < minLeft) && (x = minLeft);
            (x > maxLeft) && (x = maxLeft);
            (y > maxTop) && (y = maxTop); //(y = y - h - topPos);
            (y < 0) && (y = topEdgeValue);

            domStyle.left = x + 'px';
            domStyle.top = y + 'px';
        }

    }

    function onTapEnd(ev){
        if (isCanvas[ev.target.nodeName] && working){
            var figs = subPixel.getElementsNearMouse();
            detectLastHovered(figs);
        }

    }

    function detectLastHovered(els){
        var founded;
        var partType;

        for (var i = 0, l = els.length; i < l; i++){
            var fig = els[i];
            partType = fig.partType();
            var figData = fig.data();
            if (figData.name === "anno-image") {
                broadcast.trig(tooltipEvs.onTap, figData);
            }
            if (partType && collection[partType]){
                founded = fig;
            }
        }
        if (founded){
            //check prev
            if (founded != lastDrawnFig){
                partType = founded.partType();
                if (partType && collection[partType]){
                    var item = collection[partType];
                    lastDrawnFig = founded;
                    drawElement(item, founded);
                } else {
                    cleanCanvas();
                }
            }
        } else {
            cleanCanvas();
        }
        founded = null;
    }

    function onScenarioChanged(){
        broadcast.off(mouseEvs.tapstart, onTapEnd);
        broadcast.on(mouseEvs.tapstart, onTapEnd);
    }

    function onToolSelect(toolName){
        working = true;
        if (!accToolsSelect[toolName]){
            hideTooltip();
            working = false;
        }
    }

    function onElementRemoved(remEls) {
        var map = {};
        for (var key in collection){
            var item = collection[key];
            var id = item.currentId;
            map[id] = item;
        }

        utils.map(remEls, function (el) {
            var id = el.getId();
            if (map[id]){
                hideContainerTip(map[id]);
            }
        });
        map = null;
    }

    // bind events by action
    //
    // action - "on" or "off" state for broadcast module
    function processBroadcast(action){
        broadcast
            [action](elementsCtrlEvs.removedElements, onElementRemoved)
            [action](mouseEvs.tapstart, onTapEnd)
            [action](scenarioCtrlEvs.scenarioChanged, onScenarioChanged)
            [action]([mouseEvs.tapmove, mouseEvs.wheelstart, mouseEvs.pitchstart], hideTooltip)
            [action]([toolboxEvs.select], onToolSelect);
    }

    //start working
    function start(){
        var confTooltip = config.tooltip;
        drawOptObj.lineRatio = config.lineRatio;
        if (confTooltip.selectionColor){
            var clr = utils.colorToStr(confTooltip.selectionColor);
            drawOptObj.strokeStyle = clr;
        }
        var actionTools = confTooltip.tools;
        utils.clearObject(accToolsSelect);
        for (var key in actionTools){
            accToolsSelect[key] = actionTools[key];
        }

        working = true;
        processBroadcast('on');
    }

    function stop(){
        hideTooltip();
        processBroadcast('off');

        for (var key in collection){
            var domTemplate = collection[key].container;
            fragment.appendChild(domTemplate);
        }
    }


    utils.bindStartStop(start, stop);

    configBlocks.onAfterProcess(function (newConf) {
        !newConf.tooltip && (newConf.tooltip = {});
        !newConf.tooltip.tools && (newConf.tooltip.tools = {});
    });

    inter.register({
        hideTooltips: function () {
            tooltip.hideTip();
        }
    });

})(subPixel);
