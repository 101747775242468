/*
 * Project:   PlotPad HTML5 Viewer
 * File:      holdover.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/21/2016
 */
(function (subPixel, undefined) {
    var utils = subPixel('utils');


    function HoldOverClass(isNew) {
        if (isNew) {
            return new HoldOverClass();
        } else {
            this.isDone = false;
            this.isFail = false;
            this.isResulted = false;
            this.isDestroyed = false;
            this.res = undefined;
            this.waiters = [];
        }
    }

    utils.extendClass(HoldOverClass, {
        then: function (cb) {
            if (this.isResulted) {
                runItemResult(this, cb, this.res);
                this.destroy();
            } else {
                this.waiters.push(cb);
            }
        },
        await: function () {
            var self = this;
            return function () {
                self.resolve.call(self, arguments);
            }
        },
        resolve: function () {
            var self = this;
            self.isDone = true;
            processAwait.call(self, arguments);
        },
        reject: function () {
            var self = this;
            self.isFail = true;
            processAwait.call(self, arguments);
        },
        destroy: function () {
            this.isDestroyed = true;
            this.waiters.length = 0;
            this.res = undefined;
        }
    });

    function processAwait() {
        var self = this;
        var res = self.res = arguments;
        self.isResulted = true;
        var waiters = self.waiters;
        utils.map(waiters, function (cb) {
            runItemResult(self, cb, res);
        });
        if (waiters.length) {
            self.destroy();
        }
    }

    function runItemResult(context, cb, res) {
        cb && cb.apply(context, res);
    }

    subPixel('holdover', HoldOverClass);

})(subPixel);
