/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cross-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var crossingHelper = parts('crossing-helper');

    var figure = utils.createChildClass(AnnoBaseClass, 'crossChunkClass');

    figure.drawCross = function(ctx, points, w, h){
        var fromx = points[0].x;
        var fromy = points[0].y;

        var tox = points[1].x;
        var toy = points[1].y;



        ctx.beginPath();

        ctx.moveTo(fromx, fromy);

        ctx.lineTo(tox, toy);

        ctx.moveTo(tox, fromy);

        ctx.lineTo(fromx, toy);

        ctx.stroke();
        ctx.closePath();
    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        // for figure from parameters getting for now only 'color' and 'filled' value
        var drawParams = this.drawParams;
        var points = data.points;

        this.initAnnoDrawParams(data);

        var p1 = points[0];
        var p2 = points[1];

        var sPoints = [];
        sPoints.push({
            x: p1.x,
            y: p1.y
        });

        sPoints.push({
            x: p2.x,
            y: p2.y
        });

        sPoints.push({
            x: p1.x,
            y: p2.y
        });

        sPoints.push({
            x: p2.x,
            y: p1.y
        });

        //center point

        sPoints.push({
            x: ((p2.x - p1.x) / 2) + p1.x,
            y: ((p2.y - p1.y) / 2) + p1.y
        });

        var w = p2.x - p1.x;
        var h = p2.y - p1.y;

        drawParams.w = w;
        drawParams.h = h;

        this.initBoundingBox(sPoints);

        this.propPointsType(crossingHelper.TYPE_CROSS);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var points = this.data().points;

        this.setAnnoDrawStyles(opt);
        figure.drawCross(ctx, points);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-cross', figure);


})(subPixel);
