/*
 * Project:   PlotPad HTML5 Viewer
 * File:      toolbox.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/05/2014
 */
(function(subPixel, undefined){


    var config = subPixel('config');
    var utils = subPixel('utils');
    var editor = subPixel('editor');
    var tools = editor('tools');

    var base = tools.placeForTools('baseClass');

    var toolbox = utils.createChildClass(base, 'toolbox');

    var p = toolbox.prototype;

    p.init = function(){
        var base = this.base();
        base.className = 'edit-toolbox';

        // tools DOM element
        var toolboxDiv = this.element();
        toolboxDiv.className = 'toolbox-first-container';

        var dragEl = document.createElement('div');
        dragEl.className = 'toolbox-drag';
        var overflowEl = document.createElement('div');
        overflowEl.className = 'toolbox-overflow';
        base.appendChild(overflowEl);
        base.appendChild(dragEl);


        var subCont = this.subContainer();
        subCont.className = 'toolbox-sub-container';

//        buffer for store toolbox
//        this.buffer().appendChild(toolboxDiv);
    };

    var toolboxInst = new toolbox();

    tools.placeForTools('toolbox', toolboxInst);

})(subPixel);
