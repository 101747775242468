/*
 * Project:   PlotPad HTML5 Viewer
 * File:      polygon.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 26/5/2017
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var area = editor('area');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoPolygon = parts('anno-polygon');
    var logger = subPixel('logger')('polygon');

    var base = tools('anno-base');

    var startedPath = false;
    var firstPoints = false;
    var constrainDraw = false;

    var CONSTRAINT_ANGLE = Math.PI / 4;

    var polygonClass = utils.createChildClass(base, 'polygonClass', {
        onMouseStart: function(){
            var point = { x: mouse.x, y: mouse.y };

            if (!startedPath) {
                polygonClass._parent.onMouseStart.call(this);
                this.points.push(point);
                this.points.push(point);
                this.points.push(point);
                startedPath = true;
                firstPoints = true;
            }
        },
        onMouseMove: function(){
            if (firstPoints && this.points.length <= 3) {
                area.clear();
                var ctx = this.ctx;
                var index = this.points.length - 2;
                this.points[index] = getConstraintPoint(this.points[index - 1], constrainDraw);

                annoPolygon.drawPolygon(ctx, this.points);
            }
        },
        onMouseFreeMove: function () {
            if (!firstPoints && startedPath) {
                area.clear();
                var ctx = this.ctx;
                var index = this.points.length - 2;
                var point = getConstraintPoint(this.points[index], constrainDraw);
                var editedPoints = createEditedPoints(this.points, point);

                annoPolygon.drawPolygon(ctx, editedPoints);
            }
        },
        onMouseEnd: function(){
            area.clear();
            var ctx = this.ctx;
            var index = this.points.length - 1;
            var point = getConstraintPoint(this.points[index - 1], constrainDraw);

            if (!firstPoints) {
                this.points.splice(index, 0, point);
            } else {
                if (mouseStartEqualsEnd.call(this)) {
                    this.points.splice(1, 1);
                }
                firstPoints = false;
            }
            annoPolygon.drawPolygon(ctx, this.points);
        },
        onMouseDblTap: function(){
            startedPath = false;
            polygonClass._parent.onMouseEnd.call(this);
        },
        onToolSelect: function(){
            polygonClass._parent.onToolSelect.call(this);
        },
        onToolDeselect: function(){
            polygonClass._parent.onToolDeselect.call(this);
            if (startedPath) {
                polygonClass._parent.onMouseEnd.call(this);
            }
            startedPath = false;
            firstPoints = false;
        },
        finishDrawing: function(){
            polygonClass._parent.onMouseEnd.call(this);
            startedPath = false;
            firstPoints = false;
        },
        accurateDrawing: function(constrain){
            constrainDraw = constrain;
        }
    });

    function getConstraintPoint(prevPoint, constrain){
        var point = null;
        if (constrain) {
            var dx = mouse.x - prevPoint.x;
            var dy = mouse.y - prevPoint.y;
            var dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
            var angle = Math.atan2(dy, dx);
            var newAngle = Math.round(angle / CONSTRAINT_ANGLE) * CONSTRAINT_ANGLE;
            var newX = prevPoint.x + dist * Math.cos(newAngle);
            var newY = prevPoint.y + dist * Math.sin(newAngle);

            point = { x: Math.round(newX), y: Math.round(newY) };
        } else {
            point = {x: mouse.x, y: mouse.y};
        }
        return point;
    }

    function mouseStartEqualsEnd() {
        var p0 = this.points[0];
        var p1 = this.points[1];
        return p1.x === p0.x && p1.y === p0.y
    }

    function createEditedPoints(allPoints, addPoint) {
        var index = allPoints.length - 1;
        var ePoints = [];

        for(var i = 0; i < allPoints.length; i++) {
            ePoints.push(allPoints[i]);
        }
        ePoints.splice(index, 0, addPoint);

        return ePoints;
    }

    base.pushTool(polygonClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-polygon',
        zoomWindow: true,
        title: 'polygon',
        text: 'polygon'
    });
})(subPixel);
