// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Project:   PlotPad HTML5 Viewer
 * File:      subpixel-select-bar.css
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
.subpixel-select-bar {
    z-index: 1;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}

.subpixel-select-bar-show {
    display: inline-block;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
