/*
 * Project:   PlotPad HTML5 Viewer
 * File:      circle.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoCircle = parts('anno-circle');

    var base = tools('anno-base');

    var circleClass = utils.createChildClass(base, 'circleClass');

    var p = circleClass.prototype;

    p.onMouseStart = function(){
        circleClass._parent.onMouseStart.call(this);

        var point = { x: mouse.x, y: mouse.y };
        this.points.push(point);
        this.points.push(point);
    };


    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        points[1] = {x: mouse.x, y: mouse.y};
        var dx = (points[1].x - points[0].x);
        var dy = (points[1].y - points[0].y);

        var r = Math.sqrt(dx * dx + dy * dy);

        annoCircle.drawCircle(ctx, points, r);
    };


    base.pushTool(circleClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-circle',
        zoomWindow: true,
        title: 'circle',
        text: 'circle'
    });
})(subPixel);
