/*
 * Project:   PlotPad HTML5 Viewer
 * File:      zoom-window.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/06/2014
 */
(function(subPixel){
    var mouse = subPixel('mouse');
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var AnimateCtrlClass = subPixel('animate-ctrl-class');
    var domCanvasCtrl = subPixel('dom-canvas-ctrl');
    var domHelper = subPixel('dom-helper');
    var engineDom = subPixel('engine-dom');

    var animCtrl = AnimateCtrlClass();

    var bcanv = document.createElement('canvas');
    var container = document.createElement('div');
    container.appendChild(bcanv);
    container.className = 'subpixel-zoom-window';
    var bctx = bcanv.getContext('2d');

    var CSS3_TRANSFORM = utils.browserPrefix + 'transform';

    var zoomWindow = {};

    var timerId;

    var attachEl;

    var obj = {
        tx: 0,
        ty: 0,
        x: 0,
        y: 0,
        w: 50,
        h: 50
    };

    var zoomParams = {
        cross: false
    };

    var defaultParams = {
        cross: false
    };

    bcanv.width = obj.w;
    bcanv.height = obj.h;

    function setCssTranslates(xVal, yVal){
        xVal = Math.floor(xVal);
        yVal = Math.floor(yVal);

        var transVal = "translate3d(" + xVal + "px, " + yVal + "px, 0px)";

        (container.style[CSS3_TRANSFORM] != transVal) && (container.style[CSS3_TRANSFORM] = transVal);
        (container.style['transform'] != transVal) && (container.style['transform'] = transVal);
        var t = container.clientHeight;
        t++;
    }


    function render(){
        obj.tx = mouse.x - obj.w / 2;
        obj.ty = mouse.y - obj.h / 2;

        var cssy = (mouse.y - obj.h - 50);
        var cssx = obj.tx;
        setCssTranslates(cssx, cssy);

        clear();
        var x = obj.tx;
        var y = obj.ty;
        var w = obj.w;
        var h = obj.h;

        domCanvasCtrl.drawFromHelper(bctx, x, y, w, h, 0, 0, w, h);

        // bctx.drawImage(helperCanvas,
        //     x, y,
        //     w, h,
        //     0, 0,
        //     w, h
        // );
        if (zoomParams.cross){
            //draw crosshair
            bctx.beginPath();
            var cx = w / 2;
            var cy = h / 2;
            bctx.moveTo(cx + 10, cy);
            bctx.strokeStyle = '#000';
            bctx.lineTo(cx - 10, cy);

            bctx.moveTo(cx, cy + 10);
            bctx.lineTo(cx, cy - 10);
            bctx.stroke();
            bctx.closePath();
        } else if (attachEl){
            domCanvasCtrl.drawImage(bctx, attachEl,
                x, y,
                w, h,
                0, 0,
                w, h
            );
        }
    }

    function clear() {
        bctx.clearRect(0, 0, obj.w, obj.h);
    }

    function start(){
        //cap
    }

    function stop(){
        animCtrl.stopRender();
        zoomWindow.hide();
        zoomWindow.detachDraw();
    }

    zoomWindow.attachDraw = function(el){
        attachEl = el;
    };

    zoomWindow.detachDraw = function(){
        attachEl = null;
    };

    zoomWindow.show = function(params){
        if (params === true){
            for (var key in defaultParams){
                zoomParams[key] = defaultParams[key];
            }
        } else {
            for (var key in params){
                zoomParams[key] = params[key];
            }
        }
        clear();
        render();
        var baseEl = engineDom.getBaseElement();
        baseEl.appendChild(container);
        animCtrl.changeCb(render);
        animCtrl.startRender();
    };

    zoomWindow.hide = function(){
        animCtrl.stopRender();
        domHelper.moveToFragment(container);
    };


    utils.bindStartStop(start, stop);

    subPixel('zoom-window', zoomWindow);

})(subPixel);
