/*
 * Project:   PlotPad HTML5 Viewer
 * File:      main.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
(function(){
    var cafm = window.cafm = {};

    cafm.options = {};
    var RESIZE_TIMEOUT = 500;

    // please, DO NOT CHANGE this file
    // this file used for correct build

    /*subpixel-replace*/
    var subPixel = window.subPixel;
    /*subpixel-replace-end*/

    cafm.subPixel = subPixel;

    var sheetLoader = subPixel('sheet-loader');
    var offset = subPixel('offset');

    cafm.initViewer = window.initViewer = function(el, opt){
        var type = typeof el;

        if (type == 'object' && opt === undefined && el.nodeName === undefined){
            opt = el;
            el = '#' + opt.id;
        }
        cafm.options = opt || {};

        subPixel('utils').enable = false;
        subPixel.historyEnable(false);

        var obj = {};

        sheetLoader.stopLoad();
        var domHelpers = subPixel('dom-helper');
        var node = el;
        if (typeof el == "string"){
            node = document.querySelector(el);
        }

        if (node){
            domHelpers.addClass(node, 'cafm-drop-subpixel-styles');
            cafm.wrapMethods(obj);
            cafm.wrapMethods(node);
            var subPixelOpt = {
                el: el,
                indicators: {
                    drawing: '.subpixel-drawing-indicator'
                },
                groupEditColor: 'rgba(255, 255, 0, 0.4)',
                selectionFillColor: 'rgba(0, 255, 255, 0.4)',
                selectionFillType: 'dash-line',
                selectionLineJoin: 'round',
                selectionDetectType: 'detect-in-box',
                selectionStrokeColor: 'rgba(175, 175, 175, 0.8)',

                tooltip: {
                    selectionColor: "rgba(128, 128, 128, 0.5)",
                    tools: {
                        'move': true
                    }
                },

                lineRatio: 4,

                useSelectLastElement: true,
                usePriorityHover: true,
                usePlanLoadIndicator: true,

                globalStyles: {
                    fillStyle: "rgb(255,0,0)",
                    strokeStyle: "rgb(255,0,0)",
                    lineWidth: 2
                },
                tools: {
                    defaultTool: 'move'
                },
                offset: {
                    x: 0,
                    y: 0
                },
                scale: 1
            };
            //if (opt.bgcolor){
                // we need this?
                //node.style.backgroundColor = opt.bgcolor;
                //subPixelOpt.clearColor = opt.bgcolor;
            //}
            if (opt.width || opt.height){
                // bind to changes of div width
                bindElementResize(node, opt);
            }

            subPixel.start(subPixelOpt);
        }
        setTimeout(function(){
            window.flashInterfaceIsAccessible && window.flashInterfaceIsAccessible();
            if (cafm.options.documentUrl){
                cafm.LoadDrawing(cafm.options.documentUrl);
            }
        }, 10);

        return node;
    };

    cafm.resize = function (w, h) {
        clearTimeout(this._resizeTimeout);
        var self = cafm;
        var subPixel = cafm.subPixel;
        var config = subPixel('config');
        var holdover = subPixel('holdover');
        var holder = holdover(true);
        var drawingCtrl = subPixel('drawing-ctrl');
        var elem = config.el;
        elem.style.width = convertNumToStyle(w);
        elem.style.height = convertNumToStyle(h);
        subPixel('offset').checkResize();
        drawingCtrl.redraw(function(){
            checkResize(self);
            holder.resolve();
        });
        return holder;
    };

    function convertNumToStyle(val) {
        var ret = val;
        if (!isNaN(val)){
            ret = val + 'px';
        }
        return ret;
    }

    function bindElementResize(node, opt){
        var nodeStyle = node.style;

        nodeStyle.width = opt.width;
        nodeStyle.height = opt.height;

        if ((opt.width && (opt.width + '').indexOf('%') != -1) ||
            (opt.height && (opt.height + '').indexOf('%') != -1)){
            checkResize(node);
        }
    }

    function checkResize(node){
        clearTimeout(node._resizeTimeout);
        node._resizeTimeout = setTimeout(function(){
            offset.checkResize();
            checkResize(node);
            node = null;
        }, RESIZE_TIMEOUT);
    }

    //subPixel.on('system-dom-ready', function(){
    //    flashInterfaceIsAccessible()
    //});

    /*cafm-replace*/

})();
