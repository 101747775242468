/*
 * Project:   PlotPad HTML5 Viewer
 * File:      transformer.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 00/08/2014
 */
/*
 * zoom api interface
 * */
(function (subPixel, undefined) {
    var inter = subPixel('interface');
    var scale = subPixel('scale');
    var offset = subPixel('offset');
    var canvas = subPixel('canvas');
    var drawingCtrl = subPixel('drawing-ctrl');
    var holdover = subPixel('holdover');

    inter.register({
        // set/get
        zoom: function (percent, cb) {
            var ret = 0;
            if (!subPixel.isStopped()) {
                if (percent !== undefined) {
                    var oldScale = scale.val * 100;
                    if (percent != oldScale) {
                        var val = percent / 100;
                        (val < scale.min) && (val = scale.min);
                        (val > scale.max) && (val = scale.max);
                        var setVal = val * 100;

                        canvas.setZoom(setVal);
                        drawingCtrl.redraw(cb);
                    } else {
                        cb && cb();
                    }
                } else {
                    cb && cb();
                }
                ret = scale.val * 100;
            } else {
                cb && cb();
            }
            return ret;
        },

        fitToScreen: function () {
            var holder = holdover(true);
            if (!subPixel.isStopped()) {
                canvas.fitToScreen();
                drawingCtrl.redraw(holder.await());
            } else {
                holder.resolve();
            }
            return holder;
        },

        offset: function (x, y, cb) {
            var ret = {
                x: 0,
                y: 0
            };

            if (!subPixel.isStopped()) {
                if (x !== undefined && y !== undefined) {
                    canvas.setOffset(x, y);
                    drawingCtrl.redraw(cb);
                } else {
                    cb && cb()
                }
                ret.x = -offset.x;
                ret.y = -offset.y;
            } else {
                cb && cb()
            }
            return ret;
        },
        zoomTo: function (clip, zoom) {
            var holder = holdover(true);
            var x = clip.x;
            var y = clip.y;
            var w = clip.w;
            var h = clip.h;

            canvas.setZoom(100);

            // center point of element
            var cx = ((w / 2) + x);
            var cy = ((h / 2) + y);

            var cw = offset.width / 2;
            var ch = offset.height / 2;

            var dx = cw - cx;
            var dy = ch - cy;

            var pw = offset.width / w;
            var ph = offset.height / h;
            var zVal = Math.floor(Math.min(pw, ph) * 100) / 100;

            subPixel.offset(dx, dy);

            (zVal < scale.min) && (zVal = scale.min);
            (zVal > scale.max) && (zVal = scale.max);

            zoom = zoom || (zVal * 100);
            subPixel.zoom(zoom);
            drawingCtrl.redraw(holder.await());
            return holder;
        }
    });

})(subPixel);
