/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.net.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('net', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(30, 0);
        ctx.lineTo(29, 0);
        ctx.lineTo(29, 4);
        ctx.lineTo(25, 4);
        ctx.lineTo(25, 0);
        ctx.lineTo(24, 0);
        ctx.lineTo(24, 4);
        ctx.lineTo(20, 4);
        ctx.lineTo(20, 0);
        ctx.lineTo(19, 0);
        ctx.lineTo(19, 4);
        ctx.lineTo(15, 4);
        ctx.lineTo(15, 0);
        ctx.lineTo(14, 0);
        ctx.lineTo(14, 4);
        ctx.lineTo(10, 4);
        ctx.lineTo(10, 0);
        ctx.lineTo(9, 0);
        ctx.lineTo(9, 4);
        ctx.lineTo(5, 4);
        ctx.lineTo(5, 0);
        ctx.lineTo(4, 0);
        ctx.lineTo(4, 4);
        ctx.lineTo(0, 4);
        ctx.lineTo(0, 5);
        ctx.lineTo(4, 5);
        ctx.lineTo(4, 9);
        ctx.lineTo(0, 9);
        ctx.lineTo(0, 10);
        ctx.lineTo(4, 10);
        ctx.lineTo(4, 14);
        ctx.lineTo(0, 14);
        ctx.lineTo(0, 15);
        ctx.lineTo(4, 15);
        ctx.lineTo(4, 19);
        ctx.lineTo(0, 19);
        ctx.lineTo(0, 20);
        ctx.lineTo(4, 20);
        ctx.lineTo(4, 24);
        ctx.lineTo(0, 24);
        ctx.lineTo(0, 25);
        ctx.lineTo(4, 25);
        ctx.lineTo(4, 29);
        ctx.lineTo(0, 29);
        ctx.lineTo(0, 30);
        ctx.lineTo(30, 30);
        ctx.lineTo(30, 0);
        ctx.moveTo(25, 5);
        ctx.lineTo(29, 5);
        ctx.lineTo(29, 9);
        ctx.lineTo(25, 9);
        ctx.lineTo(25, 5);
        ctx.moveTo(24, 5);
        ctx.lineTo(24, 9);
        ctx.lineTo(20, 9);
        ctx.lineTo(20, 5);
        ctx.lineTo(24, 5);
        ctx.moveTo(25, 10);
        ctx.lineTo(29, 10);
        ctx.lineTo(29, 14);
        ctx.lineTo(25, 14);
        ctx.lineTo(25, 10);
        ctx.moveTo(25, 24);
        ctx.lineTo(25, 20);
        ctx.lineTo(29, 20);
        ctx.lineTo(29, 24);
        ctx.lineTo(25, 24);
        ctx.moveTo(24, 20);
        ctx.lineTo(24, 24);
        ctx.lineTo(20, 24);
        ctx.lineTo(20, 20);
        ctx.lineTo(24, 20);
        ctx.moveTo(25, 15);
        ctx.lineTo(29, 15);
        ctx.lineTo(29, 19);
        ctx.lineTo(25, 19);
        ctx.lineTo(25, 15);
        ctx.moveTo(24, 15);
        ctx.lineTo(24, 19);
        ctx.lineTo(20, 19);
        ctx.lineTo(20, 15);
        ctx.lineTo(24, 15);
        ctx.moveTo(24, 10);
        ctx.lineTo(24, 14);
        ctx.lineTo(20, 14);
        ctx.lineTo(20, 10);
        ctx.lineTo(24, 10);
        ctx.moveTo(19, 9);
        ctx.lineTo(15, 9);
        ctx.lineTo(15, 5);
        ctx.lineTo(19, 5);
        ctx.lineTo(19, 9);
        ctx.moveTo(15, 10);
        ctx.lineTo(19, 10);
        ctx.lineTo(19, 14);
        ctx.lineTo(15, 14);
        ctx.lineTo(15, 10);
        ctx.moveTo(14, 5);
        ctx.lineTo(14, 9);
        ctx.lineTo(10, 9);
        ctx.lineTo(10, 5);
        ctx.lineTo(14, 5);
        ctx.moveTo(9, 9);
        ctx.lineTo(5, 9);
        ctx.lineTo(5, 5);
        ctx.lineTo(9, 5);
        ctx.lineTo(9, 9);
        ctx.moveTo(5, 10);
        ctx.lineTo(9, 10);
        ctx.lineTo(9, 14);
        ctx.lineTo(5, 14);
        ctx.lineTo(5, 10);
        ctx.moveTo(5, 24);
        ctx.lineTo(5, 20);
        ctx.lineTo(9, 20);
        ctx.lineTo(9, 24);
        ctx.lineTo(5, 24);
        ctx.moveTo(10, 24);
        ctx.lineTo(10, 20);
        ctx.lineTo(14, 20);
        ctx.lineTo(14, 24);
        ctx.lineTo(10, 24);
        ctx.moveTo(5, 19);
        ctx.lineTo(5, 15);
        ctx.lineTo(9, 15);
        ctx.lineTo(9, 19);
        ctx.lineTo(5, 19);
        ctx.moveTo(10, 19);
        ctx.lineTo(10, 15);
        ctx.lineTo(14, 15);
        ctx.lineTo(14, 19);
        ctx.lineTo(10, 19);
        ctx.moveTo(15, 24);
        ctx.lineTo(15, 20);
        ctx.lineTo(19, 20);
        ctx.lineTo(19, 24);
        ctx.lineTo(15, 24);
        ctx.moveTo(15, 19);
        ctx.lineTo(15, 15);
        ctx.lineTo(19, 15);
        ctx.lineTo(19, 19);
        ctx.lineTo(15, 19);
        ctx.moveTo(10, 10);
        ctx.lineTo(14, 10);
        ctx.lineTo(14, 14);
        ctx.lineTo(10, 14);
        ctx.lineTo(10, 10);
        ctx.moveTo(15, 25);
        ctx.lineTo(19, 25);
        ctx.lineTo(19, 29);
        ctx.lineTo(15, 29);
        ctx.lineTo(15, 25);
        ctx.moveTo(20, 29);
        ctx.lineTo(20, 25);
        ctx.lineTo(24, 25);
        ctx.lineTo(24, 29);
        ctx.lineTo(20, 29);
        ctx.moveTo(14, 25);
        ctx.lineTo(14, 29);
        ctx.lineTo(10, 29);
        ctx.lineTo(10, 25);
        ctx.lineTo(14, 25);
        ctx.moveTo(9, 25);
        ctx.lineTo(9, 29);
        ctx.lineTo(5, 29);
        ctx.lineTo(5, 25);
        ctx.lineTo(9, 25);
        ctx.moveTo(25, 29);
        ctx.lineTo(25, 25);
        ctx.lineTo(29, 25);
        ctx.lineTo(29, 29);
        ctx.lineTo(25, 29);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
