/*
 * Project:   PlotPad HTML5 Viewer
 * File:      selection-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){
    var scale = subPixel('scale');
    var canvas = subPixel('canvas');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var parts = subPixel('parts');
    var drawOptHelper = parts('draw-options-helper');

    var logger = subPixel('logger')('selection-helper');

    var drawFillSelectionOpt = drawOptHelper({
        isSelection: true
    });

    var drawLineSelectionOpt = drawOptHelper({
        isSelection: true
    });

    var CONST_DEFAULT_DASH_VALUE = 5;
    var dashSelection = [CONST_DEFAULT_DASH_VALUE, CONST_DEFAULT_DASH_VALUE];

    var CONST_FILL_DASH_DEFAULT = 'dash';

    var DEF_SELECTION_CLR = 'rgba(0,0,0, 0.2)';

    var fillTypes = {
        dash: drawDashSelection,
        fill: drawFillSelection,
        line: drawLineSelection,
        'dash-line': function () {
            drawLineSelection.call(this);
            drawDashSelection.call(this);
        },
        'dash-fill': function () {
            drawFillSelection.call(this);
            drawDashSelection.call(this);
        },
        'line-fill': function () {
            drawFillSelection.call(this);
            drawLineSelection.call(this);
        },
        mixed: function () {
            drawFillSelection.call(this);
            drawLineSelection.call(this);
            drawDashSelection.call(this);
        }
    };

    var selectionDrawingMethod = fillTypes[CONST_FILL_DASH_DEFAULT];

    function drawLineSelection() {
        this.draw(drawLineSelectionOpt);
    }

    function drawFillSelection(){
        this.draw(drawFillSelectionOpt);
    }

    function drawDashSelection(){
        if (this.canEdit()){
            var ctx = canvas.buffCtx;

            var bbox = this.getBoundingBox();

            ctx.beginPath();
            ctx.lineWidth = scale.lineWidth;

            ctx.moveTo(bbox.minx, bbox.miny);

            ctx.lineTo(bbox.maxx, bbox.miny);
            ctx.lineTo(bbox.maxx, bbox.maxy);
            ctx.lineTo(bbox.minx, bbox.maxy);
            ctx.lineTo(bbox.minx, bbox.miny);

            ctx.strokeStyle = "#fff";

            ctx.stroke();
            ctx.closePath();
            ctx.beginPath();

            ctx.lineWidth = scale.lineWidth;

            ctx.dashedLine(bbox.minx, bbox.miny, bbox.maxx, bbox.miny, dashSelection);
            ctx.dashedLine(bbox.maxx, bbox.miny, bbox.maxx, bbox.maxy, dashSelection);
            ctx.dashedLine(bbox.maxx, bbox.maxy, bbox.minx, bbox.maxy, dashSelection);
            ctx.dashedLine(bbox.minx, bbox.miny, bbox.minx, bbox.maxy, dashSelection);

            ctx.strokeStyle = "#000";

            ctx.stroke();
            ctx.closePath();
        }
    }

    subPixel('selection-helper', {
        updateDashSelection: function(){
            var val = scale.val;
            dashSelection[1] = dashSelection[0] = CONST_DEFAULT_DASH_VALUE / val;
        },
        draw: function(el){
            selectionDrawingMethod.call(el);
        }
    });

    function start(){
        var selectionFillType = config.selectionFillType || CONST_FILL_DASH_DEFAULT;
        if (fillTypes[selectionFillType]){
            selectionDrawingMethod = fillTypes[selectionFillType];
        } else {
            logger.error('wrong "selectionFillType" config value');
        }

        var fillClr = config.selectionFillColor || DEF_SELECTION_CLR;
        drawFillSelectionOpt.fillStyle = utils.colorToStr(fillClr);

        var strokeClr = config.selectionStrokeColor || DEF_SELECTION_CLR;
        drawLineSelectionOpt.strokeStyle = utils.colorToStr(strokeClr);

        var lineJoin = config.selectionLineJoin || 'miter';
        drawLineSelectionOpt.lineJoin = lineJoin;

        drawLineSelectionOpt.lineRatio = config.lineRatio;
    }

    utils.bindStartStop(start);

})(subPixel);
