/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cmd.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/31/2016
 */
(function(subPixel, undefined){
    var batch = subPixel('batch');
    var utils = subPixel('utils');
    var holdover = subPixel('holdover');
    var logger = subPixel('logger')('batch-cmd');

    var STATE_DONE = 'done';
    var STATE_FAIL = 'fail';
    var STATE_CREATED = 'created';
    var STATE_RUNNING = 'running';

    function BaseCommandClass(method, args, opt){
        this.opt = opt || {};
        var context = batch.context();
        this.method = context[method];
        this.methodName = method;
        this.args = args;
        this.state = STATE_CREATED;
    }

    var p = BaseCommandClass.prototype;

    p.run = function(){
        var self = this;
        this.state = STATE_RUNNING;
        var context = batch.context();
        var res = this.method.apply(context, this.args);
        logger.winJsLog(this.methodName);
        var err;
        if (res instanceof holdover){
            res.then(function(){
                err = res.isFail;
                self.state = err ? STATE_FAIL : STATE_DONE;
                self.next(err, undefined);
            });
        } else {
            this.state = STATE_DONE;
            self.next(false, res);
        }
    };

    p.next = function(err, data){
        this.opt.next(err, data);
    };

    p.getState = function () {
        return this.state;
    };

    p.getMethodName = function () {
        return this.methodName;
    };

    p.getArgs = function () {
        return this.args;
    };

    batch.baseCmd(BaseCommandClass);

})(subPixel);
//
// var batch = [
//     {method: "LoadDrawing", args: ["/html5-res/p1"]},
//     {method: "CursorMode", args: ['Select']},
//     {method: "ZoomObjects", args: ["60C45"]},
//     {method: "wait", args: [1000]},
//     {method: "SaveToPng"},
//     {method: "SendPng"},
//     {method: "getCurrentBatchCommand"},
//     {method: "ShowZoomPanel", args: [true]},
//     {method: "ZoomExtents"},
//     {method: "wait", args: [1000]},
//     {method: "ShowZoomPanel", args: [false]},
//     {method: "ZoomOut", args: [20]},
//     {method: "wait", args: [1000]},
//     {method: "ZoomIn", args: [20]}
// ];
