/*
 * Project:   PlotPad HTML5 Viewer
 * File:      magnito.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/05/2014
 */
(function(subPixel){
    var mouse = subPixel('mouse');
    var broadcast = subPixel('broadcast');
    var mouseEvs = broadcast.events('mouse');
    var keysEvs = broadcast.events('keyboard');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var elementsCtrl = subPixel('elements-ctrl');
    var keyboard = subPixel('keyboard');
    var editor = subPixel('editor');
    var scale = subPixel('scale');
    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var nearestHelper = parts('nearest-helper');
    var area;


    var magnito = {
        enable: true,
        x: null,
        y: null
    };

    var CONST_DISTANCE = 5;
    var helperPoint;
    var magnitoArea = {};

    var figures;
    var working = false;
    var magniting = true;

    var foundedPoint = {
        x: 0,
        y: 0
    };

    var drawBbox = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
    };


    function draw(){
        //:todo change drawing to requestAnimationFrame method
        if (magnito.enable){
            area.clear(drawBbox);
            var ctx = area.ctx;
            ctx.beginPath();
            var prevStroke = ctx.strokeStyle;
            var preLineWidth = ctx.lineWidth;
            ctx.lineWidth = scale.defLineWidth;
            ctx.strokeStyle = "rgb(0,0,0)";
            var x = magnito.x;
            var y = magnito.y;
            ctx.moveTo(x, y);
            ctx.lineTo(x + 0.5, y);

            ctx.strokeRect(x - 5, y - 5, 10, 10);
            ctx.stroke();
            ctx.closePath();

            ctx.stroke();
            ctx.strokeStyle = prevStroke;
            ctx.lineWidth = preLineWidth;
            drawBbox.x = x - 10;
            drawBbox.y = y - 10;
            drawBbox.w = 20;
            drawBbox.h = 20;
        }
    }

    magnito.start = function(){
        figures = elementsCtrl.getElements();
        if (!working){
            working = true;
            magniting = !keyboard.isShift;
            area.clear();
        }
    };

    magnito.stop = function(){
        figures = null;
        working = false;
    };

    magnito.freeDrawStart = function(){
        magnito.start();
        broadcast.off(mouseEvs.move, onMove);
        broadcast.on(mouseEvs.move, onMove);
    };

    magnito.freeDrawStop = function(){
        magnito.stop();
        broadcast.off(mouseEvs.move, onMove);
    };

    magnito.find = function(){
        magnito.x = mouse.x;
        magnito.y = mouse.y;
        if (!magnito.enable || !working){
            return;
        }
        if (magniting){
            helperPoint = canvas.getTransformedPoint(mouse.x, mouse.y);

            var dx = CONST_DISTANCE / scale.val;

            magnitoArea.minx = helperPoint.x - dx;
            magnitoArea.maxx = helperPoint.x + dx;
            magnitoArea.miny = helperPoint.y - dx;
            magnitoArea.maxy = helperPoint.y + dx;

            magnitoArea.w = magnitoArea.maxx - magnitoArea.minx;
            magnitoArea.h = magnitoArea.maxy - magnitoArea.miny;

            magnitoArea.x = helperPoint.x;
            magnitoArea.y = helperPoint.y;
            helperPoint = null;

            var minLen = dx;

            var founded = false;
            for (var i = 0, l = figures.length; i < l; i++){
                var item = figures[i];

                var point = nearestHelper.getNearestPoint(item, magnitoArea);
                if (point && point.distance <= minLen){
                    foundedPoint.x = point.x;
                    foundedPoint.y = point.y;
                    minLen = point.distance;
                    founded = true;
                }
            }
            if (founded){
                var hPoint = canvas.getRetransformedPoint(foundedPoint.x, foundedPoint.y);
                magnito.x = hPoint.x;
                magnito.y = hPoint.y;
                hPoint = null;
            }

            draw();
        }
    };


    function onMove(){
        if (magnito.enable && working){
//            area.clear();
            magnito.find();
        }
    }

    function onKeyDown(keyboard){
        if (keyboard.isShift){
            magniting = false;
        }
    }

    function onKeyUp(){
        magniting = true;
    }

    function watchKeyboard(action){
        broadcast[action](keysEvs.down, onKeyDown);
        broadcast[action](keysEvs.up, onKeyUp);
    }



    function start(){
        watchKeyboard('on');
        area = editor('area');
    }

    function stop(){
        watchKeyboard('off');
    }

    utils.bindStartStop(start, stop);

    subPixel('magnito', magnito);

})(subPixel);
