/*
 * Project:   PlotPad HTML5 Viewer
 * File:      measure.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/04/2014
 */
/*
 *
 * supported types:
 *

 'inches': 'Inches',
 'feet': 'Feet',
 'yards': 'Yards',
 'miles': 'Miles',
 'mm': 'Millimeters',
 'cm': 'Centimeters',
 'm': 'Meters',
 'km': 'Kilometers'

 * */
(function (subPixel, undefined) {
    var utils = subPixel('utils');
    var inter = subPixel('interface');
    var drawingCtrl = subPixel('drawing-ctrl');

    var regExpSub = /(?=(?:...)*$)/;

    var decimalsVal = 1;

    var convertInches = {
        'inches': 1,
        'feet': 1 / 12,
        'yards': 1 / 36,
        'miles': 1 / 63360,
        'mm': 25.4,
        'cm': 2.54,
        'm': 0.0254,
        'km': 0.0000254
    };

    var manifestToTypeMap = {
        'inches': 'inches',
        'in': 'inches',
        'feet': 'feet',
        'yards': 'yards',
        'miles': 'miles',
        'mm': 'mm',
        'cm': 'cm',
        'm': 'm',
        'km': 'km'
    };

    var measure = subPixel('measure', {
        val: 1,
        decimals: 2,
        view: 'mm',
        type: 'mm',
        parseNumber: function (val) {
            if (measure.view != measure.type) {
                var typeConvert = convertInches[measure.type];
                var viewConvert = convertInches[measure.view];
                val = val * (viewConvert / typeConvert);
            }

            // transform to scale and set correct view type

            var leftSide = Math.floor(val);

            var toFixed = (Math.floor(val * decimalsVal) / decimalsVal);

            var rightTmp = strip(toFixed - leftSide);
            var rightSide = Math.floor(rightTmp * decimalsVal);
            rightSide = (!rightSide) ? ('') : '.' + rightSide;

            var sub = (leftSide + '').split(regExpSub);


            var text = sub.join(' ') + rightSide;
            return text;
        }
    });


    function strip(number) {
        return (parseFloat(number.toPrecision(12)));
    }

    function setMeasureScale(val, type, view) {
        var res = false;

        if (val) {
            if (!isNaN(val - 1)){
                measure.val = val;

                var typeVal = manifestToTypeMap[type];
                typeVal && (measure.type = typeVal);

                var viewVal = manifestToTypeMap[view];
                viewVal && (measure.view = viewVal);

                updateDecimalVal();
                res = true;
            }
        }

        return res;
    }

    function updateDecimalVal() {
        decimalsVal = Math.pow(10, measure.decimals);
    }

    function start() {
        updateDecimalVal();
    }

    function stop() {
        measure.val = 1;
        measure.type = 'mm';
        measure.view = 'mm';
    }

    utils.bindStartStop(start, stop);

    inter.register({
        measureScale: function (val, type, view) {
            if (!subPixel.isStopped()) {
                if (val !== undefined && setMeasureScale(val, type, view)) {
                    drawingCtrl.redraw();
                }
            }
            return measure;
        }
    });

})(subPixel);
