/*
 * Project:   PlotPad HTML5 Viewer
 * File:      styles.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/10/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var config = subPixel('config');
    var logger = subPixel('logger')('styles');

    var defaultsWant = {
        fillStyle: "rgba(0,0,0,0)",
        strokeStyle: "rgb(0,0,0)",
        lineWidth: 1
//        lineCap: 'round',
//        lineJoin: 'round'
    };

    var globals = {
        fillStyle: "rgb(255,0,0)",
        strokeStyle: "rgb(255,0,0)",
        lineWidth: 2
//        lineCap: 'round',
//        lineJoin: 'round'
    };

    var styles = editor('styles', {
        globals: globals,
        want: function(ctx, haveStyles){
            // set styles, what need only, others will be used as default styles
            for (var key in defaultsWant){
                ctx[key] = defaultsWant[key];
            }

            //set what need to draw for element
            if (haveStyles){
                for (var i = 1, l = arguments.length; i < l; i++){
                    var styleVal = arguments[i];
                    if (globals[styleVal]){
                        ctx[styleVal] = globals[styleVal];
                    } else {
                        logger.error('trying to set not defined style');
                    }
                }
            }
        }
    });

    utils.bindStartStop(function () {
        for (var key in globals){
            globals[key] = config.globalStyles[key] || globals[key];
        }
    });


})(subPixel);
