/*
 * Project:   PlotPad HTML5 Viewer
 * File:      labels.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/31/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var helpers = cafm.helpers;
    var events = cafm.events;
    var utils = subPixel('utils');
    var parts = subPixel('parts');
    var canvas = subPixel('canvas');
    var holdover = subPixel('holdover');
		
    function prepareMapIds(list, map, objIds){
        utils.map(list, function(item){
            if (item){
                var objId = item.ObjectID;
                if (!map[objId]){
                    map[objId] = item;
                    objIds.push(objId);
                }
            }
        });

    }

    cafm.UpdateLabels = function(/*labels*/){
        var holder = holdover(true);
        var list = helpers.convertToArray(arguments);

        var map = {};
        var objIds = [];
        var toAddEls = [];
        var toRemoveIds = [];
        var founded = false;

        prepareMapIds(list, map, objIds);

        helpers.getFiguresFromObjIds(objIds, function(fig){
            var objId = fig.objectId();
            var labels = map[objId];
            if (labels){
                var struct = createStruct(fig, labels);
                toAddEls.push(struct);
                fillToRemove(toRemoveIds, fig);
                fig.labels(struct.id);
                founded = true;
            }
        });

        if (founded) {			
            subPixel.removeElement(toRemoveIds);
            subPixel.addElement(toAddEls);

            subPixel.redraw(holder.await());
            events.OnLabelsLoaded();		
        } else {
            holder.resolve();
        }
        return holder;
    };

    function fillToRemove(toRemoveIds, fig){
        var labelId = fig.labels();
        if (labelId){
            toRemoveIds.push(labelId);
        }
    }

    cafm.ClearLabels = function(/*objectIds*/){
        var holder = holdover(true);
        var toRemoveIds = [];
        var founded = false;
        var objIds = helpers.convertToArray(arguments);
        helpers.getFiguresFromObjIds(objIds, function(fig){
            founded = true;
            fillToRemove(toRemoveIds, fig);
            fig.labels(null);
        });
        if (founded){
            subPixel.removeElement(toRemoveIds);
            founded && subPixel.redraw(holder.await());
        } else {
            holder.resolve();
        }
        return holder;
    };

    cafm.ClearAllLabels = function(){
        var holder = holdover(true);
        var toRemoveIds = [];
        var founded = false;
        helpers.getAllObjects(function(fig){
            founded = true;
            fillToRemove(toRemoveIds, fig);
            fig.labels(null);
        });
        if (founded){
            subPixel.removeElement(toRemoveIds);
            founded && subPixel.redraw(holder.await());
        } else {
            holder.resolve();
        }
        return holder;
    };


    function createStruct(fig, item){
        var ret = {
            id: utils.guid(),
            name: 'text-group',
            toFigId: fig.getId(),
            data: item
        };
        return ret;
    }

})(cafm);