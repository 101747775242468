/*
 * Project:   PlotPad HTML5 Viewer
 * File:      time-interval.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/18/2016
 */
(function (subPixel) {
    var MS_SEC = 1000;
    var MS_MIN = MS_SEC * 60;
    var MS_HOUR = MS_MIN * 60;

    function getTimeInterval(start, end) {
        var dx = end - start;
        var time = dx;
        if (dx < MS_SEC) {
            time = dx + " ms";
        } else if (dx < MS_MIN) {
            time = Math.floor((dx / MS_SEC) * 100) / 100 + 's';
        } else if (dx < MS_HOUR) {
            time = Math.floor((dx / MS_MIN) * 100) / 100 + 'm';
        } else {
            time = Math.floor((dx / MS_HOUR) * 100) / 100 + 'h';
        }
        return time;
    }

    subPixel('time-interval', getTimeInterval);
})(subPixel);
