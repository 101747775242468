/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base-item-tool.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/05/2014
 */
(function(subPixel, undefined){

    var editor = subPixel('editor');
    var tools = editor('tools');
    var base = tools('base');
    var area = editor('area');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var styles = editor('styles');
    var utils = subPixel('utils');
    var canvas = subPixel('canvas');
    var magnito = subPixel('magnito');
    var mouse = subPixel('mouse');



    var BaseItemToolClass = utils.createChildClass(base, 'baseTool');

    var p = BaseItemToolClass.prototype;

    p.defineParams = function(params){
        BaseItemToolClass._parent.defineParams.call(this, params);
        this.points = [];

        this.mode = params.mode;
        this.zoomWindow = params.zoomWindow;
        this.title = params.title;
        this.text = params.text;
        this.type = params.type || "default";
        this.magnito = params.magnito;
        this.imageUrl = params.imageUrl || null;
        this.initWidth = params.initWidth || null;
        this.initHeight = params.initHeight || null;

        this.clicksNum = 0;

        var name = this.name();

        var el = this.getEl();

        if (!params.el){
            // defined element for tool if NOT defined
            params.title && el.setAttribute('title', params.title);
        }

        el.setAttribute('data-toolname', name);
        el.className = tools.TOOL_CSS_CLASS + ' ' + tools.TOOL_CSS_CLASS + '-' + name + ' ' + el.className;
    };

    // calls when tool selected in tools
    p.onToolSelect = function(){
        this.ctx = area.ctx;
        if (this.magnito){
            magnito.stop();
            magnito.freeDrawStart();
        }
        this.clicks(0);
    };

    // calls when tool was deselected in tools
    p.onToolDeselect = function(){
        this.ctx = null;
        if (this.magnito){
            magnito.freeDrawStop();
            magnito.stop();
        }
        this.clicks(0);
    };

    p.incClicks = function(){
        this.clicksNum++;
    };

    p.clicks = function(val){
        if (val !== undefined){
            this.clicksNum = val;
        }
        return this.clicksNum;
    };

    // when mouse moving in canvas area
    p.onMouseMove = function(){

    };

    // when mouse start dragging
    p.onMouseStart = function(){
        if (this.magnito){
            magnito.freeDrawStop();
            magnito.start();
        }
    };

    // when mouse end dragging
    p.onMouseEnd = function(){
        if (this.magnito){
            magnito.stop();
            magnito.freeDrawStart();
        }
        this.clicks(0);
    };

    // default processing points of figure
    p.processPoints = function(points){
        var mPoints = [];
        mPoints.length = points.length;
        for (var i = 0, l = points.length; i < l; i++){
            var p = points[i];
            var helperPoint = canvas.getTransformedPoint(p.x, p.y);
            var singlePoint = mPoints[i] = {
                x: helperPoint.x,
                y: helperPoint.y
            };
            for (var key in p){
                if (key != 'x' && key != 'y'){
                    singlePoint[key] = p[key];
                }
            }
            helperPoint = null;
        }
        return mPoints;
    };

    // for getting points as correct points in plan
    p.createFigureData = function(){
        var points = this.processPoints(this.points);
        var name = this.partName() || this.name();
        var obj = {
            type: this.type,
            name: name,
            styles: styles.globals,
            points: points
        };

        return utils.clone(obj);
    };

    // when new data for figure was clone, some tools need to check params
    p.processFigureData = function(obj){
        return obj;
    };


    // when figure was added to engine, calling this method for indicate this operation
    // and return access to manipulate new figure
    p.onFigureAdded = function(fig){

    };

    // register tool in tools
    tools(BaseItemToolClass, {
        mode: scenarioCtrl.view,
        zoomWindow: true,
        name: 'base-tool',
        visible: false
    });

})(subPixel);
