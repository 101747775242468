/*
 * Project:   PlotPad HTML5 Viewer
 * File:      requester.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/28/2014
 */
/*
* requester module for load data and store to cache, if needed
*
* */
(function (subPixel) {
    var requester = subPixel('requester', {});
    var cacheModule = subPixel('cache');
    var utils = subPixel('utils');
    var logger = subPixel('logger')('requester');

    var timeout = 40000; // 40s

    var codes = {
        ABORTED: 101,
        PROCESS_DATA_ERROR: 102,
        NOT_EXIST: 103,
        NO_WORKING: 104
    };

    var reqDef = window.XMLHttpRequest;

    // creating new instance of xhr
    function getXmlHttp() {
        var xmlhttp;
        if (reqDef){
            xmlhttp = new reqDef();
        }

        return xmlhttp;
    }

    // just parse text to JSON
    function parseJSON(params){
        var defErrMsg = "Error JSON Parse";
        try{
            params.result = JSON.parse(params.text);
            params.error = false;
        } catch(e){
            params.error = true;
            params.message = e.message ? e.message : defErrMsg;
        }

        defErrMsg = null;
    }

    // checking file exist and save it, if not exist
    function saveToCache(url, text){
        // we don't need check file exist, because we do it when trying to read file at first time
        cacheModule.saveDataToFile(url, text);
    }

    // base class for HTTP request
    function reqHttpClass(){
        this.xhr = getXmlHttp();
        this.timeoutId = null;
        this.dataType = "text";
        this.canProcess = true;
        this.params = null;
        this.time = (new Date()).getTime();
    }

    var p = reqHttpClass.prototype;

    // processing done
    p.onDone = function(data){
        clearTimeout(this.timeoutId);
        this.params && this.params.onComplete && this.params.onComplete(false, 0, data, this.xhr);
        this.destroy();
    };

    // processing fail
    p.onFail = function(code, msg, resend){
        if (resend){
            var resendTimes = this.params.resend;
            if ((resendTimes !== undefined) && (resendTimes > 1)){
                this.params.resend--;
                this.send();
            } else {
                this.onFail(code, msg);
            }
        } else {
            clearTimeout(this.timeoutId);
            this.params.onComplete && this.params.onComplete(true, code, msg, this.xhr);
            this.destroy();
        }
    };

    // when data was received, process them by type
    p.onProcess = function(text, save){
        var res = {
            error: false,
            message: "",
            result: text,
            text: text
        };
        if (this.canProcess) {
            switch (this.dataType){
                case "json":
                    parseJSON(res);
                    break;
            }

            if (res.error){
                this.onFail(codes.PROCESS_DATA_ERROR, res.message, false)
            } else {
                // save result
                save && saveToCache(this.params.url, res.text);
                // onDone call
                this.onDone(res.result);
            }
        }


        // clean code
        res.text = null;
        res.result = null;
        res.message = null;
        res = null;

    };

    // abort request
    p.abort = function(){
        if (!this.aborted){
            this.aborted = true;
            clearTimeout(this.timeoutId);
            this.xhr && this.xhr.abort();
        }
    };

    // stop request and drop processing
    p.stop = function(){
        this.canProcess = false;
        this.abort();
    };

    // send request to server api
    p.send = function(){
        var xhr = this.xhr;
        xhr.abort();
        xhr.open("GET", this.params.url, true);
        xhr.send();

        xhr.timeout && (xhr.timeout = timeout + 1000);

        clearTimeout(this.timeoutId);
        var self = this;
        this.timeoutId = setTimeout(function(){
            xhr.abort();
            self.onFail(codes.ABORTED, "timeout abort", true);
        }, timeout);

    };

    // destroy request
    p.destroy = function(){
        this.abort();
        clearTimeout(this.timeoutId);
        if (this.xhr){
            cleanXhr(this.xhr)
        }

        this.xhr = null;
        var par = this.params;
        if (par){
            par.onComplete = null;
            par.url = null;
            par.resend = null;
            par = null;
        }
        this.params = null;
        this.time = null;
        this.dataType = null;
        this.timeoutId = null;
    };

    // clean xhr object
    function cleanXhr(xhr){
        xhr.responseText = null;
        xhr.onerror = null;
        xhr.onload = null;
        xhr.ontimeout = null;
    }

    // return file name from url
    function getFileName(url){
        var name = url.split("/").pop();
        return name;
    }

    // create cache request to file api
    function cacheRequest(reqHttp){
        var params = reqHttp.params;
        var url = params.url;
        var fileName = getFileName(url);
        logger.log('read start:' + fileName);
        var startTime = (new Date).getTime();
        cacheModule.getFile(url, function(error, code, data){
            var endTime = (new Date).getTime();
            logger.log('cache: read done;' + fileName + ' time: ' + (endTime - startTime) + ' ms' + '; error:' + error + '; code:' + code);
            if (!reqHttp.aborted){
                if (!error){
                    reqHttp.onProcess(data, false);
                } else {
                    var codes = cacheModule.getCodes();
                    if (code == codes.NOT_EXISTS){
                        logger.log('cache: read error: ' + fileName + '; file NOT EXIST');
                        // file not exists, send server request
                        serverRequest(reqHttp);
                    } else {
                        logger.log('cache: read error: ' + fileName + '; code:' + code);
                        // when trying to read file, errors was happens
                        reqHttp.onFail(code, data);
                    }
                }
            }
            data = null;
            code = null;
            reqHttp = null;
            logger.log('cache: read end -----------');
        });
    }

    // create server api request
    function serverRequest(reqHttp){
        if (!reqHttp.aborted){
            var params = reqHttp.params;
            var xhr = reqHttp.xhr;

            var loaded = 0;
            xhr.onprogress = function(evt){
                if (evt.lengthComputable) {
                    if (evt.loaded !== undefined) {
                        loaded = evt.loaded;
                    } else if (evt.position !== undefined){
                        loaded++;
                    }

                    params.onProgress && params.onProgress(evt.total, loaded);
                }
            };

            xhr.onload = function () {
                clearTimeout(reqHttp.timeoutId);
                reqHttp.onProcess(xhr.responseText, true);
            };

            xhr.onerror = function(){
                // need some wait, until onerror processed
                setTimeout(function(){
                    reqHttp.onFail(codes.NOT_EXIST, 'Error happens when trying to load ' + params.url, true);
                }, 10);
            };

            xhr.ontimeout = function(){
                xhr.abort();
            };

            reqHttp.send();
        }
    }

    // trying to get JSON file from url
    requester.getJSON = function (params) {
        var reqHttp = new reqHttpClass();
        var xhr = reqHttp.xhr;

        reqHttp.dataType = "json";
        params = params || {};
        reqHttp.params = params;
        if (xhr) {
            if (cacheModule.enable()){
                // cache request
                cacheRequest(reqHttp);
            } else {
                // default request to server
                serverRequest(reqHttp);
            }
        } else {
            reqHttp.onFail(codes.NO_WORKING, "Can't create XHR request");
        }
        return reqHttp;
    };

})(subPixel);
