/*
 * Project:   PlotPad HTML5 Viewer
 * File:      image-loader.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/28/2014
 */
(function(subPixel, undefined) {

    var cacheModule = subPixel('cache');
    var utils = subPixel('utils');
    var domHelper = subPixel('dom-helper');

    var MAX_STORING = 5;

    var PROGRESS_VAL_TOTAL = 100;

    var PART_DOWNLOAD = 0.5; //50%;

    var imgDomHelper = createDomHelper();

    function createDomHelper() {
        var domHelper = document.createElement('div');
        var style = domHelper.style;
        style.position = 'absolute';
        style.top = '-9000px';
        style.left = '-9000px';
        style.width = '100px';
        style.height = '100px';
        style.overflow = 'hidden';
        return domHelper;
    }

    function ImgLoaderClass(params) {
        params = params || {};
        this._name = params.name;
        this._cacheModule = params.cacheModule || cacheModule;

        this._loadImgData = (params.loadImgData !== false);
        this._cachedNodes = {};

        this._onProgress = null;
        this._onAllLoaded = null;
        this._isLoading = false;

        this.logger = subPixel('logger')('image-loader:' + this.name);
    }

    utils.extend(ImgLoaderClass.prototype, {
        getCacheModule: function () {
            return this._cacheModule;
        },
        load: function (path, onDone) {
            var node = this._cachedNodes[path];
            if (!node){
                // start load
                startLoad.call(this, path, onDone);
            } else if (node.loading){
                node.cbs.push(onDone);
            } else {
                onDone && onDone(node.error, node);
            }
        },
        setProcessors: function (params) {
            this._onProgress = params.onProgress;
            this._onAllLoaded = params.onAllLoaded;
            params = null;
        },
        dropProcessors: function (params) {
            if (params.onProgress) {
                this._onProgress = null;
            }
            if (params.onAllLoaded) {
                this._onAllLoaded = null;
            }
        },
        abort: function () {
            var self = this;
            this._isLoading = false;
            processNodes.call(this, function (item) {
                removeNode.call(self, item);
            });
        },
        clean: function () {
            var self = this;
            processNodes.call(this, function (item) {
                onNodeDone.call(self, item, true);
                removeNode.call(self, item);
            });
            this._isLoading = false;
        },
        isLoading: function () {
            return this._isLoading;
        }
    });

    function processLoadingNodes() {
        var isLoading = false;
        var cnt = 0;
        var self = this;
        processNodes.call(this, function (item) {
            if (item.loading === true){
                isLoading = true;
            }
            if (item.loading !== true){
                cnt++;
                if (cnt >= MAX_STORING){
                    removeNode.call(self, item);
                }
            }
        });
        this._isLoading = isLoading;
    }

    function checkAllLoaded() {
        processLoadingNodes.call(this);
        if (!this._isLoading){
            this._onAllLoaded && this._onAllLoaded();
        }
    }

    function processNodes(cb) {
        var _cachedNodes = this._cachedNodes;
        for (var key in _cachedNodes){
            var item = _cachedNodes[key];
            cb(item);
        }
    }

    function createImgEl() {
        // we use this constructor for correct processing of svg size by IE 11
        var ret = document.createElement('img');
        return ret;
    }

    function createImgNode() {
        var node = {
            message: "OK",
            cbs: [], // array of onDone callbacks
            img: null, // image must be image, not other type
            loading: true,
            progress: 0,
            total: PROGRESS_VAL_TOTAL,
            src: '',
            id: '',
            xhr: null,
            error: false
        };
        return node;
    }

    function startLoad(url, onDone) {
        this._isLoading = true;
        var self = this;
        var node = this._cachedNodes[url] = createImgNode();

        node.cbs.push(onDone);
        node.img = createImgEl();
        node.id = url;

        var cacheModule = this._cacheModule;

        if (cacheModule && cacheModule.enable()) {
            cacheModule.processUrlForStore(url, function(ev){
                // progress doing
                var perc = (ev.loaded * 100 / ev.total) * PART_DOWNLOAD;
                node.progress = perc;
                onNodeProgress.call(self, node);
            },function (error, code, storedPath) {
                if (!error){
                    node.loading && loadImgData.call(self, storedPath, node);
                } else{
                    onNodeError.call(self, node);
                    checkAllLoaded.call(self);
                }
            });
        } else {
            loadImgData.call(self, url, node);
        }
    }

    function loadImgData(src, node) {
        var self = this;
        node.src = src;

        var logger = this.logger;
        if (this._loadImgData){
            var imageElement = node.img = createImgEl();
            var startPos = node.progress;
            var endPos = node.total;
            var totalSecond = endPos - startPos;
            node.xhr = loadWithProgress(src, imageElement, function (perc) {
                var val = (perc * totalSecond / 100);
                node.progress = startPos + val;
                onNodeProgress.call(self, node);
            }, function (ev) {
                //err
                logger.error("error: " + src);
                onNodeError.call(self, node);
                checkAllLoaded.call(self);
            }, function (ev) {
                //done
                logger.log("load: " + src);
                onNodeDone.call(self, node, false);
                checkAllLoaded.call(self);
            });

            // cross origin for iphone load correct images
        } else {
            // finish loading node
            onNodeDone.call(self, node, false);
            checkAllLoaded.call(self);
        }
    }

    function onNodeError(node, err) {
        node.error = true;
        node.message = err || 'something wrong when loading image...';
        onNodeDone.call(this, node, true);
    }

    function onNodeProgress() {
        var nodes = this._cachedNodes;
        var val = 0;
        var cnt = 0;
        for (var key in nodes){
            cnt++;
            var node = nodes[key];
            val += node.progress;
        }
        var total = cnt * PROGRESS_VAL_TOTAL;

        var perc = 100 * val / total;
        if (perc > 100){
            perc = 100;
        }
        this._onProgress && this._onProgress(perc);
    }

    function onNodeDone(node, error) {
        if (node.loading){
            node.loading = false;
            var cbs = node.cbs;
            if (!error){
                node.progress = node.total;
            }
            if (cbs){
                utils.map(cbs, function(cb){
                    cb && cb(error, node);
                });
                cbs.length = 0;
            }
            if (error){
                removeNode.call(this, node);
            }
        }
    }

    function removeNode(node){
        var id = node.id;
        node.cbs && (node.cbs.length = 0);
        node.cbs = null;
        node.loading = null;

        // do not drop src field, img used in other parts of core
        //item.img.src = "";

        if (node.img){
            var img = node.img;
            img.onerror = null;
            img.onprogress = null;
            img.onload = null;
            node.img = null;
        }

        if (node.xhr) {
            var xhr = node.xhr;
            xhr.onerror = null;
            xhr.onload = null;
            xhr.onprogress = null;
            xhr.abort();
            node.xhr = null;
        }

        this._cachedNodes[id] = null;
        delete this._cachedNodes[id];
    }

    function loadWithProgress(url, img, onProgress, onError, onDone) {
        var completedPercentage = 0;
        var xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';

        function loadAsImg() {
            imgDomHelper.appendChild(img); // enable access to img width, height cause IE 11 doesn't know naturalWidth, naturalHeight
            img.onerror = function (ev) {
                onError(ev);
            };
            img.onload = function (ev) { // IE 11 sometimes firing the event too soon
                setTimeout(function() {
                    onDone(ev);
                    imgDomHelper.removeChild(img);
                }, 100); // lesser values may cause problems with drawing img on canvas http://stackoverflow.com/questions/25214395/unexpected-call-to-method-or-property-access-while-drawing-svg-image-onto-canvas
            };
            img.crossOrigin = url;
            img.src = url;
        }
        var totalLen = 0;
        var tryGetTotal = true;
        xmlHTTP.onerror = function (e) {
            setTimeout(function () {
                // try to load default way
                loadAsImg();
            }, 10);
        };
        xmlHTTP.onload = function (e) {
            completedPercentage = 100;
            loadAsImg();
        };
        xmlHTTP.onprogress = function (e) {
            if (tryGetTotal) {
                totalLen = (this.getResponseHeader('Content-Length') - 0) || 0;
                tryGetTotal = false;
            }
            if (e.lengthComputable || totalLen) {
                var len = e.lengthComputable ? e.total : totalLen;
                completedPercentage = Math.round((e.loaded / len) * 100 * 100) / 100;
                if (completedPercentage > 100) {
                    completedPercentage = 100;
                }
                onProgress(completedPercentage);
            }
        };
        xmlHTTP.send();
        return xmlHTTP;
    }

    function onStart() {
        document.body.appendChild(imgDomHelper);
    }

    function onStop() {
        domHelper.moveToFragment(imgDomHelper);
    }

    utils.bindStartStop(onStart, onStop);

    subPixel('image-loader-class', ImgLoaderClass);

})(subPixel);
