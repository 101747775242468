/*
 * Project:   PlotPad HTML5 Viewer
 * File:      second-click.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/16/2014
 */
(function(subPixel){
    var editor = subPixel('editor');
    var scenarios = editor('tools-scenarios');
    var utils = subPixel('utils');
    var base = scenarios('base');

    var SecondClick = utils.createChildClass(base, "SecondClickClass", {
        init: function () {
            this.drop();
        },
        onMouseStart: function (ev) {
            if (this.clicks == 0) {
                editor.startDraw();
            }
        },
        onMouseMove: function (ev) {
            if (this.clicks == 1) {
                this.tool.onMouseMove(ev);
            }
        },
        onMouseEnd: function (ev) {
            var clicks = this.clicks;
            if (clicks == 0) {
                this.tool.onMouseStart(ev);
                this.clicks++;
            } else if (clicks >= 1) {
                this.tool.onMouseEnd(ev);
                this.clicks = 0;
            }
        },
        drop: function () {
            this.clicks = 0;
        }
    });

    scenarios('second-click', SecondClick);

})(subPixel);
