/*
 * Project:   PlotPad HTML5 Viewer
 * File:      crossing-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){
    var parts = subPixel('parts');
    var utils = subPixel('utils');
    var config = subPixel('config');
    var logger = subPixel('logger')('crossing-helper');

    var currDetector;

    var TYPE_DEFAULT = '__default';

    var detectTypes = {};


    var crossingHelper = {

        TYPE_POLY: 'poly',
        TYPE_CIRCLE: 'circle',
        TYPE_LINE: 'line',
        TYPE_TEXT: 'text',
        TYPE_CROSS: 'cross',
        TYPE_BOX: 'box',
        TYPE_DEFAULT: TYPE_DEFAULT,

        registerDetectType: function (type, opt) {
            if (detectTypes[type]){
                logger.error('trying to detect already defined detectTypes');
            } else {
                if (!opt.forSelect || !opt.forHover || !opt.crossTypes){
                    logger.error('not defined type methods or crossTypes');
                } else {
                    detectTypes[type] = {
                        forSelect: opt.forSelect,
                        forHover: opt.forHover,
                        crossTypes: opt.crossTypes
                    };
                }
            }
        },

        detectCrossHover: function (boxClip) {
            var res = false;
            //before check figure in points area, check bbox
            var bbox = this.getBoundingBox();
            if (crossingHelper.isBoxCrossingBox(bbox, boxClip)){
                //after check poly lines
                var forHover = currDetector.forHover;
                res = forHover(this, bbox, boxClip);
            }
            return res;
        },
        detectCrossSelection: function (boxClip) {
            var res = false;
            var bbox = this.getBoundingBox();
            if (crossingHelper.isBoxCrossingBox(bbox, boxClip)){
                var forSelect = currDetector.forSelect;
                res = forSelect(this, bbox, boxClip);
            }
            return res;
        },
        isBoxCrossingBox: function(mainBox, childBox){
            // check box crossing box,
            // and childBox inside mainBox
            return ((childBox.maxx - mainBox.minx) >=0 &&
            (mainBox.maxx - childBox.minx) >= 0 &&
            (childBox.maxy - mainBox.miny) >=0 &&
            (mainBox.maxy - childBox.miny) >= 0);
        },
        isPointInBox: function (point, bbox) {
            var ret = false;
            if (bbox.vertexes){

            } else {
                ret = (
                    (point.x <= bbox.maxx) &&
                    (point.x >= bbox.minx) &&
                    (point.y <= bbox.maxy) &&
                    (point.y >= bbox.miny)
                );
            }
            return ret;
        },
        isBoxInBox: function(bbox, bbox2){
            var ret = (
                (bbox2.maxx >= bbox.maxx) &&
                (bbox2.maxy >= bbox.maxy) &&
                (bbox2.minx <= bbox.minx) &&
                (bbox2.miny <= bbox.miny)
            );
            return ret;
        },
        getCrossByType: getCrossByType,
        registerInDetectType: function (detectType, crossName, method) {
            var detector = detectTypes[detectType];
            if (!detector){
                logger.error('wrong detector name for register in detector');
            } else {
                if (detector.crossTypes[crossName]){
                    logger.error('wrong cross name for register in detector');
                } else {
                    detector.crossTypes[crossName] = method;
                }
            }
        }
    };

    function getCrossByType(fig, clip, cTypes){
        var ret = false;
        var method;

        if (cTypes){
            var type = fig.propPointsType();
            method = cTypes[type] || cTypes[TYPE_DEFAULT];
        }

        if (method){
            ret = method(fig, clip);
        }
        return ret;
    }

    var defCrossTypes = {};
    crossingHelper.registerDetectType('default', {
        forSelect: function () {
            return true;
        },
        forHover: function (fig, bbox, boxClip) {
            var res = getCrossByType(fig, boxClip, defCrossTypes);
            return res;
        },
        crossTypes: defCrossTypes
    });

    parts('crossing-helper', crossingHelper);

    function start(){
        currDetector = detectTypes['in-selection-box']; //detectTypes['default'];
        if (config.selectionDetectType){
            var confDetectType = config.selectionDetectType;
            if (detectTypes[confDetectType]){
                currDetector = detectTypes[confDetectType]
            } else {
                logger.warn('using default crossing detector');
            }
        }
    }

    utils.bindStartStop(start);

})(subPixel);
