/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cloud-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/04/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var toolsHelper = subPixel('tools-helper');
    var crossingHelper = parts('crossing-helper');

    var PI = Math.PI;

    var CONST_DEFAULT_RADIUS = 10;

    var figure = utils.createChildClass(AnnoBaseClass, 'cloudChunkClass');

    figure.drawCloud = function(ctx, points, w, h){
        var fromx = points[0].x;
        var fromy = points[0].y;
        var tox = fromx + w;
        var toy = fromy + h;

        ctx.beginPath();

        var radius = toolsHelper.getScaledValue(points, CONST_DEFAULT_RADIUS);
        var diameter = radius * 2;

        var absW = Math.abs(w);
        var absH = Math.abs(h);

        var pw = Math.floor((absW / diameter));
        var ph = Math.floor((absH / diameter));

        var currx = Math.min(fromx, tox) - radius;
        var curry = Math.min(fromy, toy);

        // top
        for (var i = 0; i < pw; i++){
            currx = currx + diameter;
            ctx.arc(currx, curry, radius, PI, 0);
        }
        currx += diameter;
        var dxVal = (absW - pw * diameter);
        var dx = dxVal / diameter;
        ctx.arc(currx, curry, radius, PI, PI + PI * dx);

        // // right
        // curry -= dxVal / 2;
        currx -= radius;
        var rCurrx = currx + dxVal;
        for (var k = 0; k < ph; k++){
            curry = curry + diameter;
            ctx.arc(rCurrx, curry - radius, radius, 1.5 * PI, 0.5 * PI);
        }
        curry += radius;
        var dyVal = (absH - ph * diameter);
        var dy = dyVal / diameter;
        ctx.arc(rCurrx, curry, radius, 1.5 * PI, 1.5 * PI + PI * dy);


        // bottom
        curry -= radius;
        currx -= radius;
        var rCurry = curry + dyVal;
        currx += diameter;

        ctx.arc(currx, rCurry, radius, PI * (1 - dx), PI);
        for (var i = 0; i < pw; i++){
            currx = currx - diameter;
            ctx.arc(currx, rCurry, radius, 0, PI);
        }

        // // left
        currx -= radius;
        curry += diameter;
        ctx.arc(currx, curry - radius, radius, 0.5 * PI + PI * (1 - dy), 1.5 * PI);
        for (var k = 0; k < ph; k++){
            curry = curry - diameter;
            ctx.arc(currx, curry - radius, radius, 0.5 * PI, 1.5 * PI);
        }

        ctx.stroke();
        ctx.closePath();
    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        // for figure from parameters getting for now only 'color' and 'filled' value
        var drawParams = this.drawParams;
        var points = data.points;

        this.initAnnoDrawParams(data);

        var p1 = points[0];
        var p2 = points[1];

        var w = p2.x - p1.x;
        var h = p2.y - p1.y;

        drawParams.w = w;
        drawParams.h = h;

        // points for bbox
        var r = toolsHelper.getScaledValue(points, CONST_DEFAULT_RADIUS);
        points[2] = {
            x: p1.x + r,
            y: p1.y + r
        };
        points[3] = {
            x: p1.x - r,
            y: p1.y - r
        };
        points[4] = {
            x: p2.x - r,
            y: p2.y - r
        };
        points[5] = {
            x: p2.x + r,
            y: p2.y + r
        };

        this.initBoundingBox(points);

        this.propPointsType(crossingHelper.TYPE_BOX);

        p1 = null;
        p2 = null;
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var drawParams = this.drawParams;
        var points = this.data().points;

        this.setAnnoDrawStyles(opt);
        figure.drawCloud(ctx, points, drawParams.w, drawParams.h);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-cloud', figure);


})(subPixel);
