/*
 * Project:   PlotPad HTML5 Viewer
 * File:      helpers.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/14/2014
 */
/*
 * Helper user api
 * */
(function (subPixel, undefined) {
    var inter = subPixel('interface');
    var offset = subPixel('offset');
    var canvas = subPixel('canvas');
    var broadcast = subPixel('broadcast');
    var systemEvs = broadcast.events('system');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var drawingCtrl = subPixel('drawing-ctrl');
    var domHelper = subPixel('dom-helper');
    var engineDom = subPixel('engine-dom');

    inter.register({
        appendTo: function (el) {
            var elem = engineDom.getBaseElement();
            el.appendChild(elem);
            offset.checkResize();
            drawingCtrl.redraw(function () {
                utils.recalcDom(elem);
            });
        },
        detach: function () {
            var elem = engineDom.getBaseElement();
            domHelper.moveToFragment(elem);
        },
        disableResize: function () {
            offset.disableResize();
        },
        enableResize: function () {
            offset.enableResize();
        },
        getScreenImage: function (type) {
            var dataUrl;
            if (!subPixel.isStopped()) {
                dataUrl = canvas.getScreenImage(type);
            }
            return dataUrl;
        },
        getPrintImage: function (callback) {
            if (!subPixel.isStopped()) {
                var x = offset.x;
                var y = offset.y;
                canvas.setViewToModelSize(0, 0);

                drawingCtrl.redraw(function () {

                    var dataUrl = canvas.getScreenImage();
                    canvas.setViewToScreenSize(-x, -y);

                    drawingCtrl.redraw(function () {
                        callback && callback(dataUrl);
                    });
                });
            } else {
                callback && callback();
            }
        },
        // call system to set default state, if some modules have this
        setDefaultState: function () {
            broadcast.trig(systemEvs.defaultState);
        },
        selectTool: function (name, params) {
            var editor = subPixel('editor');
            var toolsLib = editor('tools');
            toolsLib.selectTool(name);
            if(params) {
                toolsLib.setToolParams(params);
            }
        },
        isScenarioSelect: function () {
            var ret = (scenarioCtrl.getCurrentScenario() == scenarioCtrl.select);
            return ret;
        },
        isScenarioEdit: function () {
            var ret = (scenarioCtrl.getCurrentScenario() == scenarioCtrl.edit);
            return ret;
        },
        isDrawing: function(){
            return drawingCtrl.isDrawing();
        }
    });

})(subPixel);
