/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/09/2014
 */
(function(subPixel, undefined){

    var editor = subPixel('editor');
    var tools = editor('tools');
    var scenarios = editor('tools-scenarios');
    var baseScenario = scenarios('base');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var logger = subPixel('logger')('base-tool');

    var utils = subPixel('utils');

    function BaseToolClass(params, className){
        this._class = className;
        this.defineParams.apply(this, arguments);
        this.init.apply(this, arguments);
        this._enable = true;
    }

    var p = BaseToolClass.prototype;

    p.getClass = function(){
        return this._class;
    };

    p.getScenario = function(){
        return this._scenario;
    };

    p.defineParams = function(params){
        var scenario = baseScenario;
        if (params.scenario){
            var pScenario = params.scenario;
            if (typeof pScenario == "string"){
                scenario = scenarios(pScenario);
            } else {
                scenario = pScenario;
            }

            if (typeof scenario != 'function'){
                logger.error('not exists scenario "'+ pScenario +'" for tool, using default scenario');
                scenario = baseScenario
            }
        }

        this._scenario = new scenario(this);
        this._isPrimary = (params.isPrimary === true);
        this._isExtTool = (params.isExtTool == true);
        this._extTools = params.extTools || [];

        this._name = params.name;
        this._partName = params.partName;
        this._el = params.el || document.createElement('div');

        (!params.place) && (params.place = "toolbox");

        this._placeName = params.place;

        this._place = tools.placeForTools(this.getPlaceName());
    };

    // default method for some initialization of tool, if needed
    p.init = function(){

    };

    p.name = function () {
        return this._name;
    };

    p.partName = function () {
        return this._partName;
    };

    p.isGroup = function(){
        return false;
    };

    p.canUseTool = function () {
        return true;
    };

    // calls when tool selected in tools
    p.onToolSelect = function(){

    };

    // calls when tool was deselected in tools
    p.onToolDeselect = function(){

    };

    // when tool was added to tools
    p.onToolAdded = function(){

    };

    p.onToolDrawing = function () {

    };

    // when mouse clicked inside tool div, some tools can drop deselect
    // (see color-picker for example)
    p.canDeselectInside = function(){
        return true;
    };

    // when mouse clicked outside of tool div, this method calls for check drop selection of current tool
    // (see color-picker for example)
    p.canDeselectOutside = function(){
        return false;
    };

    // return container, where tool is located
    p.getPlace = function(){
        return this._place;
    };

    p.getPlaceName = function(){
        return this._placeName;
    };

    // return element of tool
    p.getEl = function(){
        return this._el;
    };

    p.parentGroup = function (val) {
        if (val !== undefined){
            this._parentGroup = val;
        }
        return this._parentGroup;
    };

    // when mouse clided inside of tools, this method will call
    //
    // e - mouse event for getting target
    p.onClick = function(e){

    };

    // some tools can be accessible after prev tool changed one of options for draw or something else
    p.isPrimary = function(){
        return this._isPrimary;
    };

    // for tools, that are not is main for change some actions
    p.isExtTool = function(){
        return this._isExtTool;
    };

    p.extTools = function(){
        return this._extTools;
    };

    p.enable = function(){
        this._enable = true;
    };

    p.isEnable = function(){
        return this._enable;
    };

    p.disable = function(){
        this._enable = false;
    };

    p.visible = function(val){
        if (val !== undefined){
            this._visible = val;
            this._el.style.display = val ? 'inline-block' : 'none';
        }
        return this._visible;
    };

    p.onStart = function () {
        // cap
    };


    // register tool in tools
    tools(BaseToolClass, {
        mode: scenarioCtrl.view,
        visible: false,
        name: 'base'
    });

})(subPixel);
