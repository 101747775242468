/*
 * Project:   PlotPad HTML5 Viewer
 * File:      events.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 00/23/2014
 */
/*
 * Events for user api
 *
 * system events must not be the same as the user api, this is different events
 *
 * because user can call .off for some event and this will can destroy part of system
 *
 * system is system, user is user, separate this please
 *
 *
 * */
(function(subPixel, undefined){
    var inter = subPixel('interface');
    var broadcast = subPixel('broadcast');
    var systemEvs = broadcast.events('system');
    var smEvs = broadcast.events('select-manager');
    var hmEvs = broadcast.events('hover-manager');
    var elementsCtrlEvs = broadcast.events('elements-ctrl');
    var drawingCtrlEvs = broadcast.events('drawing-ctrl');

    var mouseEvs = broadcast.events('mouse');
    var utils = subPixel('utils');
    var logger = subPixel('logger');

    // special broadcaster for user
    var userBroadcast = broadcast.instance('2D-engine-userApi');

    var userApiEvents = subPixel('user-api-events', registerSingleApiEv);

    var globalEventNames = {}; // names of user api

    // for example, user can use this as subPixel.on('design-drawend', function(){...});
    var AE = {}; // accessible events for user api

    function regInAE(userApiEvs) {
        for (var userEvName in userApiEvs){
            var systemEvName = userApiEvs[userEvName];
            registerSingleApiEv(systemEvName, userEvName);
        }
    }

    function registerSingleApiEv(systemEvName, userEvName) {
        if (!AE[systemEvName] && !globalEventNames[userEvName]){
            AE[systemEvName] = userEvName;
            globalEventNames[userEvName] = true;
        } else {
            logger.error('Trying to register defined user api event "'+ systemEvName +'", as "'+ userEvName +'"');
        }
    }

    // register default user events as API events, for use by developers as external events
    regInAE({
        'design-drawend': drawingCtrlEvs.endDrawing,
        'design-added-new-element': elementsCtrlEvs.addedNewElement,
        'design-removed-element': elementsCtrlEvs.removedElements,
        'sm-changed': smEvs.changed,
        'system-dom-ready': systemEvs.domReady,
        'hm-hovered': hmEvs.hovered,
        'mouse-hold': mouseEvs.hold,
        'mouse-dbl-tap': mouseEvs.dblTap,
        'mouse-tap-end': mouseEvs.tapend,

        'system-start': systemEvs.start,
        'system-stop': systemEvs.stop
    });

    // for simplest way to remove all callbacks
    var CONST_NAMESPACE = ".user-broadcast-bindings";

    // return arguments for accessing they as array
    function getArgs(){
        return Array.prototype.slice.apply(arguments[0]);
    }

    // processing system start
    function onStart(){

        // event api will be binded only that events, what are defined in top of this file
        // others will be dropped, because we need to associate user api event name and system event name

        var allEvents = broadcast.getAllEvents();
        // getting all events from storage and trying to bind them
        for (var key in allEvents){
            var evNames = allEvents[key];
            for (var evKey in evNames){
                var evName = evNames[evKey];
                if (AE[evName]){
                    //defined handler and bind to user event
                    var handler = (function(trigName){
                        return function(){
                            var args = getArgs(arguments);
                            args.splice(0, 0, AE[trigName]);
                            userBroadcast.trig.apply(userBroadcast, args);
                            args = null;
                        };
                    })(evName);
                    broadcast.on(evName, CONST_NAMESPACE, handler);
                }
            }
        }
    }

    function onStop(){
        //this unbind must be the last
        broadcast.off(CONST_NAMESPACE);
    }

    // process for register methods in subPixel namespace
    // register all broadcast methods in subPixel as interface
    (function (list){
        for (var i = 0, l = list.length; i < l; i++){
            var item = list[i];
            (function(item){
                inter.register(item, function(){
                    userBroadcast[item].apply(userBroadcast, arguments);
                })
            })(item);
        }
    })(['on', 'one', 'off', 'trig', 'aTrig']);

    utils.bindStartStop(onStart, onStop);

    subPixel('userBroadcast', userBroadcast);

})(subPixel);
