/*
 * Project:   PlotPad HTML5 Viewer
 * File:      draw-options-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){

    var utils = subPixel('utils');
    var parts = subPixel('parts');

    var defStruct = {
        isSelection: false,

        fillStyle: null,
        lineJoin: null,

        lineRatio: null,
        strokeStyle: null,

        _canDrawTheme: true
    };

    parts('draw-options-helper', function (opt) {
        var ret = utils.clone(defStruct);

        if (opt){
            for (var key in opt){
                ret[key] = opt[key];
            }
        }

        return ret;
    });


})(subPixel);
