/*
 * Project:   PlotPad HTML5 Viewer
 * File:      crossing-type-near-point.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2017
 */
(function(subPixel, undefined){
    var utils = subPixel('utils');
    var parts = subPixel('parts');
    var crossingHelper = parts('crossing-helper');
    var scale = subPixel('scale');
    var broadcast = subPixel('broadcast');
    var scaleEvs = broadcast.events('scale');
    var getDistance = utils.getDistance;

    var THRESHOLDS_DETECT_SELECTION = 3;
    var multiplyCheck = THRESHOLDS_DETECT_SELECTION;

    function isPointNearCircle(fig, clip){
        var p1 = fig.getCenterPoint();
        var radius = fig.getRadius();
        var inRadius = fig.getInternalRadius();

        var ret = isCircleInClip(
            p1.x, p1.y, radius,
            clip.minx, clip.miny, clip.w, clip.h);

        if (ret && inRadius) {
            var dim = inRadius * 2;
            var inRadVal = inRadius - multiplyCheck;
            (inRadVal <= 0) && (inRadVal = 0.001);
            ret = isCrossTouchCircle(fig, inRadVal, clip);

            // ret = !isCircleInClip(
            //     p1.x, p1.y, inRadVal,
            //     clip.minx, clip.miny, clip.w, clip.h);
        }

        return ret;
    }

    function isCrossTouchCircle(fig, inRad, clip) {
        var centerPoint = fig.getCenterPoint();
        var leftTop = getDistance(centerPoint.x, clip.minx, centerPoint.y, clip.miny);
        if (leftTop > inRad){
            return true;
        }
        var rightTop = getDistance(centerPoint.x, clip.maxx, centerPoint.y, clip.miny);
        if (rightTop > inRad){
            return true;
        }
        var rightBottom = getDistance(centerPoint.x, clip.maxx, centerPoint.y, clip.maxy);
        if (rightBottom > inRad){
            return true;
        }
        var leftBottom = getDistance(centerPoint.x, clip.minx, centerPoint.y, clip.maxy);
        if (leftBottom > inRad){
            return true;
        }
        return false;
    }

    function isCircleInClip(
        circle_x, circle_y, circleRadius,
        x, y, w, h) {

        var distX = Math.abs(circle_x - x - w / 2);
        var distY = Math.abs(circle_y - y - h / 2);

        if (distX > (w / 2 + circleRadius)) {
            return false;
        }
        if (distY > (h / 2 + circleRadius)) {
            return false;
        }

        if (distX <= (w / 2)) {
            return true;
        }
        if (distY <= (h / 2)) {
            return true;
        }

        var dx = distX - w / 2;
        var dy = distY - h / 2;
        var ret = (dx * dx + dy * dy <= (circleRadius * circleRadius));
        return ret;
    }

    function lineSegmentsIntersect(
        x1, y1, x2, y2, // line 1
        x3, y3, x4, y4 // line 2
    ) {
        // WARNING!!! WILL NOT WORK WITH POINTS! only line coordinates
        var a_dx = x2 - x1;
        var a_dy = y2 - y1;
        var b_dx = x4 - x3;
        var b_dy = y4 - y3;
        var s = (-a_dy * (x1 - x3) + a_dx * (y1 - y3)) / (-b_dx * a_dy + a_dx * b_dy);
        var t = (+b_dx * (y1 - y3) - b_dy * (x1 - x3)) / (-b_dx * a_dy + a_dx * b_dy);
        return (s >= 0 && s <= 1 && t >= 0 && t <= 1);
    }

    function isPointNearPoints(poly, clip){
        var ret = false;
        // check first point for crossing clip
        var pointStart = poly[0];
        if (crossingHelper.isPointInBox(pointStart, clip)){
            ret = true;
        } else {
            // check lines
            for (var i = 1, l = poly.length; i < l; i++){
                var pointEnd = poly[i];
                ret = lineIntersectClip(clip, pointStart.x, pointStart.y, pointEnd.x, pointEnd.y);
                if (ret){
                    break;
                } else {
                    pointStart = pointEnd;
                }
            }
        }
        return ret;
    }

    function lineIntersectClip(clip, pointStart_x, pointStart_y, pointEnd_x, pointEnd_y) {
        // at first try to detect as CROSS X

        // left top - right bottom
        var ret = lineSegmentsIntersect(
            pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
            clip.minx, clip.miny, clip.maxx, clip.maxy
        );

        // right too - left bottom
        if (!ret){
            ret = lineSegmentsIntersect(
                pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
                clip.maxx, clip.miny, clip.minx, clip.maxy
            );
        }

        // if not detected use default clip cross as BOX
        if (!ret){
            // top
            ret = lineSegmentsIntersect(
                pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
                clip.minx, clip.miny, clip.maxx, clip.miny
            );
            // right
            if (!ret){
                ret = lineSegmentsIntersect(
                    pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
                    clip.maxx, clip.miny, clip.maxx, clip.maxy
                );
            }
            // bottom
            if (!ret){
                ret = lineSegmentsIntersect(
                    pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
                    clip.maxx, clip.maxy, clip.minx, clip.maxy
                );
            }
            // left
            if (!ret){
                ret = lineSegmentsIntersect(
                    pointStart_x, pointStart_y, pointEnd_x, pointEnd_y,
                    clip.minx, clip.maxy, clip.minx, clip.miny
                );
            }
        }
        return ret;
    }

    function boxFigIntersectClip(fig, clip) {
        var points = fig.data().points;
        var p1 = points[0];
        var p2 = points[1];

        // top
        var ret = lineIntersectClip(clip, p1.x, p1.y, p2.x, p1.y);
        if (!ret){
            // right
            ret = lineIntersectClip(clip, p2.x, p1.y, p2.x, p2.y);
        }
        if (!ret){
            // bottom
            ret = lineIntersectClip(clip, p2.x, p2.y, p1.x, p2.y);
        }

        if (!ret){
            // left
            ret = lineIntersectClip(clip, p1.x, p2.y, p1.x, p1.y);
        }
        return ret;
    }

    var crossingTypesInBox = (function () {
        var ret = {};
        ret[crossingHelper.TYPE_CIRCLE] = function(fig, clip){
            return isPointNearCircle(fig, clip);
        };

        ret[crossingHelper.TYPE_LINE] = function (fig, clip) {
            var points = fig.data().points;
            var p1 = points[0];
            var p2 = points[1];
            return lineIntersectClip(clip, p1.x, p1.y, p2.x, p2.y);
        };

        ret[crossingHelper.TYPE_CROSS] = function (fig, clip) {
            var points = fig.data().points;
            var p1 = points[0];
            var p2 = points[1];
            var ret = lineIntersectClip(clip, p1.x, p1.y, p2.x, p2.y);
            if (!ret){
                ret = lineIntersectClip(clip, p1.x, p2.y, p2.x, p1.y);
            }
            return ret;
        };

        ret[crossingHelper.TYPE_BOX] = function (fig, clip) {
            return boxFigIntersectClip(fig, clip);
        };

        ret[crossingHelper.TYPE_TEXT] = function(fig, clip){
            var ret = true;
            return ret;
        };

        ret[crossingHelper.TYPE_POLY] = function(fig, clip){
            var figPoints = fig.getHelpPoints();
            return isPointNearPoints(figPoints, clip);
        };

        ret[crossingHelper.TYPE_DEFAULT] = ret[crossingHelper.TYPE_POLY];
        return ret;
    })();


    function forSelect(fig, bbox, boxClip) {
        var res = false;
        if (crossingHelper.isBoxInBox(bbox, boxClip)){
            res = true;
        } else {
            res = crossingHelper.getCrossByType(fig, boxClip, crossingTypesInBox);
        }
        return res;
    }

    function forHover(fig, bbox, boxClip) {
        var res = false;
        if (crossingHelper.isBoxInBox(bbox, boxClip)){
            res = true;
        } else {
            res = crossingHelper.getCrossByType(fig, boxClip, crossingTypesInBox);
        }
        return res;
    }

    crossingHelper.registerDetectType('in-near-point', {
        forSelect: forSelect,
        forHover: forHover,
        crossTypes: crossingTypesInBox
    });

    utils.bindStartStop(function () {
        broadcast.on(scaleEvs.onUpdated, function () {
            multiplyCheck = (THRESHOLDS_DETECT_SELECTION / scale.val) * 2 + 0.001;
        })
    });

})(subPixel);
