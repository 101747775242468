/*
 * Project:   PlotPad HTML5 Viewer
 * File:      mouse.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/29/2013
 */
(function(subPixel, undefined) {
    // exportable modules
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');
    var getDistance = utils.getDistance;
    var engineDom = subPixel('engine-dom');

    // register events in storage
    var mouseEvs = broadcast.events('mouse', {
        pitchstop: 'pitchstop',
        pitchstart: 'pitchstart',
        pitch: 'pitch',
        pitchin: 'pitchin',
        pitchout: 'pitchout',

        up: 'up',
        down: 'down',
        move: 'move',


        // this events is the same as for 'tap' and 'mouse'
        tapstart: 'tapstart',
        tapmove: 'tapmove',
        tapend: 'tapend',

        fingersChanged: 'fingersChanged',

        hold: 'hold',
        dblTap: 'dblTap',

        leave: 'leave'
    });

    // mouse object external
    var mouse = {
        down: false,
        distance: 0,
        fingers: 0,
        tapStartX: 0,
        tapStartY: 0,

        isTouch: false,

        x: 0,
        y: 0,

        // bound rect area of container
        containerLeft: 0,
        containerTop: 0,

        vector: 0
    };

    // keys for touches
    var touchMap = {
        touchstart: true,
        touchend: true,
        touchmove: true,
        touchcancel: true,

        MSPointerDown: true,
        MSPointerMove: true,
        MSPointerUp: true,
        MSPointerCancel: true,

        pointerdown: true,
        pointermove: true,
        pointerup: true,
        pointercancel: true,
        pointerleave: true
    };


    var mouseMoveTmp = {
        x: 0,
        y: 0
    };

    var clickers = {};
    var clickersArr = [];
    var clickersLen = 0;
    var lastClicker = {};
    var THRESHOLD_CLICKER = 3;
    var clickerBuffArr = [];

    var touchSupport = utils.support.touch;

    // this const must be in one char only, because this is type of clicker
    var C_TOUCH_NORM = 'a';
    var C_TOUCH_MS = 'd';
    var C_TOUCH_MOUSE = 'm';

    // container for catch mouse event
    var pContainer;

    var firstMove = false, startDistance = 0, pitchStopped = true;
    var secondTap = {
        x: 0,
        y: 0
    };

    // value for detect move event
    var THRESHOLD_MOVE = 2;

    // value for detect hold event
    var HOLD_DELAY = 400;

    // timer handler for detect hold event
    var holdTimeStart;

    var DBL_DELAY = 1000;
    var DBL_AREA_VAL = 10;
    var dblTimeValue = 0;
    var dblTimeValuePoint = {
        x: 0,
        y: 0
    };
    var winScrollY = 0;
    var winScrollX = 0;

    // update bounding rect of container
    function updateBound(){
        var sRect = pContainer.getBoundingClientRect();
        mouse.containerLeft = sRect.left;
        mouse.containerTop = sRect.top;
    }

    function getWindowScrollX(){
        var ret = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
        return ret;
    }

    function getWindowScrollY(){
        var ret = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        return ret;
    }

    function updateByScrollPos(){
        var newX = getWindowScrollX();
        var newY = getWindowScrollY();
        if (newX != winScrollX || newY != winScrollY){
            winScrollX = newX;
            winScrollY = newY;
            updateBound();
        }
    }

    // stop picth event
    function stopPitch(e) {
        !pitchStopped && broadcast.trig(mouseEvs.pitchstop, e);
        startDistance = 0;
        pitchStopped = true;
    }

    function removeAllClickers(){
        for (var key in clickers){
            clickers[key] = null;
            delete clickers[key];
        }
        clickersArr.length = 0;
        clickersLen = clickersArr.length;

    }

    function cleanupClickers(clickerIds){
        var haveClickers = false;
        var map = {};
        for (var i = 0, l = clickerIds.length; i < l; i++){
            var clickerId = clickerIds[i];
            if (clickers[clickerId]){
                haveClickers = true;
                var oldClicker = clickers[clickerId];
                lastClicker.x = oldClicker.x;
                lastClicker.y = oldClicker.y;
                lastClicker.type = oldClicker.type;
                oldClicker = null;
                map[clickerId] = true;
                clickers[clickerId] = null;
                delete clickers[clickerId];
            }
        }

        if (haveClickers) {
            // cleanup clickers
            for (var i = clickersArr.length - 1; i >= 0; i--) {
                var item = clickersArr[i];
                if (map[item.id]) {
                    clickersArr.splice(i, 1);

                    if (i == 0 && clickersLen > 1) {
                        // if first tap finish, finish them all
                        clickersArr.length = 0;
                    }
                }
            }
            clickersLen = clickersArr.length;

        }
        map = null;

        return haveClickers;
    }

    // stop tap event
    function trigTapEnd(clickerIds, e){
        var haveClickers = cleanupClickers(clickerIds);

        //check fingers on board
        updateFingers();
        if (mouse.down){
            clearTimeout(holdTimeStart);

            if (clickersLen){
                // do not stop tap
            } else {
                updateBound();
                mouse.down = false;
                mouse.fingers = 0;
                stopPitch(e);
                broadcast.trig(mouseEvs.tapend, e);
                mouse.distance = 0;
                firstMove = false;
            }
        }
        return haveClickers;
    }

    // crossbrowser prevent event
    function preventEvent(e){
        e.preventManipulation && e.preventManipulation();// ie10
        e.preventDefault && e.preventDefault(); // others browsers
    }


    function processHStart(hasClicker, canClickArea, e){
        // create new and process
        updateObjectByEvent(mouse, clickersArr[0]);
        if (canClickArea && !hasClicker){
            clearTimeout(holdTimeStart);

            firstMove = false;

            if (!mouse.down){
                mouse.down = true;

                updateFingers();

                updateSecondTap();

                mouse.tapStartX = mouse.x;
                mouse.tapStartY = mouse.y;

                startDistance = mouse.distance = getDistance(mouse.x, secondTap.x, mouse.y, secondTap.y);

                broadcast.trig(mouseEvs.tapstart, e);

                processHoldTap();
                processDblTap();
            }
        }
    }

    function processHoldTap(){
        holdTimeStart = setTimeout(function(){
            holdTimeStart = null;
            broadcast.trig(mouseEvs.hold);
        }, HOLD_DELAY);
    }


    function processDblTap(){
        if (mouse.fingers == 1){
            var now = (new Date).getTime();
            var dx = now - dblTimeValue;
            if (dx < DBL_DELAY){
                if (inPushArea(dblTimeValuePoint)){
                    // clicked here
                    broadcast.trig(mouseEvs.dblTap);
                    now = 0;
                }
            }
            dblTimeValue = now;
            dblTimeValuePoint.x = mouse.x;
            dblTimeValuePoint.y = mouse.y;
        } else {
            dblTimeValue = 0;
        }
    }

    // processing start event
    function hStart(e) {
        updateBound();
        var clickerIds = getClickerId(e);
        var canTrigDown = false;
        var rectUpdated = false;

        var targetClassName = e.target.className;

        if (targetClassName.indexOf('do-not-process-canvas-click') == -1){
            var canClickArea = false;
            if (targetClassName.indexOf('can-click-area') != -1){
                canClickArea = true;
            }

            for (var i = 0, l = clickerIds.length; i < l; i++){
                var clickerId = clickerIds[i];
                if (!rectUpdated) {
                    updateBound();
                    rectUpdated = true;
                }
                if (clickerId){
                    var hasClicker = clickers[clickerId];
                    createClicker(clickerId, e);
                    if (isTargetEqual()){
                        // all ok
                        canTrigDown = true;
                        processHStart(hasClicker, canClickArea, e);
                    } else {
                        // drop all clickers and call on tap end
                        hEnd(e);
                        removeAllClickers();
                    }
                }

            }

            if (canTrigDown) {
                broadcast.trig(mouseEvs.down, e);
            }
            preventEvent(e);
        }
    }

    // processing mouse up event
    function hEnd(e){
        preventEvent(e);
        hEndProcess(e, function(ev){
            broadcast.trig(mouseEvs.up, ev);
        });
    }

    // processing leave event
    function hLeave(e) {
        hEndProcess(e);
        broadcast.trig(mouseEvs.leave, e);
    }

    function hEndProcess(e, cb){
        var clickerIds = getClickerId(e);
        if (clickerIds.length && trigTapEnd(clickerIds, e)) {
            cb && cb(e);
        }
        preventEvent(e);
    }

    // update secondary tap object
    function updateSecondTap(){
        if (clickersLen >= 2){
            updateObjectByEvent(secondTap, clickersArr[1]);
        } else {
            secondTap.x = mouse.x;
            secondTap.y = mouse.y;
        }
    }

    // check threshold value
    function inThreshold(){
        return (Math.abs(mouse.x - mouse.tapStartX) > THRESHOLD_MOVE) ||
            (Math.abs(mouse.y - mouse.tapStartY) > THRESHOLD_MOVE)
    }

    function inPushArea(obj){
        return (Math.abs(mouse.x - obj.x) <= DBL_AREA_VAL) &&
            (Math.abs(mouse.y - obj.y) <= DBL_AREA_VAL)
    }

    // update count of touched fingers
    function updateFingers(){
        var old = mouse.fingers;

        mouse.fingers = clickersLen;
        if (old != mouse.fingers){
            broadcast.trig(mouseEvs.fingersChanged, old);
        }
    }

    // processing move event
    function hMove(e) {
        updateByScrollPos();
        if (clickersLen){
            updateClicker(e);
            clickersArr[0] && updateObjectByEvent(mouse, clickersArr[0]);
            if (mouse.down){
                if (firstMove || inThreshold()) {

                    clearTimeout(holdTimeStart);
                    dblTimeValue = 0;

                    updateSecondTap();

                    mouse.distance = getDistance(mouse.x, secondTap.x, mouse.y, secondTap.y);
                    var dx = (mouse.distance - startDistance);

                    updateFingers();

                    var isPitch = (mouse.fingers == 2);
                    if (isPitch) {
                        // pitch action
                        if (!firstMove && pitchStopped){
                            broadcast.trig(mouseEvs.pitchstart, e);
                            pitchStopped = false;
                        }

                        mouse.vector = (dx > 0) ? 1 : (dx < 0) ? -1 : 0;
                        startDistance = mouse.distance;
                        broadcast.trig(mouseEvs.pitch, e);

                        (mouse.vector > 0) && broadcast.trig(mouseEvs.pitchin, e);
                        (mouse.vector < 0) && broadcast.trig(mouseEvs.pitchout, e);
                    } else {
                        // move action

                        stopPitch(e);

                    }

                    // always fire move event, if mouse coordinates was changed
                    broadcast.trig(mouseEvs.tapmove, e);

                    firstMove = true;
                }
            }
        } else {
            if (e.clientX){
                mouseMoveTmp.x = e.clientX;
                mouseMoveTmp.y = e.clientY;
                updateObjectByEvent(mouse, mouseMoveTmp);
            }
        }
        broadcast.trig(mouseEvs.move, e);
        preventEvent(e);
    }


    // stop catching DOM events
    function stop() {
        mouse.down = false;
        mouse.x = 0;
        mouse.y = 0;
        mouse.vector = 0;

        pContainer.removeEventListener(utils.t_start_ev, hStart, false);
        pContainer.removeEventListener(utils.t_end_ev, hEnd, false);
        pContainer.removeEventListener(utils.t_move_ev, hMove, false);

        pContainer.removeEventListener(utils.start_ev, hStart, false);
        pContainer.removeEventListener(utils.end_ev, hEnd, false);
        pContainer.removeEventListener(utils.move_ev, hMove, false);
        pContainer.removeEventListener(utils.leave_ev, hLeave, false);

        pContainer.removeEventListener(utils.t_cancel_ev, hEnd, false);
        pContainer.removeEventListener(utils.t_leave_ev, hEnd, false);

        pContainer = null;
    }

    // when system starting, calling init()
    // add events to DOM element and processing mouse module events
    function init(){
        pContainer = engineDom.getBaseElement();

        updateBound();

        pContainer.addEventListener(utils.t_start_ev, hStart, false);
        pContainer.addEventListener(utils.t_end_ev, hEnd, false);
        pContainer.addEventListener(utils.t_move_ev, hMove, false);

        pContainer.addEventListener(utils.t_cancel_ev, hEnd, false);
        pContainer.addEventListener(utils.t_leave_ev, hEnd, false);

        pContainer.addEventListener(utils.leave_ev, hLeave, false);
        pContainer.addEventListener(utils.start_ev, hStart, false);
        pContainer.addEventListener(utils.end_ev, hEnd, false);
        pContainer.addEventListener(utils.move_ev, hMove, false);
    }

    // update object by
    //
    // obj - object structure, like mouse or pitch
    function updateObjectByEvent(obj, item){
        obj.x = Math.floor(item.x - mouse.containerLeft);
        obj.y = Math.floor(item.y - mouse.containerTop);
    }

    function isTargetEqual(){
        var res = true;
        var target;
        for (var key in clickers){
            if (!target){
                target = clickers[key].target;
            } else {
                res = (target == clickers[key].target);
                if (!res){
                    break;
                }
            }
        }
        return res;
    }

    function createClicker(id, e){
        var type = id[0];

        var item = {
            type: type,
            target: e.target,
            id: id,
            x: 0,
            y: 0
        };

        if (!clickers[id]){
            clickersArr.push(item);
            clickersLen = clickersArr.length;
        }

        clickers[id] = item;

        updateClicker(e);

        return item;
    }

    function updateClicker(e){

        var type = e.type;
        var touchId;
        var item;
        var touch;
        if (touchMap[type]){
            // process tap click
            var changedTouches = e.changedTouches;
            if (changedTouches){
                // default touches support
                for (var i = 0, l = changedTouches.length; i < l; i++){
                    touch = changedTouches[i];
                    touchId = C_TOUCH_NORM + touch.identifier;
                    if (clickers[touchId]){
                        item = clickers[touchId];
                        item.x = touch.pageX - getWindowScrollX();
                        item.y = touch.pageY - getWindowScrollY();
                    }
                }
            } else {
                // ms touch support
                touchId = C_TOUCH_MS + getMsIdentifier(e);
                if (clickers[touchId]){
                    touch = e;
                    item = clickers[touchId];
                    item.x = touch.pageX - getWindowScrollX();
                    item.y = touch.pageY - getWindowScrollY();
                }
            }
        } else {
            // mouse click
            touchId = C_TOUCH_MOUSE;
            if (clickers[touchId]){
                touch = e;
                item = clickers[touchId];
                item.x = touch.clientX;
                item.y = touch.clientY;
            }
        }

    }

    function getClickerId(e){
        clickerBuffArr.length = 0;
        var type = e.type;
        var touchId;
        mouse.isTouch = false;
        if (touchMap[type]){
            // process tap click
            var changedTouches = e.changedTouches;
            if (changedTouches){
                for (var i = 0, l = changedTouches.length; i < l; i++){
                    var touch = changedTouches[i];
                    touchId = touch ? C_TOUCH_NORM + touch.identifier : '';
                    if (touchId){
                        clickerBuffArr.push(touchId);
                    }
                }
            } else {
                touchId = C_TOUCH_MS + getMsIdentifier(e);
                clickerBuffArr.push(touchId);
            }
            mouse.isTouch = true;
        } else {
            // process mouse click
            touchId = C_TOUCH_MOUSE;

            if (touchSupport && touchId == C_TOUCH_MOUSE){
                // try to find touch by mouse clicker
                if (lastClicker.x && lastClicker.type != C_TOUCH_MOUSE) {
                    if ((Math.abs(lastClicker.x - e.clientX) < THRESHOLD_CLICKER) ||
                        (Math.abs(lastClicker.y - e.clientY) < THRESHOLD_CLICKER)){
                        touchId = '';
                    }
                }
            }
            touchId && clickerBuffArr.push(touchId);

        }

        return clickerBuffArr;
    }

    function getMsIdentifier(e){
        return (typeof e.identifier !== 'undefined') ? e.identifier : (typeof e.pointerId !== 'undefined') ? e.pointerId : 'x1';
    }

    //initialize mouse module, when system starts or stops
    utils.bindStartStop(init, stop);


    //register mouse module
    subPixel('mouse', mouse);

})(subPixel);
