/*
 * Project:   PlotPad HTML5 Viewer
 * File:      guid.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 06/23/2014
 */
(function(subPixel){
    var utils = subPixel('utils');
//
//    //helper for generate guids
//    function fourChars() {
//        return Math.floor(
//            Math.random() * 0x10000 /* 65536 */
//        ).toString(16);
//    }
//
//    // generate guid for some reasons
//    utils.guid = function (num) {
//        var today = (new Date()).getTime().toString(16);
//
//        if (num) {
//            var ret = fourChars();
//            for (var i = 0; i < (num); i++) {
//                ret += '-' + fourChars();
//            }
//            return (ret + '');
//        } else {
//            // return as "8x-8x-7x" (x - max chars)
//            return (
//                fourChars() + fourChars() + fourChars() + '-' +
//                    fourChars() + fourChars() + fourChars() + '-' +
//                    today
//                );
//        }
//    };

    var machineId = utils.localStorage('machineId');
    if (!machineId){
        machineId = (Math.abs(Math.random()*0xff|0)).toString(16);
        utils.localStorage('machineId', machineId);
    }

    var lut = []; for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
    var guid = function(){
        var timeObj = (new Date).getTime();
        var s = Math.floor((timeObj) / 1000);
        var ms = timeObj - s * 1000;

        var d1 = ms + Math.random()*0xffff|0;
        var d2 = Math.random()*0xffffffff|0;
        var d3 = machineId; //Math.random()*0xffffffff|0;
        var d4 = s.toString(16);
        return d4 +
        lut[d3&0xff] +
        lut[d1&0xff] + lut[d1>>8&0xff] + lut[d1>>16&0x0f|0x40]+
        lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+lut[d2>>16&0xff]+lut[d2>>24&0xff];

    };

/*
    var err = {}; var map = {};
    var MAX = 10000000;

    for (var i = 0; i < MAX; i++){
        var id = utils.guid();
        if (!map[id]){
            map[id] = 1;
        } else {
            if (!err[id]){
               err[id] = 1;
            } else {
               err[id]++;
            }
        }
    }
    console.log(err);
*/

    /*

     return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
     lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
     lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
     lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff]+'-'+
     lut[d4&0xff]+lut[d4>>8&0xff]+lut[d4>>16&0xff]+lut[d4>>24&0xff];

      */
    utils.extend(utils, {
        guid: guid
    });


})(subPixel);
