import "./styles/cafm.css";
import "./styles/sub-fonts.css";
import "./styles/subpixel-canvas.css";
import "./styles/subpixel-editor.css";
import "./styles/subpixel-select-bar.css";
import "./styles/subpixel-zoom-container.css";

import "./subPixel/core/main.js";

import "./subPixel/core/config.js";
import "./subPixel/core/broadcast.js";
import "./subPixel/core/config-blocks.js";
import "./subPixel/core/utils/codes.js";
import "./subPixel/core/utils/time-interval.js";
import "./subPixel/core/utils/logger.js";
import "./subPixel/core/utils/utils.js";
import "./subPixel/core/utils/time-info.js";
import "./subPixel/core/utils/animate-ctrl.js";
import "./subPixel/core/utils/holdover.js";
import "./subPixel/core/utils/localStorage.js";
import "./subPixel/core/utils/guid.js";
import "./subPixel/core/utils/utils-time.js";
import "./subPixel/core/utils/sorting.js";
import "./subPixel/core/utils/dom-helper.js";

import "./subPixel/core/engine-dom.js";
import "./subPixel/core/engine.js";

import "./subPixel/core/history.js";
import "./subPixel/core/portion.js";
import "./subPixel/core/mouse/mouse.js";
import "./subPixel/core/mouse/mouse-wheel.js";
import "./subPixel/core/transformer/scale.js";
import "./subPixel/core/transformer/offset.js";
import "./subPixel/core/transformer/rotate.js";
import "./subPixel/core/canvas/canvas-helper.js";
import "./subPixel/core/canvas/canvas-el.js";
import "./subPixel/core/canvas/canvas.js";

import "./subPixel/core/managers/hover-manager.js";
import "./subPixel/core/managers/lm.js";
import "./subPixel/core/managers/select-manager.js";
import "./subPixel/core/scenario-ctrl.js";
import "./subPixel/core/designer/move-ctrl.js";
import "./subPixel/core/designer/elements-ctrl.js";
import "./subPixel/core/designer/dom-canvas-ctrl.js";
import "./subPixel/core/designer/drawing-ctrl.js";

import "./subPixel/core/interface.js";
import "./subPixel/core/build-version.js";

import "./subPixel/core/batch/batch.js";
import "./subPixel/core/batch/cmd.js";

import "./subPixel/core/parts.js";
import "./subPixel/parts/helpers/draw-options-helper.js";
import "./subPixel/parts/helpers/selection-helper.js";
import "./subPixel/parts/helpers/crossing-helper.js";
import "./subPixel/parts/helpers/crossing-types/crossing-type-in-sel-box.js";
import "./subPixel/parts/helpers/crossing-types/crossing-type-near-point.js";
import "./subPixel/parts/helpers/nearest-helper.js";

import "./subPixel/parts/base.js";
import "./subPixel/parts/rect.js";
import "./subPixel/parts/shape.js";
import "./subPixel/parts/figure.js";
import "./subPixel/parts/text.js";
import "./subPixel/parts/image.js";

import "./subPixel/core/models.js";

import "./subPixel/core/interface/helpers.js";
import "./subPixel/core/interface/transformer.js";
import "./subPixel/core/interface/elements.js";
import "./subPixel/core/interface/events.js";
import "./subPixel/core/interface/history.js";

import "./subPixel/plugins/keyboard.js";
import "./subPixel/plugins/zoom-window.js";

import "./subPixel/plugins/cache-module.js";
import "./subPixel/plugins/image-loader.js";
import "./subPixel/plugins/requester.js";
import "./subPixel/plugins/sheet-loader/sheet-requester.js";
import "./subPixel/plugins/sheet-loader/chunk-manager.js";
import "./subPixel/plugins/sheet-loader/sheet-loader.js";
import "./subPixel/plugins/tooltip.js";
import "./subPixel/plugins/sheet-loader/sheet-load-indicator.js";

import "./subPixel/plugins/prompt-event-sender.js";

import "./subPixel/plugins/editor/editor.js";
import "./subPixel/plugins/editor/magnito.js";
import "./subPixel/plugins/editor/elements.js";
import "./subPixel/plugins/editor/area.js";
import "./subPixel/plugins/editor/styles.js";

import "./subPixel/plugins/editor/editing-modes/modes.js";
import "./subPixel/plugins/editor/editing-modes/base.js";
import "./subPixel/plugins/editor/editing-modes/box-mode.js";
import "./subPixel/plugins/editor/editing-modes/polygon-mode.js";

import "./subPixel/plugins/editor/group-editing.js";
import "./subPixel/plugins/editor/tools.js";
import "./subPixel/plugins/editor/tool-containers/base.js";
import "./subPixel/plugins/editor/tool-containers/toolbox.js";
import "./subPixel/plugins/editor/tool-containers/zoom-container.js";

import "./subPixel/plugins/editor/tools-scenarios/scenarios.js";
import "./subPixel/plugins/editor/tools-scenarios/base.js";
import "./subPixel/plugins/editor/tools-scenarios/second-click.js";

import "./subPixel/plugins/editor/tools/base.js";
import "./subPixel/plugins/editor/tools/base-item-tool.js";
import "./subPixel/plugins/editor/tools/base-group.js";
import "./subPixel/plugins/editor/tools/select.js";
import "./subPixel/plugins/editor/tools/move.js";
import "./subPixel/plugins/editor/tools/scale.js";
import "./subPixel/plugins/editor/tools/size.js";
import "./subPixel/plugins/editor/tools/zoom-to.js";
import "./subPixel/plugins/editor/tools/group-editing.js";

import "./subPixel/plugins/annotations/anno-const.js";
import "./subPixel/plugins/annotations/measure.js";

import "./subPixel/plugins/annotations/tools/anno-base-chunk.js";
import "./subPixel/plugins/annotations/tools/anno-base.js";
import "./subPixel/plugins/annotations/tools/group-anno.js";
import "./subPixel/plugins/annotations/tools/tools-helper.js";
import "./subPixel/plugins/annotations/tools/arrow-chunk.js";
import "./subPixel/plugins/annotations/tools/arrow.js";
import "./subPixel/plugins/annotations/tools/box-chunk.js";
import "./subPixel/plugins/annotations/tools/box.js";
import "./subPixel/plugins/annotations/tools/circle-chunk.js";
import "./subPixel/plugins/annotations/tools/circle.js";
import "./subPixel/plugins/annotations/tools/cloud-chunk.js";
import "./subPixel/plugins/annotations/tools/cloud.js";
import "./subPixel/plugins/annotations/tools/cross-chunk.js";
import "./subPixel/plugins/annotations/tools/cross.js";
// import "./subPixel/plugins/annotations/tools/group-measure.js";
import "./subPixel/plugins/annotations/tools/line-chunk.js";
import "./subPixel/plugins/annotations/tools/line.js";
import "./subPixel/plugins/annotations/tools/pen-chunk.js";
import "./subPixel/plugins/annotations/tools/pen.js";
import "./subPixel/plugins/annotations/tools/text-chunk.js";
import "./subPixel/plugins/annotations/tools/text.js";

import "./subPixel/plugins/annotations/tools/polygon-chunk.js";
import "./subPixel/plugins/annotations/tools/polygon.js";
import "./subPixel/plugins/annotations/tools/image-chunk.js";
import "./subPixel/plugins/annotations/tools/image.js";

import "./cafm/main.js";
import "./cafm/subpixel-modules/object/theming/theme.js";
import "./cafm/subpixel-modules/object/theming/theme-as-method.js";
import "./cafm/subpixel-modules/object/theming/theme-patterns-create.js";
import "./cafm/subpixel-modules/object/theming/theme-patterns-fill.js";
import "./cafm/subpixel-modules/object/theming/theme-class.js";
import "./cafm/subpixel-modules/object/theming/theme-manager.js";
import "./cafm/subpixel-modules/object/theming/object-theme-draw.js";

import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.brick.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.brickSlant.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.checkerboard.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.diamond.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.dots.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.herringbone.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.horizontal.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.horizontalDash.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.leftSlant.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.net.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.netSlant.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.rightSlant.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.slantLeftDash.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.slantRightDash.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.vertical.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.verticalDash.js";
import "./cafm/subpixel-modules/object/theming/themes-as-method/theme.wave.js";

import "./cafm/subpixel-modules/object/object.js";
import "./cafm/subpixel-modules/object/label/object-label.js";
import "./cafm/subpixel-modules/object/text-group.js";

import "./cafm/helpers.js";
import "./cafm/events.js";
import "./cafm/cursor-mode.js";
import "./cafm/layers.js";
import "./cafm/plans.js";
import "./cafm/objects.js";
import "./cafm/select.js";
import "./cafm/zoom.js";
import "./cafm/theme.js";
import "./cafm/labels.js";
import "./cafm/shapes.js";

import "./cafm/to-object-ns.js";

import "./cafm/batch/batch-manager.js";
