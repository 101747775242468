/*
 * Project:   PlotPad HTML5 Viewer
 * File:      circle-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var crossingHelper = parts('crossing-helper');

    var f360 = 2 * Math.PI;

    function updateBbox(inst, points, r){
        var nPoints = [];
        var point = points[0];// it's center point

        nPoints.push({x: point.x + r, y: point.y + r});
        nPoints.push({x: point.x - r, y: point.y - r});

        inst.initBoundingBox(nPoints);
    }

    var figure = utils.createChildClass(AnnoBaseClass, 'circleChunkClass');

    figure.drawCircle = function(ctx, points, r){
        var cx = points[0].x;
        var cy = points[0].y;

        ctx.beginPath();
        ctx.arc(cx, cy, r, f360, false);
        ctx.stroke();
        ctx.closePath();
    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        // for figure from parameters getting for now only 'color' and 'filled' value
        var drawParams = this.drawParams;
        var points = data.points;

        this.initAnnoDrawParams(data);

        var dx = (points[1].x - points[0].x);
        var dy = (points[1].y - points[0].y);

        var r = Math.sqrt(dx * dx + dy * dy);

        drawParams.radius = r;

        updateBbox(this, points, r);

        this.propPointsType(crossingHelper.TYPE_CIRCLE);
    };

    p.getRadius = function(){
        return this.drawParams.radius;
    };

    p.getInternalRadius = function () {
        return this.drawParams.radius;
    };

    p.getCenterPoint = function(){
        return this.data().points[0];
    };

    p.getSubPoint = function(){
        return this.data().points[1];
    };

    p.canDraw = function(){
        var ret = AnnoBaseClass._parent.canDraw.apply(this, arguments);
        ret = ret && !this.isAccessEdit();
        return ret;
    };

    p.drawEditing = function(ctx, x, y, w, h){
        var points = this.data().points;
        var halfW = w / 2;

        points[0] = { x: x + halfW, y: y + halfW };
        var drawParams = this.drawParams;

        drawParams.radius = halfW;

        this.setAreaCtx(ctx);
        figure.drawCircle(ctx, points, drawParams.radius);
    };

    p.setDimensions = function(newX, newY, newW, newH){
        var drawParams = this.drawParams;

        var ctx = canvas.buffCtx;
        var points = this.data().points;

        var halfH = newW/2;

        points[0] = { x: newX + halfH/scale.val, y: newY + halfH/scale.val };
        points[1] = { x: newX + newW/scale.val, y: newY + newH/scale.val };

        var p1 = points[0];
        var p2 = points[1];

        var sPoints = [];
        sPoints[0] = {
            x: p1.x,
            y: p1.y
        };

        sPoints[1] = {
            x: p2.x,
            y: p2.y
        };

        sPoints[2] = {
            x: p1.x,
            y: p2.y
        };

        sPoints[3] = {
            x: p2.x,
            y: p1.y
        };

        drawParams.radius = halfH/scale.val;

        updateBbox(this, sPoints, drawParams.radius);

        figure.drawCircle(ctx, sPoints, drawParams.radius);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var drawParams = this.drawParams;
        var points = this.data().points;
        var bbox = this.getBoundingBox();

        var halfW = bbox.w / 2;
        points[0] = { x: bbox.minx + halfW, y: bbox.miny + halfW };

        drawParams.radius = halfW;

        this.setAnnoDrawStyles(opt);
        figure.drawCircle(ctx, points, drawParams.radius);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-circle', figure);


})(subPixel);
