/*
 * Project:   PlotPad HTML5 Viewer
 * File:      scale.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/11/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var scaleEvs = broadcast.events('scale');
    var scale = subPixel('scale');

    var scenarioCtrl = subPixel('scenario-ctrl');
    var domHelper = subPixel('dom-helper');


    var base = tools('base-tool');

    var container = document.createElement('div');
    var overflowEl = document.createElement('div');
    overflowEl.className = 'tool-scales-overflow tools-overflow';
    container.appendChild(overflowEl);
    var DOMel = document.createElement('div');
    var indicatorEl = document.createElement('div');
    indicatorEl.className = 'indicator';
    DOMel.appendChild(indicatorEl);

    domHelper.moveToFragment(container);
    domHelper.moveToFragment(DOMel);
    container.className = 'tool-el-scales tool-el-container';


    var values = [50, 100, 150, 200, 'Fit'];


    function createValues(){
        for (var i = 0, l = values.length; i < l; i++){
            var el = document.createElement('div');
            el.className = 'item';
            var item = values[i];
            el.innerHTML = item + ((typeof item != "string") ? ('%') : '');
            el.setAttribute('data-scale', item);
            container.appendChild(el);
        }
    }

    function updateScaleIndicator(){
        indicatorEl.innerHTML = scale.getPercentValue() + '%';
    }

    createValues();

    var scaleTool = utils.createChildClass(base, 'scaleTool');

    var p = scaleTool.prototype;

    p.onToolSelect = function(){
        domHelper.show(container);
    };

    p.onToolDeselect = function(){
        domHelper.hide(container);
    };

    p.onToolAdded = function(){
        this.getEl().appendChild(container);
    };

    p.onClick = function(e){
        var target = domHelper.closest(e.target, 'item');
        var scaleVal = target && target.getAttribute('data-scale');
        if (scaleVal){
            if (typeof scaleVal =="string" && scaleVal.toLowerCase() == 'fit'){
                subPixel.fitToScreen();
            } else {
                subPixel.zoom(scaleVal);
            }
        }
    };

    tools(scaleTool, {
        mode: scenarioCtrl.view,
        zoomWindow: false,
        name: 'scaleTool',
        title: 'scaleTool',
        el: DOMel,
        text: 'scale'
    });

    function processBroadcast(action){
        broadcast
            [action](scaleEvs.onUpdated, updateScaleIndicator);
    }

    function start(){
        updateScaleIndicator();
        processBroadcast('on');
    }

    function stop(){
        processBroadcast('off');
//        fragment.appendChild(container);
    }

    utils.bindStartStop(start, stop);

})(subPixel);
