/*
 * Project:   PlotPad HTML5 Viewer
 * File:      text-group.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/06/2016
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var figure = parts('figure');
    var utils = subPixel('utils');
    var base = parts('base');
    var textFigure = parts('text');
    var scale = subPixel('scale');
    var sheetLoader = subPixel('sheet-loader');
    var chunkManager = subPixel('chunk-manager');
    var drawOptHelper = parts('draw-options-helper');
    var crossingHelper = parts('crossing-helper');

    var drawOptObj = drawOptHelper();
    var G_NAME = chunkManager.TYPE_OBJECT;
    var SCALE_FONT_K = 3025;//don't know why value is correct, maybe migrated from flex.


    var defObject = utils.createChildClass(figure, 'defObjectClass', {
        init: function(data){

            var figId = data.toFigId;
            var fig = subPixel.getElementById(figId);
            this.props.hover = false;
            this.props.scaling = true;
            this.propPointsType(crossingHelper.TYPE_BOX);
            this._struct = createStruct(fig, data.data);
            this.initTextGroupParent();
        },
        getObjectId: function(){
            return this.data().toFigId;
        },
        initTextGroupParent: function(){
            var fullStruct = this._struct;
            this.editStruct = utils.clone(fullStruct, true);

            var texts = fullStruct.texts;
            var els = this._els = [];
            var elsMap = this._elsMap = {};
            utils.map(texts, function(item){
                var partName = item.name;
                var fig = parts(partName);
                var newFig = new fig(item);
                els.push(newFig);
                elsMap[newFig.getId()] = newFig;
            });

            defObject._parent.init.call(this, fullStruct);

            var props = this.props;
            props.drawSelection = false;
            props.layerGroup = G_NAME;
            props.editing = true;
        },
        preBoundingInit: function(){
            var points = [];
            utils.map(this._els, function(el){
                var bbox = el.getBoundingBox();
                points.push(
                    {x: bbox.minx, y: bbox.miny},
                    {x: bbox.maxx, y: bbox.maxy}
                )
            });
            return points;
        },
        canDraw: function(){
            var ret = figure._parent.canDraw.apply(this, arguments);
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function(ctx, x, y, w, h){
            var eStruct = this.editStruct;
            var texts = eStruct.texts || '';
            calcPosEditing(eStruct, x, y, w, h);

            drawBackground(ctx, eStruct, x, y, w, h);

            utils.map(this._els, function(el, index){
                var elData = texts[index];
                if (elData){
                    var pos = elData.position;
                    var dim = elData.dimension;
                    el.canDraw() && el.drawEditing(ctx, pos.x, pos.y, dim.w, dim.h, false);
                }
            });
        },
        getStruct: function(){
            return this._struct;
        },
        setDimensions: function(x, y, w, h){
            var struct = this._struct;
            calcPosEndEditing.call(this, struct, x, y, w, h);
        },
        draw: function(){
            var ctx = canvas.buffCtx;
            var data = this._struct;

            // bbox view
            //ctx.beginPath();
            //ctx.lineWidth = scale.lineWidth;
            //var bbox = this.getBoundingBox();
            //var x = bbox.minx;
            //var y = bbox.miny;
            //var th = bbox.h;
            //var tw = bbox.w;
            //ctx.rect(x, y, tw, th);
            //ctx.fillStyle = 'rgba(0,0,0, 0.5)';
            //ctx.fill();
            //ctx.closePath();

            // center point
            //ctx.beginPath();
            //ctx.lineWidth = scale.val * 10;
            //ctx.fillStyle = 'yellow';
            //ctx.strokeStyle = 'yellow';
            //x = data.pos.x;
            //y = data.pos.y;
            //ctx.rect(x, y, 10, 10);
            //ctx.fill();
            //ctx.closePath();
            var bbox = this.getBoundingBox();
            drawBackground(ctx, data, bbox.minx, bbox.miny, bbox.w, bbox.h);

            utils.map(this._els, function(el){
                el.canDraw() && el.draw(drawOptObj);
            });
        }
    });

    function drawBackground(ctx, struct, x, y, w, h) {
        ctx.globalAlpha = struct.opacity / 100;
        ctx.beginPath();
        ctx.fillStyle = struct.background;
        ctx.rect(x, y, w, h);
        ctx.fill();
        ctx.closePath();
        ctx.globalAlpha = 1;
    }

    function calcPosEndEditing(struct, newX, newY, newW, newH){
        var screenBbox = this.getScreenBounds();
        var map = this._elsMap;

        var halhH = newH / 2;
        var halhW = newW / 2;
        var centerX = newX + halhW;
        var centerY = newY + halhH;
        var posX = centerX;
        var posY = centerY;
        var texts = struct.texts;
        if (utils.isArray(texts)){

            var kh = newH / screenBbox.h;
            var kw = newW / screenBbox.w;
            var dy = 0;
            // calculate positions for each line
            utils.map(texts, function(initData){
                var el = map[initData.id];
                var scBox = el.getScreenBounds();

                var elw = scBox.w * kw;
                var elh = scBox.h * kh;

                var elx = newX + (newW / scale.val - elw / scale.val) / 2;
                var ely = newY + dy;

                el.setDimensions(elx, ely, elw, elh);
                //el.setDimensions(newX, newY, newW, newH);
                dy += (elh / scale.val);
            })


        }

        this.initTextGroupParent();

        calculateGroupProps.call(this, struct, kw);
    }

    function calculateGroupProps(struct, kw){
        //calculate other props
        struct.scale = struct.scale * kw;

        var figId = this.data().toFigId;
        var fig = subPixel.getElementById(figId);

        var bbox = fig.getBoundingBox();

        var startX = bbox.minx + bbox.w / 2;
        var startY = bbox.miny + bbox.h / 2;

        var thisBbox = this.getBoundingBox();

        var dx = thisBbox.minx + thisBbox.w / 2;
        var dy = thisBbox.miny + thisBbox.h / 2;



        struct.pos.x = dx - startX;
        struct.pos.y = dy - startY;
    }

    function calcPosEditing(struct, newX, newY, newW, newH){
        var halhH = newH / 2;
        var halhW = newW / 2;
        var centerX = newX + halhW;
        var centerY = newY + halhH;
        var posX = centerX;
        var posY = centerY;
        var texts = struct.texts;
        if (utils.isArray(texts)){

            var totalH = 0;
            var totalW = 0;
            utils.map(texts, function(initData){
                var h = initData.dimension.h;
                totalH += h;
                totalW = Math.max(totalW, initData.dimension.w);
            });
            //
            var kh = newH / totalH;
            var kw = newW / totalW;
            var startY = centerY - (newH / 2);
            // calculate positions for each line
            utils.map(texts, function(initData){
                var elPos = initData.position;
                var dim = initData.dimension;
                dim.h = dim.h * kh;
                dim.w = dim.w * kw;
                elPos.x = posX;
                elPos.y = startY;
                startY += dim.h;
            })
        }

        //struct.pos.x = posX;
        //struct.pos.y = posY;
    }

    function createStruct(assignFig, item){
        var texts = [];
        var lines = item.Lines;
        var figBbox = assignFig.getBoundingBox();
        var labelPos = assignFig.labelPosition();
        var w = figBbox.w;
        var h = figBbox.h;
        var halfW = w / 2;
        var halfH = h / 2;
        var totalTextHeight = 0;

        var topx = (labelPos.x === undefined) ? figBbox.minx : (labelPos.x - halfW);
        var topy = (labelPos.y === undefined) ? figBbox.miny : (labelPos.y - halfH);

        var centerX = topx + halfW;
        var centerY = topy + halfH;
        var pos = {
            x: centerX,
            y: centerY
        };

        var prePosX = 0;
        var prePosY = 0;
        if (item.Position){
            prePosX = item.Position[0];
            prePosY = item.Position[1];
            pos.x += prePosX;
            pos.y += prePosY;
        }
        var scale = item.Scale || 1;
        var background = utils.colorToStr(item.Background) || '#000';
        var opacity = item.BackOpacity || 0;
        if (utils.isArray(lines)){
            var defSizeK = scale;
            var canvW = canvas.bbox.maxx - canvas.bbox.minx;
            var manifest = sheetLoader.getManifestData();
            var k = 1;
            if (manifest && manifest.info){
                k = manifest.info.width / canvW;
            }

            canvW && (defSizeK = (scale * canvW / SCALE_FONT_K) * k);

            utils.map(lines, function(line, index){
                // create structure for text figure
                var initData = createObjectLabel(line, defSizeK);
                texts.push(initData); // data for "object-label" figure property
                //toAddEls.push(initData);

                var bbox = textFigure.getInitDataBbox(initData);
                var bboxH = bbox.h;

                initData.dimension.w = bbox.w;
                initData.dimension.h = bboxH;

                // calculate height of text, for detect correct position of element
                totalTextHeight += bboxH;
            });

            var startY = prePosY + centerY - totalTextHeight/2;
            // calculate positions for each line
            utils.map(texts, function(initData){
                var elPos = initData.position;
                var h = initData.dimension.h;
                elPos.x = pos.x;
                elPos.y = startY;
                startY += h;
            })
        }
        if (item.Position){
            pos.x = prePosX;
            pos.y = prePosY;
        }

        var ret = {
            background: background,
            opacity: opacity,
            pos: pos,
            scale: scale,
            texts: texts
        };
        return ret;
    }

    function createObjectLabel(line, defSizeK){
        var obj = {
            id: utils.guid(),
            name: 'object-label',
            align: 'center',
            baseline: 'top',
            visibleScale: !isNaN(line.VisibleAt) ? line.VisibleAt / 100 : 0,
            position: {
                x: 0,
                y: 0
            },
            dimension:{
                w: 0,
                h: 0
            },
            font: {
                name: line.Font || '',
                size: line.Size * defSizeK || 0,
                color: line.Color !== undefined ? line.Color : ''
            },
            text: line.Text || ''
        };
        return obj;
    }


    parts('text-group', defObject);


})(subPixel);
