/*
 * Project:   PlotPad HTML5 Viewer
 * File:      batch-manager.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/21/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var broadcast = subPixel('broadcast');
    var batchEvs = broadcast.events('batch');
    var batch = subPixel('batch');
    var events = cafm.events;
    var utils = subPixel('utils');
    var holdover = subPixel('holdover');
    
    var MESSAGE_SUCCESS = "The operations batch was executed successfully.";
    var MESSAGE_ERROR = "The operations batch was executed with errors.";

    var STATUS_OK = 'success';
    var STATUS_ERR = 'error';

    var NS = '.cafmBatches';

    function bindEvents() {
        broadcast
            .off(NS)
            .on(batchEvs.cmdExecuted, NS, function (ev) {
                var obj = {
                    methodName: ev.methodName,
                    status: ev.error ? STATUS_ERR : STATUS_OK,
                    args: ev.args,
                    result: ev.result
                };
                events.onCommandExecutionComplete(obj);
            })
            .on(batchEvs.competed, NS, function (ev) {
                var err = ev.error;
                var obj = {
                    status: err ? STATUS_ERR : STATUS_OK,
                    message: err ? MESSAGE_ERROR : MESSAGE_SUCCESS,
                    commandResults: ev.result
                };
                events.onBatchExecutionComplete(obj);
            })
    }

    cafm.wait = function(timeout){
        var holder = holdover(true);
        timeout = timeout || 0;
        setTimeout(function(){
            holder.resolve();
        }, timeout);
        return holder;
    };
    
    cafm.getCurrentBatchCommand = function () {
        return batch.getCurrentCmd();
    };
    
    cafm.ExecuteBatch = function(commands, timeout){
        bindEvents();
        if (typeof commands == 'string'){
            // try to convert from string to object
            try{
                var test = JSON.parse(commands);
                if (utils.isArray(test)){
                    commands = test;
                }
                test = null;
            } catch(e){}
        }

        batch.executeBatch(commands, timeout);
    };

    events.onCommandExecutionComplete = function(){

    };

    events.onBatchExecutionComplete = function(){

    };

    batch.context(cafm);

})(cafm);