/*
 * Project:   PlotPad HTML5 Viewer
 * File:      box-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var crossingHelper = parts('crossing-helper');

    var boxClass = utils.createChildClass(AnnoBaseClass, 'boxChunkClass', {
        init: function(data) {
            // for boxClass from parameters getting for now only 'color' and 'filled' value
            var drawParams = this.drawParams;
            var points = data.points;
            this._struct = this.createStruct();

            this.initAnnoDrawParams(data);

            var p1 = points[0];
            var p2 = points[1];

            var w = p2.x - p1.x;
            var h = p2.y - p1.y;

            drawParams.w = w;
            drawParams.h = h;

            this.props.scaling = false;
            this.updateBoundingBox(points);
            this.propPointsType(crossingHelper.TYPE_BOX);
        },
        drawBox: function(ctx, points, w, h, filled) {
            boxClass.drawBox(ctx, points, w, h, filled);
        },
        getBoxPoints: function() {
            return this.data().points;
        },
        canDraw: function() {
            var ret = AnnoBaseClass._parent.canDraw.apply(this, arguments);
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function(ctx, x, y, w, h) {
            var points = [
                { x: x, y: y },
                { x: x + w, y: y + h }
            ];

            this.setAreaCtx(ctx);
            this.drawBox(ctx, points, w, h);
        },
        setDimensions: function(newX, newY, newW, newH) {
            var drawParams = this.drawParams;

            var ctx = canvas.buffCtx;
            var editedPoints = this.getBoxPoints();

            editedPoints[0] = { x: newX, y: newY};
            editedPoints[1] = { x: newX + newW/scale.val, y: newY + newH/scale.val };

            drawParams.w = newW/scale.val;
            drawParams.h = newH/scale.val;

            this.updateBoundingBox(editedPoints);

            this._struct.points[0] = editedPoints[0];
            this._struct.points[1] = editedPoints[1];

            this.drawBox(ctx, editedPoints, newW/scale.val, newH/scale.val);
        },
        draw: function(opt) {
            var ctx = canvas.buffCtx;
            var drawParams = this.drawParams;
            var points = this.getBoxPoints();

            var filled = !!opt.fillStyle;

            this.setAnnoDrawStyles(opt);
            this.drawBox(ctx, points, drawParams.w, drawParams.h, filled);
        },
        updateBoundingBox: function(points) {
            var p1 = points[0];
            var p2 = points[1];

            var sPoints = [];
            sPoints[0] = {
                x: p1.x,
                y: p1.y
            };

            sPoints[1] = {
                x: p2.x,
                y: p2.y
            };

            sPoints[2] = {
                x: p1.x,
                y: p2.y
            };

            sPoints[3] = {
                x: p2.x,
                y: p1.y
            };

            this.initBoundingBox(sPoints);
        },
        createStruct: function() {
            var data = this.data();
            var bPoints = this.getBoxPoints();
            return {
                points: [
                    bPoints[0],
                    bPoints[1]
                ],
                name: data.name,
                id: data.id,
                styles: data.styles
            };
        },
        getStruct: function() {
            return this._struct;
        },
        objectId: function() {
            return this.data().id;
        }
    });

    boxClass.drawBox = function(ctx, points, w, h, filled){
        var fromx = points[0].x;
        var fromy = points[0].y;

        ctx.beginPath();
        if (filled) {
            ctx.fillRect(fromx, fromy, w, h);
        }
        ctx.strokeRect(fromx, fromy, w, h);
        ctx.stroke();
        ctx.closePath();
    };

    parts('anno-box', boxClass);


})(subPixel);
