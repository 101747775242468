/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme-patterns-create.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/30/2016
 */
(function(subPixel, undefined){
    var themeMod = subPixel('theme-module');
    var themeAsMethod = themeMod('theme-as-method');
    var themeAsImg = themeMod('theme-as-img');

    var utils = subPixel('utils');

    function createPattern(pId, props){
        return themeAsMethod.process(pId, props);
        // return themeAsImg.process(pId, props);
    }


    themeMod('theme-patterns-create', createPattern);

})(subPixel);
