/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.vertical.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('vertical', 20, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(1, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 30);
        ctx.lineTo(1, 30);
        ctx.lineTo(1, 0);
        ctx.moveTo(6, 0);
        ctx.lineTo(5, 0);
        ctx.lineTo(5, 30);
        ctx.lineTo(6, 30);
        ctx.lineTo(6, 0);
        ctx.moveTo(11, 0);
        ctx.lineTo(10, 0);
        ctx.lineTo(10, 30);
        ctx.lineTo(11, 30);
        ctx.lineTo(11, 0);
        ctx.moveTo(16, 0);
        ctx.lineTo(15, 0);
        ctx.lineTo(15, 30);
        ctx.lineTo(16, 30);
        ctx.lineTo(16, 0);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
