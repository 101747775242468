/*
 * Project:   PlotPad HTML5 Viewer
 * File:      figure.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/04/2013
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var BasePartClass = parts('base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var broadcast = subPixel('broadcast');


    var figure = utils.createChildClass(BasePartClass, 'figureClass');

    // var CURSOR_LINE_TO = 'l';
    // var CURSOR_MOVE_TO = 'm';
    // var CURSOR_CURVE_TO = 'c';
    // var CURSOR_CUBIC_CURVE_TO = 'b';
    //
    // var OLD_CURSOR_LINE_TO = 'LINE_TO';
    // var OLD_CURSOR_MOVE_TO = 'MOVE_TO';
    // var OLD_CURSOR_CURVE_TO = 'CURVE_TO';
    // var OLD_CURSOR_CUBIC_CURVE_TO = 'CUBIC_CURVE_TO';

    var CURSOR_LINE_TO = 'LINE_TO';
    var CURSOR_MOVE_TO = 'MOVE_TO';
    var CURSOR_CURVE_TO = 'CURVE_TO';
    var CURSOR_CUBIC_CURVE_TO = 'CUBIC_CURVE_TO';

    var DEF_CLR = 'transparent';

    var p = figure.prototype;

    p.init = function(data){
        // for figure from parameters getting for now only 'color' and 'filled' value
        var parameters = data.parameters || {};
        var drawParams = this.drawParams;

        var color = utils.colorToStr(parameters.color) ;
        if (!color){
            color = DEF_CLR;
        }

        drawParams.fillStyle = color;
        drawParams.filled = parameters.filled;
        drawParams.strokeStyle = color;
        drawParams.lineJoin = parameters['line-join'] || drawParams.lineJoin;

        var points = this.preBoundingInit();
        this.initBoundingBox(points);
    };

    p.preBoundingInit = function(){
        var comps = this.data().components;
        var points = [];
        for (var i = 0, l = comps.length; i < l; i++){
            var comp = comps[i];
            // convertComponentType(comp);

            if (comp.type == CURSOR_MOVE_TO || comp.type == CURSOR_LINE_TO) {
                points.push(comp.point);
            } else if (comp.type == CURSOR_CURVE_TO || comp.type == CURSOR_CUBIC_CURVE_TO) {
                utils.push(points, comp.points);
            }
        }
        return points;
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx,
            drawParams = this.drawParams;

        if (drawParams.fillStyle || drawParams.strokeStyle){
            ctx.beginPath();

            ctx.lineWidth = BasePartClass.getLineWidth(drawParams, opt);

            ctx.lineJoin = BasePartClass.getLineJoin(drawParams, opt);

            this.processFigureComponents(
                ctx.moveTo,
                ctx.lineTo,
                ctx.quadraticCurveTo,
                ctx.bezierCurveTo,
                ctx
            );

            if (BasePartClass.setFillStyle(ctx, drawParams, opt)){
                ctx.fill();
            }

            ctx.strokeStyle = BasePartClass.getStrokeStyle(drawParams, opt);
            ctx.stroke();

            ctx.closePath();
        }
    };

    p.processFigureComponents = function(moveTo, lineTo, curveTo, bezieTo, context){
        var components = this.data().components;
        for (var i = 0, l = components.length; i < l; i++){
            var comp = components[i];
            if (comp.type == CURSOR_MOVE_TO) {
                moveTo.call(context, comp.point.x, comp.point.y);
            }  else if (comp.type == CURSOR_LINE_TO) {
                lineTo.call(context, comp.point.x, comp.point.y);
            } else if (comp.type == CURSOR_CURVE_TO) {
                curveTo.call(context,
                    comp.points[0].x, comp.points[0].y,
                    comp.points[1].x, comp.points[1].y
                );
            } else if (comp.type == CURSOR_CUBIC_CURVE_TO) {
                bezieTo.call(context,
                    comp.points[0].x, comp.points[0].y,
                    comp.points[1].x, comp.points[1].y,
                    comp.points[2].x, comp.points[2].y
                );
            }
        }
    };


    p.processScreenFigureComponents = function(moveTo, lineTo, curveTo, bezieTo, context){
        var components = this.data().components;
        for (var i = 0, l = components.length; i < l; i++){
            var comp = components[i];
            var p, p1, p2;
            if (comp.type == CURSOR_MOVE_TO) {
                p = canvas.getRetransformedPoint(comp.point.x, comp.point.y);
                moveTo.call(context, p.x, p.y);
            }  else if (comp.type == CURSOR_LINE_TO) {
                p = canvas.getRetransformedPoint(comp.point.x, comp.point.y);
                lineTo.call(context, p.x, p.y);
            } else if (comp.type == CURSOR_CURVE_TO) {
                p = canvas.getRetransformedPoint(comp.points[0].x, comp.points[0].y);
                p1 = canvas.getRetransformedPoint(comp.points[1].x, comp.points[1].y);
                curveTo.call(context,
                    p.x, p.y,
                    p1.x, p1.y
                );
            } else if (comp.type == CURSOR_CUBIC_CURVE_TO) {
                p = canvas.getRetransformedPoint(comp.points[0].x, comp.points[0].y);
                p1 = canvas.getRetransformedPoint(comp.points[1].x, comp.points[1].y);
                p2 = canvas.getRetransformedPoint(comp.points[2].x, comp.points[2].y);
                bezieTo.call(context,
                    p.x, p.y,
                    p1.x, p1.y,
                    p2.x, p2.y
                );
            }
            p = null;
            p1 = null;
            p2 = null;
        }
    };

    // function convertComponentType(comp){
    //     if (comp.type == OLD_CURSOR_MOVE_TO){
    //         comp.type = CURSOR_MOVE_TO;
    //     } else if (comp.type == OLD_CURSOR_LINE_TO){
    //         comp.type = CURSOR_LINE_TO;
    //     } else if (comp.type == OLD_CURSOR_CURVE_TO){
    //         comp.type = CURSOR_CURVE_TO;
    //     } else if (comp.type == OLD_CURSOR_CUBIC_CURVE_TO){
    //         comp.type = CURSOR_CUBIC_CURVE_TO;
    //     }
    // }

    parts('figure', figure);


})(subPixel);
