/*
 * Project:   PlotPad HTML5 Viewer
 * File:      portion.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 11/19/2013
 */
/*
 * portion module
 *
 * this is module for call methods not in main callstack (like async).
 * using setTimeout for this
 * */
(function(subPixel, undefined){

    var setTimeout = window.setTimeout;

    var TIMEOUT = 0;

    // processing portions callbacks
    function processPortions(context, start, maxPortions, callback){
        context._timeout(function(){
            var len = context.getLen();
            var isLast = false;
            var endPos = start + maxPortions - 1;
            if (endPos >= len) {
                isLast = true;
                (endPos = len - 1);
            }
            callback(start, endPos);
            start = endPos + 1;
            if (isLast){
                processEnd(context);
            } else {
                processPortions(context, start, maxPortions, callback);
            }
        });
    }

    // process last callback after all portions
    function processEnd(context){
        context._timeout(function(){
            context._lastCallback();
            context.isWorking = false;
        });
    }


    // before process all portions, process first callback
    function processStart(startPos){
        var maxPortions = this._maxPortions;
        var portionCallback = this._processCallback;
        var self = this;
        (!startPos) && (startPos = 0);
        this._timeout(function(){
            self._firstCallback(startPos);
            processPortions(self, startPos, maxPortions, portionCallback);
        });
    }

    var portion = function(params){
        this._maxPortions = params.maxPortions;

        this.isWorking = false;
        this._isFilled = false;

        this._firstCallback = null;
        this._lastCallback = null;
        this._processCallback = null;
        return this;
    };

    var p = portion.prototype;

    // internal method for create new function in js stack
    p._timeout = function(callback){
        this._timerId = setTimeout(function(){
            callback();
        }, TIMEOUT);
    };

    // start processing portions
    p.start = function(pos){
        if (this._isFilled){
            this.stop();
            this.isWorking = true;
            processStart.call(this, pos);
        }
        return this;
    };

    // stop processing
    // stopped only between portions. can't stop when single portion is working
    p.stop = function(){
        if (this._isFilled){
            clearTimeout(this._timerId);
            this._timerId = null;
            this.isWorking = false;
            this.onStopCallback && this.onStopCallback();
        }
        return this;
    };

    // set first callback
    p.pushFirst = function(callback){
        this._isFilled = true;
        this._firstCallback = callback;
        return this;
    };

    // set last callback
    p.pushLast = function(callback){
        this._isFilled = true;
        this._lastCallback = callback;
        return this;
    };

    // method for getting actual len of all elements for generate correct portions
    p.getLen = function(){
        return 0; // as default
    };

    // when processing started, need bind for actual len of elements
    p.onGetLen = function(getLenCallback){
        this.getLen = getLenCallback;
        return this;
    };

    p.onStop = function(onStopCallback){
        this.onStopCallback = onStopCallback;
    };

    // set processing portion callback
    p.pushProcess = function(callback){
        this._isFilled = true;
        this._processCallback = callback;
        return this;
    };

    // remove all and clean portion object
    p.clean = function(){
        this.stop();
        this._firstCallback = null;
        this._lastCallback = null;
        this._processCallback = null;
        this._isFilled = false;
        this.getLen = function(){return 0;};
        return this;
    };

    portion.setTimeout = function (val) {
        TIMEOUT = val;
    };

    subPixel('portion', portion);


})(subPixel);
