/*
 * Project:   PlotPad HTML5 Viewer
 * File:      scenarios.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/16/2014
 */
(function(subPixel){
    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var logger = subPixel('logger')('scenarios');

    var scenarios = {};

    function toolsScenarios(name, val){
        var ret;
        if (val){
            // setter
            if (scenarios[name]){
                // defined
                logger.error('trying to register defined tool scenario', name);
            } else {
                scenarios[name] = val;
            }
        } else {
            ret = scenarios[name];
            if (!ret){
                logger.error('trying to get not defined tool scenario', name);
            }
        }

        return ret;
    }

    editor('tools-scenarios', toolsScenarios);

})(subPixel);
