/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.horizontalDash.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('horizontalDash', 34, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(23, 26);
        ctx.lineTo(30, 26);
        ctx.lineTo(30, 25);
        ctx.lineTo(23, 25);
        ctx.lineTo(23, 26);
        ctx.moveTo(0, 25);
        ctx.lineTo(0, 26);
        ctx.lineTo(2, 26);
        ctx.lineTo(2, 25);
        ctx.lineTo(0, 25);
        ctx.moveTo(13, 26);
        ctx.lineTo(13, 25);
        ctx.lineTo(6, 25);
        ctx.lineTo(6, 26);
        ctx.lineTo(13, 26);
        ctx.moveTo(17, 25);
        ctx.lineTo(17, 26);
        ctx.lineTo(19, 26);
        ctx.lineTo(19, 25);
        ctx.lineTo(17, 25);
        ctx.moveTo(13, 20);
        ctx.lineTo(11, 20);
        ctx.lineTo(11, 21);
        ctx.lineTo(13, 21);
        ctx.lineTo(13, 20);
        ctx.moveTo(19, 15);
        ctx.lineTo(17, 15);
        ctx.lineTo(17, 16);
        ctx.lineTo(19, 16);
        ctx.lineTo(19, 15);
        ctx.moveTo(0, 20);
        ctx.lineTo(0, 21);
        ctx.lineTo(7, 21);
        ctx.lineTo(7, 20);
        ctx.lineTo(0, 20);
        ctx.moveTo(2, 16);
        ctx.lineTo(2, 15);
        ctx.lineTo(0, 15);
        ctx.lineTo(0, 16);
        ctx.lineTo(2, 16);
        ctx.moveTo(6, 15);
        ctx.lineTo(6, 16);
        ctx.lineTo(13, 16);
        ctx.lineTo(13, 15);
        ctx.lineTo(6, 15);
        ctx.moveTo(0, 10);
        ctx.lineTo(0, 11);
        ctx.lineTo(7, 11);
        ctx.lineTo(7, 10);
        ctx.lineTo(0, 10);
        ctx.moveTo(2, 6);
        ctx.lineTo(2, 5);
        ctx.lineTo(0, 5);
        ctx.lineTo(0, 6);
        ctx.lineTo(2, 6);
        ctx.moveTo(7, 1);
        ctx.lineTo(7, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 1);
        ctx.lineTo(7, 1);
        ctx.moveTo(11, 10);
        ctx.lineTo(11, 11);
        ctx.lineTo(13, 11);
        ctx.lineTo(13, 10);
        ctx.lineTo(11, 10);
        ctx.moveTo(13, 0);
        ctx.lineTo(11, 0);
        ctx.lineTo(11, 1);
        ctx.lineTo(13, 1);
        ctx.lineTo(13, 0);
        ctx.moveTo(13, 6);
        ctx.lineTo(13, 5);
        ctx.lineTo(6, 5);
        ctx.lineTo(6, 6);
        ctx.lineTo(13, 6);
        ctx.moveTo(17, 5);
        ctx.lineTo(17, 6);
        ctx.lineTo(19, 6);
        ctx.lineTo(19, 5);
        ctx.lineTo(17, 5);
        ctx.moveTo(30, 21);
        ctx.lineTo(30, 20);
        ctx.lineTo(28, 20);
        ctx.lineTo(28, 21);
        ctx.lineTo(30, 21);
        ctx.moveTo(24, 21);
        ctx.lineTo(24, 20);
        ctx.lineTo(17, 20);
        ctx.lineTo(17, 21);
        ctx.lineTo(24, 21);
        ctx.moveTo(30, 16);
        ctx.lineTo(30, 15);
        ctx.lineTo(23, 15);
        ctx.lineTo(23, 16);
        ctx.lineTo(30, 16);
        ctx.moveTo(30, 11);
        ctx.lineTo(30, 10);
        ctx.lineTo(28, 10);
        ctx.lineTo(28, 11);
        ctx.lineTo(30, 11);
        ctx.moveTo(24, 11);
        ctx.lineTo(24, 10);
        ctx.lineTo(17, 10);
        ctx.lineTo(17, 11);
        ctx.lineTo(24, 11);
        ctx.moveTo(24, 1);
        ctx.lineTo(24, 0);
        ctx.lineTo(17, 0);
        ctx.lineTo(17, 1);
        ctx.lineTo(24, 1);
        ctx.moveTo(30, 6);
        ctx.lineTo(30, 5);
        ctx.lineTo(23, 5);
        ctx.lineTo(23, 6);
        ctx.lineTo(30, 6);
        ctx.moveTo(30, 0);
        ctx.lineTo(28, 0);
        ctx.lineTo(28, 1);
        ctx.lineTo(30, 1);
        ctx.lineTo(30, 0);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
