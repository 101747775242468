/*
 * Project:   PlotPad HTML5 Viewer
 * File:      pen.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoPen = parts('anno-pen');


    var base = tools('anno-base');

    var penClass = utils.createChildClass(base, 'penClass');

    var p = penClass.prototype;

    p.onMouseStart = function(){
        penClass._parent.onMouseStart.call(this);

        var point = { x: mouse.x, y: mouse.y };
        this.points.push(point);
    };


    p.onToolDrawing = function () {
        area.clear();
        var ctx = this.ctx;

        annoPen.drawPen(ctx, this.points, true);
    };

    p.onMouseMove = function(){
        var points = this.points;

        var point = {x: mouse.x, y: mouse.y};
        points.push(point);
    };

    p.onMouseEnd = function(){
        this.ctx.closePath();
        penClass._parent.onMouseEnd.call(this);
    };


    base.pushTool(penClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-pen',
        zoomWindow: true,
        title: 'pen',
        text: 'pen'
    });
})(subPixel);
