/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cloud.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/04/2014
 */
(function (subPixel, undefined) {

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var tools = editor('tools');
    var styles = editor('styles');
    var area = editor('area');
    var canvas = subPixel('canvas');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var parts = subPixel('parts');
    var annoCloud = parts('anno-cloud');
    var scale = subPixel('scale');


    var base = tools('anno-base');

    var cloudClass = utils.createChildClass(base, 'cloudClass');

    var p = cloudClass.prototype;

    p.onMouseStart = function(){
        cloudClass._parent.onMouseStart.call(this);

        this.points.push({ x: mouse.x, y: mouse.y});
        this.points.push({ x: mouse.x, y: mouse.y});
    };


    p.onMouseMove = function(){
        area.clear();
        var ctx = this.ctx;
        var points = this.points;

        var pointTwo = points[1];
        pointTwo.x = mouse.x;
        pointTwo.y = mouse.y;
        var w = pointTwo.x - points[0].x;
        var h = pointTwo.y - points[0].y;
        annoCloud.drawCloud(ctx, points, w, h);
    };

    p.onMouseEnd = function () {
        this.points[0].scale = scale.val;
        cloudClass._parent.onMouseEnd.call(this);
    };


    base.pushTool(cloudClass, {
        mode: scenarioCtrl.edit,
        name: 'anno-cloud',
        zoomWindow: true,
        title: 'cloud',
        text: 'cloud'
    });
})(subPixel);
