/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme-class.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function(subPixel, undefined){
    var themeMod = subPixel('theme-module');
    var createPattern = themeMod('theme-patterns-create');
    var themePatternsFill = themeMod('theme-patterns-fill');
    var utils = subPixel('utils');
    var elementsCtrl = subPixel('elements-ctrl');

    function ThemeClass(opt){
        this._destroyed = false;
        var props = this.props = {};
        var patternId = opt.pattern || '';
        props.scale = opt.scale || 1;
        props.foreground = utils.colorToStr(opt.foreground) || 'transparent';
        props.background = utils.colorToStr(opt.background) || 'transparent';
        var objectsIds = props.objects = utils.isArray(opt.objects) ? opt.objects : [];
        var isSingle = !opt.hasOwnProperty('percentage');

        props.percentage = opt.percentage;

        props.pattern = createPattern(patternId, props);

        var self = this;
        utils.map(objectsIds, function(figId){
            var el = elementsCtrl.getElementById(figId);
            if (el){
                if (isSingle){
                    el.themes([self]);
                } else {
                    var oldThemes = el.themes() || [];
                    oldThemes.push(self);
                    el.themes(oldThemes);
                }
            }
        })
    }

    var p = ThemeClass.prototype;

    p.drawTheme = function(translatePos, ctx, subBgColor){
        !this._destroyed && themePatternsFill(translatePos, ctx, this.props, subBgColor);
    };

    p.percentage = function(){
        return this.props.percentage;
    };

    p.objects = function(){
        return this.props.objects;
    };

    p.destroy = function(){
        this._destroyed = true;
        var props = this.props;
        for (var key in props){
            props[key] = null;
            delete props[key];
        }
    };

    themeMod('theme-class', ThemeClass);

})(subPixel);
