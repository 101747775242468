/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.verticalDash.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('verticalDash', 30, 34, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(21, 30);
        ctx.lineTo(21, 23);
        ctx.lineTo(20, 23);
        ctx.lineTo(20, 30);
        ctx.lineTo(21, 30);
        ctx.moveTo(25, 30);
        ctx.lineTo(26, 30);
        ctx.lineTo(26, 28);
        ctx.lineTo(25, 28);
        ctx.lineTo(25, 30);
        ctx.moveTo(6, 28);
        ctx.lineTo(5, 28);
        ctx.lineTo(5, 30);
        ctx.lineTo(6, 30);
        ctx.lineTo(6, 28);
        ctx.moveTo(16, 30);
        ctx.lineTo(16, 28);
        ctx.lineTo(15, 28);
        ctx.lineTo(15, 30);
        ctx.lineTo(16, 30);
        ctx.moveTo(10, 6);
        ctx.lineTo(10, 13);
        ctx.lineTo(11, 13);
        ctx.lineTo(11, 6);
        ctx.lineTo(10, 6);
        ctx.moveTo(11, 23);
        ctx.lineTo(10, 23);
        ctx.lineTo(10, 30);
        ctx.lineTo(11, 30);
        ctx.lineTo(11, 23);
        ctx.moveTo(16, 17);
        ctx.lineTo(15, 17);
        ctx.lineTo(15, 24);
        ctx.lineTo(16, 24);
        ctx.lineTo(16, 17);
        ctx.moveTo(10, 17);
        ctx.lineTo(10, 19);
        ctx.lineTo(11, 19);
        ctx.lineTo(11, 17);
        ctx.lineTo(10, 17);
        ctx.moveTo(5, 17);
        ctx.lineTo(5, 24);
        ctx.lineTo(6, 24);
        ctx.lineTo(6, 17);
        ctx.lineTo(5, 17);
        ctx.moveTo(1, 23);
        ctx.lineTo(0, 23);
        ctx.lineTo(0, 30);
        ctx.lineTo(1, 30);
        ctx.lineTo(1, 23);
        ctx.moveTo(1, 17);
        ctx.lineTo(0, 17);
        ctx.lineTo(0, 19);
        ctx.lineTo(1, 19);
        ctx.lineTo(1, 17);
        ctx.moveTo(6, 11);
        ctx.lineTo(5, 11);
        ctx.lineTo(5, 13);
        ctx.lineTo(6, 13);
        ctx.lineTo(6, 11);
        ctx.moveTo(6, 7);
        ctx.lineTo(6, 0);
        ctx.lineTo(5, 0);
        ctx.lineTo(5, 7);
        ctx.lineTo(6, 7);
        ctx.moveTo(1, 6);
        ctx.lineTo(0, 6);
        ctx.lineTo(0, 13);
        ctx.lineTo(1, 13);
        ctx.lineTo(1, 6);
        ctx.moveTo(1, 0);
        ctx.lineTo(0, 0);
        ctx.lineTo(0, 2);
        ctx.lineTo(1, 2);
        ctx.lineTo(1, 0);
        ctx.moveTo(16, 11);
        ctx.lineTo(15, 11);
        ctx.lineTo(15, 13);
        ctx.lineTo(16, 13);
        ctx.lineTo(16, 11);
        ctx.moveTo(16, 0);
        ctx.lineTo(15, 0);
        ctx.lineTo(15, 7);
        ctx.lineTo(16, 7);
        ctx.lineTo(16, 0);
        ctx.moveTo(11, 2);
        ctx.lineTo(11, 0);
        ctx.lineTo(10, 0);
        ctx.lineTo(10, 2);
        ctx.lineTo(11, 2);
        ctx.moveTo(21, 13);
        ctx.lineTo(21, 6);
        ctx.lineTo(20, 6);
        ctx.lineTo(20, 13);
        ctx.lineTo(21, 13);
        ctx.moveTo(25, 17);
        ctx.lineTo(25, 24);
        ctx.lineTo(26, 24);
        ctx.lineTo(26, 17);
        ctx.lineTo(25, 17);
        ctx.moveTo(21, 19);
        ctx.lineTo(21, 17);
        ctx.lineTo(20, 17);
        ctx.lineTo(20, 19);
        ctx.lineTo(21, 19);
        ctx.moveTo(21, 2);
        ctx.lineTo(21, 0);
        ctx.lineTo(20, 0);
        ctx.lineTo(20, 2);
        ctx.lineTo(21, 2);
        ctx.moveTo(25, 7);
        ctx.lineTo(26, 7);
        ctx.lineTo(26, 0);
        ctx.lineTo(25, 0);
        ctx.lineTo(25, 7);
        ctx.moveTo(26, 11);
        ctx.lineTo(25, 11);
        ctx.lineTo(25, 13);
        ctx.lineTo(26, 13);
        ctx.lineTo(26, 11);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
