/*
 * Project:   PlotPad HTML5 Viewer
 * File:      elements.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 00/20/2014
 */
/*
 * Elements user api
 * */
(function (subPixel, undefined) {
    var inter = subPixel('interface');
    var drawingCtrl = subPixel('drawing-ctrl');
    var elementsCtrl = subPixel('elements-ctrl');
    var parts = subPixel('parts');
    var hoverManager = subPixel('hover-manager');
    var selectManager = subPixel('select-manager');
    var offset = subPixel('offset');
    var canvas = subPixel('canvas');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var mouse = subPixel('mouse');

    var CONST_ANNO_LAYER = 'annotation';
    var THRESHOLD_MOUSE = 3;

    inter.register({
        // set/get elements to engine
        elements: function (elems) {
            if (!subPixel.isStopped() && elems) {
                elementsCtrl.setElements(elems);
            }
            return elementsCtrl.getElements();
        },
        // redraw call
        redraw: function (cb) {
            if (!subPixel.isStopped()) {
                drawingCtrl.redraw(cb);
            } else {
                cb && cb();
            }
        },

        // on/off silent mode for silent add elements
        // and after redraw, if needed
        silentMode: function (val) {
            !subPixel.isStopped() && drawingCtrl.silentMode(val);
        },

        // add new element(s)
        addElement: function (figureInitData) {
            if (!subPixel.isStopped()) {
                var figures;
                if (figureInitData instanceof Array) {
                    figures = [];
                    for (var i = 0, l = figureInitData.length; i < l; i++) {
                        var item = createFigure(figureInitData[i]);
                        item && figures.push(item);
                    }
                } else {
                    figures = [createFigure(figureInitData)];
                }

                figures && figures.length && elementsCtrl.add(figures);
            }
        },

        // remove element(s)
        removeElement: function (ids) {
            if (!subPixel.isStopped()) {
                var toDel = [];
                if (ids && ids.length !== undefined) {
                    for (var i = 0, l = ids.length; i < l; i++) {
                        var id = ids[i];
                        if (typeof id != "object") {
                            pushToDel(toDel, id);
                        } else {
                            // this is object, not id
                            toDel.push(id);
                        }
                    }
                } else {
                    for (var key in ids) {
                        pushToDel(toDel, key);
                    }
                }
                toDel.length && elementsCtrl.remove(toDel);
            }
        },

        // update element drawn
        updateElement: function (id, data) {
            if (!subPixel.isStopped()) {
                var el = elementsCtrl.getElementById(id);
                if (el) {
                    //:todo add changes to history
                    el.dropDrawn();
                    el.updateFigure(data);
                    drawingCtrl.redraw();
                }
            }
        },

        // checking existing element by id
        isElementExist: function (id) {
            var el = elementsCtrl.getElementById(id);
            return !!el;
        },

        // set reindex position of element
        reindexPosition: elementsCtrl.reindexPosition,

        // call reindex of elements, started from reindexPosition
        reindex: function () {
            !subPixel.isStopped() && elementsCtrl.reindex();
        },

        // return element by id
        getElementById: elementsCtrl.getElementById,

        // set focus to element
        focusElement: function (ids, zoom, cb) {
            var focused = false;
            if (typeof zoom == "function"){
                cb = zoom;
                zoom = undefined;
            }
            if (!subPixel.isStopped()) {
                if (!utils.isArray(ids)) {
                    ids = [ids];
                }
                // multiple
                var bbox = getFullBbox(ids);
                if (bbox.sets) {
                    focused = true;
                    var clip = {
                        x: bbox.minx,
                        y: bbox.miny,
                        w: bbox.maxx - bbox.minx,
                        h: bbox.maxy - bbox.miny
                    };
                    subPixel.zoomTo(clip, zoom).then(cb);
                    clip = null;
                } else {
                    cb && cb();
                }
                bbox = null;
            } else {
                cb && cb();
            }
            return focused;
        },

        // select elements by id's
        selectElements: function (id) {
            if (!subPixel.isStopped()) {
                subPixel.selectTool('select');
                selectManager.selectElements(id);
                drawingCtrl.drawSelection();
            }
        },

        getSelectedElements: function () {
            if (!subPixel.isStopped()) {
                return selectManager.getSelected();
            }
        },

        getSelectedElementsIds: function () {
            if (!subPixel.isStopped()) {
                return selectManager.getSelectedIds();
            }
        },

        getAllAnnotationElements: function () {
            return elementsCtrl.getElementsByLayerName(CONST_ANNO_LAYER);
        },

        clearSelection: function (id) {
            if (!subPixel.isStopped()) {
                selectManager.clear();
                drawingCtrl.drawForeground();
            }
        },

        // hover elements by id's
        hoverElements: function (id) {
            if (!subPixel.isStopped()) {
                subPixel.hideTooltips();
                hoverManager.dropHovers();
                id && hoverManager.hoverElements(id);
            }
        },

        // processing access to remove elements
        setCheckRemoveCb: function (cb) {
            elementsCtrl.setCheckRemoveCb(cb);
        },

        enableCheckRemove: function () {
            elementsCtrl.enableCheckRemove();
        },

        disableCheckRemove: function () {
            elementsCtrl.disableCheckRemove();
        },


        // processing reindex elements
        processDynamicElements: function (cb) {
            if (!subPixel.isStopped()) {
                var els = elementsCtrl.getElements();
                var object = elementsCtrl.objectIndex();
                if (els.length) {
                    for (var i = object; i < els.length; i++) {
                        cb(els[i]);
                    }
                }
            }
        },

        getElementsNearMouse: function (val) {
            var screenValPx = val ? val : THRESHOLD_MOUSE;
            var posMin = canvas.getTransformedPoint(mouse.x - screenValPx, mouse.y - screenValPx);
            var posMax = canvas.getTransformedPoint(mouse.x + screenValPx, mouse.y + screenValPx);

            return getElementsByClip(posMin, posMax);
        },
        getElementsByMousePos: function () {
            var pos = canvas.getTransformedPoint(mouse.x, mouse.y);

            return getElementsByClip(pos, pos);
        },
        getCanvasMousePos: function () {
            return canvas.getTransformedPoint(mouse.x, mouse.y);
        },

        dropElements: function () {
            if (!subPixel.isStopped()) {
                elementsCtrl.drop();
                elementsCtrl.reindexPosition(0);
                canvas.dropModelBbox();
            }
        }
    });

    function getElementsByClip(posMin, posMax) {
        //get mouse pointer position
        var w = posMax.x - posMin.x;
        var h = posMax.y - posMin.y;

        var bboxClip = {
            minx: posMin.x,
            maxx: posMax.x,
            miny: posMin.y,
            maxy: posMax.y,
            w: posMax.x - posMin.x,
            h: posMax.y - posMin.y,
            x: posMin.x + w / 2,
            y: posMin.y + h / 2
        };


        var elements = subPixel.elements();

        var figs = [];
        for (var i = 0, l = elements.length; i < l; i++) {
            var item = elements[i];
            if (item.inSelectionArea(bboxClip)) {
                figs.push(item);
            }
        }
        return figs;
    }


    function pushToDel(toDel, id) {
        var el = elementsCtrl.getElementById(id);
        if (el) {
            toDel.push(el);
        }
    }

    // create figure by init data
    function createFigure(figureInitData) {
        var fig;
        if (figureInitData) {
            var constructor = parts(figureInitData.name);
            if (constructor) {
                fig = new constructor(figureInitData);
            }
        }
        return fig;
    }

    function getFullBbox(ids) {
        var bbox = {
            sets: false
        };
        utils.createBboxSctruct(bbox);
        utils.map(ids, function (id) {
            var el = elementsCtrl.getElementById(id);
            if (el && el.canDraw()) {
                var elBbox = el.getBoundingBox();
                bbox.minx = Math.min(elBbox.minx, bbox.minx);
                bbox.maxx = Math.max(elBbox.maxx, bbox.maxx);
                bbox.miny = Math.min(elBbox.miny, bbox.miny);
                bbox.maxy = Math.max(elBbox.maxy, bbox.maxy);
                bbox.sets = true;
            }
        });
        return bbox;
    }


})(subPixel);
