/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base.js
 * Author:    Anton Ayvarov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/24/2017
 */
(function(subPixel){

    var editor = subPixel('editor');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var canvas = subPixel('canvas');
    var drawingCtrl = subPixel('drawing-ctrl');
    var broadcast = subPixel('broadcast');
    var userApiEvents = subPixel('user-api-events');
    var area = editor('area');
    var parts = subPixel('parts');
    var crossingHelper = parts('crossing-helper');
    var modes = editor('editing-modes');
    var BasePartClass = parts('base');

    var CONST_ACCESS_KEY = BasePartClass.A_EDIT_MODE;

    var groupEditingEvs = broadcast.events('group-editing', {
        changed: 'changed'
    });

    userApiEvents(groupEditingEvs.changed, 'editor-ge-changed');

    function BaseEditingClass() {
        this.currFig = null;

        this.groupEditing = {
            enable: true,
            x: 0,
            y: 0,
            ex: 0,
            ey: 0,
            dx: 0,
            dy: 0
        };
        this.rotation = {
            angle: 0,
            translateX: 0,
            translateY: 0
        };

        this.drawBbox = null;

        this.hoverRegions = {};
        this.regionSize = 20;
        this.regionMargin = 50;
        this.regionsPos = null;

        this.clearBox = {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            min: this.regionSize + 10,
            max: (this.regionSize + 10) * 2
        };
        this.colorGroupEdit = null;

        this.moved = false;
        this.isSizing = false;
        this.isRotating = false;
        this.working = false;
        this.mouseClicked = false;

        this.init();
    }

    utils.extendClass(BaseEditingClass, {
        start: function () {
            this.working = true;
            this.clearBox.x = 0;
            this.clearBox.y = 0;
            this.clearBox.w = 0;
            this.clearBox.h = 0;
        },
        stop: function () {
            this.working = false;
            this.mouseClicked = false;
            this.processFig(null);
        },
        onMouseEnd: function () {
            if (this.moved && this.currFig) {
                this.setDimensions();
                this.isSizing = false;
                this.isRotating = false;
                this.processFig(this.currFig);
                this.detectRegionPos();
            }
            this.moved = false;
        },
        onMouseMove: function () {
            if (this.currFig) {
                this.moved = true;
                this.calculateHelpers();
                this.draw();
            }
        },
        onMouseStart: function () {
            this.isSizing = false;
            this.isRotating = false;
            this.moved = false;
            this.mouseClicked = true;
            this.detectRegionPos();

            if (this.regionsPos && this.regionsPos == 0) {
                this.isRotating = true;
            } else if (this.regionsPos) {
                this.isSizing = true;
            }
        },
        setColorGroup: function(color) {
            this.colorGroupEdit = color;
        },
        init: function () {
        },
        updateSingleReg: function (pos, x, y, w, h) {
            var minx = Math.min(x, x - w / 2);
            var miny = Math.min(y, y - h / 2);
            var maxx = Math.max(x, x + w / 2);
            var maxy = Math.max(y, y + h / 2);
            var ww = Math.abs(w);
            var hh = Math.abs(h);
            var reg = this.hoverRegions[pos] = this.hoverRegions[pos] || {};

            reg.x = minx;
            reg.y = miny;
            reg.w = ww;
            reg.h = hh;
            reg.minx = minx;
            reg.miny = miny;
            reg.maxx = maxx;
            reg.maxy = maxy;
        },
        processFig: function (fig) {
            if (fig != this.currFig && this.currFig){
                broadcast.trig(groupEditingEvs.changed, this.currFig);
            }

            if (fig && fig.canEdit()){
                if (this.currFig != fig){
                    drawingCtrl.redraw();
                }
                this.currFig = fig;
                this.rotation.angle = this.currFig.rotationAngle();

                this.updateDrawBox();
                this.calculateHelpers();
                this.draw();
            } else {
                if (this.currFig){
                    this.currFig.setAccess(CONST_ACCESS_KEY, false);
                    drawingCtrl.redraw();
                }
                this.currFig = null;
                this.regionsPos = null;
                this.rotation.angle = 0;
                //drop
            }
        },
        drawRegions: function (ctx) {
            for (var key in this.hoverRegions){
                var ret = this.hoverRegions[key];
                if (key == 0) {
                    var r = ret.w / 2;
                    ctx.arc(ret.x + r, ret.y + r, r, 2 * Math.PI, false);
                } else {
                    ctx.strokeRect(ret.x, ret.y, ret.w, ret.h);
                    ctx.rect(ret.x, ret.y, ret.w, ret.h);
                }
            }
        },
        detectRegionPos: function () {
            this.regionsPos = null;
            var mouseHover = {
                minx:0,
                miny:0,
                maxx:0,
                maxy:0
            };
            mouseHover.minx = mouseHover.maxx = mouse.x;
            mouseHover.miny = mouseHover.maxy = mouse.y;

            for (var key in this.hoverRegions){
                var reg = this.hoverRegions[key];
                if (crossingHelper.isBoxInBox(mouseHover, reg)){
                    this.regionsPos = key;
                }
            }
        },
        clearDraw: function () {
            area.clear(this.clearBox);
        },
        updateClearBox: function () {
            if (this.currFig.canRotate()) {
                var maxSize = (this.drawBbox.w >= this.drawBbox.h) ? this.drawBbox.w : this.drawBbox.h;
                this.clearBox.x = this.groupEditing.ex - (maxSize / 2) - this.regionMargin - 3;
                this.clearBox.y = this.groupEditing.ey - (maxSize / 2) - this.regionMargin - 3;
                this.clearBox.w = maxSize * 4 + this.regionMargin + 3;
                this.clearBox.h = maxSize * 4 + this.regionMargin + 3;
            } else {
                this.clearBox.x = this.groupEditing.ex - this.clearBox.min;
                this.clearBox.y = this.groupEditing.ey - this.clearBox.min;
                this.clearBox.w = this.drawBbox.w + this.clearBox.max;
                this.clearBox.h = this.drawBbox.h + this.clearBox.max;
            }
        },
        checkExist: function () {
            if (this.currFig){
                var id = this.currFig.getId();
                var isExist = !!subPixel.getElementById(id);
                if (!isExist){
                    this.processFig(null);
                }
            }
        },
        getExternalParams: function () {
            return {
                moved: this.moved,
                isSizing: this.isSizing,
                isRotating: this.isRotating,
                currFig: this.currFig,
                regionsPos: this.regionsPos,
                hoverRegions: this.hoverRegions,
                colorGroupEdit: this.colorGroupEdit
            };
        },
        drawBoundingBoxRect: function (ctx, x, y) {
            ctx.strokeRect(x, y, this.drawBbox.w, this.drawBbox.h);

            if(this.currFig.canRotate()) {
                var halfW = this.drawBbox.w / 2;
                var halfReg = this.regionSize / 2;
                var xx = x + halfW;
                var yy = y - this.regionMargin + halfReg;
                var w = 0;
                var h = this.regionMargin - halfReg;
                // not line because artifacts are drawn
                ctx.strokeRect(xx, yy, w, h);
            }
        }
    });


    modes('base', BaseEditingClass);

})(subPixel);
