/*
 * Project:   PlotPad HTML5 Viewer
 * File:      move-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/06/2013
 */
(function(subPixel, undefined){
    // exportable modules
    var broadcast = subPixel('broadcast');
    var canvas = subPixel('canvas');
    var utils = subPixel('utils');
    var mouse = subPixel('mouse');
    var portion = subPixel('portion');
    var offset = subPixel('offset');
    var scale = subPixel('scale');
    var hoverManager = subPixel('hover-manager');
    var history = subPixel('history');
    var logger = subPixel('logger')('move-ctrl');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var drawingCtrl;
    var elementsCtrl;

    var working = false;

    // design controller for export
    var moveCtrl = {};

    // transformers flags
    var scaling = false;


    var firstMove = true;
    var transformed = {
        working: false,
        currState: null
    };

    function canWork(){
        return !scaling;
    }

    // detect start transformations for history
    function onTransformStart(){
        if (!transformed.working){
            transformed.working = true;
            //push undo
            var x = -offset.x;
            var y = -offset.y;
            var scaleVal = scale.val;
            transformed.currState = history.pushUndo(12, function(){
                onHistoryTransform(x, y, scaleVal);
            });
        }
    }

    // process history transform
    function onHistoryTransform(x, y, scaleVal){

        canvas.setZoom(scaleVal * 100);
        canvas.setOffset(x, y);

        drawingCtrl.redraw();
    }

    // detect end transformations for history
    function onTransformEnd(){
        if (transformed.working && firstMove && !scaling){
            transformed.working = false;
            // push redo
            var x = -offset.x;
            var y = -offset.y;
            var scaleVal = scale.val;
            history.pushRedo(transformed.currState, function(){
                onHistoryTransform(x, y, scaleVal);
            });
            transformed.currState = null;
        }
    }

    // processing hover
    function onMouseMove(ev){
        if (!mouse.down && canWork()){
            onMouseMoveHover(ev);
        }
    }

    function onMouseMoveHover(ev){
        if (!drawingCtrl.isDrawing()){
            hoverManager.hover(ev);
        }
    }

    // processing mouse move event and draw canvas as it need
    function onTapMove(){
        if (mouse.down){
            if (firstMove) {
                firstMove = false;
                onTransformStart();
                drawingCtrl.doMoveStart();
            }
            drawingCtrl.doMove();
        }
    }

    //processing mouse up
    function onTapEnd(){
        if (!firstMove){
            firstMove = true;
            drawingCtrl.doMoveStop();
            onTransformEnd();
        }
    }

    // processing mouse down
    function onTapStart(ev){
        onMouseMoveHover(ev);
        firstMove = true;
    }

    function doScaleStart(){
        if (!scaling){
            onTransformStart();
            scaling = true;
            drawingCtrl.doScaleStart.apply(drawingCtrl, arguments);
        }
    }

    // processing scale stop, update elements after scale
    function onScaleStop(){
        if (scaling){
            scaling = false;
            hoverManager.dropHovers();
            drawingCtrl.doScaleStop.apply(drawingCtrl, arguments);
            onTransformEnd();
        }
    }

    function doScale(){
        if (scaling){
            drawingCtrl.doScale.apply(drawingCtrl, arguments);
        }
    }

    // initialize

    // bind events by action
    //
    // start working design controller
    function start() {
        drawingCtrl = subPixel('drawing-ctrl');
        elementsCtrl = subPixel('elements-ctrl');
        working = true;

        scaling = false;

        scenarioCtrl.runDefaultScenario();
    }

    // stop working design controller
    function stop(){
        moveCtrl.drop();
        working = false;
    }

    function onHoverEnd(){
        hoverManager.endHovers();
    }

    function onHover(){
        drawingCtrl.drawHover();
    }


    moveCtrl.canWork = canWork;

    moveCtrl.drop = function(){
        if (!working){
            return this;
        }

        elementsCtrl.drop();
        drawingCtrl.drop();

        scaling = false;
        return this;
    };

    moveCtrl.scena = {
        onScaleStart: doScaleStart,
        onScale: doScale,
        onScaleStop: onScaleStop,

        onTapStart: onTapStart,
        onTapMove: onTapMove,
        onTapEnd: onTapEnd,

        onHover: onHover,
        onHoverEnd: onHoverEnd,
        onMouseMove: onMouseMove,
        onMouseMoveHover: onMouseMoveHover
    };

    utils.bindStartStop(start, stop);

    // register design controller module
    subPixel('move-ctrl', moveCtrl);

})(subPixel);
