/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.dots.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('dots', 28, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#000000";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(24.95, 28);
        ctx.lineTo(24.95, 27);
        ctx.lineTo(23.95, 27);
        ctx.lineTo(23.95, 28);
        ctx.lineTo(24.95, 28);
        ctx.moveTo(1, 27);
        ctx.lineTo(0, 27);
        ctx.lineTo(0, 28);
        ctx.lineTo(1, 28);
        ctx.lineTo(1, 27);
        ctx.moveTo(5, 27);
        ctx.lineTo(4.35, 27);
        ctx.lineTo(4, 27);
        ctx.lineTo(4, 28);
        ctx.lineTo(4.35, 28);
        ctx.lineTo(5, 28);
        ctx.lineTo(5, 27);
        ctx.moveTo(8, 28);
        ctx.lineTo(9, 28);
        ctx.lineTo(9, 27);
        ctx.lineTo(8, 27);
        ctx.lineTo(8, 28);
        ctx.moveTo(13, 28);
        ctx.lineTo(13, 27);
        ctx.lineTo(12, 27);
        ctx.lineTo(12, 28);
        ctx.lineTo(13, 28);
        ctx.moveTo(15.95, 27);
        ctx.lineTo(15.95, 28);
        ctx.lineTo(16.95, 28);
        ctx.lineTo(16.95, 27);
        ctx.lineTo(15.95, 27);
        ctx.moveTo(19.95, 27);
        ctx.lineTo(19.95, 28);
        ctx.lineTo(20.95, 28);
        ctx.lineTo(20.95, 27);
        ctx.lineTo(19.95, 27);
        ctx.moveTo(15, 18);
        ctx.lineTo(15, 19);
        ctx.lineTo(16, 19);
        ctx.lineTo(16, 18);
        ctx.lineTo(15, 18);
        ctx.moveTo(16, 24);
        ctx.lineTo(15, 24);
        ctx.lineTo(15, 25);
        ctx.lineTo(16, 25);
        ctx.lineTo(16, 24);
        ctx.moveTo(15.95, 22);
        ctx.lineTo(16.95, 22);
        ctx.lineTo(16.95, 21);
        ctx.lineTo(15.95, 21);
        ctx.lineTo(15.95, 22);
        ctx.moveTo(20, 24);
        ctx.lineTo(19, 24);
        ctx.lineTo(19, 25);
        ctx.lineTo(20, 25);
        ctx.lineTo(20, 24);
        ctx.moveTo(13, 22);
        ctx.lineTo(13, 21);
        ctx.lineTo(12, 21);
        ctx.lineTo(12, 22);
        ctx.lineTo(13, 22);
        ctx.moveTo(12.05, 24);
        ctx.lineTo(11.05, 24);
        ctx.lineTo(11.05, 25);
        ctx.lineTo(12.05, 25);
        ctx.lineTo(12.05, 24);
        ctx.moveTo(13, 16);
        ctx.lineTo(13, 15);
        ctx.lineTo(12, 15);
        ctx.lineTo(12, 16);
        ctx.lineTo(13, 16);
        ctx.moveTo(12.05, 18);
        ctx.lineTo(11.05, 18);
        ctx.lineTo(11.05, 19);
        ctx.lineTo(12.05, 19);
        ctx.lineTo(12.05, 18);
        ctx.moveTo(16.95, 16);
        ctx.lineTo(16.95, 15);
        ctx.lineTo(15.95, 15);
        ctx.lineTo(15.95, 16);
        ctx.lineTo(16.95, 16);
        ctx.moveTo(20, 18);
        ctx.lineTo(19, 18);
        ctx.lineTo(19, 19);
        ctx.lineTo(20, 19);
        ctx.lineTo(20, 18);
        ctx.moveTo(9, 21);
        ctx.lineTo(8, 21);
        ctx.lineTo(8, 22);
        ctx.lineTo(9, 22);
        ctx.lineTo(9, 21);
        ctx.moveTo(8.05, 24);
        ctx.lineTo(7.05, 24);
        ctx.lineTo(7.05, 25);
        ctx.lineTo(8.05, 25);
        ctx.lineTo(8.05, 24);
        ctx.moveTo(4.05, 24);
        ctx.lineTo(3.05, 24);
        ctx.lineTo(3.05, 25);
        ctx.lineTo(4.05, 25);
        ctx.lineTo(4.05, 24);
        ctx.moveTo(1, 21);
        ctx.lineTo(0, 21);
        ctx.lineTo(0, 22);
        ctx.lineTo(1, 22);
        ctx.lineTo(1, 21);
        ctx.moveTo(5, 21);
        ctx.lineTo(4, 21);
        ctx.lineTo(4, 22);
        ctx.lineTo(5, 22);
        ctx.lineTo(5, 21);
        ctx.moveTo(4.05, 18);
        ctx.lineTo(3.05, 18);
        ctx.lineTo(3.05, 19);
        ctx.lineTo(4.05, 19);
        ctx.lineTo(4.05, 18);
        ctx.moveTo(1, 15);
        ctx.lineTo(0, 15);
        ctx.lineTo(0, 16);
        ctx.lineTo(1, 16);
        ctx.lineTo(1, 15);
        ctx.moveTo(5, 15);
        ctx.lineTo(4, 15);
        ctx.lineTo(4, 16);
        ctx.lineTo(5, 16);
        ctx.lineTo(5, 15);
        ctx.moveTo(8, 15);
        ctx.lineTo(8, 16);
        ctx.lineTo(9, 16);
        ctx.lineTo(9, 15);
        ctx.lineTo(8, 15);
        ctx.moveTo(8.05, 18);
        ctx.lineTo(7.05, 18);
        ctx.lineTo(7.05, 19);
        ctx.lineTo(8.05, 19);
        ctx.lineTo(8.05, 18);
        ctx.moveTo(9, 9);
        ctx.lineTo(8, 9);
        ctx.lineTo(8, 10);
        ctx.lineTo(9, 10);
        ctx.lineTo(9, 9);
        ctx.moveTo(8.05, 12);
        ctx.lineTo(7.05, 12);
        ctx.lineTo(7.05, 13);
        ctx.lineTo(8.05, 13);
        ctx.lineTo(8.05, 12);
        ctx.moveTo(4.05, 12);
        ctx.lineTo(3.05, 12);
        ctx.lineTo(3.05, 13);
        ctx.lineTo(4.05, 13);
        ctx.lineTo(4.05, 12);
        ctx.moveTo(1, 9);
        ctx.lineTo(0, 9);
        ctx.lineTo(0, 10);
        ctx.lineTo(1, 10);
        ctx.lineTo(1, 9);
        ctx.moveTo(5, 9);
        ctx.lineTo(4, 9);
        ctx.lineTo(4, 10);
        ctx.lineTo(5, 10);
        ctx.lineTo(5, 9);
        ctx.moveTo(4.05, 6);
        ctx.lineTo(3.05, 6);
        ctx.lineTo(3.05, 7);
        ctx.lineTo(4.05, 7);
        ctx.lineTo(4.05, 6);
        ctx.moveTo(1, 3);
        ctx.lineTo(0, 3);
        ctx.lineTo(0, 4);
        ctx.lineTo(1, 4);
        ctx.lineTo(1, 3);
        ctx.moveTo(3.05, 0);
        ctx.lineTo(3.05, 1);
        ctx.lineTo(4.05, 1);
        ctx.lineTo(4.05, 0);
        ctx.lineTo(3.05, 0);
        ctx.moveTo(5, 3);
        ctx.lineTo(4, 3);
        ctx.lineTo(4, 4);
        ctx.lineTo(5, 4);
        ctx.lineTo(5, 3);
        ctx.moveTo(8.05, 6);
        ctx.lineTo(7.05, 6);
        ctx.lineTo(7.05, 7);
        ctx.lineTo(8.05, 7);
        ctx.lineTo(8.05, 6);
        ctx.moveTo(8.05, 1);
        ctx.lineTo(8.05, 0);
        ctx.lineTo(7.05, 0);
        ctx.lineTo(7.05, 1);
        ctx.lineTo(8.05, 1);
        ctx.moveTo(9, 3);
        ctx.lineTo(8, 3);
        ctx.lineTo(8, 4);
        ctx.lineTo(9, 4);
        ctx.lineTo(9, 3);
        ctx.moveTo(16, 7);
        ctx.lineTo(16, 6);
        ctx.lineTo(15, 6);
        ctx.lineTo(15, 7);
        ctx.lineTo(16, 7);
        ctx.moveTo(16, 12);
        ctx.lineTo(15, 12);
        ctx.lineTo(15, 13);
        ctx.lineTo(16, 13);
        ctx.lineTo(16, 12);
        ctx.moveTo(15.95, 10);
        ctx.lineTo(16.95, 10);
        ctx.lineTo(16.95, 9);
        ctx.lineTo(15.95, 9);
        ctx.lineTo(15.95, 10);
        ctx.moveTo(20, 12);
        ctx.lineTo(19, 12);
        ctx.lineTo(19, 13);
        ctx.lineTo(20, 13);
        ctx.lineTo(20, 12);
        ctx.moveTo(13, 9);
        ctx.lineTo(12, 9);
        ctx.lineTo(12, 10);
        ctx.lineTo(13, 10);
        ctx.lineTo(13, 9);
        ctx.moveTo(12.05, 12);
        ctx.lineTo(11.05, 12);
        ctx.lineTo(11.05, 13);
        ctx.lineTo(12.05, 13);
        ctx.lineTo(12.05, 12);
        ctx.moveTo(12, 3);
        ctx.lineTo(12, 4);
        ctx.lineTo(13, 4);
        ctx.lineTo(13, 3);
        ctx.lineTo(12, 3);
        ctx.moveTo(12.05, 6);
        ctx.lineTo(11.05, 6);
        ctx.lineTo(11.05, 7);
        ctx.lineTo(12.05, 7);
        ctx.lineTo(12.05, 6);
        ctx.moveTo(12.05, 0);
        ctx.lineTo(11.05, 0);
        ctx.lineTo(11.05, 1);
        ctx.lineTo(12.05, 1);
        ctx.lineTo(12.05, 0);
        ctx.moveTo(20, 6);
        ctx.lineTo(19, 6);
        ctx.lineTo(19, 7);
        ctx.lineTo(20, 7);
        ctx.lineTo(20, 6);
        ctx.moveTo(16.95, 3);
        ctx.lineTo(15.95, 3);
        ctx.lineTo(15.95, 4);
        ctx.lineTo(16.95, 4);
        ctx.lineTo(16.95, 3);
        ctx.moveTo(16, 1);
        ctx.lineTo(16, 0);
        ctx.lineTo(15, 0);
        ctx.lineTo(15, 1);
        ctx.lineTo(16, 1);
        ctx.moveTo(20, 0);
        ctx.lineTo(19, 0);
        ctx.lineTo(19, 1);
        ctx.lineTo(20, 1);
        ctx.lineTo(20, 0);
        ctx.moveTo(28, 24);
        ctx.lineTo(27, 24);
        ctx.lineTo(27, 25);
        ctx.lineTo(28, 25);
        ctx.lineTo(28, 24);
        ctx.moveTo(20.95, 22);
        ctx.lineTo(20.95, 21);
        ctx.lineTo(19.95, 21);
        ctx.lineTo(19.95, 22);
        ctx.lineTo(20.95, 22);
        ctx.moveTo(23.95, 22);
        ctx.lineTo(24.95, 22);
        ctx.lineTo(24.95, 21);
        ctx.lineTo(23.95, 21);
        ctx.lineTo(23.95, 22);
        ctx.moveTo(24, 24);
        ctx.lineTo(23, 24);
        ctx.lineTo(23, 25);
        ctx.lineTo(24, 25);
        ctx.lineTo(24, 24);
        ctx.moveTo(23.95, 16);
        ctx.lineTo(24.95, 16);
        ctx.lineTo(24.95, 15);
        ctx.lineTo(23.95, 15);
        ctx.lineTo(23.95, 16);
        ctx.moveTo(20.95, 16);
        ctx.lineTo(20.95, 15);
        ctx.lineTo(19.95, 15);
        ctx.lineTo(19.95, 16);
        ctx.lineTo(20.95, 16);
        ctx.moveTo(24, 18);
        ctx.lineTo(23, 18);
        ctx.lineTo(23, 19);
        ctx.lineTo(24, 19);
        ctx.lineTo(24, 18);
        ctx.moveTo(28, 18);
        ctx.lineTo(27, 18);
        ctx.lineTo(27, 19);
        ctx.lineTo(28, 19);
        ctx.lineTo(28, 18);
        ctx.moveTo(28, 12);
        ctx.lineTo(27, 12);
        ctx.lineTo(27, 13);
        ctx.lineTo(28, 13);
        ctx.lineTo(28, 12);
        ctx.moveTo(23.95, 9);
        ctx.lineTo(23.95, 10);
        ctx.lineTo(24.95, 10);
        ctx.lineTo(24.95, 9);
        ctx.lineTo(23.95, 9);
        ctx.moveTo(20.95, 10);
        ctx.lineTo(20.95, 9);
        ctx.lineTo(19.95, 9);
        ctx.lineTo(19.95, 10);
        ctx.lineTo(20.95, 10);
        ctx.moveTo(24, 12);
        ctx.lineTo(23, 12);
        ctx.lineTo(23, 13);
        ctx.lineTo(24, 13);
        ctx.lineTo(24, 12);
        ctx.moveTo(20.95, 4);
        ctx.lineTo(20.95, 3);
        ctx.lineTo(19.95, 3);
        ctx.lineTo(19.95, 4);
        ctx.lineTo(20.95, 4);
        ctx.moveTo(24, 6);
        ctx.lineTo(23, 6);
        ctx.lineTo(23, 7);
        ctx.lineTo(24, 7);
        ctx.lineTo(24, 6);
        ctx.moveTo(24.95, 3);
        ctx.lineTo(23.95, 3);
        ctx.lineTo(23.95, 4);
        ctx.lineTo(24.95, 4);
        ctx.lineTo(24.95, 3);
        ctx.moveTo(24, 1);
        ctx.lineTo(24, 0);
        ctx.lineTo(23, 0);
        ctx.lineTo(23, 1);
        ctx.lineTo(24, 1);
        ctx.moveTo(28, 0);
        ctx.lineTo(27, 0);
        ctx.lineTo(27, 1);
        ctx.lineTo(28, 1);
        ctx.lineTo(28, 0);
        ctx.moveTo(28, 6);
        ctx.lineTo(27, 6);
        ctx.lineTo(27, 7);
        ctx.lineTo(28, 7);
        ctx.lineTo(28, 6);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
