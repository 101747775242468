/*
 * Project:   PlotPad HTML5 Viewer
 * File:      interface.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 09/29/2013
 */
/*
* user api interface
* */
(function(subPixel, undefined){

    var logger = subPixel('logger')('interface');

    function defineInSub(name, method) {
        if (!subPixel[name]){
            subPixel[name] = method;
        } else {
            logger.info('method already defined', name, method);
        }
    }

    subPixel('interface', {
        // register new method in subPixel namespace for USER API
        register: function(name, method){
            if (typeof name == "string"){
                defineInSub(name, method);
            } else {
                for (var key in name){
                    var method = name[key];
                    defineInSub(key, method);
                }
            }
        },
        // deregister defined method in subPixel namespace for USER API
        deregister: function(name) {
            if (subPixel[name]){
                subPixel[name] = null;
                delete subPixel[name];
            } else {
                logger.info('method is not defined, or wrong handler', name);
            }
        }
    });

})(subPixel);
