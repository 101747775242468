/*
 * Project:   PlotPad HTML5 Viewer
 * File:      editor.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/06/2014
 */
/*
* communicate between design-ctrl and other elements of editor (tools, etc...)
* */
(function(subPixel, undefined){

    var utils = subPixel('utils');
    var elementsCtrl = subPixel('elements-ctrl');
    var scenarioCtrl = subPixel('scenario-ctrl');
    var broadcast = subPixel('broadcast');
    var toolboxEvs = broadcast.events('editor-tools');
    var keysEvs = broadcast.events('keyboard');
    var mouseEvs = broadcast.events('mouse');
    var config = subPixel('config');
    var zoomWindow = subPixel('zoom-window');
    var parts = subPixel('parts');
    var AnimateCtrlClass = subPixel('animate-ctrl-class');
    var logger = subPixel('logger')('editor');


    // const for bind/unbind events only for editor
    var CONST_NS_EDIT_MODE = '.editor-plugin';


    // current tool instance
    var currentTool;
    var currentScenario;

    // modules of editor (toolobox, etc...)
    var modules = {};

    // tools module
    var tools;

    // area module
    var area;


    function editor(name, mod){
        //define module in editor
        if (!mod){
            mod = modules[name];
        } else {
            if (modules[name]){
                logger.error('module "'+ name +'" already defined');
            } else {
                modules[name] = mod;
            }
        }
        return mod;
    }

    // flags for current tool
    var mouseWorks = false;

    var tapWorking = false;
    var isCanvas = {
        'canvas': true,
        'CANVAS': true
    };


    var animCtrl = AnimateCtrlClass();

    // processing mouse tap up
    function onMouseTapUp(e){
        if (currentScenario && tapWorking && mouseWorks){
            animCtrl.stopRender();
            currentScenario.onMouseEnd(e);
            tapWorking = false;
        }
    }

    // process tap move event, draw some graphics by tool for example
    function onMouseTapMove(e){
        if (currentScenario && mouseWorks && tapWorking){
            currentScenario.onMouseMove(e);
        }
    }

    // process mouse move event without tap
    function onMouseMove(e){
        if (currentScenario) {
            currentScenario.onMouseFreeMove(e);
        }
    }


    // processing tap start, prepare drawing for example
    function onMouseTapDown(ev){
        if (currentScenario && (isCanvas[ev.target.nodeName])){
            tapWorking = true;
            currentScenario.onMouseStart(ev);
            var tool = currentScenario.getTool();
            animCtrl.changeCb(function () {
                tool.onToolDrawing();
            });
            animCtrl.startRender();
        }
    }

    // processing mouse double tap
    function onMouseDoubleTap(e){
        if (currentScenario && tapWorking && mouseWorks) {
            currentScenario.onMouseDblTap(e)
        }
    }

    // processing changing current tool
    function onToolSelect(toolName){
        stopEditor();
        var tool = tools.getTool(toolName);
        mouseWorks = false;
        area.clear();
        if (currentTool != tool){
            if (tool){
                setCurrentTool(tool);
                dropMode();
                var scenario = scenarioCtrl.view;
                switch (currentTool.mode){
                    case scenarioCtrl.select:
                        editMode();
                        scenario = currentTool.mode;
                        break;
                    case scenarioCtrl.edit:
                        editMode();
                        scenario = currentTool.mode;
                        break;
                    case scenarioCtrl.not_move:
                        scenario = currentTool.mode;
                        break;
                }
                scenarioCtrl.switchScenarios(scenario);
            } else {
                dropCurrentTool();
            }
        }
    }

    // processing remove active tool
    function onToolDeselect(){
        dropCurrentTool();
        stopEditor();
        scenarioCtrl.switchScenarios(scenarioCtrl.view);
    }

    function onKeyProcess(ev){
        if (ev.keyCode === 46){ // delete key
            elementsCtrl.removeSelected();
        } else if (ev.keyCode === 27 && currentScenario){ // esc key
            currentScenario.finishDrawing();
        }
        currentScenario.accurateDrawing(ev.isShift);
    }

    function onKeyDown(ev){
        if (currentScenario) {
            currentScenario.accurateDrawing(ev.isShift);
        }
    }

    // set engine to edit mode
    function editMode(){
        area
            .clear()
            .attach();
        zoomWindow.attachDraw(area.place);
        broadcast
            .on([mouseEvs.move, mouseEvs.tapmove], CONST_NS_EDIT_MODE, onMouseTapMove)
            .on(mouseEvs.move, CONST_NS_EDIT_MODE, onMouseMove)
            .on(mouseEvs.tapend, CONST_NS_EDIT_MODE, onMouseTapUp)
            .on(mouseEvs.tapstart, CONST_NS_EDIT_MODE, onMouseTapDown)
            .on(mouseEvs.dblTap, CONST_NS_EDIT_MODE, onMouseDoubleTap);
    }

    function dropMode(){
        zoomWindow.detachDraw();
        area.detach();
        broadcast.off(CONST_NS_EDIT_MODE);
    }

    // bind/unbind events
    function processBroadcast(action){
        broadcast
            [action](keysEvs.down, onKeyDown)
            [action](keysEvs.up, onKeyProcess)
            [action](toolboxEvs.select, onToolSelect)
            [action](toolboxEvs.deselect, onToolDeselect);

    }


    function hideZoom(){
        zoomWindow.hide();
    }

    function showZoom(){
        // currentTool must be defined for this method, if not, methods not correct working
        // and you need to find problem not in this place =)
        if (currentTool.zoomWindow){
            zoomWindow.show(currentTool.zoomWindow);
        }
    }

    editor.startDraw = function(){
        if (!mouseWorks){
            mouseWorks = true;
            area.clear();
            showZoom();
        }
    };

    function stopEditor(){
        // :todo change ways to stop_editor/select_tool/deselect_tool/etc..., need code cleanup!!!!
        mouseWorks = false;
        hideZoom();
        // if can create
        area.clear();
    }

    editor.stopDraw = function() {
        var elementsCtrlEd = editor('elements');
        if (mouseWorks){
            stopEditor();
            // if can create element -> create element, draw to bg, draw to canvas, hide areaCanvas
            var params = currentTool.createFigureData();
            if (params){
                // checking new structure
                var data = currentTool.processFigureData(params);
                if (data){
                    var parnName = currentTool.partName() || currentTool.name();
                    var constructor = parts(parnName);
                    var fig = new constructor(data);
                    elementsCtrlEd.add(fig);
                    currentTool.onFigureAdded(fig);
                }
            }
        }
    };


    function setCurrentTool(tool){
        currentTool = tool;
        dropCurrScenario();
        currentScenario = tool.getScenario();
    }

    function dropCurrScenario(){
        currentScenario && currentScenario.drop();
    }

    function dropCurrentTool(){
        currentTool = null;
        dropCurrScenario();
        currentScenario = null;
    }


    function start(){
        tools = editor('tools');
        area = editor('area');
        processBroadcast('on');
    }

    function stop(){
        animCtrl.stopRender();
        broadcast.off(CONST_NS_EDIT_MODE);
        currentTool && currentTool.onToolDeselect();
        stopEditor();
        dropCurrentTool();
        processBroadcast('off');
    }

    utils.bindStartStop(start, stop);

    subPixel('editor', editor);
})(subPixel);
