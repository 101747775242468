/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/29/2016
 */
(function(subPixel, undefined){

    var coll = {};

    subPixel('theme-module', function (name, mod) {
        if (mod){
            // set
            if (!coll[name]){
                coll[name] = mod;
            }
        }
        return coll[name];
    });

})(subPixel);
