/*
 * Project:   PlotPad HTML5 Viewer
 * File:      tools-helper.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/29/2016
 */
(function(subPixel, undefined){
    subPixel('tools-helper', {
        getScaledValue:  function(points, def){
            var scaleVal = (points[0].scale - 0) || 1;
            var ret = def / scaleVal;
            return ret;
        }
    });
})(subPixel);
