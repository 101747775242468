/*
 * Project:   PlotPad HTML5 Viewer
 * File:      scale.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/03/2013
 */
/*
 * scale transformer module
 * */
(function(subPixel, undefined){
    // exportable modules
    var broadcast = subPixel('broadcast');
    var utils = subPixel('utils');
    var config = subPixel('config');
    var mouse = subPixel('mouse');
    var mouseEvs = broadcast.events('mouse');
    // register events in storage
    var scaleEvs = broadcast.events('scale', {
        onUpdated: 'onUpdated',
        onZoomExtend: 'onZoomExtend'
    });


    var pixelRatio = 1;// / pixelRatio;
    var CONST_FACTOR = 0.1;
    var MAX_SCALE = 100; // 10000%
    var MIN_SCALE = 0.1; // 10%
    var defLineWidth = pixelRatio;
    var roundValue = MAX_SCALE;

    var usingFingers = false;
    var fingersHelper = {
        distance: 1,
        old: 1
    };

    // scale object external
    var scale = {
        val: 1,
        defLineWidth: defLineWidth,
        lineWidth: pixelRatio,
        max: MAX_SCALE, // 10000%
        min: MIN_SCALE, // 10%,

        calcMinScale: function (w, h, sw, sh) {
            var newScaleX = w / sw;
            var newScaleY = h / sh;
            var newScale = Math.min(newScaleX, newScaleY);
            if (sw == 0 && sh == 0){
                newScale = 0;
            }
            newScale = scale.floorVal(newScale);
            return newScale;
        },
        setScale: function (val) {
            scale.val = val;
            var lineWidth = roundT((pixelRatio / (scale.val)), scale.max);
            (lineWidth > defLineWidth) && (lineWidth = defLineWidth);
            scale.lineWidth = lineWidth;
            updateIndicator();
        },
        floorVal: function (val) {
            var ret = Math.floor(val * roundValue) / roundValue;
            return ret;
        },
        getNewValue: function () {
            var newScale;
            if (mouse.fingers > 1) {
                newScale = calculatePitchScale();
            } else {
                newScale = calculateWheelScale();
            }
            newScale = scale.floorVal(newScale);

            // checking new scale value for range
            if (newScale >= scale.max) {
                newScale = scale.max;
            }
            if (newScale <= scale.min) {
                newScale = scale.min;
            }

            return newScale;
        },
        getPercentValue: function () {
            return Math.floor((scale.val * 100));
        }
    };

    function fingersChanged(){
        fingersHelper.distance = mouse.distance;
        fingersHelper.old = scale.val;
    }

    function calculateWheelScale(){
        var vector = mouse.vector;
        var oldScale = scale.val;
        var dryFactor = CONST_FACTOR * vector;

        var factorDx = dryFactor;
        var testVal = oldScale;

        if (testVal > 1){
            factorDx = factorDx * (testVal);
        } else {
            factorDx = factorDx / 2;
        }


        var newScale = oldScale + factorDx;
        return newScale;
    }

    function calculatePitchScale(){
        if (fingersHelper.distance == 0){
            fingersHelper.distance = mouse.distance;
        }
        var perc = mouse.distance / fingersHelper.distance;
        if (isNaN(perc)){
            perc = 0;
        }
        perc = perc * fingersHelper.old;
        return perc;
    }

    function roundT(val, R){
        R = R || roundValue;
        return Math.round(val * R) / R;
    }

    function updateIndicator(){
        var loader = config.indicators.scale;
        if (loader) {
            loader.innerHTML = scale.getPercentValue() + ' %';
        }
        broadcast.trig(scaleEvs.onUpdated);
    }

    function processBroadcast(action){
        if (usingFingers){
            broadcast
                [action](mouseEvs.fingersChanged, fingersChanged);
        }

    }

    // start working of scale
    function start(){
        processBroadcast('on');
    }

    // stop working of scale
    function stop(){
        processBroadcast('off');
        scale.val = 1;
        updateIndicator();
    }


    //initialize scale module, when system starts or stops
    utils.bindStartStop(start, stop);

    // define calculating scale
    if (utils.support.touch){
        usingFingers = true;
    }

    //register scale module
    subPixel('scale', scale);

})(subPixel);
