// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cafm.css
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/24/2016
 */
.cafm-drop-subpixel-styles .edit-toolbox {
    display: none;
}
.subpixel-container {
    -ms-content-zooming: none;
    /*overflow: hidden;*/
    touch-action: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
