/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme-patterns-fill.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/30/2016
 */
(function(subPixel, undefined){
    var themeMod = subPixel('theme-module');

    function solid(ctx, props){
        ctx.fillStyle = props.foreground;
        ctx.fill();
    }

    function pattern(translatePos, ctx, props, subBgColor){
        // fill bg
        ctx.translate(translatePos.x, translatePos.y);
        ctx.globalCompositeOperation = 'source-over';
        ctx.fillStyle = subBgColor ? subBgColor : props.background;
        ctx.fill();

        //fill pattern
        var pat = ctx.createPattern(props.pattern, 'repeat');
        ctx.globalCompositeOperation = 'xor';
        ctx.fillStyle = pat;
        ctx.fill();

        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = props.foreground;
        ctx.fill();
        ctx.translate(-translatePos.x, -translatePos.y);
    }

    themeMod('theme-patterns-fill', function(translatePos, ctx, props, subBgColor){
        var imgPattern = props.pattern;
        if (imgPattern){
            pattern(translatePos, ctx, props, subBgColor);
        } else {
            solid(ctx, props);
        }
    });

})(subPixel);
