import { drawing2d } from "./index";

const subPixel = drawing2d.subPixel;

class Drawing2dClass {

    start() {
        const queryParams = this._parseLocation();
        const res = JSON.parse(decodeURIComponent(queryParams.data));

        const url = res.document.src;
        const shapes = [res.pin, ... res.shapes || []];

        this.movie = drawing2d.initViewer('.draw-place', { width: "100%" });

        subPixel("utils").enable = true;

        if (url) {
            this._loadPlan(url, () => shapes.length && this.movie.AddShapes(shapes));
        }

        this.movie.OnError = (num, text) => {
            console.log(num, text);
        };
    }

    _loadPlan(url, callback) {
        this.movie.onDocumentLoadComplete = (done) => {
            console.log("onDocumentLoadComplete", done);
        };

        const getTimeInterval = subPixel("time-interval");
        const t = new Date().getTime();
        this.movie.LoadDrawing(url, [], []).then(() => {
            const e = new Date().getTime();
            console.log("done", getTimeInterval(t, e));
            callback && callback();
        });
    };

    _parseLocation(){
        const opt = {};
        let args = location.search;
        if (args[0] === "?") {
            args = args.substring(1);
        }
        args = args + "";
        const list = args.split("&");
        if (list && list.length) {
            for (let i = 0, l = list.length; i < l; i++) {
                const item = list[i] + "";
                const index = item.indexOf("=");
                const key = item.substring(0, index);
                opt[key] = item.substring(index + 1);
            }
        }
        return opt;
    };
}

const instance = new Drawing2dClass();
instance.start();
