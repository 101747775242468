/*
 * Project:   PlotPad HTML5 Viewer
 * File:      text-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/03/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var utils = subPixel('utils');
    var scale = subPixel('scale');

    var TextClass = parts('text');
    var AnnoBaseClass = parts('anno-base');
    var crossingHelper = parts('crossing-helper');

    var SUB_HOVER_COLOR = 'rgba(255, 0, 0, 1)';

    var AnnoTextClass = utils.createChildClass(TextClass, 'textChunkClass', {
        init: function () {
            AnnoTextClass._parent.init.apply(this, arguments);
            AnnoBaseClass.prototype.initAnnoDrawParams.apply(this, arguments);

            this._struct = this.createStruct();
            this.props.rotating = true;
            this.propPointsType(crossingHelper.TYPE_POLY);

            // fix for text drawing
            var bbox = this.getBoundingBox();
            var angle = this.rotationAngle();
            var data = this.data();
            data.position = rotatePoint(data.position.x, data.position.y, bbox.minx + bbox.w / 2, bbox.miny + bbox.h / 2, angle);
        },
        initBase: function () {
            AnnoTextClass._parent.initBase.apply(this, arguments);
            AnnoBaseClass.prototype.initBase.apply(this, arguments);
        },
        prepareParams: function () {
            AnnoTextClass._parent.prepareParams.apply(this, arguments);
            AnnoBaseClass.prototype.prepareParams.apply(this, arguments);

            this._struct = this.createStruct();
        },
        canDraw: function () {
            var ret = AnnoBaseClass._parent.canDraw.apply(this, arguments);
            ret = ret && !this.isAccessEdit();
            return ret;
        },
        drawEditing: function (ctx, x, y, w, h) {
            var data = this.data();
            data.font.rotation = 0;
            var drawParams = this.drawParams;
            ctx.fillStyle = drawParams.strokeStyle;
            ctx.lineWidth = scale.lineWidth;
            var bbox = this.getScreenBounds();
            var align = data.align;
            var baseLine = data.baseline;
            x += TextClass.getXByAlign(w, align);
            y += TextClass.getYByBaseline(h, baseLine);

            var eProps = {
                x: x,
                y: y,
                scaleValX: scale.val * w / bbox.w,
                scaleValY: scale.val * h / bbox.h
            };
            this.drawText(ctx, data, null, eProps);
        },
        setDimensions: function(newX, newY, newW, newH, newAngle) {
            var initData = this.data();
            var screenBbox = this.getScreenBounds();

            var align = initData.align;
            var baseLine = initData.baseline;

            var oldSize = initData.font.size;
            var oldW = screenBbox.w;
            var dxw = newW - oldW;

            var k = oldSize / oldW;
            initData.font.size = oldSize + k * dxw;

            var bbox = this.getBoundingBox();

            this.init(initData);

            var pos = initData.position;
            newX += TextClass.getXByAlign(bbox.w, align);
            newY += TextClass.getYByBaseline(bbox.h, baseLine);
            pos.x = newX;
            pos.y = newY;

            this.rotationAngle(newAngle);

            this.init(initData);
        },
        createStruct: function () {
            var data = this.data();
            var bPoints = data.points;
            var angle = this.rotationAngle();
            return {
                points: [
                    bPoints[0]
                ],
                position: data.position,
                name: data.name,
                id: data.id,
                font: data.font,
                styles: data.styles,
                text: data.text,
                angle: angle
            };
        },
        getStruct: function () {
            return this._struct;
        },
        objectId: function() {
            return this.data().id;
        },
        onHover: function() {
            var bbox = this.getBoundingBox();
            var ctx = canvas.buffCtx;
            var angle = this.rotationAngle();

            ctx.save();

            canvas.rotateAroundPoint(ctx, angle, bbox.minx + bbox.w / 2, bbox.miny + bbox.h / 2);

            ctx.beginPath();
            ctx.strokeStyle = SUB_HOVER_COLOR;
            ctx.strokeRect(bbox.minx, bbox.miny, bbox.w, bbox.h);
            ctx.closePath();

            ctx.restore();
        }
    });

    function rotatePoint(x, y, cx, cy, angle) {
        var dx = x - cx;
        var dy = y - cy;

        var newX = cx + dx * Math.cos(-angle) - dy * Math.sin(-angle);
        var newY = cy + dx * Math.sin(-angle) + dy * Math.cos(-angle);

        return {
            x: newX,
            y: newY
        };
    }

    AnnoTextClass.drawText = TextClass.drawText;


    parts('anno-text', AnnoTextClass);


})(subPixel);
