/*
 * Project:   PlotPad HTML5 Viewer
 * File:      history.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/06/2014
 */
/*
* history module for engine
*
* undo/redo - this is methods for manipulate current history state
* pushUndo/pushRedo - this is methods for create new history state
*
* */
(function(subPixel, undefined){

    var utils = subPixel('utils');

    var logger = subPixel('logger')('history');

    var historyStates = [];

    // 0 - unlimited, other value more than 0, will be applied
    var MAX_STATES = 10;

    // current position of history
    var currentIndex = -1;

    var isEnable = true;

    // states
    var CONST_REDO = "redo";
    var CONST_UNDO = "undo";

    // current called state
    var stateCalled = CONST_REDO;

    // when call undo/redo, some actions can be called bu pushers, we need prevent this actions
    var preventPush = false;

    // if undo/redo called flag, helper for pushers
    var manipulated = false;

    var historyPixel = subPixel('history', {});

    historyPixel.codes = {
        ADD_NEW: 10,//'add new element(s)',
        REMOVE: 11,//'remove element(s)',
        TRANSFORM: 12 // 'drag/zoom canvas'
    };

    // add new state with defined undo and redo actions
    historyPixel.push = function(code, undo, redo){
        var currState = this.pushUndo(code, undo);
        this.pushRedo(currState, redo);
        return this;
    };

    // push undo only action
    historyPixel.pushUndo = function(code, callback){
        if (preventPush || !isEnable){
            return;
        }
        var currState = {
            code: code,
            undo: callback
        };
        return currState;
    };

    // push redo only action
    historyPixel.pushRedo = function(currState, callback){
        if (preventPush || !isEnable){
            return;
        }
        if (currState){
            if (manipulated){
                if (stateCalled == CONST_REDO){
                    currentIndex++;
                }
                historyStates.length = currentIndex;
                manipulated = false;
            }

            stateCalled = CONST_REDO;

            currState.redo = callback;
            historyStates.push(currState);
            if (MAX_STATES > 0 && historyStates.length > MAX_STATES){
                historyStates.splice(0, 1);
            }
            currentIndex = historyStates.length - 1;
        } else {
            //error
            logger.error('currState for redo action is not defined');
        }
        return this;
    };

    // set maxStates of history
    historyPixel.setMaxStates = function(val){
        if (val && (typeof val == "number")){
            MAX_STATES = val;
        }
    };

    // undo action
    historyPixel.undo = function(){
        if (isEnable){
            var pos;
            if (stateCalled == CONST_REDO){
                pos = currentIndex;
            } else {
                currentIndex--;
                pos = currentIndex;
            }
            stateCalled = CONST_UNDO;

            if (pos > -1){
                manipulated = true;
                var undoState = historyStates[pos];

                // we need set prevent push for do not add new state to history
                preventPush = true;
                undoState.undo && undoState.undo();
                preventPush = false;
            } else {
                currentIndex = 0;
            }
        }
        return this;
    };

    // redo action
    historyPixel.redo = function(){
        if (isEnable){
            var pos;
            if (stateCalled == CONST_UNDO){
                pos = currentIndex;
            } else {
                currentIndex++;
                pos = currentIndex;
            }
            stateCalled = CONST_REDO;

            if (pos <= historyStates.length - 1){
                manipulated = true;
                var redoState = historyStates[pos];

                // we need set prevent push for do not add new state to history
                preventPush = true;
                redoState.redo && redoState.redo();
                preventPush = false;
            } else {
                currentIndex = historyStates.length - 1;
            }
        }
        return this;
    };

    // clear history
    historyPixel.clear = function(){
        historyStates.length = 0;
        currentIndex = -1;

    };

    // change state of history
    historyPixel.enable = function(val){
        if (val !== undefined){
            isEnable = val;
        }
        return isEnable;
    };

    // return states
    historyPixel.getStates = function(){
        return historyStates;
    };

    // define history state for add/remove elements
    historyPixel.createElementsPoints = function(code, historyState, reverse){
        if (!isEnable){
            return;
        }
        var forDel = [];
        for (var i = 0, l = historyState.length; i < l; i++){
            forDel.push(historyState[i].id);
        }

        function undo(){
            // create undo point for add elements
            subPixel.removeElement(forDel);
        }
        function redo(){
            // create redo point for add elements
            subPixel.addElement(historyState);
        }

        if (!reverse){
            historyPixel.push(code, undo, redo);
        } else {
            // reverse for remove elements
            historyPixel.push(code, redo, undo);
        }
    }


})(subPixel);
