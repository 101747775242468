/*
 * Project:   PlotPad HTML5 Viewer
 * File:      dom-canvas-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/18/2016
 */
(function (subPixel) {
    var utils = subPixel('utils');
    var config = subPixel('config');
    var mouse = subPixel('mouse');
    var scale = subPixel('scale');
    var offset = subPixel('offset');
    var broadcast = subPixel('broadcast');
    var drawingCtrlEvs = broadcast.events('drawing-ctrl');
    var canvasEl = subPixel('canvas-el');
    var domHelper = subPixel('dom-helper');
    var engineDom = subPixel('engine-dom');
    var logger = subPixel('logger')('dom-canvas-ctrl');
    // logger.setAsMain(true);

    var canvas;
    var drawingCtrl;
    var isUsingHelperMatrix = false;
    var isScaled = false;
    var isScaling = false;
    var isMoving = false;

    var buffCtx;
    var oldScale = 1;

    var AnimateCtrlClass = subPixel('animate-ctrl-class');

    var container;
    var place;

    var helperDragPoint;
    var helperMovePoint;

    var buffDragPoint;
    var buffMovePoint;

    var mxDrag = {
        x: 0,
        y: 0
    };

    var cachedMouse = {
        x: 0,
        y: 0
    };

    var animationControl = AnimateCtrlClass(render);

    var canvasMatrixHelper = (function () {
        var helperBbox = {};
        utils.createBboxSctruct(helperBbox);
        helperBbox.minx = 0;
        helperBbox.maxx = 100;
        helperBbox.miny = 0;
        helperBbox.maxy = 100;
        helperBbox.w = 100;
        helperBbox.h = 100;

        var el = canvasEl(
            {name: 'dom-canvas-ctrl', trackTransforms: true, boundingBox: helperBbox, w: 100, h: 100, className: 'subpixel-dom-helper-canvas can-click-area'}
        );
        return el;
    })();

    var helperCtx = canvasMatrixHelper.getCtx();
    var helperCanvas = canvasMatrixHelper.getEl();

    function onContextMenu(e){
        e.preventDefault();
    }

    function startRender(){
        if (!isUsingHelperMatrix){
            isUsingHelperMatrix = true;
            canvasMatrixHelper.setDimensions(offset.width, offset.height);

            animationControl.startRender();
            // domHelper.addClass(container, 'subpixel-helper-animate');
        }
    }

    function endDrawing(){
        if (!isScaling && !isMoving){
            isUsingHelperMatrix = false;

            // drop helper
            // draw helper

            helperCtx.setTransform(1,0,0,1,0,0);
            canvasMatrixHelper.setDimensions(offset.width, offset.height);
            // helperCtx.drawImage(canvas.backgroundCanvas, 0, 0);

            // domHelper.removeClass(container, 'subpixel-helper-animate');
            animationControl.stopRender();

            oldScale = scale.val;

            offset.mx = 0;
            offset.my = 0;


            render();
        }
    }

    function render(){
        // helper
        var helperMovePoint = helperCtx.transformedPoint(0,0);

        setCSSTransform(-helperMovePoint.x, -helperMovePoint.y, 0, 0, scale.val / oldScale, 0);
    }

    function callDraw() {
        if (!isScaling && !isMoving){
            canvas.updateBbox();
            // subPixel('portion').setTimeout(3000);
            if (isScaled){
                drawingCtrl.redraw();
                isScaled = false;
            } else {
                drawingCtrl.draw();
            }
        }
    }

    function updateCachedMouse(x, y) {
        cachedMouse.x = mouse.x;
        cachedMouse.y = mouse.y;

        if (x !== undefined && y !== undefined){
            var newX = Math.floor(parseFloat(x));
            var newY = Math.floor(parseFloat(y));
            if (!isNaN(newX) && !isNaN(newY)){
                cachedMouse.x = newX;
                cachedMouse.y = newY;
            }
        }
    }

    var domCanvasCtrl = {
        drawImage: function(dst, src, sx, sy, sw, sh, dx, dy, dw, dh) {
            // ie 10 requires non negative source position
            dx = (sx < 0) ? dx - sx : 0;
            dy = (sy < 0) ? dy - sy : 0;
            sx = Math.max(0, sx);
            sy = Math.max(0, sy);

            dst.drawImage(src,
                sx, sy,
                sw, sh,
                dx, dy,
                dw, dh
            );
        },

        isWorking: function () {
            return isUsingHelperMatrix;
        },

        drawFromHelper: function (ctx, x, y, w, h, sx, sy, sw, sh) {

            var edgePoint = helperCtx.transformedPoint(x, y);
            var dimPoint = helperCtx.transformedPoint(x + w, y + h);

            helperCtx.clearRect(edgePoint.x, edgePoint.y, dimPoint.x-edgePoint.x, dimPoint.y-edgePoint.y);
            helperCtx.drawImage(canvas.backgroundCanvas, 0, 0);

            this.drawImage(ctx, helperCanvas,
                x, y,
                w, h,
                sx, sy,
                sw, sh
            );
        },

        doMove: function () {
            updateCachedMouse();
            // helper movements
            helperMovePoint = helperCtx.transformedPoint(cachedMouse.x, cachedMouse.y);
            helperCtx.translate(helperMovePoint.x - helperDragPoint.x, helperMovePoint.y - helperDragPoint.y);

            // buff movements
            buffMovePoint = buffCtx.transformedPoint(cachedMouse.x, cachedMouse.y);
            buffCtx.translate(buffMovePoint.x - buffDragPoint.x, buffMovePoint.y - buffDragPoint.y);
            canvas.updateBbox();
        },
        doMoveStart: function () {
            updateCachedMouse();
            startRender();
            isMoving = true;

            // helper
            helperDragPoint = helperMovePoint = helperCtx.transformedPoint(cachedMouse.x, cachedMouse.y);

            // buff
            buffDragPoint = buffMovePoint = buffCtx.transformedPoint(cachedMouse.x, cachedMouse.y);

            mxDrag.x = cachedMouse.x;
            mxDrag.y = cachedMouse.y;
        },
        doMoveStop: function () {
            updateCachedMouse();
            isMoving = false;

            var dx = cachedMouse.x - mxDrag.x;
            var dy = cachedMouse.y - mxDrag.y;

            offset.mx += dx;
            offset.my += dy;

            callDraw();
        },

        doScale: function (newVal, x, y) {
            updateCachedMouse(x, y);
            isScaled = true;
            var oldScale = scale.val;
            var newScale;
            if (newVal && !isNaN(newVal - 0)){
                newScale = parseFloat(newVal);
            } else {
                newScale = scale.getNewValue();
            }
            scale.setScale(newScale);

            // helper
            setScale(helperCtx, oldScale, newScale);

            // buff
            setScale(buffCtx, oldScale, newScale);
            canvas.updateBbox();
        },
        doScaleStart: function (x, y) {
            updateCachedMouse(x, y);
            startRender();
            isScaling = true;
        },
        doScaleStop: function () {
            updateCachedMouse();
            isScaling = false;
            callDraw();
        }
    };

    function setScale(ctx, oldScale, newScale) {
        var p = ctx.transformedPoint(cachedMouse.x, cachedMouse.y);
        ctx.translate(p.x, p.y);
        ctx.scale(1/oldScale, 1/oldScale);
        ctx.scale(newScale, newScale);
        ctx.translate(-p.x, -p.y);
        p = null;
    }

    // this is method need to change canvas position.
    function setCSSTransform(offsetx, offsety, centerX, centerY, scaleVal, rotateVal){
        domHelper.setCSSTransform(container, offsetx, offsety, centerX, centerY, scaleVal, rotateVal);
    }


    function start() {
        container = engineDom.getHelperElement();
        // container.appendChild(canvasMatrixHelper.getEl());
        canvas = subPixel('canvas');
        buffCtx = canvas.buffCtx;
        drawingCtrl = subPixel('drawing-ctrl');

        place = engineDom.getCanvasElement();
        oldScale = 1;

        place.addEventListener("contextmenu", onContextMenu, false);
        place.setAttribute('crossOrigin','anonymous');
        container.addEventListener("contextmenu", onContextMenu, false);

        broadcast.on(drawingCtrlEvs.endDrawing, endDrawing);

        helperCtx.setTransform(1,0,0,1,0,0);
    }


    function stop() {
        animationControl.stopRender();
        place && place.removeEventListener("contextmenu", onContextMenu, false);
        container && container.removeEventListener("contextmenu", onContextMenu, false);

        broadcast.off(drawingCtrlEvs.endDrawing, endDrawing);

        isUsingHelperMatrix = false;
        isScaled = false;
        isScaling = false;
        isMoving = false;

    }

    utils.bindStartStop(start, stop);


    subPixel('dom-canvas-ctrl', domCanvasCtrl);


})(subPixel);
