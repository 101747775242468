/*
 * Project:   PlotPad HTML5 Viewer
 * File:      drawing-ctrl.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/18/2016
 */
(function (subPixel) {

    var portion = subPixel('portion');
    var canvas = subPixel('canvas');
    var elementsCtrl = subPixel('elements-ctrl');
    var sm = subPixel('select-manager');
    var hoverManager = subPixel('hover-manager');
    var broadcast = subPixel('broadcast');
    var offsetEvs = broadcast.events('offset');
    var offset = subPixel('offset');
    var utils = subPixel('utils');
    var selectionHelper;
    var config = subPixel('config');
    var logger = subPixel('logger')('drawing-ctrl');
    var timeInfo = subPixel('time-info')('drawing-ctrl');
    var domCanvasCtrl = subPixel('dom-canvas-ctrl');

    var drawingCtrlEvs = broadcast.events('drawing-ctrl',{
        startDrawing: 'startDrawing',
        endDrawing: 'endDrawing'
    });

    // cache for check draw element
    var drawnElementsCache = [];

    // when silent is on, redraw is not working
    var isSilent = false;

    var usePriorityHover = false;

    var drawingIndi;

    // how much elements will drawn in one portion
    var CONST_MAX_ELS_PORTION = 2000;
    var forceDraw = true;

    var CONST_NAMESPACE_EV = ".drawing-ctrl";

    var hoverElementControl = function (el) {
        el.onHover();
    };

    var drawingCtrl = {
        controlHoverElement: function (cb) {
            hoverElementControl = cb;
        },
        drawHoverSelection: function (useHovers) {
            if (this.isDrawing()){
                return;
            }
            var selected = sm.getSelected();
            var hovers = hoverManager.getHovered();
            var selLen = selected.length;
            selLen && selectionHelper.updateDashSelection();
            this.drawForeground(function () {
                if (useHovers) {
                    if (usePriorityHover) {
                        //find priority hover
                        var currPrio = -99999;
                        var drawEl;
                        utils.map(hovers, function (el) {
                            if (el.propPriorityHover() > currPrio) {
                                drawEl = el;
                                currPrio = el.propPriorityHover();
                            }
                        });
                        drawEl && onElementHover(drawEl);
                    } else {
                        utils.map(hovers, onElementHover);
                    }
                }
                utils.map(selected, function (el) {
                    el.drawSelection();
                })
            });
            hovers = null;
            selected = null;
        },
        drawSelection: function () {
            this.drawHoverSelection(false);
        },
        drawHover: function () {
            this.drawHoverSelection(true);
        },
        // draw some elements in canvas as foreground
        drawForeground: function (callback) {
            var canDraw = !this.isDrawing();
            if (canDraw && !subPixel.isStopped()){
                canvas
                    .clearBuffer()
                    .drawBgToBuffer();
                var ret = callback ? callback() : true;
                if (ret !== false) {
                    canvas
                        .clear()
                        .drawBufferToDisplay();
                }
            }
        },
        // redraw all visible elements
        redraw: function (callback) {
            if (isSilent) {
                //do not redraw in silent mode or not working mode
                callback && callback();
                return this;
            }

            if (callback) {
                broadcast.one(drawingCtrlEvs.endDrawing, callback);
            }
            forceDraw = true;
            logger.info('redraw');
            this.draw();
            return this;
        },

        draw: function () {
            asyncPrepareDraw();
            return this;
        },
        isDrawing: function () {
            return predrawPortionInst.isWorking && !isSilent;
        },
        stopDrawing: function () {
            predrawPortionInst
                .stop();

            drawnElementsCache.length = 0;
        },
        // for add/remove elements
        // is is true, when elements added/removed, redraw will NOT calling
        silentMode: function (val) {
            if (val !== undefined){
                isSilent = val;
            }
            return isSilent;
        },
        drop: function () {
            predrawPortionInst
                .stop();

            forceDraw = true;
            canvas.clearAll();

            drawnElementsCache.length = 0;
        },

        doMove: function () {
            domCanvasCtrl.doMove.apply(domCanvasCtrl, arguments);
        },
        doMoveStart: function () {
            predrawPortionInst.stop();
            domCanvasCtrl.doMoveStart.apply(domCanvasCtrl, arguments);
        },
        doMoveStop: function () {
            domCanvasCtrl.doMoveStop.apply(domCanvasCtrl, arguments);
        },

        doScale: function () {
            domCanvasCtrl.doScale.apply(domCanvasCtrl, arguments);
        },
        doScaleStart: function () {
            predrawPortionInst.stop();
            domCanvasCtrl.doScaleStart.apply(domCanvasCtrl, arguments);
        },
        doScaleStop: function () {
            domCanvasCtrl.doScaleStop.apply(domCanvasCtrl, arguments);
        }
    };

    // instance of portion
    var predrawPortionInst = new portion({
        maxPortions: CONST_MAX_ELS_PORTION
    });

    function onElementHover(el) {
        if (el && !el.isAccessSelect()) {
            hoverElementControl(el);
        }
    }

    // processing start drawing of all visible elements in canvas
    function startDrawing() {
        showLoad();
    }

    // processing end drawing of all visible elements in canvas
    function endDrawing() {
        hideLoad();
        forceDraw = false;
        broadcast.trig(drawingCtrlEvs.endDrawing);
    }

    function dropDrawnElsCache() {
        drawnElementsCache.length = 0;
        drawnElementsCache.length = elementsCtrl.getElementsLen();
    }


    // process elements by portion call
    function walkInPortions(start, end, method, elements) {
        var count = 0;
        for (var i = start; i <= end; i++) {
            var el = elements[i];
            if (drawnElementsCache[i]) {
                el[method](true);
                count++;
            } else if (el[method](forceDraw)) {
                drawnElementsCache[i] = 1;
                count++;
            }
        }
        return count;
    }

    // creating async calling prepare draw methods,
    // it was made for stop draw is some event was triggered. javascript have only one thread.
    // subPixel asking after each 2000 elements processing, what we need to do now? stop or draw anyway.
    function createPredrawPortions() {
        predrawPortionInst.clean();

        var count = 0;
        var portionsCount = 0;
        var isDrawing = false;
        var elementsLink = elementsCtrl.getElements();

        dropDrawnElsCache();
        predrawPortionInst.onGetLen(elementsCtrl.getElementsLen);

        predrawPortionInst
            .pushFirst(function () {
                if (isDrawing) {
                    // start was called, but portions process is not finished. last callback not called
                    logger.log('portions done = ' + portionsCount);
                } else {
                    // calls only after last callback was called
                    // this means, that all portions was processed
                    startDrawing();
                    dropDrawnElsCache();
                }
                isDrawing = true;
                count = 0;
                portionsCount = 0;

                // reindex elements only when force draw sets, else, do not reindex
                // because some elements can be not correctly draw in drag actions, if reindex will be called
                forceDraw && elementsCtrl.reindexElements();

                //start processing
                timeInfo.startTimer();
                logger.log('--started drawing---');
                logger.log('forced', forceDraw);
                canvas.clearBuffer();

            }).pushProcess(function (start, end) {
                count = count + walkInPortions(start, end, 'predraw', elementsLink);
                portionsCount++;
            }).pushLast(function () {
                logger.log(timeInfo.portionTimer('last cb drawing started'));
                if (!forceDraw) {
                    logger.log('drawBgToBuffer()', offset.mx, offset.my);
                    canvas.drawBgToBuffer(offset.mx, offset.my);
                }

                //enable this fix, if canvas will use clearRect method for clear canvases
                canvas.clearBg();

                //end processing
                logger.log('predraw portions = ' + portionsCount);
                logger.log(timeInfo.portionTimer('predraw(' + count + ')'));

                canvas.drawBufferToBg();
                logger.log(timeInfo.portionTimer('drawBufferToBg()'));
                canvas.clear();
                logger.log(timeInfo.portionTimer('clear()'));

                canvas.updateBbox();
                canvas.updateMouseOffsetPos();

                canvas.bgToDisplay();
                isDrawing = false;
                dropDrawnElsCache();

                predrawPortionInst.isWorking = false;

                endDrawing();
                drawingCtrl.drawSelection();
                timeInfo.stopTimer();
                var tInfo = timeInfo.getTimerInfo();
                logger.log('asyncPrepareDraw()', tInfo);
                logger.log('--- draw end ---');
            });
    }

    // prepare elements for draw
    // clean bg and draw elements to bg
    function asyncPrepareDraw() {
        if (isSilent) {
            //do not redraw in silent mode
            return;
        }
        broadcast.trig(drawingCtrlEvs.startDrawing);
        predrawPortionInst
            .stop()
            .start();
    }

    function onResize() {
        forceDraw = true;
        asyncPrepareDraw();
    }


    function processBroadcast() {
        broadcast
            .on(offsetEvs.resize, CONST_NAMESPACE_EV, onResize);
    }

    // show 'drawing' indicator
    function showDrawing(show){
        drawingIndi && (drawingIndi.style.display = show ? "block" : "none");
    }

    // show progress indicator
    function showLoad(){
        showDrawing(true);
    }

    // hide progress indicator
    function hideLoad(){
        showDrawing(false);
    }

    subPixel('drawing-ctrl', drawingCtrl);

    function start() {
        usePriorityHover = config.usePriorityHover;
        drawingIndi = config.indicators.drawing;
        selectionHelper = subPixel('selection-helper');

        forceDraw = true;
        processBroadcast();

    }

    function stop() {
        drawingCtrl.stopDrawing();
        broadcast.off(CONST_NAMESPACE_EV);
    }

    createPredrawPortions();

    utils.bindStartStop(start, stop);

})(subPixel);
