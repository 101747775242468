/*
 * Project:   PlotPad HTML5 Viewer
 * File:      elements.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/22/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var elementsCtrl = subPixel('elements-ctrl');
    var utils = subPixel('utils');

    var collection = [];

    var elements = {
        add: function (el) {
            elementsCtrl.add(el);
            collection.push(el);
        },
        remove: function () {

        }
    };

    function processBroadcast(action){

    }

    function start(){
        collection.length = 0;
        processBroadcast('on');
    }

    function stop(){
        collection.length = 0;
        processBroadcast('off');
    }



    utils.bindStartStop(start, stop);

    editor('elements', elements);
})(subPixel);
