/*
 * Project:   PlotPad HTML5 Viewer
 * File:      sheet-load-indicator.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 05/15/2016
 */
(function(subPixel, undefined){
    var broadcast = subPixel('broadcast');
    var config = subPixel('config');
    var utils = subPixel('utils');
    var planLoaderEvs = broadcast.events('sheet-loader');
    var domHelper = subPixel('dom-helper');
    var engineDom = subPixel('engine-dom');

    var pContainer = document.createElement('div');
    pContainer.className = 'subpixel-pl-indicator-container';

    var barContainer = document.createElement('div');
    barContainer.className = 'subpixel-pl-bar';
    pContainer.appendChild(barContainer);

    var textContainer = document.createElement('div');
    textContainer.className = 'subpixel-pl-text';
    pContainer.appendChild(textContainer);

    var isLoading = false;


    function onStartLoad(){
        if (!isLoading){
            barContainer.style.width = 0;
            textContainer.innerHTML = 0 + '%';
            var baseEl = engineDom.getBaseElement();
            baseEl.appendChild(pContainer);
        }
    }

    function onProgress(perc){
        var val = Math.floor(perc) + '%';
        barContainer.style.width = val;
        textContainer.innerHTML = val;
    }

    function onLoaded(){
        isLoading = false;
        domHelper.moveToFragment(pContainer);
    }

    function onError(){
        onLoaded();
    }

    function processBroadcast(action){
        broadcast
            [action](planLoaderEvs.started, onStartLoad)
            [action](planLoaderEvs.progress, onProgress)
            [action](planLoaderEvs.loaded, onLoaded)
            [action](planLoaderEvs.error, onError)

    }

    function start(){
        config.usePlanLoadIndicator && processBroadcast('on');
    }

    function stop(){
        processBroadcast('off');
    }

    utils.bindStartStop(start, stop);

})(subPixel);
