/*
 * Project:   PlotPad HTML5 Viewer
 * File:      mouse-wheel.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/06/2013
 */
/*
 * mouse wheel module
 * */
(function(subPixel, undefined){
    // exportable modules
    var utils = subPixel('utils');
    var broadcast = subPixel('broadcast');
    var mouse = subPixel('mouse');
    var config = subPixel('config');
    var engineDom = subPixel('engine-dom');

    // register events in storage
    var mouseEvs = broadcast.events('mouse', {
        wheel: 'wheel',
        wheelstart: 'wheelstart',
        wheelstop: 'wheelstop'

    });

    var wheelStarted = false;
    var wheelBegin = null;
    var isRotate = false;
    var dxTime = 400;
    var timeId = null;
    var elem;

    // processing wheel start to engine
    function wheelStart(){
        if (wheelStarted && wheelBegin == null) {
            wheelBegin = new Date();
            isRotate
                ? broadcast.trig(mouseEvs.rotatestart)
                : broadcast.trig(mouseEvs.wheelstart);
        }
    }

    // processing wheel stop to engine
    function wheelStop(){
        wheelBegin = null;
        wheelStarted = false;
        isRotate
            ? broadcast.trig(mouseEvs.rotatestop)
            : broadcast.trig(mouseEvs.wheelstop);
        isRotate = false;
    }

    // processing wheeling to engine
    function whelling(){
        if (wheelStarted && wheelBegin !== null){
            isRotate
                ? broadcast.trig(mouseEvs.rotate)
                : broadcast.trig(mouseEvs.wheel);

            clearTimeout(timeId);
            timeId = setTimeout(function(){
                wheelStop();
            }, dxTime);
        }
    }

    // processing mouse wheeling
    // e - DOM event
    function onWheel(e) {
        wheelStarted = true;
        e = e || window.event;
        // wheelDelta не дает возможность узнать количество пикселей
        var delta = e.deltaY || e.detail || e.wheelDelta;
        mouse.vector = (delta > 0) ? -1 : 1;

        var rect = elem.getBoundingClientRect();
        mouse.x = (e.clientX - rect.left);
        mouse.y = (e.clientY - rect.top);

        e.preventDefault();
        if (!isRotate && e.shiftKey){
            isRotate = true;
        }
        wheelStart();
        whelling();
    }

    // start catching event from mouse wheel
    function initWheel(){
        //mouse wheel
        elem = engineDom.getBaseElement();
        if ('onwheel' in document) {
            // IE9+, FF17+
            elem.addEventListener ("wheel", onWheel, false);
        } else if ('onmousewheel' in document) {
            // устаревший вариант события
            elem.addEventListener ("mousewheel", onWheel, false);
        }
    }

    // stop catching event from mouse wheel
    function stop(){
        if ('onwheel' in document) {
            // IE9+, FF17+
            elem.removeEventListener ("wheel", onWheel, false);
        } else if ('onmousewheel' in document) {
            // устаревший вариант события
            elem.removeEventListener ("mousewheel", onWheel, false);
        }
        elem = null;
    }

    //initialize mouse wheel module, when system starts or stops
    utils.bindStartStop(initWheel, stop);


    //this module don't need register in subPixel, because this module triggers only events

})(subPixel);
