/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/05/2014
 */
(function(subPixel, undefined){
    var config = subPixel('config');
    var editor = subPixel('editor');
    var tools = editor('tools');
    var engineDom = subPixel('engine-dom');

    function BaseContainerClass(){
        var base = this._base = document.createElement('div');
        this._fragment = document.createDocumentFragment();

        var el = this._element = document.createElement('div');

        var subContainer = this._subContainer = document.createElement('div');

        this.init.apply(this, arguments);

        // sub containers must be defined last
        base.appendChild(el);
        base.appendChild(subContainer);
    }

    var p = BaseContainerClass.prototype;

    p.init = function(){

    };

    p.element = function(){
        return this._element;
    };

    p.base = function(){
        return this._base;
    };

    p.subContainer = function(){
        return this._subContainer;
    };

    p.buffer = function(){
        return this._fragment;
    };

    p.onStart = function(){
        var baseEl = engineDom.getBaseElement();
        baseEl.appendChild(this.base());
    };

    p.onStop = function(){
        this.buffer().appendChild(this.base());
    };


    tools.placeForTools('baseClass', BaseContainerClass);

})(subPixel);
