/*
 * Project:   PlotPad HTML5 Viewer
 * File:      rotate.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 11/28/2013
 */
/*
 * rotate transformer module
 * */
(function(subPixel, undefined){
    // exportable modules

    // rotate object external
    var rotate = {
        angle: 0
    };

    //register rotate module
    subPixel('rotate', rotate);

})(subPixel);
