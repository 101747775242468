/*
 * Project:   PlotPad HTML5 Viewer
 * File:      anno-base-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/07/2017
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var BasePartClass = parts('base');
    var utils = subPixel('utils');
    var config = subPixel('config');

    var annoConst = subPixel('anno-const');
    var lm = subPixel('layer-manager');

    var broadcast = subPixel('broadcast');
    var userApiEvents = subPixel('user-api-events');
    var annoAddEvs = broadcast.events('anno-create', {
        added: 'added'
    });

    userApiEvents(annoAddEvs.added, 'anno-el-added');

    var CONST_LAYER_GROUP = annoConst.TYPE;
    var CONST_LAYER_NAME = annoConst.TYPE;

    var drawParamsHelper = parts('draw-options-helper');

    var drawParamsObj = drawParamsHelper({
        lineJoin: 'round'
    });

    lm.addLayer(CONST_LAYER_GROUP, CONST_LAYER_NAME);

    var AnnoBaseClass = utils.createChildClass(BasePartClass, 'baseAnnoChunkClass', {
        prepareParams: function (params) {
            AnnoBaseClass._parent.prepareParams.apply(this, arguments);

            params.layerGroup = CONST_LAYER_GROUP;
            params.layerName = CONST_LAYER_NAME;
        },
        initBase: function () {
            AnnoBaseClass._parent.initBase.apply(this, arguments);
            this.props.editing = true;
            this.props.scaling = true;

            // var labelPos = this.props._labelPosition = {
            //     x: undefined,
            //     y: undefined
            // };
            // if (this.labelPosition){
            //     var dataLabelPos = (this.labelPosition + '').split('|');
            //     var labelPosX = (dataLabelPos[0] - 0);
            //     var labelPosY = (dataLabelPos[1] - 0);
            //     !isNaN(labelPosX) && (labelPos.x = labelPosX);
            //     !isNaN(labelPosY) && (labelPos.y = labelPosY);
            // }
        },
        initAnnoDrawParams: function (data) {
            var figStyles = data.styles;
            var drawParams = this.drawParams;

            var color = utils.colorToStr(figStyles.strokeStyle);

            drawParams.lineWidth = figStyles.lineWidth;
            drawParams.strokeStyle = color || "#ccc";
            drawParams.lineJoin = 'round';

            this.propDrawSelection(true);
        },
        onHover: function(){
            // drawParamsObj.fillStyle = config.groupEditColor;
            this.draw(drawParamsObj);
        },
        setAnnoDrawStyles: function (opt) {
            var ctx = canvas.buffCtx;
            var drawParams = this.drawParams;

            ctx.lineWidth = BasePartClass.getLineWidth(drawParams, opt);
            ctx.strokeStyle = BasePartClass.getStrokeStyle(drawParams, opt);
            ctx.lineJoin = BasePartClass.getLineJoin(drawParams, opt);
            // ctx.fillStyle = BasePartClass.setFillStyle(ctx, drawParams, opt);
        },
        setAreaCtx: function(areaCtx){
            var ctx = canvas.buffCtx;

            areaCtx.lineWidth = ctx.lineWidth;
            areaCtx.strokeStyle = ctx.strokeStyle;
            areaCtx.lineJoin = ctx.lineJoin;
        }
        // labels: function(val){
        //     if (val !== undefined){
        //         this.props.labels = val;
        //     }
        //     return this.props.labels;
        // },
        // labelPosition: function(){
        //     return this.props._labelPosition;
        // },
        // themes: function(val){
        //     if (val !== undefined){
        //         if (val === null){
        //             if (utils.isArray(this.props._themes)){
        //                 this.props._themes.length = 0;
        //             }
        //         }
        //         this.themeBgColor(null);
        //         this.props._themes = val;
        //     }
        //     return this.props._themes;
        // }
    });


    parts('anno-base', AnnoBaseClass);

    utils.bindStartStop(function () {
        drawParamsObj.strokeStyle = config.selectionStrokeColor;
        drawParamsObj.lineRatio = config.lineRatio;
    });


})(subPixel);
