// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Project:   PlotPad HTML5 Viewer
 * File:      subpixel-canvas.css
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/16/2016
 */
.subpixel-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -ms-touch-action: none;
    -ms-text-size-adjust: none;
}

.subpixel-scenario-view {
    cursor: move;
    cursor: -webkit-grab;
}

.subpixel-container-helper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    transform-origin: 50% 50% 0;
    -webkit-transform-origin: 50% 50% 0;
    -moz-transform-origin: 50% 50% 0;
    -ms-transform-origin: 50% 50% 0;
}

.subpixel-container-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-image: url(https://www.google.ru/images/srpr/logo11w.png);*/
}

.subpixel-helper-animate {
    transition-delay: 0s;
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease;
}

.subpixel-recalc-dom {
    /* hack for immediately recalculate DOM */
    width: 0 !important;
    zoom: 1.001 !important; /*fix for chrome*/
}

.subpixel-bg-canvas {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.subpixel-canvas {
    /*display: none;*/
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform-origin-x: 50%;
    transform-origin-y: 50%;
    transform: translate(0, 0);
}
.subpixel-showLogs,
.subpixel-scale,
.subpixel-drawing-indicator {
    position: absolute;
    left: 2px;
    top: 20px;
    background: white;
    z-index: 10;
    border: 1px solid gray;
    font-size: 11px;
    font-family: tahoma;
    opacity: 0.9;
}

.subpixel-showLogs {
    top: 40px;
    overflow: auto;
    max-width: 150px;
    max-height: 300px;
}

.subpixel-drawing-indicator {
    left: 10px;
    bottom: 10px;
    display: none;
    top: auto;
    border: none;
}

.subpixel-scale {
    left: auto;
    top: auto;
    bottom: 10px;
    right: 10px;
}
.subpixel-pl-indicator-container {
    background: #e5e6e6;
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    text-align: center;
    color: #8a9b9e;
    font-family: verdana;
    font-size: 12px;
}

.subpixel-pl-bar {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #81c6f2;
}
.subpixel-pl-text {
    position: relative;
    line-height: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
