/*
 * Project:   PlotPad HTML5 Viewer
 * File:      layers.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/10/2016
 */
(function(cafm, undefined){
    var subPixel = cafm.subPixel;
    var utils = subPixel('utils');
    var holdover = subPixel('holdover');
    var lm = subPixel('layer-manager');
    var sheetLoader = subPixel('sheet-loader');
    var chunkManager = subPixel('chunk-manager');
    var helpers = cafm.helpers;

    var drawingGroup = chunkManager.TYPE_DRAWN;

    var processGroups = [
        drawingGroup //,
        // chunkManager.TYPE_OBJECT
    ];

    cafm.GetLayers = function(){
        var ret = helpers.getManifestList('layers');
        return ret;
    };

    cafm.IsLoaded = function(layerName){
        layerName = layerName + '';
        var ret = lm.isLayerVisible(drawingGroup, layerName);
        return ret;
    };

    cafm.GetGroupsNames = function(){
        var ret = helpers.getManifestList('groups');
        return ret;
    };

    cafm.LoadLayers = function (){
        var layers = helpers.convertToArray(arguments);
        return sheetLoader.loadLayersAndGroups(layers, null, null);
    };

    cafm.UnLoadLayers = function(){
        var layers = helpers.convertToArray(arguments);
        return unloadLayers(layers);
    };

    cafm.LoadAllLayers = function(){
        var layers = cafm.GetLayers();
        return sheetLoader.loadLayersAndGroups(layers, null, null);
    };

    cafm.UnLoadAllLayers = function(){
        return unloadLayers();
    };
    
    function unloadLayers(layers) {
        layers = layers || null;
        sheetLoader.dropDrawings(layers);
        return lm.holderHide(processGroups, layers);
    }
})(cafm);