/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.herringbone.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('herringbone', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.3380279541015625, 0, 0, 0.338226318359375, 0, 0);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#282425";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(0, 88.7);
        ctx.lineTo(4.95, 88.7);
        ctx.lineTo(0, 83.75);
        ctx.lineTo(0, 88.7);
        ctx.moveTo(88.75, 17.65);
        ctx.lineTo(88.75, 12.55);
        ctx.lineTo(82.4, 6.2);
        ctx.lineTo(88.6, 0);
        ctx.lineTo(83.65, 0);
        ctx.lineTo(79.9, 3.75);
        ctx.lineTo(76.15, 0);
        ctx.lineTo(71.2, 0);
        ctx.lineTo(86.3, 15.1);
        ctx.lineTo(79.9, 21.5);
        ctx.lineTo(64.6, 6.2);
        ctx.lineTo(70.85, 0);
        ctx.lineTo(65.9, 0);
        ctx.lineTo(53.25, 12.65);
        ctx.lineTo(46.85, 6.2);
        ctx.lineTo(53.05, 0);
        ctx.lineTo(48.1, 0);
        ctx.lineTo(44.35, 3.75);
        ctx.lineTo(40.6, 0);
        ctx.lineTo(35.65, 0);
        ctx.lineTo(50.75, 15.1);
        ctx.lineTo(44.35, 21.5);
        ctx.lineTo(29.05, 6.2);
        ctx.lineTo(35.3, 0);
        ctx.lineTo(30.35, 0);
        ctx.lineTo(17.7, 12.65);
        ctx.lineTo(11.3, 6.2);
        ctx.lineTo(17.55, 0);
        ctx.lineTo(12.6, 0);
        ctx.lineTo(8.8, 3.75);
        ctx.lineTo(5.05, 0);
        ctx.lineTo(0.1, 0);
        ctx.lineTo(15.25, 15.1);
        ctx.lineTo(8.8, 21.5);
        ctx.lineTo(0, 12.7);
        ctx.lineTo(0, 17.65);
        ctx.lineTo(15.25, 32.85);
        ctx.lineTo(8.8, 39.3);
        ctx.lineTo(0, 30.45);
        ctx.lineTo(0, 35.4);
        ctx.lineTo(15.25, 50.65);
        ctx.lineTo(8.8, 57.05);
        ctx.lineTo(0, 48.2);
        ctx.lineTo(0, 53.15);
        ctx.lineTo(15.25, 68.4);
        ctx.lineTo(8.8, 74.8);
        ctx.lineTo(0, 66);
        ctx.lineTo(0, 70.95);
        ctx.lineTo(15.25, 86.2);
        ctx.lineTo(12.7, 88.7);
        ctx.lineTo(17.65, 88.7);
        ctx.lineTo(26.6, 79.75);
        ctx.lineTo(33, 86.2);
        ctx.lineTo(30.45, 88.7);
        ctx.lineTo(35.4, 88.7);
        ctx.lineTo(35.5, 88.65);
        ctx.lineTo(35.55, 88.7);
        ctx.lineTo(40.5, 88.7);
        ctx.lineTo(29.05, 77.3);
        ctx.lineTo(35.45, 70.9);
        ctx.lineTo(50.75, 86.2);
        ctx.lineTo(48.25, 88.7);
        ctx.lineTo(53.2, 88.7);
        ctx.lineTo(62.15, 79.75);
        ctx.lineTo(68.55, 86.2);
        ctx.lineTo(66, 88.7);
        ctx.lineTo(70.95, 88.7);
        ctx.lineTo(71, 88.65);
        ctx.lineTo(71.05, 88.7);
        ctx.lineTo(76, 88.7);
        ctx.lineTo(64.6, 77.3);
        ctx.lineTo(71, 70.9);
        ctx.lineTo(86.3, 86.2);
        ctx.lineTo(83.8, 88.7);
        ctx.lineTo(88.75, 88.7);
        ctx.lineTo(88.75, 83.65);
        ctx.lineTo(82.35, 77.3);
        ctx.lineTo(88.75, 70.95);
        ctx.lineTo(88.75, 65.9);
        ctx.lineTo(82.4, 59.55);
        ctx.lineTo(88.75, 53.15);
        ctx.lineTo(88.75, 48.1);
        ctx.lineTo(82.4, 41.75);
        ctx.lineTo(88.75, 35.4);
        ctx.lineTo(88.75, 30.35);
        ctx.lineTo(82.35, 24);
        ctx.lineTo(88.75, 17.65);
        ctx.moveTo(64.6, 24);
        ctx.lineTo(71, 17.6);
        ctx.lineTo(86.3, 32.85);
        ctx.lineTo(79.9, 39.3);
        ctx.lineTo(64.6, 24);
        ctx.moveTo(62.15, 8.7);
        ctx.lineTo(68.55, 15.1);
        ctx.lineTo(53.25, 30.4);
        ctx.lineTo(46.85, 24);
        ctx.lineTo(62.15, 8.7);
        ctx.moveTo(53.25, 48.15);
        ctx.lineTo(46.85, 41.75);
        ctx.lineTo(62.15, 26.45);
        ctx.lineTo(68.55, 32.85);
        ctx.lineTo(53.25, 48.15);
        ctx.moveTo(50.75, 32.85);
        ctx.lineTo(44.35, 39.3);
        ctx.lineTo(29.05, 24);
        ctx.lineTo(35.45, 17.55);
        ctx.lineTo(50.75, 32.85);
        ctx.moveTo(64.6, 41.75);
        ctx.lineTo(71, 35.35);
        ctx.lineTo(86.3, 50.65);
        ctx.lineTo(79.9, 57.05);
        ctx.lineTo(64.6, 41.75);
        ctx.moveTo(26.6, 8.7);
        ctx.lineTo(33, 15.1);
        ctx.lineTo(17.7, 30.4);
        ctx.lineTo(11.3, 24);
        ctx.lineTo(26.6, 8.7);
        ctx.moveTo(17.7, 48.15);
        ctx.lineTo(11.3, 41.75);
        ctx.lineTo(26.6, 26.45);
        ctx.lineTo(33, 32.85);
        ctx.lineTo(17.7, 48.15);
        ctx.moveTo(29.05, 41.75);
        ctx.lineTo(35.5, 35.35);
        ctx.lineTo(50.75, 50.65);
        ctx.lineTo(44.35, 57.05);
        ctx.lineTo(29.05, 41.75);
        ctx.moveTo(26.6, 62);
        ctx.lineTo(33, 68.4);
        ctx.lineTo(17.7, 83.7);
        ctx.lineTo(11.3, 77.3);
        ctx.lineTo(26.6, 62);
        ctx.moveTo(29.05, 59.55);
        ctx.lineTo(35.5, 53.1);
        ctx.lineTo(50.75, 68.4);
        ctx.lineTo(44.35, 74.8);
        ctx.lineTo(29.05, 59.55);
        ctx.moveTo(17.7, 65.95);
        ctx.lineTo(11.3, 59.55);
        ctx.lineTo(26.6, 44.25);
        ctx.lineTo(33, 50.65);
        ctx.lineTo(17.7, 65.95);
        ctx.moveTo(64.6, 59.55);
        ctx.lineTo(71, 53.1);
        ctx.lineTo(86.3, 68.4);
        ctx.lineTo(79.9, 74.8);
        ctx.lineTo(64.6, 59.55);
        ctx.moveTo(62.15, 62);
        ctx.lineTo(68.55, 68.4);
        ctx.lineTo(53.25, 83.7);
        ctx.lineTo(46.85, 77.3);
        ctx.lineTo(62.15, 62);
        ctx.moveTo(53.25, 65.95);
        ctx.lineTo(46.85, 59.55);
        ctx.lineTo(62.15, 44.25);
        ctx.lineTo(68.55, 50.65);
        ctx.lineTo(53.25, 65.95);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
