/*
 * Project:   PlotPad HTML5 Viewer
 * File:      line-chunk.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/13/2014
 */
(function(subPixel, undefined){

    var canvas = subPixel('canvas');
    var parts = subPixel('parts');
    var AnnoBaseClass = parts('anno-base');
    var utils = subPixel('utils');
    var scale = subPixel('scale');
    var crossingHelper = parts('crossing-helper');

    var figure = utils.createChildClass(AnnoBaseClass, 'lineChunkClass');

    figure.drawLine = function(ctx, points){
        var fromx = points[0].x;
        var fromy = points[0].y;

        var tox = points[1].x;
        var toy = points[1].y;

        ctx.beginPath();
        ctx.moveTo(fromx, fromy);
        ctx.lineTo(tox, toy);
        ctx.stroke();
        ctx.closePath();
    };

    var p = figure.prototype;

    p.init = function(data){
        this._struct = this.createStruct();
        this.initAnnoDrawParams(data);

        this.initBoundingBox(data.points);

        this.propPointsType(crossingHelper.TYPE_LINE);
    };

    p.drawLine = function(ctx, points){
        figure.drawLine(ctx, points);
    };

    p.draw = function(opt){
        var ctx = canvas.buffCtx;
        var points = this.data().points;

        this.setAnnoDrawStyles(opt);
        this.drawLine(ctx, points);
    };

    p.createStruct = function(){
        var data = this.data();
        var bPoints = data.points;
        return {
            points: bPoints,
            name: data.name,
            id: data.id,
            styles: data.styles
        };
    };

    p.getStruct = function(){
        return this._struct;
    };

    p.objectId = function(){
        return this.data().id;
    };

    parts('anno-line', figure);


})(subPixel);
