/*
 * Project:   PlotPad HTML5 Viewer
 * File:      utils-time.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 10/06/2013
 */
/*
* utils-time module
*
* */
(function(subPixel, undefined){
    // exportable modules
    var utils = subPixel('utils');
    var logger = subPixel('logger')('utils-time');

    // utils time object external
    var subUtils = {};

    // time counter
    var sTime = {
        b:null,
        e:null,
        points:[]
    };

    // just show begin and end line for one block time
    //
    // msg - message for block
    function showBlock(msg){
        logger.log('-------- ' + msg);
    }

    // show single tag in block time
    //
    // tag - tag title
    // time - tag time
    function showTag(tag, time){
        logger.log('-> ' + tag + ' :' + time + 'ms');
    }

    // start block time
    //
    // tag - block tag title
    subUtils.startTime = function(tag){

        sTime.b = new Date();
        if (sTime.points.length != 0){
            logger.error('utils.endTime() was not called before call utils.startTime() !');
        }
        sTime.points.length = 0;
        tag = (tag || "start time");
        sTime.points.push({time: sTime.b, tag: tag});
        showBlock(tag);
        return this;
    };

    // add point to current block time
    //
    // tag - tag title
    subUtils.addPointTime = function(tag){
        sTime.points.push({time: new Date(), tag: tag});
        return this;
    };

    // stop block time
    //
    // tag - tag title
    subUtils.endTime = function(tag){
        function lastTimeLog(){
            showBlock((tag || 'time execute') + ': ' + (sTime.e - sTime.b) + 'ms');
        }
        sTime.e = new Date();
        if (sTime.b) {
            var points = sTime.points;
            tag = (tag || "end time");
            points.push({time: sTime.e, tag: tag});
            showTag((points[0].tag), "-)");
            if (points.length > 2) {
                var last = sTime.b, point;
                for (var i = 1, l = points.length; i < l; i++){
                    point = points[i];

                    showTag(point.tag, (point.time - last));
                    last = point.time;
                }
            } else {
                lastTimeLog();
            }
            lastTimeLog();
        } else {
            logger.error("endTime can't be run early than startTime for '" + tag + "'");
        }
        sTime.points.length = 0;
        return this;
    };

    //just extend utils module, now this methods, defined before, can be accessible from subPixel('utils') module
    utils.extend(utils, subUtils);

})(subPixel);

