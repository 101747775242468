/*
 * Project:   PlotPad HTML5 Viewer
 * File:      group-editing.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/06/2016
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var utils = subPixel('utils');
    var groupEditing = subPixel('group-editing');

    var scenarioCtrl = subPixel('scenario-ctrl');


    var base = tools('base-tool');

    var gEditToolClass = utils.createChildClass(base, 'groupEditTool', {
        init: function () {
            groupEditing.init();
        },
        onToolSelect: function(){
            gEditToolClass._parent.onToolSelect.call(this);
            groupEditing.start();
        },
        onToolDeselect: function(){
            gEditToolClass._parent.onToolDeselect.call(this);
            groupEditing.stop();
        },
        onMouseStart: function(){
            editor.startDraw();
            gEditToolClass._parent.onMouseStart.call(this);
            groupEditing.onMouseStart();
        },
        onMouseMove: function(){
            gEditToolClass._parent.onMouseMove.call(this);
            groupEditing.onMouseMove();
        },
        onMouseEnd: function(){
            gEditToolClass._parent.onMouseEnd.call(this);
            groupEditing.onMouseEnd();
        }
    });

    tools(gEditToolClass, {
        mode: scenarioCtrl.edit,
        zoomWindow: false,
        name: 'groupEdit',
        title: 'edit',
        text: 'edit'
    });

})(subPixel);
