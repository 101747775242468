/*
 * Project:   PlotPad HTML5 Viewer
 * File:      move.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 01/09/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var utils = subPixel('utils');
    var scenarioCtrl = subPixel('scenario-ctrl');

    var base = tools('base-tool');

    var moveTool = utils.createChildClass(base, 'moveTool');

    tools(moveTool, {
        isPrimary: true,
        mode: scenarioCtrl.view,
        name: 'move',
        zoomWindow: false,
        title: 'Move',
        text: 'm'
    });

})(subPixel);
