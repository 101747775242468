/*
 * Project:   PlotPad HTML5 Viewer
 * File:      base-group.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 02/03/2014
 */
(function(subPixel, undefined){
    var editor = subPixel('editor');
    var tools = editor('tools');
    var base = tools('base');
    var utils = subPixel('utils');
    var domHelper = subPixel('dom-helper');

    var BaseToolGroupClass = utils.createChildClass(base, 'baseGroup');

    var p = BaseToolGroupClass.prototype;

    var GROUP_CSS = BaseToolGroupClass.GROUP_CSS = 'tool-group';

    p.defineParams = function(params){
        BaseToolGroupClass._parent.defineParams.call(this, params);

        var name = this.name();

        var el = this.getEl();
        if (!params.el){
            el.className = tools.TOOL_CSS_CLASS + ' '+ GROUP_CSS +' ' + GROUP_CSS + '-' + name;
        } else {
            el = params.el;
        }
        this._el = el;
        el.setAttribute('data-toolname', name);
        this._selChildTool = '';

        var indicatorEl = document.createElement('div');
        indicatorEl.className = 'group-indicator';
        el.appendChild(indicatorEl);
        this.indicator = indicatorEl;

        var container = this.container = document.createElement('div');
        container.className = 'group-container';
        el.appendChild(container);

        var overflow = document.createElement('div');
        overflow.className = 'group-overflow';
        container.appendChild(overflow);

        this._defaultTool = params.childDefaulTool;
        this.setDefaultTool();
    };

    p.append = function(el){
        this.container.appendChild(el);
    };

    p.onToolSelect = function(){
        domHelper.show(this.container);
    };

    p.onToolDeselect = function(){
        this.setIndicator(this._selChildTool);
        updateChildState.call(this);
    };

    p.getSelectedChildTool = function () {
        return this._selChildTool;
    };

    p.setIndicator = function(toolName){
        if (toolName == this.name()){
            this._selChildTool = '';
        } else {
            this._selChildTool = toolName;
        }
        var className = "group-indicator ";
        if (toolName){
            (className += tools.TOOL_CSS_CLASS + '-' + toolName);
        }
        this.indicator.className = className;
        domHelper.hide(this.container);
    };

    p.isGroup = function(){
        return true;
    };

    p.canDeselectOutside = function(){
        return true;
    };

    p.setDefaultTool = function () {
        if (this._defaultTool){
            this.setIndicator(this._defaultTool);
            updateChildState.call(this);
        }
    };

    function updateChildState() {
        var groupEl = this.getEl();
        if (this.getSelectedChildTool()){
            domHelper.addClass(groupEl, 'group-is-used-tool');
        } else {
            domHelper.removeClass(groupEl, 'group-is-used-tool');
        }
    }

    // register tool in toolbox
    tools(BaseToolGroupClass, {
        name: 'base-group',
        visible: false
    });

})(subPixel);
