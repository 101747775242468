/*
 * Project:   PlotPad HTML5 Viewer
 * File:      cache-module.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 04/08/2014
 */
/*
* Cache module for getting data from any storage, what was defined
* this module is async, because request to server is async too, soo we need more close to each
* */

(function(subPixel){

    var utils = subPixel('utils');
    var logger = subPixel('logger')('cache-module');

    var mod = null;
    var errCodes = {};

    function cacheModule(newMod) {
        // register subCacheMod as interface for working with cache with external
        // sub module for working cache
        var oldCodes = errCodes;
        if (newMod){
            mod = newMod;
            utils.clearObject(oldCodes);
            var newCodes = newMod.getCodes();
            for (var key in newCodes){
                oldCodes[key] = newCodes[key];
            }
        }
    }

    // status of module
    var isEnable = false;

    utils.extend(cacheModule, {
        enable: function (val) {
            if (val !== undefined){
                isEnable = val;
            }
            var res = !!(isEnable && mod);
            logger.log("cacheModule.enable.call=" + res);

            return res;
        },
        saveDataToFile: function (url, data, callback) {
            return processModMethod('saveDataToFile', arguments);
        },
        getFile: function (url, cb) {
            return processModMethod('getFile', arguments);
        },
        getFilePath: function (url) {
            return processModMethod('getFilePath', arguments);
        },
        isExist: function (path, cb) {
            return processModMethod('isExist', arguments);
        },
        download: function (url, cb) {
            return processModMethod('download', arguments);
        },
        processUrlForStore: function (url, onProgress, onDone) {
            return processModMethod('processUrlForStore', arguments);
        },
        getCodes: function () {
            return errCodes;
        }
    });

    function processModMethod(method, args) {
        if (mod){
            return mod[method].apply(mod, args);
        }
    }

    // cacheModule.processCachePath = function(url, params, callback){
    //
    // };

    subPixel('cache', cacheModule);

})(subPixel);
