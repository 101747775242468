/*
 * Project:   PlotPad HTML5 Viewer
 * File:      theme.netSlant.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 07/08/2016
 */
(function (subPixel, undefined) {
    var themeMod = subPixel('theme-module');
    var pCreates = themeMod('theme-as-method');

    pCreates.addPattern('netSlant', 30, 30, function (ctx, scale) {
        ctx.save();
        ctx.translate(0, 0);
        ctx.beginPath();

        // ctx.moveTo(0,0);
        // ctx.lineTo(20,0);
        // ctx.lineTo(20,16);
        // ctx.lineTo(0,16);
        // ctx.closePath();
        // ctx.clip();

        ctx.scale(scale, scale);

        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(480.75, 35.65);
        ctx.lineTo(475.8, 35.65);
        ctx.lineTo(463.2, 48.2);
        ctx.lineTo(463.2, 53.15);
        ctx.lineTo(480.75, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(498.5, 35.65);
        ctx.lineTo(493.55, 35.65);
        ctx.lineTo(463.2, 66);
        ctx.lineTo(463.2, 70.95);
        ctx.lineTo(498.5, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(516.25, 35.65);
        ctx.lineTo(511.3, 35.65);
        ctx.lineTo(463.2, 83.75);
        ctx.lineTo(463.2, 88.7);
        ctx.lineTo(516.25, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(534.05, 35.65);
        ctx.lineTo(529.1, 35.65);
        ctx.lineTo(463.2, 101.55);
        ctx.lineTo(463.2, 106.5);
        ctx.lineTo(534.05, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.8, 35.65);
        ctx.lineTo(546.85, 35.65);
        ctx.lineTo(463.2, 119.3);
        ctx.lineTo(463.2, 124.25);
        ctx.lineTo(551.8, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 53.3);
        ctx.lineTo(551.95, 48.35);
        ctx.lineTo(475.9, 124.35);
        ctx.lineTo(480.85, 124.35);
        ctx.lineTo(551.95, 53.3);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 71.05);
        ctx.lineTo(551.95, 66.1);
        ctx.lineTo(493.65, 124.35);
        ctx.lineTo(498.6, 124.35);
        ctx.lineTo(551.95, 71.05);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 88.85);
        ctx.lineTo(551.95, 83.9);
        ctx.lineTo(511.45, 124.35);
        ctx.lineTo(516.4, 124.35);
        ctx.lineTo(551.95, 88.85);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 106.6);
        ctx.lineTo(551.95, 101.65);
        ctx.lineTo(529.2, 124.35);
        ctx.lineTo(534.15, 124.35);
        ctx.lineTo(551.95, 106.6);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 119.4);
        ctx.lineTo(547, 124.35);
        ctx.lineTo(551.95, 124.35);
        ctx.lineTo(551.95, 119.4);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(539.35, 35.65);
        ctx.lineTo(534.4, 35.65);
        ctx.lineTo(551.95, 53.15);
        ctx.lineTo(551.95, 48.2);
        ctx.lineTo(539.35, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(521.55, 35.65);
        ctx.lineTo(516.6, 35.65);
        ctx.lineTo(551.95, 70.95);
        ctx.lineTo(551.95, 66);
        ctx.lineTo(521.55, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(503.8, 35.65);
        ctx.lineTo(498.85, 35.65);
        ctx.lineTo(551.95, 88.7);
        ctx.lineTo(551.95, 83.75);
        ctx.lineTo(503.8, 35.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 106.5);
        ctx.lineTo(551.95, 101.55);
        ctx.lineTo(486.05, 35.65);
        ctx.lineTo(481.1, 35.65);
        ctx.lineTo(551.95, 106.5);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(551.95, 124.25);
        ctx.lineTo(551.95, 119.3);
        ctx.lineTo(468.25, 35.65);
        ctx.lineTo(463.3, 35.65);
        ctx.lineTo(551.95, 124.25);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(534.25, 124.35);
        ctx.lineTo(539.2, 124.35);
        ctx.lineTo(463.2, 48.35);
        ctx.lineTo(463.2, 53.3);
        ctx.lineTo(534.25, 124.35);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(516.5, 124.35);
        ctx.lineTo(521.45, 124.35);
        ctx.lineTo(463.2, 66.1);
        ctx.lineTo(463.2, 71.05);
        ctx.lineTo(516.5, 124.35);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(498.75, 124.35);
        ctx.lineTo(503.7, 124.35);
        ctx.lineTo(463.2, 83.9);
        ctx.lineTo(463.2, 88.85);
        ctx.lineTo(498.75, 124.35);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(463.2, 101.65);
        ctx.lineTo(463.2, 106.6);
        ctx.lineTo(480.95, 124.35);
        ctx.lineTo(485.9, 124.35);
        ctx.lineTo(463.2, 101.65);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.save();
        ctx.transform(0.338043212890625, 0, 0, 0.338226318359375, -156.6, -12.05);
        ctx.save();
        ctx.save();
        ctx.save();
        ctx.fillStyle = "#231f20";
        ctx.strokeStyle = "rgba(0, 0, 0, 0)";
        ctx.beginPath();
        ctx.moveTo(468.15, 124.35);
        ctx.lineTo(463.2, 119.4);
        ctx.lineTo(463.2, 124.35);
        ctx.lineTo(468.15, 124.35);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
        ctx.restore();
    });

})(subPixel);
