/*
 * Project:   PlotPad HTML5 Viewer
 * File:      object-theme-draw.js
 * Author:    Yuri Podoplelov
 * Contact:   support@plotpad.com
 * Copyright: 2015 by Mobile Solutions for Construction, LLC
 *
 * Created on 03/01/2016
 */
(function(subPixel, undefined){
    var canvas = subPixel('canvas');
    var utils = subPixel('utils');
    var offset = subPixel('offset');
    var scale = subPixel('scale');
    var themeMod = subPixel('theme-module');

    var themeTranslatePos = {
        x: 0,
        y: 0
    };

    function createNonScaleClip(ctx){
        ctx.beginPath();

        ctx.translate(offset.x, offset.y);
        ctx.scale(1/scale.val, 1/scale.val);

        ctx.lineWidth = 0;

        this.prepareThemeClip(ctx);

        // do clipped area
        ctx.closePath();
        ctx.clip();
    }

    function updateTPoint(){
        // point for correct draw pattern
        var p1 = canvas.getRetransformedPoint(0, 0);
        themeTranslatePos.x = p1.x;
        themeTranslatePos.y = p1.y;
        p1 = null;
    }


    function drawTheme(themes){
        var self = this;
        var ctx = canvas.buffCtx;
        ctx.save();

        // set scale and clip area
        createNonScaleClip.call(this, ctx);

        updateTPoint();

        var screenBbox = this.getScreenBounds();
        var w = screenBbox.w;
        var h = screenBbox.h;
        var startX = screenBbox.minx;
        var startY = screenBbox.miny;
        var rected = false;

        //draw themes for single object
        var prevPos = startX;
        utils.map(themes, function(theme, index){
            var perc = theme.percentage();
            if (perc){
                ctx.beginPath();
                rected = true;
                var dx = w * perc / 100;
                var pos = prevPos;
                ctx.rect(pos, startY, dx, h);
                prevPos += dx;
            } else if (rected){
                ctx.beginPath();
                ctx.rect(startX, startY, w, h);
                rected = false;
            }

            theme.drawTheme(themeTranslatePos, ctx, self.props._themeBgColor);
        });
        // return to default
        ctx.globalCompositeOperation = 'source-over';

        ctx.closePath();
        ctx.restore();
    }

    themeMod('object-theme-draw', drawTheme)

})(subPixel);
